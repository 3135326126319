import React, { Component } from "react";
import Selectbox from "../Selectbox/selectbox";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { dateToReverseHyphenFormat } from "../../utils/commonutils";
import "./customInput.scss";

export default class CustomInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "text",
      option: [],
      startDate: new Date(),
    };
  }

  //From May,1994 To Till Date
  componentDidMount() {
    if (this.props.antecedantId && this.props.antecedantId === 60) {
      this.setState({ type: "date" });
    } else if (this.props.antecedantId && this.props.antecedantId === 3728) {
      //for period of bank statement , text instead of date
      this.setState({ type: "text" });
    } else {
      this.setType();
    }
  }

  setType = () => {
    let type;
    switch (this.props.type) {
      case "Text Field":
        type = "text";
        break;
      case "Date":
        type = "date";
        break;
      case "Text Area":
        type = "textArea";
        break;
      case "Drop Down":
        type = "dropDown";
        this.formatOption();
        break;

      default:
        type = "text";
    }

    this.setState({ type });
  };

  formatOption = () => {
    let option = this.props.option;
    option =
      option && option.map((option) => ({ label: option, value: option }));
    this.setState({ option });
  };

  months = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };

  formatDate = (date = "") => {
    if (date) {
      date = date.toString();
      if (!date) {
        return "";
      }
      if (!date.includes("-")) {
        if (date.includes(".")) {
          if (date.indexOf(".") < 3) {
            date = date.split(".").reverse().join("-");
          } else {
            date = date.split(".").join("-");
          }
        } else if (date.includes("/")) {
          if (date.indexOf("/") < 3) {
            date = date.split("/").reverse().join("-");
          } else {
            date = date.split("/").join("-");
          }
        } else {
          if (date.indexOf(" ") < 3) {
            const arr = date.split(" ");
            let month = arr.length >= 2 ? arr[1] : "";
            month = this.months[month];
            arr[1] = month;
            date = arr.reverse().join("-");
          } else {
            date = date.split(" ").join("-");
          }
        }
      } else {
        if (date.indexOf("-") < 3) {
          date = date.split("-").reverse().join("-");
        }
      }
    }
    return date ? new Date(date) : null;
  };

  onDateChange = (date) => {
    const hyphenDate = dateToReverseHyphenFormat(date);

    this.props.onChange({ target: { value: hyphenDate } });
  };

  render() {
    const { type } = this.state;
    const {
      value,
      required,
      isDisable = false,
      minlength,
      maxlength,
      uniqueIdForInput,
    } = this.props;
    return (
      <React.Fragment>
        {type === "text" && (
          <input
            type="text"
            id={uniqueIdForInput}
            spellCheck="true"
            className={`form-control text-primary input-section ${
              this.props.isDisable ? "row-disabled" : ""
              } ${this.props.isError ? "border border-danger" : ""}`}
            onChange={(e) => this.props.onChange(e)}
            value={value}
            required={required}
            readOnly={isDisable}
            minlength={minlength}
            maxlength={maxlength}
          ></input>
        )}
        {type === "date" && (
          <DatePicker
            popperModifiers={{
              preventOverflow: {
                enabled: true,
              },
            }}
            dateFormat="dd-MMM-yyyy"
            disabled={isDisable}
            placeholderText="dd-mmm-yyyy"
            selected={this.formatDate(value)}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            className={`form-control text-primary input-section ${
              this.props.isDisable ? "row-disabled" : ""
              } ${this.props.isError ? "border border-danger" : ""}`}
            onChange={(date) => this.onDateChange(date)}
          />
        )}
        {type === "textArea" && (
          <textarea
            className={`form-control transparent-input text-primary input-section w-100 ${
              this.props.isDisable ? "row-disabled" : ""
              } ${this.props.isError ? "border border-danger" : ""}`}
            id={uniqueIdForInput}
            onChange={(e) => this.props.onChange(e)}
            value={value}
            spellCheck="true"
            required={required}
            disabled={isDisable}
            minlength={minlength}
            maxlength={maxlength}
          ></textarea>
        )}
        {type === "dropDown" && (
          <Selectbox
            option={this.state.option}
            onChange={(e) => this.props.onChange(e)}
            defaultValue={{ label: value, value }}
            callback={(selectedPackage) =>
              this.props.onChange({ target: { value: selectedPackage.value } })
            }
            isDisable={isDisable}
          ></Selectbox>
        )}
      </React.Fragment>
    );
  }
}
