import React, { Component } from "react";
import OtpInput from "react-otp-input";
import api from "./../../../apiBaseConfig";
import { CTS_PORTAL_CREDENTIAL, DOWNLOAD_READY_OTP } from "./../../../apiurl";
import { Toast } from "./../../../utils/toastutils";
import Selectbox from "./../../../components/Selectbox/selectbox";
import { modalManager } from "./../../../utils/modalutils";
import "./caseDownloadOtp.scss";

export default class CaseDownloadOtp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
      selectedCredential: {value:this.props.currentUser,label:this.props.currentUser},
      credentials: [],
      isLoader: false,
      isDownloadReadyOtp: false,
    };
  }

  componentDidMount = (props) => {
    this.getPortalCredential();
  };

  getPortalCredential = async () => {
    const response = await api.get(`${CTS_PORTAL_CREDENTIAL}`);
    const credentialsData = response.data.map((data) => {
      return { value: data, label: data };
    });
    this.setState({ credentials: credentialsData });
  };

  setOTP = (otp) => this.setState({ otp });
  sendOtp = async () => {
    const { selectedCredential } = this.state;
    const body = {
      email: selectedCredential.value,
      download_ready: true,
      otp:null,
    };
    try {
        const response = await api.patch(`${DOWNLOAD_READY_OTP}1/`, body);
        Toast.success({
          description: `User Changed to ${body.email}`,
        });
        this.props.updateUser(body.email);
        this.setState({ otp: "" });
        modalManager.close({});
    } catch (e) {}
  };
  submitOtp = async () => {
    const { selectedCredential, otp } = this.state;
    try {
      const response = await api.get(`${DOWNLOAD_READY_OTP}1/`);
      const downloadReady = response.data.download_ready;
      if (downloadReady) {
        this.props.callback(selectedCredential, otp);
      } else {
        Toast.info({ description: "OTP has expired.again request OTP" });
        this.setState({ otp: "", isDownloadReadyOtp: false });
      }
    } catch (e) {}
  };
  render() {
    const {
      otp,
      selectedCredential,
      credentials,
      isLoader,
    } = this.state;
    return (
      <div className="otp-block">
        <div className="otp-select-box">
          <Selectbox
            defaultValue={selectedCredential}
            option={credentials}
            callback={(selectedOption) => {
              debugger
              this.setState({
                selectedCredential: selectedOption,
              });
            }}
            required={false}
            menuHeight={153}
          />
        </div>
        {selectedCredential && selectedCredential.value && (
          <div className="send-otp-button">
            <button
              class="btn btn-primary btn-sm ml-2"
              onClick={(e) => {
                this.sendOtp();
              }}
            >
              CHANGE USER
            </button>
          </div>
        )}
       
        <div className="otp-footer">
          <button
            class="btn btn-danger btn-sm float-left mr-2"
            data-dismiss="modal"
            onClick={() => {
              modalManager.close({});
            }}
          >
            Close
          </button>

        </div>
      </div>
    );
  }
}
