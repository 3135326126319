import { put, takeEvery } from "redux-saga/effects";
import { ActionTypes } from "./login.types";
import api from "../../../apiBaseConfig";
import {SET_CLIENT} from './../../../apiurl'

export function* login({ payload }) {
  try {
    const response = yield api.patch(SET_CLIENT, {
      ...payload
    });
    console.log("login response", response);
    yield put({
      type: ActionTypes.LOGIN_SUCCESS,
      payload: {
        ...response.data
      }
    });
  } catch (error) {
    console.log("Error during login", error);
    yield put({
      type: ActionTypes.LOGIN_FAIL
    });
  }
}

export default function*() {
  yield takeEvery(ActionTypes.LOGIN, login);
}
