import React, { Component } from "react";
import api from "./../../../apiBaseConfig";
import { connect } from "react-redux";
import { update } from "./../../../screens/login/state/login.actions";
import { HCL_EDIT_CASE_DESCRIPTION } from "../../../apiurl";
import CustomInput from "./../../../hcl/components/custumInput/custumInput";
import LoadingScreen from "./../../../components/loadingScreen/loadingScreen";
import { Toast } from "../../../utils/toastutils";
import { modalManager } from "../../../utils/modalutils";
import "./editDescription.scss";

class EditDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caseDetails: {},
      mandatoryFields: [],
      loading: false,
      isDisable: true,
      updatedFields: {},
    };
  }

  componentDidMount = () => {
    this.getInstructionsDetail();
  };

  getInstructionsDetail = async () => {
    const { mandatoryFields } = this.state;
    const { userInfo } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const myToken = urlParams.get("token");
    if (!userInfo.accessToken) {
      this.props.update({ token: myToken });
    }
    try {
      this.setState({ loading: true });
      const response = await api.get(`${HCL_EDIT_CASE_DESCRIPTION}`);
      const instructionDetails = response.data;
      const instructionArr = instructionDetails[0];
      this.setState({
        caseDetails: instructionDetails,
        mandatoryFields: instructionArr,
        loading: false,
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  editDetails = async (e) => {
    const { isDisable, caseDetails, mandatoryFields } = this.state;
    const isEditable = !isDisable;
    this.setState({
      caseDetails: caseDetails,
      mandatoryFields: mandatoryFields,
      isDisable: isEditable,
    });
  };

  submit = async (e) => {
    const {
      isDisable,
      caseDetails,
      mandatoryFields,
      updatedFields,
    } = this.state;
    const isEditable = true;

    try {
      const body = await this.saveData(e);
      const result = await api.post(`${HCL_EDIT_CASE_DESCRIPTION}`, body);
      Toast.success({
        description: "updated successfully",
      });
      modalManager.close({});
      isEditable = false;
    } catch (e) {
      this.setState({ isEditable: false });
      console.log(e);
    }

    this.setState({
      caseDetails: caseDetails,
      mandatoryFields: mandatoryFields,
      updatedFields: updatedFields,
      isDisable: isEditable,
    });
  };

  saveData = async (e) => {
    const { mandatoryFields, updatedFields } = this.state;
    e.preventDefault();
    for (let key in updatedFields) {
      if (!updatedFields[key]) {
        delete updatedFields[key];
      }
    }
    this.setState({
      mandatoryFields: mandatoryFields,
      updatedFields: updatedFields,
    });
    delete mandatoryFields.instruction_id;
    return mandatoryFields;
  };

  render() {
    const {
      loading,
      caseDetails,
      mandatoryFields,
      updatedFields,
      isDisable,
    } = this.state;
    return (
      <div className="instructions-dashboard">
        <div className="header">
          <h6> Case Details Instructions</h6>
          <div className="all-rows">
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label className="text-muted text-uppercase label-header">
                    First Name
                  </label>
                  <CustomInput
                    type={"text"}
                    value={mandatoryFields.first_name}
                    onChange={(e) => {
                      let { mandatoryFields } = this.state;
                      mandatoryFields.first_name = e.target.value;
                      updatedFields.first_name = e.target.value;
                      this.setState({
                        mandatoryFields,
                        updatedFields,
                      });
                    }}
                    required={false}
                    isDisable={isDisable}
                  ></CustomInput>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="text-muted text-uppercase label-header">
                    Last Name
                  </label>
                  <CustomInput
                    type={"text"}
                    value={mandatoryFields.last_name}
                    onChange={(e) => {
                      let { mandatoryFields } = this.state;
                      mandatoryFields.last_name = e.target.value;
                      updatedFields.last_name = e.target.value;
                      this.setState({
                        mandatoryFields,
                        updatedFields,
                      });
                    }}
                    required={true}
                    isDisable={isDisable}
                  ></CustomInput>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="text-muted text-uppercase label-header">
                    Gender
                  </label>
                  <CustomInput
                    type={"text"}
                    value={mandatoryFields.gender}
                    onChange={(e) => {
                      let { mandatoryFields } = this.state;
                      mandatoryFields.gender = e.target.value;
                      updatedFields.gender = e.target.value;
                      this.setState({
                        mandatoryFields,
                        updatedFields,
                      });
                    }}
                    required={true}
                    isDisable={isDisable}
                  ></CustomInput>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="text-muted text-uppercase label-header">
                    Date Of Birth
                  </label>
                  <CustomInput
                    type={"text"}
                    value={mandatoryFields.date_of_birth}
                    onChange={(e) => {
                      let { mandatoryFields } = this.state;
                      mandatoryFields.date_of_birth = e.target.value;
                      updatedFields.date_of_birth = e.target.value;
                      this.setState({
                        mandatoryFields,
                        updatedFields,
                      });
                    }}
                    required={true}
                    isDisable={isDisable}
                  ></CustomInput>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label className="text-muted text-uppercase label-header">
                    Document Referred For Dob
                  </label>
                  <CustomInput
                    type={"text"}
                    value={mandatoryFields.document_referred_for_dob}
                    onChange={(e) => {
                      let { mandatoryFields } = this.state;
                      mandatoryFields.document_referred_for_dob =
                        e.target.value;
                      updatedFields.document_referred_for_dob = e.target.value;
                      this.setState({
                        mandatoryFields,
                        updatedFields,
                      });
                    }}
                    required={true}
                    isDisable={isDisable}
                  ></CustomInput>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="text-muted text-uppercase label-header">
                    Mobile Number
                  </label>
                  <CustomInput
                    type={"text"}
                    value={mandatoryFields.mobile_number}
                    onChange={(e) => {
                      let { mandatoryFields } = this.state;
                      mandatoryFields.mobile_number = e.target.value;
                      updatedFields.mobile_number = e.target.value;
                      this.setState({
                        mandatoryFields,
                        updatedFields,
                      });
                    }}
                    required={true}
                    isDisable={isDisable}
                  ></CustomInput>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="text-muted text-uppercase label-header">
                    Email Address
                  </label>
                  <CustomInput
                    type={"text"}
                    value={mandatoryFields.email_address}
                    onChange={(e) => {
                      let { mandatoryFields } = this.state;
                      mandatoryFields.email_address = e.target.value;
                      updatedFields.email_address = e.target.value;
                      this.setState({
                        mandatoryFields,
                        updatedFields,
                      });
                    }}
                    required={true}
                    isDisable={isDisable}
                  ></CustomInput>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="text-muted text-uppercase label-header">
                    Fathers Name
                  </label>
                  <CustomInput
                    type={"text"}
                    value={mandatoryFields.fathers_name}
                    onChange={(e) => {
                      let { mandatoryFields } = this.state;
                      mandatoryFields.fathers_name = e.target.value;
                      updatedFields.fathers_name = e.target.value;
                      this.setState({
                        mandatoryFields,
                        updatedFields,
                      });
                    }}
                    required={true}
                    isDisable={isDisable}
                  ></CustomInput>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label className="text-muted text-uppercase label-header">
                    Package Name
                  </label>
                  <CustomInput
                    type={"text"}
                    value={mandatoryFields.package_name}
                    onChange={(e) => {
                      let { mandatoryFields } = this.state;
                      mandatoryFields.package_name = e.target.value;
                      updatedFields.package_name = e.target.value;
                      this.setState({
                        mandatoryFields,
                        updatedFields,
                      });
                    }}
                    required={true}
                    isDisable={isDisable}
                  ></CustomInput>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="text-muted text-uppercase label-header">
                    Location
                  </label>
                  <CustomInput
                    type={"text"}
                    value={mandatoryFields.location}
                    onChange={(e) => {
                      let { mandatoryFields } = this.state;
                      mandatoryFields.location = e.target.value;
                      updatedFields.location = e.target.value;
                      this.setState({
                        mandatoryFields,
                        updatedFields,
                      });
                    }}
                    required={true}
                    isDisable={isDisable}
                  ></CustomInput>
                </div>
              </div>
            </div>
          </div>
          <div className="buttons"></div>
          <button
            className="btn btn-primary btn-sm float-left m-2"
            onClick={(e) => {
              this.editDetails(e);
            }}
          >
            Edit
          </button>
          <button
            className="btn btn-primary btn-sm float-left m-2"
            onClick={(e) => {
              this.submit(e);
            }}
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.login.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  update: (payload) => dispatch(update(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDescription);
