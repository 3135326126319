import React, { Component } from "react";
import _get from "lodash/get";
import "./fileInput.scss";

class FileInput extends React.Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
  }

  render() {
    const { text = "", type = "" } = this.props;
    return (
      <label className="custom-file-upload">
        {type ? (
          <input
            type="file"
            ref={this.fileInput}
            onChange={event => {
              console.log("file changed", event);

              this.props.onChange(_get(this.fileInput.current, "files[0]"));
            }}
            accept={type}
          />
        ) : (
          <input
            type="file"
            ref={this.fileInput}
            onChange={event => {
              console.log("file changed", event);

              this.props.onChange(_get(this.fileInput.current, "files[0]"));
            }}
          />
        )}
        {text ? (
          <div className="file-input-text">{text}</div>
        ) : (
          <span className="wh20 btn border border-primary rounded-circle p-1 d-flex align-items-center justify-content-center">
            <i className="fa fa-plus icon-primary"></i>
          </span>
        )}
      </label>
    );
  }
}

export default FileInput;
