import React, { Component } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "./imageViewer.scss";

export default class ImageViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: null,
    };
  }

  componentDidMount() {
    this.setState({
      url: this.props.url,
    });
  }

  render() {
    const { url } = this.state;
    return (
      <TransformWrapper
        initialScale={1}
        initialPositionX={1}
        initialPositionY={1}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            <div className="tools">
              <button
                className="btn btn-primary btn-sm"
                onClick={() => zoomIn()}
              >
                Zoom +
              </button>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => zoomOut()}
              >
                Zoom -
              </button>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => resetTransform()}
              >
                Reset
              </button>
            </div>
            <TransformComponent>
              {/* <img src="https://tinyjpg.com/images/jpg/example-original.jpg" alt="test" /> */}
              <img id="image" src={url} alt="Failed to Load Image"></img>
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
    );
  }
}
