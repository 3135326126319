/**
 * timer component
 * use for timer update for any case
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import "./buckettimer.scss";
import api from "./../../apiBaseConfig";
import {
  PLAY_PAUSE,
  CHANGE_STATUS,
  QCDASHBOARD_ADD_COMMENT,
  UPLOAD_CSIS,
} from "./../../apiurl";
import { formatTime } from "./../../utils/commonutils";
import { pause, resume, hold, deleteAction } from "./state/timer.action";
import Escalation from "./../Escalation/escalation";
import { modalManager } from "./../../utils/modalutils";
import { Toast } from "./../../utils/toastutils";
import { Redirect } from "react-router-dom";
import Csisupload from "./component/csisupload";
import Toggle from "../../screens/bucketDetails/components/toggle/toggle";
let myInterval;
class BUCKETTIMER extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTimer: false,
      minutes: 120,
      seconds: 0,
      actionType: "resume",
      csisUrl: [],
    };
  }

  componentDidMount = () => {
    const { timerObj, status, isCse, pvtId } = this.props;
    console.log("timerobj", timerObj);
    const timer = timerObj && timerObj[pvtId];
    this.getCsis();
    if (!isCse) {
      if (status === "Onhold") {
        this.setState({ actionType: "hold" });
      } else {
        if (timer) {
          if (timer.actionType === "pause") {
            this.setState(
              {
                minutes: timer.minutes,
                seconds: timer.seconds,
                actionType: timer.actionType,
              },
              () => {
                clearInterval(myInterval);
              }
            );
          } else {
            this.setState(
              {
                minutes: timer.minutes,
                seconds: timer.seconds,
                actionType: timer.actionType,
              },
              () => {
                this.setTimer();
              }
            );
          }
        } else {
          this.props.resume({
            minutes: 120,
            seconds: 0,
            actionType: "resume",
            pvtId: pvtId,
          });
          this.setState(
            {
              minutes: 120,
              seconds: 0,
              actionType: "resume",
            },
            () => {
              this.setTimer();
            }
          );
        }
      }
    }
  };

  componentWillUnmount() {
    clearInterval(myInterval);
  }

  /**
   * this function use for update the timer dynamicaly
   */
  setTimer = (flag) => {
    myInterval = setInterval(() => {
      const { seconds, minutes } = this.state;
      this.props.resume({
        minutes: minutes,
        seconds: seconds,
        actionType: "resume",
        pvtId: this.props.pvtId,
      });

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
          this.changeStatus();
          this.props.deleteAction({ pvtId: this.props.pvtId });
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59,
          }));
        }
      }
    }, 1000);
  };

  getCsis = async () => {
    let arr = [];
    try {
      const response = await Promise.all([
        this.getDownloadCsis(1),
        this.getDownloadCsis(2),
        this.getDownloadCsis(3),
      ]);
      for (let i = 0; i < response.length; i++) {
        arr.push({
          url: `${process.env.REACT_APP_FILE_URL}${response[i].data.path}`,
        });
      }
      this.setState({ csisUrl: arr });
    } catch (e) {
      console.log("ee");
    }
  };
  changeStatus = async () => {
    const { pvtId } = this.props;
    const body = {
      pvtId: parseInt(pvtId),
    };
    try {
      const response = await api.post(
        `${CHANGE_STATUS}?status=Unassigned`,
        body
      );
      this.setState({ isTimer: true }, () => {
        Toast.info({ description: "time is over", header: "info" });
      });
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * this function use for pause the timer
   */
  pause = () => {
    const { minutes, seconds } = this.state;
    clearInterval(myInterval);
    this.setState({ minutes, seconds, actionType: "pause" }, () => {
      const { minutes, seconds } = this.state;
      this.props.pause({
        minutes,
        seconds,
        actionType: "pause",
        pvtId: this.props.pvtId,
      });
    });
  };

  /**
   * use for taking action {play,pause}
   */
  submitApi = async (flag) => {
    const { userId = "", pvtId = "" } = this.props;
    const { minutes, seconds } = this.state;
    const timeStemp = minutes * 60 + seconds;
    const body = {
      userId,
      pvtId: parseInt(pvtId),
    };
    if (flag === "play") {
      body["actionType"] = "play";
    } else {
      body["actionType"] = "pause";
      body["actionTakenAt"] = formatTime(timeStemp);
    }
    try {
      const response = await api.post(`${PLAY_PAUSE}`, body);
      if (flag === "play") {
        this.resume();
      } else {
        this.pause();
      }
    } catch (e) {
      Toast.error({
        description: "something went wrong",
        title: "Error",
      });
      console.log(e);
    }
  };

  /**
   * resume the timer
   */
  resume = () => {
    const { minutes, seconds } = this.state;
    this.setState({ actionType: "resume" }, () => {
      this.props.resume({
        actionType: "resume",
        minutes,
        seconds,
        pvtId: this.props.pvtId,
      });
      this.setTimer();
    });
  };

  /**
   * this function help for hold the case and add the comment in case history
   */
  submitHoldComment = async (data) => {
    const body = {
      pvtId: parseInt(data.pvtId),
    };
    try {
      const response = await api.post(`${CHANGE_STATUS}?status=Onhold`, body);
      if (response.data["status"] === "success") {
        const { minutes, seconds } = this.state;
        const comment = await api.post(QCDASHBOARD_ADD_COMMENT, {
          userId: data.userId,
          pvtId: parseInt(data.pvtId),
          comment: data.comment,
        });
        clearInterval(myInterval);
        modalManager.close({});
        this.setState({ actionType: "hold" });
        this.props.hold({ actionType: "hold", pvtId: this.props.pvtId });
        this.props.getCaseDetail();
        Toast.success({
          description: response.data["msg"],
          title: "Success",
        });
      }
    } catch (e) {
      Toast.error({
        description: "something went wrong",
        title: "Error",
      });
    }
  };

  /**
   * hold the case
   */
  onHold = () => {
    const { pvtId, userName, userId } = this.props;
    const data = {
      label: "onhold",
      userName: userName,
      pvtId: pvtId,
      userId,
    };
    modalManager.open({
      isHeader: false,
      component: () => (
        <Escalation
          callback={this.submitHoldComment}
          data={data}
          btnLabel="Submit"
        />
      ),
    });
  };

  /**
   * download the csis excel sheet
   */
  openUrl = () => {
    const { csisUrl } = this.state;
    for (var i = 0; i < csisUrl.length; i++) {
      setTimeout(
        function (path) {
          window.location = path;
        },
        1000 + i * 1000,
        csisUrl[i].url
      );
    }
  };

  getDownloadCsis = (id) => {
    return api.get(`${UPLOAD_CSIS}${id}/`);
  };
  /**
   * this help for upload the csis file
   */
  uploadCsis = async (data, formData) => {
    try {
      const response = await api.post(`${UPLOAD_CSIS}${data.id}/`, formData);
      modalManager.close({});
      Toast.success({
        description: "csis uploaded successfully",
        title: "success",
      });
      this.getCsis();
    } catch (e) {
      Toast.error({ description: "somthing went wrong", title: "error" });
    }
  };

  openWindow = () => {
    modalManager.open({
      width: "small",
      component: () => <Csisupload callback={this.uploadCsis} />,
    });
  };

  render() {
    const {
      minutes,
      seconds,
      actionType,
      isExpand,
      csisUrl,
      isTimer,
    } = this.state;
    const { userId = "", pvtId = "", timer, status = "", isCse } = this.props;
    if (isTimer) {
      return <Redirect to="/qcdashboard" />;
    }
    return (
      <div className="timer-block">
        <div class="row mb-3">
          <div class="col-md-8 d-flex">
            <button
              class="btn btn-primary btn-sm text-uppercase border-0"
              onClick={() => {
                this.openUrl();
              }}
            >
              csis <i class="fa fa-arrow-down" aria-hidden="true"></i>
            </button>
            {isCse ? (
              <button
                class="btn btn-primary btn-sm text-uppercase border-0"
                onClick={() => {
                  this.openWindow();
                }}
                style={{ marginLeft: "4px" }}
              >
                csis <i class="fa fa-arrow-up" aria-hidden="true"></i>
              </button>
            ) : null}
            <Toggle
              callback={this.props.handleSubmittedChecksToggleChange}
              isToggleOn={this.props.isToggleOn}
            />
          </div>
          {actionType != "hold" && !isCse ? (
            <div class="col-md-4">
              <div class="row">
                <div class="col timer-action">
                  <button
                    type="button"
                    class="btn btn-outline-danger btn-sm"
                    onClick={() => {
                      this.onHold();
                    }}
                  >
                    HOLD{" "}
                  </button>
                  <div
                    class="float-left mr-2 pause"
                    onClick={() => {
                      this.submitApi("pause");
                    }}
                  >
                    <i
                      class="fa fa-pause-circle-o pause-icon"
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
                <div class="col">
                  <div class="col-auto p-1 text-center text-primary border-primary small rounded">
                    Time Remaining: {minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds} mins
                  </div>
                </div>
              </div>
            </div>
          ) : !isCse ? (
            <span class="badge badge-info">HOLD</span>
          ) : null}
        </div>
        {actionType === "pause" && (
          <div class="loader-container" id="resumeWindow">
            <span class="loader-timer">
              <button
                class="btn btn-danger mb-2"
                onClick={() => {
                  this.submitApi("play");
                }}
              >
                Resume
              </button>
            </span>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  timerObj: { ...state.timer },
});

const mapDispatchToProps = (dispatch) => ({
  pause: (data) => dispatch(pause(data)),
  resume: (data) => dispatch(resume(data)),
  hold: (data) => dispatch(hold(data)),
  deleteAction: (data) => dispatch(deleteAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BUCKETTIMER);
