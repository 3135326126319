/**
 * client details component
 * which contain case details,client details
 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Selectbox from "./../../../../components/Selectbox/selectbox";
import api from "./../../../../apiBaseConfig";
import {
  DUPLICATE_CASE,
  SUBMIT_ARS_CASE,
  SAVE_PERSONAL_DATA,
} from "./../../../../apiurl";
import DocSelection from "./../../../../components/docselection/docselection";
import { Toast } from "./../../../../utils/toastutils";
import {
  getRealData,
  uniqueId,
  getDocuments,
} from "./../../../../utils/commonutils";
import CustomInput from "./../../../../components/customInput/customInput";

class ClientDetailAccordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientFormData: {},
      candidateFormData: {},
      selectedDoc: [],
      isExpand: true,
      selectDropdown: [],
    };
  }

  componentDidMount() {
    let {
      candidateFormData,
      clientFormData,
      selectedDoc,
      selectDropdown,
    } = this.state;
    const {
      personalData,
      caseDetails,
      isDocDisable = false,
      documentList,
    } = this.props;
    candidateFormData = { ...personalData };
    if (!candidateFormData.email) {
      candidateFormData.email = "Not mentioned";
    }

    if (candidateFormData.middleName === "-") {
      candidateFormData.middleName = "";
    }

    if (!candidateFormData.contacts) {
      candidateFormData.contacts = [];
    }
    clientFormData = { ...caseDetails };
    if (personalData.documentList && personalData.documentList.length) {
      selectedDoc = personalData.documentList.map((data, index) => {
        return { ...data, indexNumber: uniqueId(), isDocDisable };
      });
    }
    selectDropdown = documentList.map((data) => {
      return {
        type: data.type,
        id: data.id,
        label: data.type + "_" + data.id,
        path: data.path,
        gcspath: data.gcspath,
      };
    });
    this.setState({
      candidateFormData,
      clientFormData,
      selectedDoc,
      isExpand: true,
      selectDropdown,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.personalData !== this.props.personalData ||
      prevProps.caseDetails !== this.props.caseDetails ||
      prevProps.isDocDisable !== this.props.isDocDisable
    ) {
      let { candidateFormData, clientFormData, selectedDoc } = this.state;
      const { personalData, caseDetails, isDocDisable = false } = this.props;
      candidateFormData = { ...personalData };
      if (!candidateFormData.email) {
        candidateFormData.email = "Not mentioned";
      }
      if (candidateFormData.middleName === "-") {
        candidateFormData.middleName = "";
      }
      if (!candidateFormData.contacts) {
        candidateFormData.contacts = [];
      }
      clientFormData = { ...caseDetails };
      if (personalData.documentList && personalData.documentList.length) {
        selectedDoc = personalData.documentList.map((data, index) => {
          return { ...data, indexNumber: uniqueId(), isDocDisable };
        });
      }
      this.setState({
        candidateFormData,
        clientFormData,
        selectedDoc,
        isExpand: true,
      });
    }
  }

  /**
   * this functioon use for modify the client details
   */
  setClintData = (key, value) => {
    let { clientFormData } = this.state;
    clientFormData[key] = value;
    this.setState({ clientFormData });
  };

  /**
   * this function use for modify the candidate details
   */

  setCandidateData = (key, value) => {
    let { candidateFormData } = this.state;
    candidateFormData[key] = value;
    this.setState({ candidateFormData });
  };

  /**
   * this function is used for document tagging in case label
   */
  getSelectDoc = (array) => {
    this.setState({ selectedDoc: array });
  };

  saveCaseDetails = async (e, flag = "") => {
    e.preventDefault();
    await this.props.saveDeatils(flag, this.state.clientFormData);
  };

  /**
   * this function use for save the personal details in case label
   */
  savePersonalData = async (e) => {
    e.preventDefault();

    const { candidateFormData, selectedDoc = [] } = this.state;
    const { pvtId, packageId, isDocDisable = false } = this.props;
    const selectedDocArray = selectedDoc.map((value) => {
      return { ...value, isDocDisable };
    });

    const body = {
      pvtId: pvtId,
      personalDetails: {
        ...candidateFormData,
        packageId: packageId,
        documentList: selectedDocArray,
      },
    };
    try {
      const result = await api.post(`${SAVE_PERSONAL_DATA}`, body);
      Toast.success({
        description: "Case details saved",
        title: "Success",
      });
      this.setState({ selectedDoc: selectedDocArray });
    } catch (e) {
      Toast.error({
        description: "something went wrong",
        title: "error",
      });
    }
  };

  /**
   * this function use for generate the ars number
   * and check the duplicity
   */
  submit = async (e) => {
    e.preventDefault();
    let {
      candidateFormData,
      clientFormData,
      selectedDoc,
      isExpand,
    } = this.state;
    const {
      userId = "",
      pvtId = "",
      packageName,
      callback = () => {},
      isDocDisable = false,
      arsObj,
      selectedLocation,
      caseDetails,
    } = this.props;
    let documetData = [];
    if (selectedDoc.length) {
      documetData = selectedDoc
        .filter((data) => data.selected_option)
        .map((value) => {
          return {
            doc_id: value.documentId,
            case_doc_path: value.selected_option
              ? value.selected_option.gcspath
              : "",
            page: value.pageNumber ? value.pageNumber : null,
          };
        });
    }
    const duplicateData = {
      pvt_id: parseInt(pvtId),
      user_id: userId,
      remarks: "None",
      firstName: candidateFormData.firstName,
      middleName: candidateFormData.middleName
        ? candidateFormData.middleName
        : "",
      lastName: candidateFormData.lastName,
      fatherName: candidateFormData.fathersName,
      DOB: candidateFormData.dob,
      mobileNo: candidateFormData.mobile,
      aadharNo: "",
      panCard: candidateFormData.panNo ? candidateFormData.panNo : "",
      passportNo: candidateFormData.passportNo
        ? candidateFormData.passportNo
        : "",
      drivingLicense: candidateFormData.drivingLicenseNo
        ? candidateFormData.drivingLicenseNo
        : "",
      voterID: candidateFormData.voterId ? candidateFormData.voterId : "",
      flexField3:
        clientFormData.empId === "Not Mentioned" || !clientFormData.empId
          ? null
          : clientFormData.empId,
      flexField4:
        clientFormData.candidateId === "Not Mentioned" ||
        !clientFormData.candidateId
          ? null
          : clientFormData.candidateId,
    };
    const condidateData = {
      package_name: packageName,
      office_name: selectedLocation.officeName || "",
      first_name: candidateFormData.firstName,
      middle_name: candidateFormData.middleName
        ? candidateFormData.middleName
        : "",
      last_name: candidateFormData.lastName,
      dob: candidateFormData.dob,
      alias_fname: candidateFormData.alias ? candidateFormData.alias : "",
      alias_lname: "",
      gender: candidateFormData.sex,
      mobile: candidateFormData.mobile,
      email: candidateFormData.email,
      father_name: candidateFormData.fathersName,
      last_updated_by: userId,
      dual_entry_counter: 111,
      aadhar: "",
      pan: candidateFormData.panNo ? candidateFormData.panNo : "",
      passport: candidateFormData.passportNo
        ? candidateFormData.passportNo
        : "",
      driving_license: candidateFormData.drivingLicenseNo
        ? candidateFormData.drivingLicenseNo
        : "",
      voter_id: candidateFormData.voterId ? candidateFormData.voterId : "",
      received_date: caseDetails.caseReceivedOn,
      document_list: documetData,
      pvt_id: pvtId,
      remarks: "None",
      user_id: userId,
      case_doc_content: "",
      contacts: candidateFormData.contacts,
      flexFields: {
        packageName: clientFormData.packageName || "Not Mentioned",
        priorityFlag: clientFormData.priorityFlag,
        verificationType: clientFormData.verificationType,
        bgvId: clientFormData.bgvId,
        empId: clientFormData.empId,
        bgvId2: clientFormData.bgvId2,
        dateOfReinitiation: candidateFormData.dateOfReinitiation,
        candidateId: clientFormData.candidateId,
      },
    };
    const selectedDocArray = selectedDoc.map((value) => {
      return { ...value, isDocDisable };
    });
    const f = [
      { key: "firstName", value: candidateFormData.firstName },
      { key: "lastName", value: candidateFormData.lastName },
      { key: "dob", value: candidateFormData.dob },
      { key: "fathersName", value: candidateFormData.fathersName },
      { key: "mobile", value: candidateFormData.mobile },
      { key: "email", value: candidateFormData.email },
      { key: "gender", value: candidateFormData.sex },
      { key: "packageName", value: packageName },
      { key: "Location", value: selectedLocation.officeName },
    ];
    let s = "";
    for (let i = 0; i < f.length; i++) {
      if (!f[i].value) {
        s = s + f[i].key + ",";
      }
    }
    const isDisabled =
      candidateFormData.firstName &&
      candidateFormData.lastName &&
      candidateFormData.dob &&
      candidateFormData.fathersName &&
      candidateFormData.mobile &&
      candidateFormData.email &&
      candidateFormData.sex &&
      packageName &&
      selectedLocation.officeName;
    candidateFormData.documentList = selectedDocArray;

    if (arsObj.arsNo) {
      Toast.error({
        description: "ARS number has already been generated for this case",
        title: "Error",
      });
    } else {
      if (!isDisabled) {
        Toast.error({
          hideAfter: 8,
          description: `Please ensure that following mandatory fields are filled before clicking Add Case:${s}`,
          title: "Error",
        });
      } else {
        try {
          this.props.setLoadingValue(true);
          const response = await api.post(`${DUPLICATE_CASE}`, duplicateData);
          if (!response.data.isDuplicate) {
            Toast.info({
              description: "No Duplicates found",
              title: "info",
            });
            try {
              const saveDetails = await this.saveCaseDetails(e);
              const personalDataObj = await this.savePersonalData(e);
              const result = await api.post(`${SUBMIT_ARS_CASE}`, {
                ...condidateData,
                case_duplicity: 0,
              });
              Toast.success({
                description: "ARS number generated",
                title: "Success",
              });
              this.props.getArs(result.data, candidateFormData);
              this.setState({ isExpand: false });
            } catch (e) {
              Toast.error({
                description: "something went wrong",
                title: "error",
              });
              this.props.setLoadingValue(false);
              console.log(e);
            }
          } else {
            await this.saveCaseDetails(e);
            await this.savePersonalData(e);
            const actualData = getRealData(clientFormData, candidateFormData);
            this.props.history.push({
              pathname: "/duplicates",
              state: {
                duplicates: response.data.duplicates,
                actualData,
                userId: userId,
                pvtId,
                arsBody: condidateData,
              },
            });
          }
        } catch (e) {
          Toast.error({
            description: "something went wrong",
            title: "error",
          });
          this.props.setLoadingValue(false);
          console.log(e);
        }
      }
    }
  };

  setAlternateMobile = () => {
    let { candidateFormData } = this.state;
    candidateFormData.contacts.push("");
    this.setState({ candidateFormData });
  };

  render() {
    const {
      candidateFormData,
      clientFormData,
      selectedDoc,
      isExpand,
      selectDropdown
    } = this.state;
    const {
      loadDocument,
      documentList,
      personalData,
      arsObj,
      isCSE,
      isCaseInsuff,
      packageName,
    } = this.props;
    let isDisable = isCSE || isCaseInsuff || arsObj.arsNo;
    return (
      <div>
        {isExpand ? (
          <div>
            <div className="row">
              <div>
                <hr className="brdr-dashed" />
                <div className="col">
                  <div className="small">
                    <h6
                      className={`${
                        isCaseInsuff ? "text-danger" : "text-muted"
                      } font-weight-bold mb-3`}
                    >
                      Client Details
                    </h6>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            BGV ID Pre- Joining
                          </label>
                          <input
                            type="text"
                            className={`input-section form-control text-primary ${
                              isDisable ? "row-disabled" : ""
                            }`}
                            style={isDisable ? { color: "#888" } : {}}
                            value={clientFormData.bgvId}
                            disabled
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Verification Type
                          </label>
                          <input
                            type="text"
                            className={`input-section form-control text-primary ${
                              isDisable ? "row-disabled" : ""
                            }`}
                            value={
                              clientFormData.verificationType || "Not Mentioned"
                            }
                            disabled
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Employee ID
                          </label>
                          <input
                            type="text"
                            className={`input-section form-control text-primary ${
                              isDisable ? "row-disabled" : ""
                            }`}
                            value={
                              clientFormData.empId
                                ? clientFormData.empId
                                : "Not Mentioned"
                            }
                            disabled
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Candidate ID
                          </label>
                          <input
                            type="text"
                            className={`input-section form-control text-primary ${
                              isDisable ? "row-disabled" : ""
                            }`}
                            value={
                              clientFormData.candidateId || "Not Mentioned"
                            }
                            disabled
                          ></input>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            BGV ID 2 Post Joining
                          </label>
                          <input
                            type="text"
                            className={`input-section form-control text-primary ${
                              isDisable ? "row-disabled" : ""
                            }`}
                            value={clientFormData.bgvId2 || "Not Mentioned"}
                            disabled
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Date of Reinitiation
                          </label>
                          <div className="position-relative has-icon">
                            <CustomInput
                              type={"Date"}
                              onChange={(e) => {
                                let { candidateFormData } = this.state;
                                candidateFormData.dateOfReinitiation =
                                  e.target.value;
                                this.setState({ candidateFormData });
                              }}
                              value={candidateFormData.dateOfReinitiation}
                              required={false}
                              isDisable={isDisable}
                            ></CustomInput>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Process Name
                          </label>
                          <input
                            type="text"
                            className={`input-section form-control text-primary ${
                              isDisable ? "row-disabled" : ""
                            }`}
                            value={
                              clientFormData.packageName || "Not Mentioned"
                            }
                            disabled
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Fast Track Accounts
                          </label>
                          <CustomInput
                            type="Drop Down"
                            option={["yes", "no"]}
                            value={this.props.priorityFlag === 1 ? "yes" : "no"}
                            className={`input-section form-control text-primary ${
                              isDisable ? "row-disabled" : ""
                            }`}
                            // isDisable={true}
                            onChange={(e) => {
                              const flagValue =
                                e.target.value === "yes" ? 1 : 0;

                              this.setClintData("priorityFlag", flagValue);
                            }}
                          ></CustomInput>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Data Source
                          </label>
                          <input
                            type="text"
                            className={`input-section form-control text-primary ${
                              isDisable ? "row-disabled" : ""
                            }`}
                            value={
                              clientFormData["dataSource"] || "Not Mentioned"
                            }
                            disabled
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <DocSelection
                          callback={this.getSelectDoc}
                         // documentList={documentList}
                          loadDocument={loadDocument}
                          checkId={100}
                          docArray={selectedDoc}
                          documentCheck={getDocuments()}
                          selectDropdown={selectDropdown}
                          isDisable={this.props.isCaseInsuff}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <hr className="brdr-dashed" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <h6
                          className={`${
                            isCaseInsuff ? "text-danger" : "text-muted"
                          } font-weight-bold mb-3`}
                        >
                          Candidate Details
                        </h6>
                        <div
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginBottom: "4px",
                          }}
                        >
                          All (*) field are required!
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                First Name<sup className="staric-data">*</sup>
                              </label>
                              <input
                                type="text"
                                className={`input-section form-control text-primary ${
                                  isDisable ? "row-disabled" : ""
                                }`}
                                name="firstName"
                                required
                                value={
                                  candidateFormData["firstName"]
                                    ? candidateFormData["firstName"]
                                    : ""
                                }
                                onChange={(e) => {
                                  this.setCandidateData(
                                    "firstName",
                                    e.target.value
                                  );
                                }}
                                disabled={isDisable}
                              ></input>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Middle Name
                              </label>
                              <input
                                type="text"
                                className={`input-section form-control text-primary ${
                                  isDisable ? "row-disabled" : ""
                                }`}
                                id="middleName"
                                name="middleName"
                                value={candidateFormData["middleName"]}
                                onChange={(e) => {
                                  this.setCandidateData(
                                    "middleName",
                                    e.target.value
                                  );
                                }}
                                disabled={isDisable}
                              ></input>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Last Name<sup className="staric-data">*</sup>
                              </label>
                              <input
                                type="text"
                                className={`input-section form-control text-primary ${
                                  isDisable ? "row-disabled" : ""
                                }`}
                                id="lastName"
                                name="lastName"
                                value={
                                  candidateFormData["lastName"]
                                    ? candidateFormData["lastName"]
                                    : ""
                                }
                                onChange={(e) => {
                                  this.setCandidateData(
                                    "lastName",
                                    e.target.value
                                  );
                                }}
                                disabled={isDisable}
                              ></input>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Gender <sup className="staric-data">*</sup>
                              </label>
                              <Selectbox
                                option={[
                                  { value: "male", label: "male" },
                                  { value: "female", label: "female" },
                                ]}
                                callback={(data) => {
                                  this.setCandidateData("sex", data.value);
                                }}
                                defaultValue={{
                                  value: candidateFormData.sex,
                                  label: candidateFormData.sex,
                                }}
                                isDisable={isDisable}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Mobile Number
                                <sup className="staric-data">*</sup>
                              </label>
                              <input
                                type="text"
                                name="mobile"
                                className={`input-section form-control text-primary ${
                                  isDisable ? "row-disabled" : ""
                                }`}
                                value={
                                  candidateFormData["mobile"]
                                    ? candidateFormData["mobile"]
                                    : ""
                                }
                                onChange={(e) => {
                                  this.setCandidateData(
                                    "mobile",
                                    e.target.value
                                  );
                                }}
                                disabled={isDisable}
                              ></input>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                DOB<sup className="staric-data">*</sup>
                              </label>

                              <CustomInput
                                type={"Date"}
                                onChange={(e) => {
                                  this.setCandidateData("dob", e.target.value);
                                }}
                                value={
                                  candidateFormData["dob"]
                                    ? candidateFormData["dob"]
                                    : ""
                                }
                                required={true}
                                isDisable={isDisable}
                              ></CustomInput>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Email Address
                                <sup className="staric-data">*</sup>
                              </label>
                              <input
                                type="text"
                                className={`input-section form-control text-primary ${
                                  isDisable ? "row-disabled" : ""
                                }`}
                                id="emailId"
                                name="emailId"
                                value={candidateFormData["email"]}
                                onChange={(e) => {
                                  this.setCandidateData(
                                    "email",
                                    e.target.value
                                  );
                                }}
                                disabled={isDisable}
                              ></input>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Alias
                              </label>
                              <input
                                type="text"
                                className={`input-section form-control text-primary ${
                                  isDisable ? "row-disabled" : ""
                                }`}
                                id="firstName"
                                name="firstName"
                                value={
                                  candidateFormData["alias"]
                                    ? candidateFormData["alias"]
                                    : ""
                                }
                                onChange={(e) => {
                                  this.setCandidateData(
                                    "alias",
                                    e.target.value
                                  );
                                }}
                                disabled={isDisable}
                              ></input>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Father's Name
                                <sup className="staric-data">*</sup>
                              </label>
                              <input
                                className={`input-section form-control text-primary ${
                                  isDisable ? "row-disabled" : ""
                                }`}
                                type="text"
                                id="fathersName"
                                name="fathersName"
                                value={
                                  candidateFormData["fathersName"]
                                    ? candidateFormData["fathersName"]
                                    : ""
                                }
                                onChange={(e) => {
                                  this.setCandidateData(
                                    "fathersName",
                                    e.target.value
                                  );
                                }}
                                disabled={isDisable}
                              ></input>
                            </div>
                          </div>
                        </div>

                        {/* alternaate mobile */}
                        <div class="row">
                          <div className="col-md-6">
                            <label className="text-muted text-uppercase label-header">
                              Alternate Mobile Number{" "}
                              <button
                                className="transparent-borderless"
                                onClick={() => {
                                  this.setAlternateMobile();
                                }}
                                disabled={isDisable}
                              >
                                <i className="fa fa-plus icon-primary alter-plus"></i>
                              </button>
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          {candidateFormData.contacts &&
                            candidateFormData.contacts.map((value, index) => {
                              return (
                                <div className="col-md-4" key={index}>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className={`input-section form-control text-primary ${
                                        isDisable ? "row-disabled" : ""
                                      }`}
                                      value={value}
                                      onChange={(e) => {
                                        let { candidateFormData } = this.state;
                                        candidateFormData.contacts[index] =
                                          e.target.value;
                                        this.setState({ candidateFormData });
                                      }}
                                      disabled={isDisable}
                                    ></input>
                                  </div>
                                </div>
                              );
                            })}
                        </div>

                        <div class="row">
                          <div class="col">
                            <hr class="brdr-dashed" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <h6
                              className={`${
                                isCaseInsuff ? "text-danger" : "text-muted"
                              } font-weight-bold mb-3`}
                            >
                              National Identity Document
                            </h6>
                            <div class="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className="text-muted text-uppercase label-header">
                                    Pan Card No.
                                    {/* <sup>*</sup> */}
                                  </label>
                                  <input
                                    className={`input-section form-control text-primary ${
                                      isDisable ? "row-disabled" : ""
                                    }`}
                                    type="text"
                                    id="panNo"
                                    name="panNo"
                                    value={candidateFormData["panNo"]}
                                    onChange={(e) => {
                                      this.setCandidateData(
                                        "panNo",
                                        e.target.value
                                      );
                                    }}
                                    disabled={isDisable}
                                  ></input>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className="text-muted text-uppercase label-header">
                                    Driving License
                                    {/* <sup>*</sup> */}
                                  </label>
                                  <input
                                    className={`input-section form-control text-primary ${
                                      isDisable ? "row-disabled" : ""
                                    }`}
                                    type="text"
                                    value={
                                      candidateFormData["drivingLicenseNo"]
                                    }
                                    onChange={(e) => {
                                      this.setCandidateData(
                                        "drivingLicenseNo",
                                        e.target.value
                                      );
                                    }}
                                    disabled={isDisable}
                                  ></input>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label className="text-muted text-uppercase label-header">
                                    Voter ID
                                  </label>
                                  <input
                                    type="text"
                                    className={`input-section form-control text-primary ${
                                      isDisable ? "row-disabled" : ""
                                    }`}
                                    value={candidateFormData["voterId"]}
                                    onChange={(e) => {
                                      this.setCandidateData(
                                        "voterId",
                                        e.target.value
                                      );
                                    }}
                                    disabled={isDisable}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className="text-muted text-uppercase label-header">
                                    Passport No.
                                    {/* <sup>*</sup> */}
                                  </label>
                                  <input
                                    className={`input-section form-control text-primary ${
                                      isDisable ? "row-disabled" : ""
                                    }`}
                                    type="text"
                                    id="passportNo"
                                    name="passportNo"
                                    value={candidateFormData["passportNo"]}
                                    onChange={(e) => {
                                      this.setCandidateData(
                                        "passportNo",
                                        e.target.value
                                      );
                                    }}
                                    disabled={isDisable}
                                  ></input>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <hr className="brdr-dashed" />
                      <div className="col">
                        <button
                          className="btn btn-primary btn-sm float-right m-2"
                          // type="submit"
                          disabled={this.props.isCaseInsuff}
                          onClick={(e) => {
                            this.submit(e);
                          }}
                        >
                          Add Case
                        </button>
                        <button
                          className="btn btn-primary btn-sm float-right m-2 "
                          disabled={this.props.isCaseInsuff}
                          onClick={(e) => {
                            this.saveCaseDetails(e, "save");
                            this.savePersonalData(e);
                          }}
                        >
                          Save Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto text-center expand-link text-primary small position-relative">
                &nbsp;&nbsp;
                <i
                  className="fa fa-caret-up text-muted"
                  style={{ fontSize: "36px" }}
                  onClick={() => {
                    this.setState({ isExpand: !isExpand });
                  }}
                ></i>
              </div>
            </div>
          </div>
        ) : (
          <div className="row justify-content-center">
            <div className="col-auto text-center expand-link text-primary small position-relative">
              &nbsp;&nbsp;
              <i
                className="fa fa-caret-down text-muted"
                style={{ fontSize: "36px" }}
                onClick={() => {
                  this.setState({ isExpand: !isExpand });
                }}
              ></i>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(ClientDetailAccordion);
