/**
 * client details component
 * which contain case details,client details
 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Selectbox from "./../../../components/Selectbox/selectbox";
import api from "./../../../apiBaseConfig";
import {
  CTS_GENERATE_ARS,
  CTS_DUPLICITY_CHECK,
  GET_DROP_DOWN_VALUES,
} from "./../../../apiurl";
import DocSelection from "./../../../components/docselection/docselection";
import { Toast } from "./../../../utils/toastutils";
import {
  uniqueId,
  getDocuments,
  getHclDuplicityData,
  getCtsDuplicityData,
} from "./../../../utils/commonutils";
import CustomInput from "./../../components/custumInput/custumInput";

class ClientDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientFormData: {},
      candidateFormData: {},
      selectedDoc: [],
      isExpand: true,
      selectDropdown: [],
      updatedFormData: {},
      isAddCaseLoader: false,
      cutOffTimeDropdown: {},
    };
  }

  componentDidMount() {
    let { candidateFormData, clientFormData, selectedDoc, selectDropdown } =
      this.state;
    const { caseDetails, isDocDisable = false, documentList } = this.props;
    clientFormData = { ...caseDetails };
    if (caseDetails.case_documents && caseDetails.case_documents.length) {
      selectedDoc = caseDetails.case_documents.map((data, index) => {
        return { ...data, indexNumber: uniqueId(), isDocDisable };
      });
    }
    selectDropdown = documentList.map((data) => {
      return {
        type: data.type,
        id: data.id,
        label: data.type + "_" + data.id,
        path: data.path,
        gcspath: data.gcspath,
        documentType: data.documentType,
      };
    });
    this.getValues();
    this.setState({
      updatedFormData: {},
      clientFormData,
      selectedDoc,
      isExpand: true,
      selectDropdown,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    let { clientFormData, selectedDoc, selectDropdown } = this.state;
    const { caseDetails, isDocDisable = false, documentList } = this.props;
    if (
      prevProps.caseDetails !== this.props.caseDetails ||
      prevProps.isDocDisable !== this.props.isDocDisable
    ) {
      clientFormData = { ...caseDetails };
      if (caseDetails.case_documents && caseDetails.case_documents.length) {
        selectedDoc = caseDetails.case_documents.map((data, index) => {
          return { ...data, indexNumber: uniqueId(), isDocDisable };
        });
      }
      selectDropdown = documentList.map((data) => {
        return {
          type: data.type,
          id: data.id,
          label: data.type + "_" + data.id,
          path: data.path,
          gcspath: data.gcspath,
          documentType: data.documentType,
        };
      });
      this.setState({
        updatedFormData: {},
        clientFormData,
        selectDropdown,
        selectedDoc,
        isExpand: true,
      });
    }
  }

  /**
   * this functioon use for modify the client details
   */
  setClintData = (key, value) => {
    let { clientFormData } = this.state;
    clientFormData[key] = value;
    this.setState({ clientFormData });
  };

  /**
   * this function use for modify the candidate details
   */

  setCandidateData = (key, value) => {
    let { candidateFormData } = this.state;
    candidateFormData[key] = value;
    this.setState({ candidateFormData });
  };

  /**
   * this function is used for document tagging in case label
   */
  getSelectDoc = (array) => {
    this.setState({ selectedDoc: array });
  };

  saveCaseDetails = async (e, flag = "") => {
    const { selectedDoc, clientFormData, updatedFormData } = this.state;
    const tagDocuments = selectedDoc.filter((document) => document.id);
    const re = /\S+@\S+\.\S+/;
    e.preventDefault();
    if (
      clientFormData.email_address &&
      !re.test(clientFormData.email_address)
    ) {
      Toast.info({
        description: "Please enter valid email format (abc@def.xx)",
      });
      return Promise.reject(null);
    }
    for (let key in updatedFormData) {
      if (!updatedFormData[key]) {
        delete updatedFormData[key];
      }
    }
    return this.props.saveDeatils(
      flag,
      this.state.clientFormData,
      tagDocuments,
      updatedFormData
    );
  };
  /**
   * this function use for generate the ars number
   * and check the duplicity
   */
  submit = async (e) => {
    e.preventDefault();
    const { pvtId, userId, packageName, selectedLocation } = this.props;
    const { clientFormData } = this.state;
    const f = [
      { key: "firstName", value: clientFormData.first_name },
      { key: "lastName", value: clientFormData.last_name },
      { key: "dob", value: clientFormData.date_of_birth },
      // { key: "gender", value: clientFormData.gender },
      { key: "mobile", value: clientFormData.mobile_number },
      { key: "project id", value: clientFormData.project_id },
      // { key: "email", value: clientFormData.email_address },
      { key: "fatherName", value: clientFormData.fathers_name },
      {
        key: "Mandatory Current/Permanent Address",
        value: clientFormData.mandatory_current_or_permanent_address,
      },
      { key: "packageName", value: packageName },
      { key: "location", value: selectedLocation.label },
      { key: "work location", value: clientFormData.work_location },
    ];
    let s = "";
    for (let i = 0; i < f.length; i++) {
      if (!f[i].value) {
        s = s + f[i].key + ",";
      }
    }
    const isDisabled =
      clientFormData.first_name &&
      clientFormData.last_name &&
      clientFormData.date_of_birth &&
      clientFormData.project_id &&
      clientFormData.mandatory_current_or_permanent_address &&
      clientFormData.work_location &&
      // clientFormData.gender &&
      clientFormData.mobile_number &&
      // clientFormData.email_address &&
      clientFormData.fathers_name &&
      packageName &&
      selectedLocation.label;
    if (clientFormData.bridge_ars_no) {
      Toast.info({
        description: "ARS number has already been generated for this case",
        title: "info",
      });
    } else {
      if (!isDisabled) {
        Toast.info({
          hideAfter: 8,
          description: `Please ensure that following mandatory fields are filled before clicking Add Case:${s}`,
          title: "info",
        });
      } else {
        try {
          this.setState({ isAddCaseLoader: true });
          await this.saveCaseDetails(e);
          const response = await api.get(`${CTS_DUPLICITY_CHECK}${pvtId}`);
          if (response.status !== 200) {
            try {
              const result = await api.post(`${CTS_GENERATE_ARS}`, {
                pvt_id: pvtId,
              });
              Toast.success({
                description: "ARS number generated",
                title: "Success",
              });
              this.props.callback();
              this.setState({ isExpand: false });
            } catch (e) {
              this.setState({ isAddCaseLoader: false });
              console.log(e);
            }
          } else {
            const actualData = getCtsDuplicityData(clientFormData);
            this.props.history.push({
              pathname: "/duplicates",
              state: {
                duplicates: response.data,
                actualData,
                userId: userId,
                pvtId,
              },
            });
          }
        } catch (e) {
          this.setState({ isAddCaseLoader: false });
          console.log(e);
        }
      }
    }
  };

  setAlternateMobile = () => {
    let { clientFormData } = this.state;
    clientFormData.alternate_mobile_numbers.push("");
    this.setState({ clientFormData });
  };

  getValues = async () => {
    let getDropdown = {};
    const body = {
      flex_field_id: "23",
    };
    try {
      const res = await api.post(`${GET_DROP_DOWN_VALUES}`, body);
      getDropdown = res.data.map((data) => {
        return {
          value: data.value,
          label: data.value,
        };
      });
      this.setState({ cutOffTimeDropdown: getDropdown });
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const {
      updatedFormData,
      clientFormData,
      selectedDoc,
      isExpand,
      selectDropdown,
      isAddCaseLoader,
      cutOffTimeDropdown,
    } = this.state;
    const { loadDocument, documentList, isCSE, isCaseInsuff, packageName } =
      this.props;
    let isDisable = isCSE || isCaseInsuff || clientFormData.bridge_ars_no;
    return (
      <div>
        {isExpand ? (
          <div>
            <div className="row">
              <div>
                <hr className="brdr-dashed" />
                <div className="col">
                  <div className="small">
                    <h6
                      className={`${
                        isCaseInsuff ? "text-danger" : "text-muted"
                      } font-weight-bold mb-3`}
                    >
                      Client Details
                    </h6>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Candidate Id <sup className="staric-data">*</sup>
                          </label>
                          <CustomInput
                            type={"text"}
                            value={clientFormData.candidate_id}
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.candidate_id = e.target.value;
                              updatedFormData.candidate_id = e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={true}
                            isDisable={isDisable}
                            isExcludeTrim={true}
                          ></CustomInput>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Employee Id <sup className="staric-data">*</sup>
                          </label>
                          <CustomInput
                            type={"text"}
                            value={clientFormData.employee_id}
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.employee_id = e.target.value;
                              updatedFormData.employee_id = e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={true}
                            isDisable={isDisable}
                            isExcludeTrim={true}
                          ></CustomInput>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Date Of Joining<sup className="staric-data">*</sup>
                          </label>

                          <CustomInput
                            type={"Date"}
                            value={clientFormData.date_of_joining}
                            required={true}
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.date_of_joining = e.target.value;
                              updatedFormData.date_of_joining = e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            isDisable={false}
                          ></CustomInput>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Project Name<sup className="staric-data">*</sup>
                          </label>
                          <CustomInput
                            type={"text"}
                            value={clientFormData.project_name}
                            required={false}
                            isDisable={isDisable}
                            isExcludeTrim={true}
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.project_name = e.target.value;
                              updatedFormData.project_name = e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                          ></CustomInput>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Project Id<sup className="staric-data">*</sup>
                          </label>
                          <CustomInput
                            type={"text"}
                            value={clientFormData.project_id}
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.project_id = e.target.value;
                              updatedFormData.project_id = e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={false}
                            isDisable={isDisable}
                            isExcludeTrim={true}
                          ></CustomInput>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Request Id<sup className="staric-data">*</sup>
                          </label>
                          <CustomInput
                            type={"text"}
                            value={clientFormData.request_id}
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.request_id = e.target.value;
                              updatedFormData.request_id = e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={false}
                            isDisable={isDisable}
                            isExcludeTrim={true}
                          ></CustomInput>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Account Name<sup className="staric-data">*</sup>
                          </label>

                          <CustomInput
                            type={"text"}
                            value={clientFormData.account_name}
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.account_name = e.target.value;
                              updatedFormData.account_name = e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={false}
                            isDisable={isDisable}
                            isExcludeTrim={true}
                          ></CustomInput>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Bgv Type<sup className="staric-data">*</sup>
                          </label>
                          <CustomInput
                            type={"text"}
                            value={clientFormData.bgv_type}
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.bgv_type = e.target.value;
                              updatedFormData.bgv_type = e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={false}
                            isDisable={isDisable}
                            isExcludeTrim={true}
                          ></CustomInput>

                          {/* <Selectbox
                            defaultValue={{
                              value: clientFormData.priority_flag
                                ? "Yes"
                                : "No",
                              label: clientFormData.priority_flag
                                ? "Yes"
                                : "No",
                            }}
                            option={[
                              { value: "Yes", label: "Yes" },
                              { value: "No", label: "No" },
                            ]}
                            callback={(selectedOption) => {
                              let { clientFormData } = this.state;
                              clientFormData.priority_flag =
                                selectedOption.value === "Yes" ? 1 : 0;
                              updatedFormData.priority_flag =
                                selectedOption.value === "Yes" ? 1 : 0;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={false}
                            isDisable={isDisable}
                          /> */}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            BU<sup className="staric-data">*</sup>
                          </label>

                          <CustomInput
                            type={"text"}
                            value={clientFormData.bu}
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.bu = e.target.value;
                              updatedFormData.bu = e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={false}
                            isDisable={isDisable}
                            isExcludeTrim={true}
                          ></CustomInput>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Tennessee
                          </label>

                          <Selectbox
                            defaultValue={{
                              value: clientFormData.tennessee ? "Yes" : "No",
                              label: clientFormData.tennessee ? "Yes" : "No",
                            }}
                            option={[
                              { value: "Yes", label: "Yes" },
                              { value: "No", label: "No" },
                            ]}
                            callback={(selectedOption) => {
                              let { clientFormData } = this.state;
                              clientFormData.tennessee =
                                selectedOption.value === "Yes" ? true : false;
                              updatedFormData.tennessee =
                                selectedOption.value === "Yes" ? true : false;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={false}
                            isDisable={isDisable}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Suspect Case
                          </label>

                          <Selectbox
                            defaultValue={{
                              value: clientFormData.suspect_case ? "Yes" : "No",
                              label: clientFormData.suspect_case ? "Yes" : "No",
                            }}
                            option={[
                              { value: "Yes", label: "Yes" },
                              { value: "No", label: "No" },
                            ]}
                            callback={(selectedOption) => {
                              let { clientFormData } = this.state;
                              clientFormData.suspect_case =
                                selectedOption.value === "Yes" ? true : false;
                              updatedFormData.suspect_case =
                                selectedOption.value === "Yes" ? true : false;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={false}
                            isDisable={isDisable}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            M&A
                          </label>

                          <CustomInput
                            type={"text"}
                            value={clientFormData.m_and_a}
                            onChange={(e) => {}}
                            required={false}
                            isDisable={true}
                          ></CustomInput>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Department
                          </label>

                          <CustomInput
                            type={"text"}
                            value={clientFormData.department}
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.department = e.target.value;
                              updatedFormData.department = e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={false}
                            isDisable={isDisable}
                            isExcludeTrim={true}
                          ></CustomInput>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Account Group
                          </label>

                          <CustomInput
                            type={"text"}
                            value={clientFormData.account_group}
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.account_group = e.target.value;
                              updatedFormData.account_group = e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={false}
                            isDisable={isDisable}
                            isExcludeTrim={true}
                          ></CustomInput>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            BGV Sub Status
                          </label>

                          <CustomInput
                            type={"text"}
                            value={clientFormData.bgv_sub_status}
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.bgv_sub_status = e.target.value;
                              updatedFormData.bgv_sub_status = e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={true}
                            isDisable={isDisable}
                            isExcludeTrim={true}
                          ></CustomInput>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Client <sup className="staric-data">*</sup>
                          </label>

                          <CustomInput
                            type={"text"}
                            value={clientFormData.client}
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.client = e.target.value;
                              updatedFormData.client = e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={true}
                            isDisable={isDisable}
                            isExcludeTrim={true}
                          ></CustomInput>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            BGV Stop
                          </label>

                          <Selectbox
                            defaultValue={{
                              value: clientFormData.bgv_stop,
                              label: clientFormData.bgv_stop,
                            }}
                            option={[
                              { value: "Yes", label: "Yes" },
                              { value: "No", label: "No" },
                              {
                                value: "Not Mentioned",
                                label: "Not Mentioned",
                              },
                            ]}
                            callback={(selectedOption) => {
                              let { clientFormData } = this.state;
                              clientFormData.bgv_stop = selectedOption.value;
                              updatedFormData.bgv_stop = selectedOption.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={false}
                            isDisable={isDisable}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Report uploaded date
                          </label>

                          <CustomInput
                            type={"text"}
                            value={clientFormData.report_uploaded_date}
                            onChange={(e) => {}}
                            required={false}
                            isDisable={true}
                          ></CustomInput>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Is Form 16 mandatory for verification
                          </label>

                          <Selectbox
                            defaultValue={{
                              value: clientFormData.form_16_mandatory
                                ? "Yes"
                                : "No",
                              label: clientFormData.form_16_mandatory
                                ? "Yes"
                                : "No",
                            }}
                            option={[
                              { value: "Yes", label: "Yes" },
                              { value: "No", label: "No" },
                            ]}
                            callback={(selectedOption) => {
                              let { clientFormData } = this.state;
                              clientFormData.form_16_mandatory =
                                selectedOption.value === "Yes" ? true : false;
                              updatedFormData.form_16_mandatory =
                                selectedOption.value === "Yes" ? true : false;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={true}
                            isDisable={isDisable}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Work location <sup className="staric-data">*</sup>
                          </label>

                          <CustomInput
                            type={"text"}
                            value={clientFormData.work_location}
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.work_location = e.target.value;
                              updatedFormData.work_location = e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            isExcludeTrim={true}
                            required={true}
                            isDisable={isDisable}
                          ></CustomInput>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            LOA received date
                          </label>
                          <CustomInput
                            type={"Date"}
                            value={clientFormData.loa_received_date}
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.loa_received_date = e.target.value;
                              updatedFormData.loa_received_date =
                                e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={true}
                            isDisable={isDisable}
                          ></CustomInput>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <label className="text-muted text-uppercase label-header">
                          Mandatory Current/Permanent Address
                          <sup className="staric-data">*</sup>
                        </label>
                        <div>
                          <Selectbox
                            option={[
                              {
                                label: "Greater Then 6 Months",
                                value: "Greater Then 6 Months",
                              },
                              {
                                label: "Less Then 6 Months",
                                value: "Less Then 6 Months",
                              },
                            ]}
                            defaultValue={
                              clientFormData.mandatory_current_or_permanent_address
                                ? {
                                    label:
                                      clientFormData.mandatory_current_or_permanent_address,
                                    value:
                                      clientFormData.mandatory_current_or_permanent_address,
                                  }
                                : null
                            }
                            callback={(selectObj) => {
                              let { clientFormData } = this.state;
                              clientFormData.mandatory_current_or_permanent_address =
                                selectObj.value;
                              updatedFormData.mandatory_current_or_permanent_address =
                                selectObj.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            isDisable={isDisable}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label className="text-muted text-uppercase label-header">
                          Cut off date after 8 pm
                          <sup className="staric-data">*</sup>
                        </label>
                        <div>
                          <Selectbox
                            option={cutOffTimeDropdown}
                            defaultValue={
                              clientFormData.cut_off_date_after_8_pm
                                ? {
                                    label:
                                      clientFormData.cut_off_date_after_8_pm,
                                    value:
                                      clientFormData.cut_off_date_after_8_pm,
                                  }
                                : null
                            }
                            callback={(selectObj) => {
                              let { clientFormData } = this.state;
                              clientFormData.cut_off_date_after_8_pm =
                                selectObj.value;
                              updatedFormData.cut_off_date_after_8_pm =
                                selectObj.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            isDisable={isDisable}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <label className="text-muted text-uppercase label-header">
                        PA,A and SA level
                        </label>
                        <div>
                          <Selectbox
                            option={[
                              {
                                  "value": "No",
                                  "label": "No"
                              },
                              {
                                  "value": "Yes",
                                  "label": "Yes"
                              }
                          ]}
                          defaultValue={
                            clientFormData.palevel
                              ? {
                                  label:
                                    clientFormData.palevel,
                                  value:
                                    clientFormData.palevel,
                                }
                              : null
                          }
                            callback={(selectObj) => {
                              let { clientFormData } = this.state;
                              clientFormData.palevel =
                                selectObj.value;
                              updatedFormData.palevel =
                                selectObj.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            isDisable={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                           Grade
                          </label>
                          <CustomInput
                            type="text"
                            value={clientFormData.grade}
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.grade = e.target.value;
                              updatedFormData.grade =
                                e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={true}
                            isDisable={true}
                          ></CustomInput>
                        </div>
                      </div>



                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                          Candidate name as per NID
                          </label>
                          <CustomInput
                            type="text"
                            value={clientFormData.candidate_name_as_per_NID}
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.candidate_name_as_per_NID = e.target.value;
                              updatedFormData.candidate_name_as_per_NID =
                                e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={true}
                            isDisable={true}
                          ></CustomInput>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                          Candidate name as per education doc
                          </label>
                          <CustomInput
                            type="text"
                            value={clientFormData.candidate_name_as_per_education_document}
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.candidate_name_as_per_education_document = e.target.value;
                              updatedFormData.candidate_name_as_per_education_document =
                                e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={true}
                            isDisable={true}
                          ></CustomInput>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                          Have we checked the review
                          </label>
                          <div>
                          <Selectbox
                            option={[
                              {
                                  "value": "No",
                                  "label": "No"
                              },
                              {
                                  "value": "Yes",
                                  "label": "Yes"
                              }
                          ]}
                            defaultValue={
                              clientFormData.have_we_checked_the_review
                                ? {
                                    label:
                                      clientFormData.have_we_checked_the_review,
                                    value:
                                      clientFormData.have_we_checked_the_review,
                                  }
                                : null
                            }
                            callback={(selectObj) => {
                              let { clientFormData } = this.state;
                              clientFormData.have_we_checked_the_review =
                                selectObj.value;
                              updatedFormData.have_we_checked_the_review =
                                selectObj.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            isDisable={isDisable}
                          />
                        </div>
                        </div>
                      </div>


                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <DocSelection
                          callback={this.getSelectDoc}
                          loadDocument={loadDocument}
                          docArray={selectedDoc}
                          documentCheck={getDocuments()}
                          selectDropdown={selectDropdown}
                          isDisable={isDisable}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <hr className="brdr-dashed" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <h6
                          className={`${
                            isCaseInsuff ? "text-danger" : "text-muted"
                          } font-weight-bold mb-3`}
                        >
                          Candidate Details
                        </h6>
                        <div
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginBottom: "20px",
                          }}
                        >
                          All (*) fields are required!
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                First Name<sup className="staric-data">*</sup>
                              </label>
                              <CustomInput
                                type={"text"}
                                value={clientFormData.first_name}
                                onChange={(e) => {
                                  let { clientFormData } = this.state;
                                  clientFormData.first_name = e.target.value;
                                  updatedFormData.first_name = e.target.value;
                                  this.setState({
                                    clientFormData,
                                    updatedFormData,
                                  });
                                }}
                                required={false}
                                isDisable={isDisable}
                              ></CustomInput>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Middle Name
                              </label>
                              <CustomInput
                                type={"text"}
                                value={clientFormData.middle_name}
                                onChange={(e) => {
                                  let { clientFormData } = this.state;
                                  clientFormData.middle_name = e.target.value;
                                  updatedFormData.middle_name = e.target.value;
                                  this.setState({
                                    clientFormData,
                                    updatedFormData,
                                  });
                                }}
                                required={true}
                                isDisable={isDisable}
                              ></CustomInput>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Last Name <sup className="staric-data">*</sup>
                              </label>
                              <CustomInput
                                type={"text"}
                                value={clientFormData.last_name}
                                onChange={(e) => {
                                  let { clientFormData } = this.state;
                                  clientFormData.last_name = e.target.value;
                                  updatedFormData.last_name = e.target.value;
                                  this.setState({
                                    clientFormData,
                                    updatedFormData,
                                  });
                                }}
                                required={true}
                                isDisable={isDisable}
                              ></CustomInput>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Date of Birth
                                <sup className="staric-data">*</sup>
                              </label>
                              <CustomInput
                                type={"Date"}
                                value={clientFormData.date_of_birth}
                                onChange={(e) => {
                                  let { clientFormData } = this.state;
                                  clientFormData.date_of_birth = e.target.value;
                                  updatedFormData.date_of_birth =
                                    e.target.value;
                                  this.setState({
                                    clientFormData,
                                    updatedFormData,
                                  });
                                }}
                                required={false}
                                isDisable={isDisable}
                              ></CustomInput>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Gender
                                {/* <sup className="staric-data">*</sup> */}
                              </label>

                              <Selectbox
                                defaultValue={{
                                  value: clientFormData.gender
                                    ? clientFormData.gender
                                    : null,
                                  label: clientFormData.gender
                                    ? clientFormData.gender
                                    : null,
                                }}
                                option={[
                                  { value: "Male", label: "Male" },
                                  { value: "Female", label: "Female" },
                                ]}
                                callback={(selectedOption) => {
                                  let { clientFormData } = this.state;
                                  clientFormData.gender = selectedOption.value;
                                  updatedFormData.gender = selectedOption.value;
                                  this.setState({
                                    clientFormData,
                                    updatedFormData,
                                  });
                                }}
                                required={true}
                                isDisable={isDisable}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Email
                                {/* <sup className="staric-data">*</sup> */}
                              </label>

                              <CustomInput
                                type={"text"}
                                value={clientFormData.email_address}
                                onChange={(e) => {
                                  let { clientFormData } = this.state;
                                  clientFormData.email_address = e.target.value;
                                  updatedFormData.email_address =
                                    e.target.value;
                                  this.setState({
                                    clientFormData,
                                    updatedFormData,
                                  });
                                }}
                                required={false}
                                isDisable={isDisable}
                              ></CustomInput>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-md-4">
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Father Name<sup className="staric-data">*</sup>
                              </label>
                              <CustomInput
                                type={"text"}
                                value={clientFormData.fathers_name}
                                onChange={(e) => {
                                  let { clientFormData } = this.state;
                                  clientFormData.fathers_name = e.target.value;
                                  updatedFormData.fathers_name = e.target.value;
                                  this.setState({
                                    clientFormData,
                                    updatedFormData,
                                  });
                                }}
                                required={true}
                                isDisable={isDisable}
                              ></CustomInput>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Mobile Number{" "}
                                <sup className="staric-data">*</sup>
                              </label>

                              <CustomInput
                                type={"text"}
                                value={clientFormData.mobile_number}
                                onChange={(e) => {
                                  let { clientFormData } = this.state;
                                  clientFormData.mobile_number = e.target.value;
                                  updatedFormData.mobile_number =
                                    e.target.value;
                                  this.setState({
                                    clientFormData,
                                    updatedFormData,
                                  });
                                }}
                                required={true}
                                isDisable={isDisable}
                              ></CustomInput>
                            </div>
                          </div>
                          { packageName === "Campus Bschool" || packageName === "Campus Tschool" ?
                            <div className="col-md-4">
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Name As On Pan Card
                                {/* <sup className="staric-data">*</sup> */}
                              </label>
                              <CustomInput
                                type={"text"}
                                value={clientFormData.name_as_on_pan_card}
                                onChange={(e) => {
                                  let { clientFormData } = this.state;
                                  clientFormData.name_as_on_pan_card = e.target.value;
                                  updatedFormData.name_as_on_pan_card = e.target.value;
                                  this.setState({
                                    clientFormData,
                                    updatedFormData,
                                  });
                                }}
                                required={true}
                                isDisable={true}
                              ></CustomInput>
                            </div>
                          </div> : ""}
                        </div>
                        alternate mobile
                        <div class="row">
                          <div className="col-md-6">
                            <label className="text-muted text-uppercase label-header">
                              Alternate Mobile Number{" "}
                              <button
                                className="transparent-borderless"
                                onClick={() => {
                                  this.setAlternateMobile();
                                }}
                                disabled={isDisable}
                              >
                                <i className="fa fa-plus icon-primary alter-plus"></i>
                              </button>
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          {clientFormData.alternate_mobile_numbers &&
                            clientFormData.alternate_mobile_numbers.map(
                              (value, index) => {
                                return (
                                  <div className="col-md-4" key={index}>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className={`input-section form-control text-primary ${
                                          isDisable ? "row-disabled" : ""
                                        }`}
                                        value={value}
                                        onChange={(e) => {
                                          let { clientFormData } = this.state;
                                          clientFormData.alternate_mobile_numbers[
                                            index
                                          ] = e.target.value;
                                          updatedFormData.alternate_mobile_numbers =
                                            [
                                              ...clientFormData.alternate_mobile_numbers,
                                            ];
                                          this.setState({
                                            clientFormData,
                                            updatedFormData,
                                          });
                                        }}
                                        disabled={isDisable}
                                      ></input>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                        <div class="row">
                          <div class="col">
                            <hr class="brdr-dashed" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <h6
                              className={`${
                                isCaseInsuff ? "text-danger" : "text-muted"
                              } font-weight-bold mb-3`}
                            >
                              National Identity Document
                            </h6>
                            <div class="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className="text-muted text-uppercase label-header">
                                    Pan Card No.
                                  </label>
                                  <CustomInput
                                    type={"text"}
                                    value={clientFormData.pan}
                                    onChange={(e) => {
                                      let { clientFormData } = this.state;
                                      clientFormData.pan = e.target.value;
                                      updatedFormData.pan = e.target.value;
                                      this.setState({
                                        clientFormData,
                                        updatedFormData,
                                      });
                                    }}
                                    required={true}
                                    isDisable={isDisable}
                                  ></CustomInput>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className="text-muted text-uppercase label-header">
                                    Passport Number
                                  </label>
                                  <CustomInput
                                    type={"text"}
                                    value={clientFormData.passport}
                                    onChange={(e) => {
                                      let { clientFormData } = this.state;
                                      clientFormData.passport = e.target.value;
                                      updatedFormData.passport = e.target.value;
                                      this.setState({
                                        clientFormData,
                                        updatedFormData,
                                      });
                                    }}
                                    required={true}
                                    isDisable={isDisable}
                                  ></CustomInput>
                                </div>
                              </div>

                              {/* <div className="col-md-4">
                                <div className="form-group">
                                  <label className="text-muted text-uppercase label-header">
                                    Voter ID
                                  </label>

                                  <CustomInput
                                    type={"text"}
                                    value={clientFormData.voter}
                                    onChange={(e) => {
                                      let { clientFormData } = this.state;
                                      clientFormData.voter = e.target.value;
                                      updatedFormData.voter = e.target.value;
                                      this.setState({
                                        clientFormData,
                                        updatedFormData,
                                      });
                                    }}
                                    required={true}
                                    isDisable={isDisable}
                                  ></CustomInput>
                                </div>
                              </div> */}
                            </div>

                            {/* <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                  <label className="text-muted text-uppercase label-header">
                                    Driving License
                                  </label>
                                  <CustomInput
                                    type={"text"}
                                    value={clientFormData.driving_license}
                                    onChange={(e) => {
                                      let { clientFormData } = this.state;
                                      clientFormData.driving_license =
                                        e.target.value;
                                      updatedFormData.driving_license =
                                        e.target.value;
                                      this.setState({
                                        clientFormData,
                                        updatedFormData,
                                      });
                                    }}
                                    required={true}
                                    isDisable={isDisable}
                                  ></CustomInput>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <hr className="brdr-dashed" />
                      <div className="col">
                        {!isAddCaseLoader ? (
                          <button
                            className="btn btn-primary btn-sm float-right m-2"
                            // type="submit"
                            disabled={this.props.isCaseInsuff || isCSE}
                            onClick={(e) => {
                              this.submit(e);
                            }}
                          >
                            Add Case
                          </button>
                        ) : (
                          <div
                            class="spinner-border text-info float-right m-2"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        )}
                        <button
                          className="btn btn-primary btn-sm float-right m-2 "
                          disabled={this.props.isCaseInsuff || isCSE}
                          onClick={(e) => {
                            this.saveCaseDetails(e, "save");
                          }}
                        >
                          Save Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto text-center expand-link text-primary small position-relative">
                &nbsp;&nbsp;
                <i
                  className="fa fa-caret-up text-muted"
                  style={{ fontSize: "36px" }}
                  onClick={() => {
                    this.setState({ isExpand: !isExpand });
                  }}
                ></i>
              </div>
            </div>
          </div>
        ) : (
          <div className="row justify-content-center">
            <div className="col-auto text-center expand-link text-primary small position-relative">
              &nbsp;&nbsp;
              <i
                className="fa fa-caret-down text-muted"
                style={{ fontSize: "36px" }}
                onClick={() => {
                  this.setState({ isExpand: !isExpand });
                }}
              ></i>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(ClientDetail);
