/***********
 * Modal Wrapper Component
 ***********/

import React, { Component } from "react";
import ModalDialog, { ModalTransition } from "@atlaskit/modal-dialog";
import "./modalwrapper.scss";

const Header = (props) => {
  return (
    <div className="header-block">
      <div className="heading">{props.heading ? props.heading : ""}</div>
      <div
        onClick={() => {
          props.closeModal();
        }}
        className="cross"
      >
        <i class="fa fa-times" aria-hidden="true"></i>
      </div>
    </div>
  );
};
export default class ModalWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      modals: [],
    };
  }

  /**
   * @param  {object} {...data}
   * getting component as a parameter and open the bottom sheet
   */
  openModal = ({ ...args }) => {
    let { modals = [] } = this.state;
    args.isOpen = true;
    modals.push({ ...args });

    this.setState({ modals });
  };

  /**
   * close the bottom sheet
   */
  closeModal = (key) => {
    let { modals = [] } = this.state;
    if (typeof key === "undefined" || typeof key === "object" || key === null) {
      modals.pop();
    } else {
      modals.splice(key, 1);
    }

    this.setState({ modals });
  };

  /**
   * @width : "small","medium","large","x-large"
   * @scrollBehavior :"inside","outside"
   * @height : number,string
   */

  render() {
    const { modals } = this.state;
    return (
      <div className="modal-wrapper">
        {modals.map((modal, key) => {
          return (
            <div>
              <ModalTransition key={key}>
                {modal.isOpen && (
                  <ModalDialog
                    height={"auto"}
                    onClose={modal.onClose ? modal.onClose : null}
                    width={modal.width ? modal.width : "medium"}
                  >
                    {modal.isHeader && (
                      <Header
                        heading={modal.heading}
                        closeModal={this.closeModal}
                      />
                    )}
                    <div className="modal-body">{modal.component()}</div>
                  </ModalDialog>
                )}
              </ModalTransition>
            </div>
          );
        })}
      </div>
    );
  }
}
