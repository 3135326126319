import React, { Component } from "react";
import "./toggle.scss";

export default class Toggle extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.isToggleOn !== this.props.isToggleOn) {
      return true;
    }
    return false;
  }
  render() {
    return (
      <div className="switch-container">
        <label class="switch">
          <input
            type="checkbox"
            onChange={() => {
              this.props.callback(!this.props.isToggleOn);
            }}
            checked={this.props.isToggleOn}
          ></input>
          <span class="slider round"></span>
        </label>
        <span>Submitted checks</span>
      </div>
    );
  }
}
