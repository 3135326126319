import React from "react";
import "./loadingScreen.scss";
import ModalDialog, { ModalTransition } from "@atlaskit/modal-dialog";

export default function LoadingScreen({ isLoading }) {
  return (
    <ModalTransition>
      {isLoading && (
        <ModalDialog width="50px" isChromeless={true}>
          <div className="loading-container">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </ModalDialog>
      )}
    </ModalTransition>
  );
}
