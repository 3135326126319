import React, { Component } from "react";
import ModalDialog, { ModalTransition } from "@atlaskit/modal-dialog";
import "./addMoreDocumentsModal.scss";

export default class AddMoreDocumentsModal extends Component {
  render() {
    return (
      <ModalTransition>
        {this.props.isDocumentListModalOpen && (
          <ModalDialog>
            <div className="modal-body small modal-container">
              <div className="row">
                <div className="col-md-12 heading-container">
                  <h5 className="text-primary">
                    Add More documents for Tagging
                  </h5>
                  <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    onClick={() => this.props.closeModal()}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>

              <hr className="border-top-0 border-bottom" />

              <div className="docs-name-list">
                {this.props.documentsPerCheck.map(document => (
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        className="btn btn-outline-primary mb-2 rounded btn-block transparent-borderless"
                        onClick={() => this.props.addDocs(document)}
                      >
                        <span
                          className="float-left wh20 btn bg-white rounded-circle p-1 d-flex align-items-center justify-content-center"
                          style={{ display: "none" }}
                        >
                          <i className="fa fa-plus icon-primary"></i>
                        </span>
                        <span> {document.documentName}</span>
                      </button>
                    </div>
                  </div>
                ))}
              </div>

              <div className="row">
                <div className="col-md-12">
                  <button
                    className="btn btn-primary btn-sm float-right"
                    onClick={() => this.props.closeModal}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
            {/* </div> */}
          </ModalDialog>
        )}
      </ModalTransition>
    );
  }
}
