import { ActionTypes } from "./login.types";

const initialState = {
  userInfo: {
    accessToken: "",
    roleName: "",
    userId: "",
    userFirstName: "",
    userLastName: "",
    roleId: "",
    clientId: "",
    clientName: "",
    email: "",
  },
};

export default function rootReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        userInfo: {
          accessToken: state.userInfo.accessToken,
          roleName: payload.role_name,
          userId: payload.id,
          userFirstName: payload.first_name,
          userLastName: payload.last_name,
          roleId: payload.role_id,
          email: payload.email,
          clientId: payload.client.id,
          clientName: payload.client.name,
        },
      };
    case ActionTypes.LOGIN_FAIL:
      return {
        ...state,
        userInfo: {
          accessToken: "",
          roleName: "",
          userId: "",
          userFirstName: "",
          userLastName: "",
          roleId: "",
          clientId: "",
          clientName: "",
          email: "",
        },
      };
    case ActionTypes.LOGOUT:
      return {
        ...state,
        userInfo: {
          accessToken: "",
          roleName: "",
          userId: "",
          userFirstName: "",
          userLastName: "",
          roleId: "",
          clientId: "",
          clientName: "",
          email: "",
        },
      };
    case ActionTypes.UPDATE:
      return {
        ...state,
        userInfo: {
          accessToken: payload.token,
          roleName: "",
          userId: "",
          userFirstName: "",
          userLastName: "",
          roleId: "",
          clientId: "",
          clientName: "",
          email: "",
        },
      };

    default:
      return state;
  }
}
