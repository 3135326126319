import { TimerAction } from "./timer.type";

export const pause = payload => ({
  type: TimerAction.PAUSE,
  payload: payload
});

export const resume = payload => ({
  type: TimerAction.RESUME,
  payload: payload
});

export const hold = payload => ({
  type: TimerAction.HOLD,
  payload:payload
});
export const deleteAction = payload => ({
  type: TimerAction.DELETE,
  payload:payload
});

