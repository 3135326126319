import React, { useReducer, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { modalManager } from "../../utils/modalutils";
import {Toast} from '../../utils/toastutils';
import { loginSuccess } from "../../screens/login/state/login.actions";
import Selectbox from "../Selectbox/selectbox";
import { ROLE_ID, SET_CLIENT } from "../../apiurl";
import api from "../../apiBaseConfig";

import "./changeUserRole.scss";

function reducer(state, action) {
  return action;
}
const ChangeUser = (props) => {
  const [roleArray, dispatch] = useReducer(reducer, []);
  const [roleObj, setRole] = useState({});
  const { userInfo: userDetail } = useSelector((state) => ({
    userInfo: state.login.userInfo,
  }));
  const dispatchLogin = useDispatch();


  useEffect(() => {
    getRoleId();
  }, []);

  const getRoleId = async () => {
    try {
      const response = await api.get(ROLE_ID);
      const roleArray = response.data.map((role) => {
        return { ...role, label: role.role_name };
      });
      dispatch(roleArray);
    } catch (e) {}
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.patch(SET_CLIENT, {
        client_id: userDetail.clientId,
        role_id: roleObj.role_id,
        role_name: roleObj.role_name,
      });

      dispatchLogin(
        loginSuccess({
          ...response.data,
        })
      );
      Toast.success({
        description: "change user role successfully",
      });
      modalManager.close({});
      if (roleObj.role_id == 71) {
        // return <Redirect push to="/qcdashboard"/>

        window.location.href = "/qcdashboard";
      }
      if (roleObj.role_id == 72) {
    
      //  return <Redirect push to="/dashboard"/>
         window.location.href = "/dashboard";
      }
    } catch (e) {}
  };

  return (
    <div className="user-container-box">
      <div className="costuminput">
        <label>Select Role Id</label>
        <Selectbox
          option={roleArray}
          callback={(value) => {
            setRole({ ...value });
          }}
          // defaultValue={null}
        />
      </div>

      <div className="client-button-section">
        <button
          class="btn btn-primary btn-sm ml-2"
          onClick={(e) => {
            handleSubmit(e);
          }}
          disabled={!roleObj.role_id}
        >
          SUBMIT
        </button>
      </div>
    </div>
  );
};

export default ChangeUser;
