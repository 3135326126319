import React from "react";
import "./raiseResearchModal.scss";

export default function RaiseResearchModal({
  callback,
  researchText,
  closeModal,
}) {
  const comment = `Raise Research - ${researchText}`;
  return (
    <div className="raise-research-modal-container">
      <div className="d-flex justify-content-between">
        <h5 className="text-muted">
          Do you want to{" "}
          <span class="text-danger">raise research request </span>?
        </h5>
        <button
          type="button"
          class="close"
          aria-label="Close"
          onClick={() => closeModal()}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <hr class="border-top-0 border-bottom" />
      <div class="row  form-group">
        <div class="col-md-12">
          <textarea
            className="form-control raise-research-modal-textarea "
            value={comment}
            rows="5"
          ></textarea>
        </div>
      </div>

      <div>
        <div class="btn-container">
          {/* <button
            className="btn btn-danger btn-sm "
            onClick={() => callback(false)}
          >
            No
          </button> */}
          <button
            className="btn btn-primary btn-sm "
            onClick={() => callback(true, comment)}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
}
