/**
 * timer component
 * use for timer update for any case
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./bucketTimer.scss";
import api from "./../../../apiBaseConfig";
import {
  CASE_HISTORY_COMMENT,
  SUBMIT_COMMENT,
  INFY_CHANGE_STATUS_CASE,
  INFY_CSIS_DOWNLOAD,
  INFY_UPLOAD_JAF,
  INFY_BUCKET_CASES,
} from "./../../../apiurl";
import { formatTime } from "./../../../utils/commonutils";
import {
  pause,
  resume,
  hold,
  deleteAction,
} from "./../../../components/buckettimer/state/timer.action";
import Escalation from "./../../../components/Escalation/escalation";
import { modalManager } from "./../../../utils/modalutils";
import { constants } from "./../../../constants";
import { Toast } from "./../../../utils/toastutils";
import { Redirect } from "react-router-dom";
import Csisupload from "./../../../components/buckettimer/component/csisupload";
import Toggle from "./../../../screens/bucketDetails/components/toggle/toggle";
import Selectbox from "./../../../components/Selectbox/selectbox";
import LoadingScreen from "../../../components/loadingScreen/loadingScreen";
let myInterval;
class BUCKETTIMER extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTimer: false,
      minutes: 120,
      seconds: 0,
      actionType: "resume",
      csisUrl: [],
      listDoc: {},
      selectedDocument: null,
      isLoading: false,
    };
  }

  componentDidMount = () => {
    const { timerObj, status, isCse, pvtId } = this.props;
    const timer = timerObj && timerObj[pvtId];
    //this.getCsis();
    this.getList();
    if (!isCse) {
      if (status === "Onhold") {
        this.setState({ actionType: "hold" });
      } else {
        if (timer) {
          if (timer.actionType === "pause") {
            this.setState(
              {
                minutes: timer.minutes,
                seconds: timer.seconds,
                actionType: timer.actionType,
              },
              () => {
                clearInterval(myInterval);
              }
            );
          } else {
            this.setState(
              {
                minutes: timer.minutes,
                seconds: timer.seconds,
                actionType: timer.actionType,
              },
              () => {
                this.setTimer();
              }
            );
          }
        } else {
          this.props.resume({
            minutes: 120,
            seconds: 0,
            actionType: "resume",
            pvtId: pvtId,
          });
          this.setState(
            {
              minutes: 120,
              seconds: 0,
              actionType: "resume",
            },
            () => {
              this.setTimer();
            }
          );
        }
      }
    }
  };

  componentWillUnmount() {
    clearInterval(myInterval);
  }

  getList = () => {
    const { listDoc } = this.state;
    const { documentList } = this.props;
    const selectList = documentList.map((item) => {
      return { ...item, value: item.type, label: item.type };
    });
    this.setState({
      listDoc: selectList,
    });
  };
  /**
   * this function use for update the timer dynamicaly
   */
  setTimer = (flag) => {
    myInterval = setInterval(() => {
      const { seconds, minutes } = this.state;
      this.props.resume({
        minutes: minutes,
        seconds: seconds,
        actionType: "resume",
        pvtId: this.props.pvtId,
      });

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
          this.changeStatus();
          this.props.deleteAction({ pvtId: this.props.pvtId });
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59,
          }));
        }
      }
    }, 1000);
  };

  // getCsis = async () => {
  //   let arr = [];
  //   try {
  //     const response = await api.get(INFY_CSIS_DOWNLOAD);
  //     for (let i = 0; i < response.length; i++) {
  //       arr.push({
  //         url: `${process.env.REACT_APP_FILE_URL}${response[i].data.path}`,
  //       });
  //     }
  //     this.setState({ csisUrl: arr });
  //   } catch (e) {
  //     console.log("ee");
  //   }
  // };

  changeStatus = async () => {
    const { pvtId } = this.props;
    const body = {
      status: constants.caseStatus.UNASSIGNED,
    };
    try {
      const response = await api.patch(
        `${INFY_CHANGE_STATUS_CASE}${pvtId}/`,
        body
      );
      this.setState({ isTimer: true }, () => {
        Toast.info({ description: "time is over", header: "info" });
      });
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * this function use for pause the timer
   */
  pause = () => {
    const { minutes, seconds } = this.state;
    clearInterval(myInterval);
    this.setState({ minutes, seconds, actionType: "pause" }, () => {
      const { minutes, seconds } = this.state;
      this.props.pause({
        minutes,
        seconds,
        actionType: "pause",
        pvtId: this.props.pvtId,
      });
    });
  };

  /**
   * use for taking action {play,pause}
   */
  submitApi = async (flag) => {
    const { userId = "", pvtId = "" } = this.props;
    const { minutes, seconds } = this.state;
    const timeStemp = minutes * 60 + seconds;
    // const body = {
    //   userId,
    //   pvtId: parseInt(pvtId),
    // };
    // if (flag === "play") {
    //   body["actionType"] = "play";
    // } else {
    //   body["actionType"] = "pause";
    //   body["actionTakenAt"] = formatTime(timeStemp);
    // }

    const body = { timer: timeStemp, action: flag };
    try {
      // const response = await api.post(`${PLAY_PAUSE}`, body);
      const result = await api.patch(
        `${INFY_CHANGE_STATUS_CASE}${pvtId}/`,
        body
      );
      if (flag === "play") {
        this.resume();
      } else {
        this.pause();
      }
    } catch (e) {
      Toast.error({
        description: "something went wrong",
        title: "Error",
      });
      console.log(e);
    }
  };

  /**
   * resume the timer
   */
  resume = () => {
    const { minutes, seconds } = this.state;
    this.setState({ actionType: "resume" }, () => {
      this.props.resume({
        actionType: "resume",
        minutes,
        seconds,
        pvtId: this.props.pvtId,
      });
      this.setTimer();
    });
  };

  /**
   * this is for submit the case history comment
   */
  submitCaseComment = async (commentObject) => {
    const { bridgeCaseId } = this.props;
    try {
      const action = await api.post(
        `${CASE_HISTORY_COMMENT}?pvt_id=${commentObject.pvtId}`,
        {
          pvt_id: commentObject.pvtId,
          name: commentObject.action
            ? commentObject.action
            : "Comment Added on case",
          created_by: commentObject.email,
        }
      );
      const actionId = action.data.id;
      const response = await api.post(SUBMIT_COMMENT, {
        message: commentObject.comment,
        action: actionId,
        bridge_case_id: bridgeCaseId,
      });
      return Promise.resolve(response);
    } catch (error) {
      console.log("error", error);
      return Promise.reject(error);
    }
  };

  /**
   * this function help for hold the case and add the comment in case history
   */
  submitHoldComment = async (obj) => {
    const { userInfo } = this.props;
    const body = {
      status: obj.status,
    };
    try {
      const commentObj = {
        comment: obj.comment,
        pvtId: obj.pvtId,
        email: userInfo.email,
        action: obj.action,
      };
      const responseObj = await this.submitCaseComment(commentObj);
      const response = await api.patch(
        `${INFY_CHANGE_STATUS_CASE}${obj.pvtId}/`,
        body
      );
      Toast.success({
        description: "change status successfully",
      });
      modalManager.close({});
      clearInterval(myInterval);
      this.setState({ actionType: "hold" });
      this.props.hold({ actionType: "hold", pvtId: this.props.pvtId });
      this.props.history.push("/qcdashboard");
      //this.props.getCaseDetail();
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * hold the case
   */
  onHold = async () => {
    debugger
    const { pvtId, userName, userId } = this.props;
    const data = {
      label: "onhold",
      userName: userName,
      pvtId: pvtId,
      userId,
      status: constants.caseStatus.ON_HOLD,
      action: "Hold",
    };
    try{
      const res = await api.get(`${INFY_BUCKET_CASES}?status=on_hold`);
      if(res.data.count < 3){
        modalManager.open({
          isHeader: false,
          component: () => (
            <Escalation
            callback={this.submitHoldComment}
            data={data}
            btnLabel="Submit"
            />
            ),
          });
        }else{
          Toast.error({
            description: "Only 3 cases allowed on Hold",
          });
        }
    }catch(e){
    console.log(e);
    }
  };

  /**
   * download the csis excel sheet
   */
  openUrl = async () => {
    let csisArray = [];
    try {
      const csisFile = await api.get(INFY_CSIS_DOWNLOAD);
      const fileData = csisFile.data;
      Object.keys(fileData).map((key) => {
        csisArray.push({
          url: `${process.env.REACT_APP_FILE_URL}${fileData[key]}`,
        });
      });
      for (var i = 0; i < csisArray.length; i++) {
        setTimeout(
          function (path) {
            window.location = path;
          },
          1000 + i * 1000,
          csisArray[i].url
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  // getDownloadCsis = (id) => {
  //   return api.get(`${UPLOAD_CSIS}${id}/`);
  // };
  /**
   * this help for upload the csis file
   */
  // uploadCsis = async (data, formData) => {
  //   try {
  //     const response = await api.post(INFY_CSIS_DOWNLOAD, formData);
  //     modalManager.close({});
  //     Toast.success({
  //       description: "csis uploaded successfully",
  //       title: "success",
  //     });
  //     // this.getCsis();
  //   } catch (e) {
  //     Toast.error({ description: "somthing went wrong", title: "error" });
  //   }
  // };

  // openWindow = () => {
  //   modalManager.open({
  //     width: "small",
  //     component: () => (
  //       <Csisupload
  //         callback={this.uploadCsis}
  //         csisArray={[
  //           {
  //             label: "Final Offshore Customer Package",
  //             version: 0,
  //             id: 1,
  //             type: "final_offshore_file",
  //           },
  //           {
  //             label: "Existing Offshore Customer Package",
  //             version: 0,
  //             id: 2,
  //             type: "existing_offshore_file",
  //           },
  //           {
  //             label: "HCL Technologies_1951 (Special Instructions)",
  //             version: 97,
  //             id: 3,
  //             type: "special_instructions_file",
  //           },
  //         ]}
  //       />
  //     ),
  //   });
  // };
  viewDocument = (val) => {
    const { loadDocument, documentList } = this.props;
    if (val) loadDocument(val.documentType, val.gcs_signed_url, val.id);
  };

  uploadJaf = (value) => {
    if (value) {
      this.submitJaf(value);
    }
  };

  submitJaf = async (data) => {
    this.setState({ isLoading: true });
    const { pvtId } = this.props;
    const body = {
      pvt_id: pvtId,
      document_id: data.id,
    };
    try {
      const response = await api.post(INFY_UPLOAD_JAF, body);
      Toast.success({ description: response.data.detail });
      this.props.getCaseDetail()
    } catch (e) {
      console.log(e);
    }
    this.setState({ isLoading: false });
  };

  render() {
    const {
      minutes,
      seconds,
      actionType,
      isExpand,
      csisUrl,
      isTimer,
      listDoc,
      selectedDocument,
      isLoading,
    } = this.state;
    const { userId = "", pvtId = "", timer, status = "", isCse , bridge_ars_no } = this.props;
    if (isTimer) {
      return <Redirect to="/qcdashboard" />;
    }
    return (
      <div className="timer-block">
        <div class="row mb-3">
          <div class="col-md-5 d-flex">
            <button
              class="btn btn-primary btn-sm text-uppercase border-0"
              onClick={() => {
                this.openUrl();
              }}
            >
              csis <i class="fa fa-arrow-down" aria-hidden="true"></i>
            </button>
            {/* {!isCse ? (
              <button
                class="btn btn-primary btn-sm text-uppercase border-0"
                onClick={() => {
                  this.openWindow();
                }}
                style={{ marginLeft: "4px" }}
              >
                csis <i class="fa fa-arrow-up" aria-hidden="true"></i>
              </button>
            ) : null} */}
            <Toggle
              callback={this.props.handleSubmittedChecksToggleChange}
              isToggleOn={this.props.isToggleOn}
            />
          </div>
          {actionType != "hold" && !isCse ? (
            <div class="col-md-7">
              <div class="row">
                {/* { !bridge_ars_no ? 
                ( <div className="drop col-md-4">
                  <div className="downlist">
                    <Selectbox
                      option={listDoc}
                      defaultValue={selectedDocument ? selectedDocument : null}
                      callback={(val) =>
                        this.setState({ selectedDocument: val })
                      }
                    />
                  </div>
                  {selectedDocument ? (
                    <div className="buttons">
                      <span
                        className="m-2 "
                        onClick={() => {
                          this.viewDocument(selectedDocument);
                        }}
                      >
                        <i className="fa fa-eye fnt16"></i>
                      </span>
                      {!isLoading ? (
                        <button
                          className="btn btn-primary btn-sm float-left m-2 "
                          onClick={(e) => {
                            this.uploadJaf(selectedDocument);
                          }}
                        >
                          Submit JAF
                        </button>
                      ) : (
                        <div
                          class="spinner-border text-info float-right m-2 spinner-block"
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="text-muted text-uppercase label-header outer">
                      UPLOAD JAF
                    </div>
                  )}
                </div> ) : null 
                } */}
                <div class="col timer-action">
                  <button
                    type="button"
                    class="btn btn-outline-danger btn-sm"
                    onClick={() => {
                      this.onHold();
                    }}
                  >
                    HOLD{" "}
                  </button>
                  <div
                    class="float-left mr-2 pause"
                    onClick={() => {
                      this.submitApi("pause");
                    }}
                  >
                    <i
                      class="fa fa-pause-circle-o pause-icon"
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
                <div class="col">
                  <div class="col-auto p-1 text-center text-primary border-primary small rounded">
                    Time Remaining: {minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds} mins
                  </div>
                </div>
              </div>
            </div>
          ) : !isCse ? (
            <span class="badge badge-info">HOLD</span>
          ) : null}
        </div>
        {actionType === "pause" && (
          <div class="loader-container" id="resumeWindow">
            <span class="loader-timer">
              <button
                class="btn btn-danger mb-2"
                onClick={() => {
                  this.submitApi("play");
                }}
              >
                Resume
              </button>
            </span>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  timerObj: { ...state.timer },
});

const mapDispatchToProps = (dispatch) => ({
  pause: (data) => dispatch(pause(data)),
  resume: (data) => dispatch(resume(data)),
  hold: (data) => dispatch(hold(data)),
  deleteAction: (data) => dispatch(deleteAction(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BUCKETTIMER)
);
