import React, { Component } from "react";
import _cloneDeep from "lodash/cloneDeep";
import _isEqual from "lodash/isEqualWith";
import _get from "lodash/get";
import CustomInput from "./../../../components/customInput/customInput";
import DocSelection from "./../../../components/docselection/docselection";
import CaseHistory from "./../../../components/caseHistory/caseHistory";
import Selectbox from "./../../../components/Selectbox/selectbox";
import Escalation from "./../../../components/Escalation/escalation";
import AsyncSelectBox from "./../../../components/asyncSelectBox/asyncSelectBox";
import _debounce from "lodash/debounce";
import { uniqueId, getEncodeError } from "./../../../utils/commonutils";

import api from "./../../../apiBaseConfig";
import PopupMenuCheck from "../../../components/popupMenuCheck/popupMenuCheck";
import HiringModal from "./../../components/hiringModal/hiringModal";
import Asterisk from "./../../../screens/bucketDetails/components/asterisk/asterisk";
import RaiseResearchModal from "./../../../components/raiseResearchModal/raiseResearchModal";
import HiringList from "./component/hiringList/hiringList";
import { constants } from "./../../../constants";
import "./checkListCard.scss";
import { DOCUMENTS_PER_CHECK } from "../../../apiurl";
import { modalManager } from "../../../utils/modalutils";
import {
  PACKAGE_LIST,
  BUCKET_CASES,
  DROPDOWN_SEARCH,
  CHANGE_STATUS_CASE,
  GET_DOCUMENTS,
  CASE_HISTORY_COMMENT,
  SUBMIT_COMMENT,
  GET_CHECK_LIST,
  RAISE_CASE_INSUFF,
  CHECK_BY_PACKAGE,
  ANTECEDENT_BY_PACKAGE,
  SEARCH_BY_CITY_ID,
  SEARCH_BY_ENTITY_ID,
} from "./../../../apiurl";

export default class CheckListCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkObj: {},
      docList: [],
      selectDropdown: [],
      selectedDoc: [],
      filterLocation: {},
      researchType: null,
      isSubmitCheckLoader: false,
    };
  }

  componentDidMount = () => {
    this.getCheckObject();
  };

  componentDidUpdate = (prevprops, prevstate) => {
    if (prevprops.checkObject !== this.props.checkObject) {
      this.getCheckObject();
    }
  };

  getCheckObject = () => {
    const { checkObject, documentList, isDocDisable = false } = this.props;
    let { selectDropdown } = this.state;
    const check = _cloneDeep(checkObject);
    let selectedDoc = [];
    check.indexValue = uniqueId();
    selectDropdown = documentList.map((data) => {
      return {
        type: data.type,
        id: data.id,
        label: data.type + "_" + data.id,
        path: data.path,
        gcspath: data.gcspath,
        documentType: data.documentType,
      };
    });
    if (checkObject.check_documents && checkObject.check_documents.length) {
      selectedDoc = checkObject.check_documents.map((data, index) => {
        return { ...data, indexNumber: uniqueId(), isDocDisable };
      });
    }

    this.setState({
      checkObj: check,
      selectDropdown,
      selectedDoc,
      filterLocation: {},
      isSubmitCheckLoader: false,
    });
  };

  getDocuments = async (checkId) => {
    let { docList } = this.state;
    try {
      const response = await api.get(`${DOCUMENTS_PER_CHECK}${checkId}`);
      docList = response.data.documents.map((docObj) => {
        return {
          end_page: null,
          id: null,
          id_bridge: docObj.id,
          bridge_name: docObj.name,
          actual_name: null,
          start_page: null,
          gcs_signed_url: null,
          document_type: null,
        };
      });
      this.setState({ docList });
    } catch (e) {
      console.log(e);
    }
  };

  getSelectDoc = (docs) => {
    this.setState({ selectedDoc: docs });
  };

  isSourceValid = () => {
    const { checkObj } = this.state;
    const {
      check_id,
      verification_source,
      verification_source_location,
      verification_source_address,
      verification_source_id,
      is_in_hiring_list,
    } = checkObj;
    let isSourceValid = false;
    let isHiringValid = true;
    let isFromToValid = true;
    const regex = /^[A-Za-z0-9]{1}/;
    //for education and employment checks
    if (
      verification_source_id === constants.EDUCATION_SOURCE_VERIFICATION_TYPE ||
      verification_source_id === constants.COMPANY_SOURCE_VERIFICATION_TYPE
    ) {
      //hiring list validation
      if (is_in_hiring_list === null) {
        isHiringValid = false;
      }
      if (
        verification_source &&
        this.myTrim(verification_source.label) &&
        regex.test(verification_source.label)
      ) {
        if (verification_source.value) {
          if (
            verification_source_location &&
            this.myTrim(verification_source_location.label) &&
            this.myTrim(verification_source_address)
          ) {
            isSourceValid = true;
          } else {
            isSourceValid = false;
          }
        } else {
          isSourceValid = true;
        }
      } else {
        isSourceValid = false;
      }
    } else if (
      // city sourceVerificationType  and india police verification through law firm checks
      verification_source_id === constants.CITY_SOURCE_VERIFICATION_TYPE ||
      verification_source_id === constants.COUNTRY_SOURCE_VERIFICATION_TYPE
      // checkId === 84 //need to confirm it
    ) {
      if (
        verification_source_location &&
        verification_source_location.value &&
        regex.test(verification_source_location.value)
      ) {
        isSourceValid = true;
      } else {
        isSourceValid = false;
      }
    } else {
      isSourceValid = true;
    }
    return isSourceValid && isHiringValid;
  };

  myTrim = (x) => {
    if (x) {
      return x.replace(/^\s+|\s+$/gm, "");
    }
    return x;
  };
  checkValidationForCheck = () => {
    const { checkObj } = this.state;
    let isValid = true;
    checkObj.check_fields.forEach((element) => {
      if (
        element.is_mandatory &&
        element.antecedent_type !== "Verified" &&
        !this.myTrim(element.antecedent_value) &&
        element.antecedent_value !== 0
      ) {
        //element.isError = true;
        isValid = false;
      } else {
        //element.isError = false;
      }
    });
    const isSourceValid = this.isSourceValid();
    isValid = isValid && isSourceValid ? true : false;
    return isValid;
  };

  getCheckCommentHistory = async () => {
    const { checkObj } = this.state;
    const comments = checkObj.comments.map((commentObj, index) => {
      return {
        commentId: commentObj.id || index,
        createdAt: commentObj.created_at,
        comment: commentObj.comment,
        userName: commentObj.user_name,
        roleName: "",
      };
    });
    return Promise.resolve(comments);
  };

  submitCheckComment = async (obj) => {
    const { userInfo } = this.props;
    const { checkObj } = this.state;
    let checkHistory = [...checkObj.comments];
    const commentObj = {
      comment: obj.comment,
      special_comment: false,
      user_name: userInfo.userFirstName,
      created_at: new Date(),
      user_id: userInfo.userId,
    };
    checkHistory.push(commentObj);
    return this.props.saveCheckList({
      ...checkObj,
      comments: checkHistory,
    });
  };

  openCheckHistoryModel = () => {
    const { userInfo, pvtId } = this.props;
    modalManager.open({
      isHeader: false,
      component: () => (
        <CaseHistory
          isCheck={false}
          pvtId={pvtId}
          userInfo={userInfo}
          getCommentHistory={() => {
            return this.getCheckCommentHistory();
          }}
          label="Check History"
          buttonName="Add To Comment"
          submitComment={this.submitCheckComment}
          closeCommentHistoryModal={() => modalManager.close({})}
          successMessage={"comment added successfully"}
          errorMessage={"There is some error in adding comment"}
        />
      ),
    });
  };

  onInsufficiencyRaised = async (obj) => {
    const { userInfo } = this.props;
    const { checkObj } = this.state;
    let checkHistory = [...checkObj.comments];
    const commentObj = {
      comment: obj.comment,
      special_comment: true,
      user_name: userInfo.userFirstName,
      created_at: new Date(),
      user_id: userInfo.userId,
    };
    checkHistory.push(commentObj);
    try {
      let updatedCheckObj = {
        ...checkObj,
        is_research_raised: false,
        comments: checkHistory,
        is_frozen: true,
      };
      if (obj.isDisable) {
        updatedCheckObj.is_disabled = true;
      } else {
        updatedCheckObj.is_insufficiency_raised = true;
      }
      await this.props.saveCheckList(updatedCheckObj);
      modalManager.close({});
    } catch (e) {
      console.log(e);
    }
  };

  setDisableCheck = (e) => {
    const obj = {
      label: "Disabling",
      btnLabel: "Disable",
      isDisable: true,
    };
    this.raiseInsufficiencyCheck(e, obj);
  };

  raiseInsufficiencyCheck = (e, obj = {}) => {
    const { userInfo, pvtId } = this.props;
    e.preventDefault();
    modalManager.open({
      isHeader: false,
      component: () => (
        <Escalation
          data={{
            label: obj.label || "Raising Insufficiency",
            userName: `${userInfo.userFirstName} ${userInfo.userLastName}`,
            userId: userInfo.userId,
            id: pvtId,
            isDisable: obj.isDisable || false,
          }}
          btnLabel={obj.btnLabel || "Raise Insufficiency"}
          isCheck={true}
          callback={this.onInsufficiencyRaised}
        />
      ),
    });
  };
  getResearchText = () => {
    const { researchType } = this.state;
    return researchType;
  };

  preSubmitCheckAfterRaiseResearch = (isRaiseResearch, comment) => {
    let { checkObj } = this.state;
    const { userInfo } = this.props;
    checkObj.is_research_raised = isRaiseResearch;
    if (checkObj.is_research_raised) {
      let checkHistory = [...checkObj.comments];
      const commentObj = {
        comment: comment,
        special_comment: true,
        user_name: userInfo.userFirstName,
        created_at: new Date(),
        user_id: userInfo.userId,
      };
      checkHistory.push(commentObj);
      checkObj.comments = [...checkHistory];
    }
    this.setState({ checkObj }, async () => {
      try {
        await this.submitCheck();
        modalManager.close({});
      } catch (e) {
        console.log(e);
      }
    });
  };
  saveList = () => {
    const { selectedDoc, checkObj } = this.state;
    if (this.checkValidationForCheck()) {
      /**
       * special characator issue
       */
      const encodeObj = getEncodeError(checkObj);
      if (encodeObj.isEncodeError) {
        alert(`please type manually in following antecedent---
        ${encodeObj.s}`);
        return Promise.reject({});
      }
      /**
       * end
       */

      if (checkObj.is_research_raised) {
        modalManager.open({
          isHeader: false,
          component: () => (
            <RaiseResearchModal
              callback={this.preSubmitCheckAfterRaiseResearch}
              researchText={this.getResearchText()}
              closeModal={() => modalManager.close({})}
            ></RaiseResearchModal>
          ),
        });
      } else {
        this.setState({ isSubmitCheckLoader: true });
        this.submitCheck();
      }
    } else {
      alert(
        "Please ensure that all mandatory fields and valid verificarion source are filled before submitting the check"
      );
    }
  };

  submitCheck = async () => {
    const { selectedDoc, checkObj } = this.state;
    const tagDocuments = selectedDoc.filter((document) => document.id);
    try {
      const res = await this.props.saveCheckList({
        ...checkObj,
        check_documents: tagDocuments,
        is_frozen: true,
      });
      // this.setState({ isSubmitCheckLoader: false });
      return Promise.resolve(res);
    } catch (e) {
      this.setState({ isSubmitCheckLoader: false });
      return Promise.reject(e);
    }
  };

  openHiringModal = (entity, serachString) => {
    modalManager.open({
      isHeader: false,
      width: "980px",
      component: () => (
        <HiringModal
          searchString={serachString}
          entity={entity}
          closeModal={() => {
            modalManager.close({});
          }}
        />
      ),
    });
  };

  getLocationBasedOnEntity = (searchString, callbackToAsyncSelect) => {
    const { checkObj } = this.state;
    api
      .post(`${SEARCH_BY_ENTITY_ID}company/`, {
        company_id: checkObj.verification_source.value,
      })
      .then((response) => {
        let locationObj = response.data;
        let locationArray = [];
        // locationArray = locationArray.map((location) => ({
        locationArray.push({
          label: locationObj.city_name,
          value: locationObj.city_id,
          address: locationObj.address,
        });
        // }));
        locationArray.push({
          label: "others",
          value: "-1",
          address: "",
        });
        callbackToAsyncSelect(locationArray);
        // this.setState({ locationOptionBasedOnSearch: locationArray });
      })
      .catch((err) =>
        console.log("error in fetching location based on search string", err)
      );
  };

  getCompaniesBasedOnSearch = (searchString = "", callbackToAsyncSelect) => {
    api
      .post(`${DROPDOWN_SEARCH}company/`, { search_string: searchString })
      .then((response) => {
        let companyArray = response.data;
        companyArray = companyArray.map((company) => ({
          label: company.company_name,
          value: company.company_id,
        }));
        callbackToAsyncSelect(companyArray);
      })
      .catch((err) =>
        console.log("error in fetching company based on search string", err)
      );
  };

  getCountryLocationOptionBasedOnSearch = (
    searchString = "",
    callbackToAsyncSelect
  ) => {
    api
      .post(`${DROPDOWN_SEARCH}country/`, { search_string: searchString })
      .then((response) => {
        let locationArray = response.data;
        locationArray = locationArray.map((location) => ({
          label: location.country_name,
          value: location.country_id,
        }));
        callbackToAsyncSelect(locationArray);
      })
      .catch((err) =>
        console.log("error in fetching location based on search string", err)
      );
  };
  getLocationBasedOnInstituteId = (searchString, callbackToAsyncSelect) => {
    const { checkObj } = this.state;
    api
      .post(`${SEARCH_BY_ENTITY_ID}institute/`, {
        institute_id: checkObj.verification_source.value,
      })
      .then((response) => {
        let locationObj = response.data;
        let locationArray = [];
        // locationArray = locationArray.map((location) => ({
        locationArray.push({
          label: locationObj.city_name,
          value: locationObj.city_id,
          address: locationObj.address,
        });
        // }));
        locationArray.push({
          label: "others",
          value: "-1",
          address: "",
        });
        //this.props.handleCheckChange(this.checkObject);
        callbackToAsyncSelect(locationArray);
      })
      .catch((err) =>
        console.log("error in fetching location based on search string", err)
      );
  };

  getInstituteBasedOnSearch = (searchString = "", callbackToAsyncSelect) => {
    api
      .post(`${DROPDOWN_SEARCH}institute/`, { search_string: searchString })
      .then((response) => {
        let instituteArray = response.data;
        instituteArray = instituteArray.map((institute) => ({
          label: institute.institute_name,
          value: institute.institute_id,
        }));
        callbackToAsyncSelect(instituteArray);
      })
      .catch((err) =>
        console.log("error in fetching company based on search string", err)
      );
  };

  getInstituteBasedOnLocation = (searchString = "", callbackToAsyncSelect) => {
    api
      .post(`${SEARCH_BY_CITY_ID}institute/`, {
        search_string: searchString,
        city_id: this.state.filterLocation.value,
      })
      .then((response) => {
        let instituteArray = response.data;
        instituteArray = instituteArray.map((institute) => ({
          label: institute.institute_name,
          value: institute.institute_id,
        }));
        callbackToAsyncSelect(instituteArray);
        // this.setState({ instituteOptionBasedOnSearch: instituteArray });
      })
      .catch((err) =>
        console.log("error in fetching institute based on search string", err)
      );
  };

  getLocationOptionBasedOnSearch = (
    searchString = "",
    callbackToAsyncSelect
  ) => {
    api
      .post(`${DROPDOWN_SEARCH}city/`, { search_string: searchString })
      .then((response) => {
        let locationArray = response.data;
        locationArray = locationArray.map((location) => ({
          label: location.city_name,
          value: location.city_id,
        }));
        callbackToAsyncSelect(locationArray);
      })
      .catch((err) =>
        console.log("error in fetching location based on search string", err)
      );
  };

  getBorderClass = () => {
    const {
      is_frozen = false,
      is_disabled = false,
      is_insufficiency_raised = false,
    } = this.state.checkObj;
    if (is_frozen) {
      if (is_insufficiency_raised) {
        return "border-insuff";
      } else if (is_disabled) {
        return "border-disable";
      } else {
        return "border-submit";
      }
    } else {
      return "border-primary";
    }
  };

  getTextClass = () => {
    const {
      is_frozen = false,
      is_disabled = false,
      is_insufficiency_raised = false,
    } = this.state.checkObj;

    if (is_frozen) {
      if (is_insufficiency_raised) {
        return "text-insuff";
      } else if (is_disabled) {
        return "text-disable";
      } else {
        return "text-submit";
      }
    } else {
      return "text-blue";
    }
  };

  render() {
    const {
      checkObj,
      docList,
      selectDropdown,
      selectedDoc,
      isSubmitCheckLoader,
    } = this.state;
    const { loadDocument, isDocDisable } = this.props;
    return (
      <div className="card-body small" key={checkObj.indexValue}>
        <div className="accordion" id="employment-check-accordioni">
          <div className={`card p-2 rounded ${this.getBorderClass()}`}>
            <div className="main-head collapsed d-flex align-items-center justify-content-between">
              <a
                className={`${this.getTextClass()}`}
                onClick={() => {
                  if (this.props.selectedCheckUID === checkObj.check_uid) {
                    this.props.onCheckSelected(null);
                  } else {
                    this.props.onCheckSelected(checkObj.check_uid);
                    this.getDocuments(checkObj.check_id);
                  }
                }}
              >
                {checkObj.check_name}
              </a>
            </div>

            <div
              id={`emp-veri-collapse${checkObj.check_uid}`}
              className={`${
                this.props.selectedCheckUID === checkObj.check_uid
                  ? "collapse show"
                  : "collapse"
              }`}
              aria-labelledby="headingOne"
            >
              <div className="row doc-container-block">
                <DocSelection
                  callback={this.getSelectDoc}
                  loadDocument={loadDocument}
                  docArray={selectedDoc}
                  documentCheck={docList}
                  selectDropdown={selectDropdown}
                  isDisable={isDocDisable}
                />
              </div>

              {/*  education sourceVerificationType  */}
              {checkObj.verification_source_id ===
                constants.EDUCATION_SOURCE_VERIFICATION_TYPE && (
                <React.Fragment>
                  <div className="form-group">
                    <label className="text-uppercase text-secondary">
                      Location
                    </label>
                    <div className="d-flex">
                      <div className="w-100">
                        <AsyncSelectBox
                          loadOptions={_debounce(
                            this.getLocationOptionBasedOnSearch,
                            300
                          )}
                          defaultValue={this.state.filterLocation}
                          onChange={(location) => {
                            this.setState({
                              filterLocation: location || {},
                            });
                          }}
                          isDisable={checkObj.is_frozen || this.props.isCSE}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="text-uppercase text-secondary">
                      Verification Source (Institute/ University Name)
                      <Asterisk />
                    </label>
                    <div className="d-flex">
                      <div className="w-100">
                        <AsyncSelectBox
                          loadOptions={_debounce(
                            this.state.filterLocation.value
                              ? this.getInstituteBasedOnLocation
                              : this.getInstituteBasedOnSearch,
                            300
                          )}
                          defaultValue={checkObj.verification_source}
                          // isError={this.state.isVerificationSourceError}
                          onChange={(institute) => {
                            let { checkObj, researchType } = this.state;
                            checkObj.verification_source = institute || null;
                            checkObj.verification_source_location = null;
                            if (
                              !(
                                checkObj.verification_source &&
                                checkObj.verification_source.value
                              )
                            ) {
                              checkObj.is_research_raised = true;
                              researchType = "verificationSource";
                            } else {
                              checkObj.is_research_raised = false;
                              researchType = null;
                            }
                            checkObj.organisation_type = 2;
                            this.setState({ checkObj, researchType });
                          }}
                          isDisable={checkObj.is_frozen || this.props.isCSE}
                        />
                      </div>
                    </div>
                  </div>

                  {checkObj.verification_source &&
                    checkObj.verification_source.value && (
                      <div
                        className="form-group"
                        key={
                          checkObj.verification_source &&
                          checkObj.verification_source.value
                        }
                      >
                        <label className="text-uppercase text-secondary">
                          Institute/ University Location
                          <Asterisk />
                        </label>

                        <AsyncSelectBox
                          key={
                            checkObj.verification_source &&
                            checkObj.verification_source.value
                          }
                          loadOptions={_debounce(
                            this.getLocationBasedOnInstituteId,
                            300
                          )}
                          defaultOptions={true}
                          isClearable={false}
                          // isError={
                          //   this.state.isVerificationSourceLocationError
                          // }
                          defaultValue={checkObj.verification_source_location}
                          onChange={(location) => {
                            let { checkObj, researchType } = this.state;
                            if (location && location.label !== "others") {
                              checkObj.verification_source_location =
                                location || null;
                              checkObj.verification_source_address = location
                                ? location.address
                                : null;
                              checkObj.is_research_raised = false;
                            } else if (
                              location &&
                              location.label === "others"
                            ) {
                              checkObj.verification_source_location = {
                                label: "others",
                                value: null,
                              };
                              checkObj.is_research_raised = true;
                              researchType = "verificationSourceLocation";
                              checkObj.organisation_type = 2;
                              checkObj.verification_source_address = null;
                            }
                            this.setState({ checkObj, researchType });
                          }}
                          isDisable={checkObj.is_frozen || this.props.isCSE}
                        />
                      </div>
                    )}

                  {checkObj.verification_source &&
                    checkObj.verification_source.value &&
                    checkObj.verification_source_location &&
                    checkObj.verification_source_location.label && (
                      <div
                        className="form-group"
                        key={checkObj.verification_source_location.label}
                      >
                        <label className="text-uppercase text-secondary">
                          Address-
                          <Asterisk />
                        </label>
                        <textarea
                          type="text"
                          className={`form-control input-section`}
                          // ${
                          //   this.state.isVerificationSourceAddressError
                          //     ? "error-border"
                          //     : ""
                          // }`}
                          value={checkObj.verification_source_address}
                          readOnly={
                            checkObj.verification_source_location.label !==
                            "others"
                          }
                          onChange={(e) => {
                            let { checkObj } = this.state;
                            checkObj.verification_source_address =
                              e.target.value;
                            this.setState({ checkObj });
                          }}
                        ></textarea>
                      </div>
                    )}
                </React.Fragment>
              )}

              {/*  city sourceVerificationType  case */}
              {checkObj.verification_source_id ===
                constants.CITY_SOURCE_VERIFICATION_TYPE && (
                <React.Fragment>
                  <div class="row">
                    <div class="col-md-12">
                      <div className="form-group">
                        <label className="text-uppercase text-secondary">
                          Verification Source (City Name)
                          <Asterisk />
                        </label>
                        <div className="d-flex">
                          <div className="w-100">
                            <AsyncSelectBox
                              loadOptions={_debounce(
                                this.getLocationOptionBasedOnSearch,
                                300
                              )}
                              defaultValue={
                                checkObj.verification_source_location
                              }
                              onChange={(location) => {
                                let { checkObj } = this.state;
                                checkObj.verification_source_location =
                                  location || null;
                                checkObj.verification_source = location || null;
                                this.setState({ checkObj });
                              }}
                              isDisable={checkObj.is_frozen || this.props.isCSE}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}

              {/*  country sourceVerificationType case */}

              {checkObj.verification_source_id ===
                constants.COUNTRY_SOURCE_VERIFICATION_TYPE && (
                <React.Fragment>
                  <div class="row">
                    <div class="col-md-12">
                      <div className="form-group">
                        <label className="text-uppercase text-secondary">
                          Verification Source (Country Name)
                          <Asterisk />
                        </label>
                        <div className="d-flex">
                          <div className="w-100">
                            <AsyncSelectBox
                              loadOptions={_debounce(
                                this.getCountryLocationOptionBasedOnSearch,
                                300
                              )}
                              defaultValue={
                                checkObj.verification_source_location
                              }
                              //isError={this.state.isVerificationSourceError}
                              onChange={(location) => {
                                let { checkObj } = this.state;
                                checkObj.verification_source_location =
                                  location || null;
                                checkObj.verification_source = location || null;
                                this.setState({ checkObj });
                                // this.props.handleCheckChange(checkObject);
                              }}
                              isDisable={checkObj.is_frozen || this.props.isCSE}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}

              {/*  company sourceVerificationType case */}
              {checkObj.verification_source_id ===
                constants.COMPANY_SOURCE_VERIFICATION_TYPE && (
                <React.Fragment>
                  <div className="form-group">
                    <label className="text-uppercase text-secondary">
                      Verification Source (Company Name)
                      <Asterisk />
                    </label>
                    <div className="d-flex">
                      <div className="w-100">
                        <AsyncSelectBox
                          loadOptions={_debounce(
                            this.getCompaniesBasedOnSearch,
                            300
                          )}
                          // isError={this.state.isVerificationSourceError}
                          defaultValue={checkObj.verification_source}
                          onChange={(company) => {
                            let { checkObj, researchType } = this.state;
                            checkObj.verification_source = company || null;
                            checkObj.verification_source_location = null;

                            if (
                              !(
                                checkObj.verification_source &&
                                checkObj.verification_source.value
                              )
                            ) {
                              checkObj.is_research_raised = true;
                              researchType = "verificationSource";
                            } else {
                              checkObj.is_research_raised = false;
                              researchType = null;
                            }
                            checkObj.organisation_type = 1;
                            this.setState({ checkObj, researchType });
                          }}
                          isDisable={checkObj.is_frozen || this.props.isCSE}
                        />
                      </div>
                    </div>
                  </div>

                  {checkObj.verification_source &&
                    checkObj.verification_source.value && (
                      <div
                        key={
                          checkObj.verification_source &&
                          checkObj.verification_source.value
                        }
                        className="form-group"
                      >
                        <label className="text-uppercase text-secondary">
                          Company Location
                          <Asterisk />
                        </label>

                        <AsyncSelectBox
                          key={
                            checkObj.verification_source &&
                            checkObj.verification_source.value
                          }
                          loadOptions={_debounce(
                            this.getLocationBasedOnEntity,
                            300
                          )}
                          defaultOptions={true}
                          isClearable={false}
                          // isError={
                          //   this.state.isVerificationSourceLocationError
                          // }
                          defaultValue={checkObj.verification_source_location}
                          onChange={(location) => {
                            let { checkObj, researchType } = this.state;
                            if (location && location.label !== "others") {
                              checkObj.verification_source_location =
                                location || null;
                              checkObj.verification_source_address = location
                                ? location.address
                                : null;
                              checkObj.is_research_raised = false;
                            } else if (
                              location &&
                              location.label === "others"
                            ) {
                              checkObj.verification_source_location = {
                                label: "others",
                                value: null,
                              };
                              checkObj.is_research_raised = true;
                              researchType = "verificationSourceLocation";
                              checkObj.organisation_type = 1;
                              checkObj.verification_source_address = null;
                            }
                            this.setState({ checkObj, researchType });
                          }}
                          isDisable={checkObj.is_frozen || this.props.isCSE}
                        />
                      </div>
                    )}

                  {checkObj.verification_source &&
                    checkObj.verification_source.value &&
                    checkObj.verification_source_location &&
                    checkObj.verification_source_location.label && (
                      <div className="form-group">
                        <label className="text-uppercase text-secondary">
                          Address-
                          <Asterisk />
                        </label>
                        <textarea
                          className={`form-control input-section`}
                          //  ${
                          //   this.state.isVerificationSourceAddressError
                          //     ? "error-border"
                          //     : ""
                          // }`}
                          type="text"
                          value={checkObj.verification_source_address}
                          readOnly={
                            checkObj.verification_source_location.label !==
                            "others"
                          }
                          onChange={(e) => {
                            let { checkObj } = this.state;
                            checkObj.verification_source_address =
                              e.target.value;
                            this.setState({ checkObj });
                          }}
                        ></textarea>
                      </div>
                    )}
                </React.Fragment>
              )}

              <div class="row">
                {checkObj.check_fields &&
                  checkObj.check_fields.map((checkField) =>
                    checkField.antecedent_type === "Verified" ? null : (
                      <div
                        className={
                          checkField.antecedantInputType === "Text Area"
                            ? "col-md-12"
                            : "col-md-6"
                        }
                      >
                        <div
                          className="form-group "
                          key={checkField.antecedent_id}
                        >
                          <label className="text-uppercase text-secondary">
                            {checkField.antecedent_name}
                            {checkField.is_mandatory && <Asterisk />}
                          </label>
                          {
                            <CustomInput
                              type={checkField.antecedent_input_type}
                              onChange={(e) => {
                                checkField.antecedent_value = e.target.value;
                                this.setState({ checkObj });
                                // this.props.handleAntecedantSync(
                                //   e,
                                //   checkObject.checkId,
                                //   checkObject.checkNumber,
                                //   checkField.antecedantId
                                // );
                                // this.props.handleCheckChange(this.checkObject);
                              }}
                              //   uniqueIdForInput={`${checkObject.checkId}-${checkObject.checkNumber}-${checkField.antecedantId}`}
                              value={checkField.antecedent_value}
                              option={checkField.drop_down_values}
                              required={checkField.is_mandatory}
                              isDisable={checkObj.is_frozen || this.props.isCSE}
                              //   isHidden={checkField.isHidden}
                              //   antecedantId={checkField.antecedantId}
                              //   isError={checkField.isError}
                              //   minlength={checkField.minlength}
                              //   maxlength={checkField.maxlength}
                            ></CustomInput>
                          }
                        </div>
                      </div>
                    )
                  )}
              </div>

              {/* Hiring  employement verification case */}
              {(checkObj.check_id ===
                constants.checkIds.PREVIOUS_EMPLOYMENT_VERIFICATION_CHECK_ID ||
                checkObj.check_id ===
                  constants.checkIds.CURRENT_EMPLOYMENT_VERIFICATION_CHECK_ID ||
                checkObj.check_id ===
                  constants.checkIds.CURRENT_COMPANY_CHECK_ID ||
                checkObj.check_id ===
                  constants.checkIds.PREVIOUS_COMPANY_CHECK_ID) && (
                <HiringList
                  checkObj={checkObj}
                  callback={(data) => {
                    let { checkObj } = this.state;
                    checkObj.is_in_hiring_list = data.value;
                    this.setState({ checkObj });
                  }}
                  getHiringList={() => {
                    this.openHiringModal(
                      "company",
                      checkObj.verification_source.label
                    );
                  }}
                />
              )}

              {/* Hiring education verification case */}
              {(checkObj.check_id === constants.checkIds.EDUCATION_W_CHECK_ID ||
                checkObj.check_id ===
                  constants.checkIds.EDUCATION_CHECK_ID) && (
                <HiringList
                  checkObj={checkObj}
                  callback={(data) => {
                    let { checkObj } = this.state;
                    checkObj.is_in_hiring_list = data.value;
                    this.setState({ checkObj });
                  }}
                  getHiringList={() => {
                    this.openHiringModal(
                      "institute",
                      checkObj.verification_source.label
                    );
                  }}
                />
              )}

              <div class="row">
                <div class="col-md-12 d-flex justify-content-between align-items-center">
                  {isSubmitCheckLoader ? (
                    <div
                      class="spinner-border text-info float-right m-2 spinner-block"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <button
                      className="btn btn-primary btn-sm "
                      disabled={checkObj.is_frozen || this.props.isCSE}
                      onClick={() => {
                        this.saveList();
                      }}
                    >
                      Submit Check
                    </button>
                  )}
                  <button
                    className="transparent-borderless check-close-btn"
                    onClick={() => this.props.onCheckSelected(null)}
                  >
                    <i
                      className="fa fa-caret-up text-muted"
                      style={{ fontSize: "36px" }}
                    ></i>
                  </button>
                  <PopupMenuCheck
                    raiseInsufficiencyCheck={this.raiseInsufficiencyCheck}
                    openCheckHistoryModel={this.openCheckHistoryModel}
                    setDisableCheck={this.setDisableCheck}
                    checkObject={{ ...checkObj, isSubmit: checkObj.is_frozen }}
                    //  submitCheck={this.submitCheck}
                    isCSE={this.props.isCSE}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
