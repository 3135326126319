import React, { Component } from "react";
import { modalManager } from "./../../../../utils/modalutils";
import Selectbox from "./../../../../components/Selectbox/selectbox";
import "./gapcomment.scss";
export default class Gapcomment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
      isLoader: false,
      gapType: {},
    };
  }
  componentDidMount = () => {
    const { data } = this.props;
    const commentString = `From Date-${
      data.fromDate ? data.fromDate.split("-").reverse().join("-") : ""
    }
To Date-${data.toDate ? data.toDate.split("-").reverse().join("-") : ""}
Gap Type-${data.gap.gapType}
Gap Between-${data.gap.gapBetween}
    `;
    this.setState({ comment: commentString });
  };
  getComment = (value) => {
    this.setState({ comment: value });
  };

  submit = async (e) => {
    const { comment, gapType } = this.state;
    const { data = {} } = this.props;
    this.setState({ isLoader: true });
    try {
      await this.props.callback({
        ...data,
        comment,
        e,
        gapTypeId: gapType.value,
      });
      this.setState({ isLoader: false });
    } catch (e) {
      this.setState({ isLoader: false });
    }
  };
  render() {
    const { comment, isLoader, gapType } = this.state;
    const { btnLabel = "submit", header = "deleting" } = this.props;
    return (
      <div className="gap-comment-box">
        <h5 class="text-muted">
          Please provide the reason for{" "}
          <span class="text-danger">{header}</span> this gap{" "}
        </h5>
        <hr class="border-top-0 border-bottom" />
        <div class="row  form-group">
          <div class="col-md-12">
            <textarea
              formControlName="comment"
              tabindex="1"
              class="form-control mb-3"
              id=""
              rows="5"
              name="comment"
              placeholder="Use this area to add comments for the request"
              onChange={(event) => {
                this.getComment(event.target.value);
              }}
              value={comment}
            ></textarea>
          </div>
          {!comment && <div className="gap-comment">Comment is required.</div>}
          {
            <div className="col-md-12">
              <label>
                Select gap type
                <sup className="staric-data">*</sup>
              </label>
              <Selectbox
                defaultValue={gapType}
                option={[
                  {
                    value: 192,
                    label: "Gap Check - Police Verification through Law Firm",
                  },
                  {
                    value: 198,
                    label: "Gap Check - Court Record Check through Law Firm",
                  },
                ]}
                callback={(selectedOption) => {
                  this.setState({
                    gapType: selectedOption,
                  });
                }}
                required={false}
              />
            </div>
          }
        </div>

        <div className="row gap-footer">
          <button
            class="btn btn-danger btn-sm float-left mr-2"
            data-dismiss="modal"
            onClick={() => {
              modalManager.close({});
            }}
          >
            Cancel
          </button>
          {!isLoader ? (
            <button
              class="btn btn-primary btn-sm float-right ml-2 send-btn"
              // type="submit"
              disabled={!comment || !gapType.value}
              onClick={(e) => {
                this.submit(e);
              }}
            >
              {btnLabel}
            </button>
          ) : (
            <div
              class="spinner-border text-info float-right m-2 spinner-block"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}
