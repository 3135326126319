import React, { Component } from "react";
import _cloneDeep from "lodash/cloneDeep";
import _isEqual from "lodash/isEqualWith";
import _get from "lodash/get";
import CustomInput from "./../../components/custumInput/custumInput";
import DocSelection from "./../../../components/docselection/docselection";
import CaseHistory from "./../../../components/caseHistory/caseHistory";
import Selectbox from "./../../../components/Selectbox/selectbox";
import Escalation from "./../../../components/Escalation/escalation";
import AsyncSelectBox from "./../../../components/asyncSelectBox/asyncSelectBox";
import _debounce from "lodash/debounce";
import {
  uniqueId,
  monthNameFromNumber,
  fullMonthNameFromNumber,
  getEncodeError,
} from "./../../../utils/commonutils";

import api from "./../../../apiBaseConfig";
import PopupMenuCheck from "../../../components/popupMenuCheck/popupMenuCheck";
import HiringModal from "./../../components/hiringModal/hiringModal";
import Asterisk from "./../../../screens/bucketDetails/components/asterisk/asterisk";
import RaiseResearchModal from "./../../../components/raiseResearchModal/raiseResearchModal";
import HiringList from "./component/hiringList/hiringList";
import MultipleTenure from "../multipleTenure/multipleTenure";
import { constants } from "./../../../constants";
import "./checkListCard.scss";
import { DOCUMENTS_PER_CHECK, COURSE_LIST } from "../../../apiurl";
import { modalManager } from "../../../utils/modalutils";
import {
  DROPDOWN_SEARCH,
  SEARCH_BY_CITY_ID,
  SEARCH_BY_ENTITY_ID,
} from "./../../../apiurl";

export default class CheckListCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkObj: {},
      docList: [],
      selectDropdown: [],
      selectedDoc: [],
      filterLocation: {},
      researchType: null,
      fromToDurationObj: {},
      isBankStatement: false,
      isSubmitCheckLoader: false,
    };
  }

  componentDidMount = () => {
    this.getCheckObject();
  };

  componentDidUpdate = (prevprops, prevstate) => {
    if (prevprops.checkObject !== this.props.checkObject) {
      this.getCheckObject();
    }
  };

  getCheckObject = () => {
    const { checkObject, documentList, isDocDisable = false } = this.props;
    let { selectDropdown } = this.state;
    const check = _cloneDeep(checkObject);
    let selectedDoc = [];
    check.indexValue = uniqueId();
    selectDropdown = documentList.map((data) => {
      return {
        type: data.type,
        id: data.id,
        label: data.type + "_" + data.id,
        path: data.path,
        gcspath: data.gcspath,
        documentType: data.documentType,
      };
    });
    if (checkObject.check_documents && checkObject.check_documents.length) {
      selectedDoc = checkObject.check_documents.map((data, index) => {
        return { ...data, indexNumber: uniqueId(), isDocDisable };
      });
    }

    let fromToDurationObj = constants.fromToDurationCTS.find(
      (obj) => obj.checkId === check.check_id
    );
    if (fromToDurationObj) {
      let checkFieldIndex = check.check_fields.findIndex((obj) =>
        fromToDurationObj.antecedentId.includes(obj.antecedent_id)
      );
      if (
        checkFieldIndex !== -1 &&
        check.check_fields[checkFieldIndex].antecedent_value &&
        check.check_fields[checkFieldIndex].antecedent_value.includes("To")
      ) {
        let antecedentValArr =
          check.check_fields[checkFieldIndex].antecedent_value.split("To");
        let fromToDuration = "";
        if (
          check.check_id ===
            constants.checkIds
              .GAP_CHECK_POLICE_RECORD_VERIFICATION_THROUGH_LAW_FIRM_CHECK_ID ||
          check.check_id ===
            constants.checkIds
              .GAP_CHECK_COURT_RECORD_CHECK_VERIFICATION_THROUGH_LAW_FIRM_CHECK_ID
        ) {
          fromToDuration = this.setDateFromToHelper(
            antecedentValArr[0].trim(),
            antecedentValArr[1].trim(),
            true,
            true
          );
        } else {
          if (
            checkObject.check_id ===
            constants.checkIds.CURRENT_EMPLOYMENT_VERIFICATION_CHECK_ID
          ) {
            if (checkObject.date_from && checkObject.date_to) {
              fromToDuration = this.setFullDateFromToHelper(
                checkObject.date_from,
                checkObject.date_to
              );
            }
          } else {
            fromToDuration = this.setFullDateFromToHelper(
              antecedentValArr[0].trim(),
              antecedentValArr[1].trim()
            );
          }
        }
        check.check_fields[checkFieldIndex].antecedent_value = fromToDuration;
      }
    }

    if (check.check_id === constants.checkIds.EDUCATION_W_CHECK_ID) {
      const checkFieldIndex = check.check_fields.findIndex(
        (obj) => obj.antecedent_id === constants.antecedantId.YEAR_OF_PASSING
      );
      if (
        checkFieldIndex !== -1 &&
        check.check_fields[checkFieldIndex].antecedent_value
      ) {
        if (
          check.check_fields[checkFieldIndex].antecedent_value.includes("-")
        ) {
          const [yearVal] =
            check.check_fields[checkFieldIndex].antecedent_value.split("-");
          check.check_fields[checkFieldIndex].antecedent_value = yearVal;
        }
      }

      const accedemicFieldIndex = check.check_fields.findIndex(
        (obj) => obj.antecedent_id === constants.antecedantId.ACCEDEMIC_YEAR
      );

      if (
        accedemicFieldIndex !== -1 &&
        check.check_fields[accedemicFieldIndex].antecedent_value &&
        check.check_fields[accedemicFieldIndex].antecedent_value.includes("To")
      ) {
        let antecedentValArr =
          check.check_fields[accedemicFieldIndex].antecedent_value.split("To");
        let fromToDuration = "";
        fromToDuration = this.setDateFromToHelper(
          antecedentValArr[0].trim(),
          antecedentValArr[1].trim(),
          true
        );
        check.check_fields[accedemicFieldIndex].antecedent_value =
          fromToDuration;
      }
    }

    if (check.check_id === constants.checkIds.DIRECTORSHIP_CHECK_ID) {
      const checkFieldIndex = check.check_fields.findIndex(
        (obj) =>
          obj.antecedent_id === constants.antecedantId.CTS_DIRECTORDHIP_DOB
      );
      if (
        checkFieldIndex !== -1 &&
        check.check_fields[checkFieldIndex].antecedent_value
      ) {
        const date = new Date(
          check.check_fields[checkFieldIndex].antecedent_value
        );
        if (date.getTime() === date.getTime()) {
          const yearFrom = date.getFullYear();
          const monthFrom = date.getMonth() + 1;
          const dateFrom = date.getDate();
          check.check_fields[checkFieldIndex].antecedent_value =
            dateFrom + "-" + monthNameFromNumber(monthFrom) + "-" + yearFrom;
        }
      }
    }

    this.setState({
      checkObj: check,
      fromToDurationObj: fromToDurationObj,
      selectDropdown,
      selectedDoc,
      filterLocation: {},
      isSubmitCheckLoader: false,
    });
  };

  getDocuments = async (checkId) => {
    let { docList } = this.state;
    try {
      const response = await api.get(`${DOCUMENTS_PER_CHECK}${checkId}`);
      docList = response.data.documents.map((docObj) => {
        return {
          end_page: null,
          id: null,
          id_bridge: docObj.id,
          bridge_name: docObj.name,
          actual_name: null,
          start_page: null,
          gcs_signed_url: null,
          document_type: null,
        };
      });
      this.setState({ docList });
    } catch (e) {
      console.log(e);
    }
  };

  getSelectDoc = (docs) => {
    this.setState({ selectedDoc: docs });
  };

  isSourceValid = () => {
    const { checkObj, isBankStatement } = this.state;
    const {
      check_id,
      verification_source,
      verification_source_location,
      verification_source_address,
      verification_source_id,
      is_in_hiring_list,
    } = checkObj;
    let isSourceValid = false;
    let isHiringValid = true;
    let isFromToValid = true;
    //const regexConsecutive = /^(?!.*[\.\,/ \-\_\;\"\/\\\<\>\=\*\&\^\#\@\!\-]{2}).*$/g;
    const regex = /^[A-Za-z0-9]{1}/;

    // from to validation
    if (
      check_id ===
        constants.checkIds.CURRENT_EMPLOYMENT_VERIFICATION_CHECK_ID ||
      check_id === constants.checkIds.EDUCATION_W_CHECK_ID ||
      check_id === constants.checkIds.EDUCATION_CHECK_ID ||
      check_id ===
        constants.checkIds.PREVIOUS_EMPLOYMENT_VERIFICATION_CHECK_ID ||
      check_id === constants.checkIds.ADDRESS_VERIFICATION_CHECK_ID ||
      check_id ===
        constants.checkIds.INDIA_COURT_RECORD_CHECK_THROUGH_LAW_FIRM_CHECK_ID ||
      // check_id === constants.checkIds.PERMANENT_VERIFICATION_CHECK_ID ||
      // check_id === constants.checkIds.CURRENT_ADDRESS_VERIFICATION_CHECK_ID ||
      check_id ===
        constants.checkIds
          .INDIAN_POLICE_RECORD_VERIFICATION_THROUGH_LAW_FIRM_CHECK_ID ||
      check_id ===
        constants.checkIds
          .GAP_CHECK_COURT_RECORD_CHECK_VERIFICATION_THROUGH_LAW_FIRM_CHECK_ID ||
      check_id ===
        constants.checkIds
          .GAP_CHECK_POLICE_RECORD_VERIFICATION_THROUGH_LAW_FIRM_CHECK_ID
    ) {
      isFromToValid =
        checkObj.date_from &&
        checkObj.date_to &&
        checkObj.date_to >= checkObj.date_from
          ? true
          : false;
    }

    //for education and employment checks
    if (
      verification_source_id === constants.EDUCATION_SOURCE_VERIFICATION_TYPE ||
      verification_source_id === constants.COMPANY_SOURCE_VERIFICATION_TYPE
    ) {
      //hiring list validation

      // if (is_in_hiring_list === null) {
      //   isHiringValid = false;
      // }
      if (
        verification_source &&
        this.myTrim(verification_source.label) &&
        regex.test(verification_source.label)
      ) {
        if (verification_source.value) {
          if (
            verification_source_location &&
            this.myTrim(verification_source_location.label) &&
            this.myTrim(verification_source_address)
          ) {
            isSourceValid = true;
          } else {
            isSourceValid = false;
          }
        } else {
          isSourceValid = true;
        }
      } else {
        isSourceValid = false;
      }
    } else if (
      // city sourceVerificationType  and india police verification through law firm checks
      verification_source_id === constants.CITY_SOURCE_VERIFICATION_TYPE ||
      verification_source_id === constants.COUNTRY_SOURCE_VERIFICATION_TYPE
      // checkId === 84 //need to confirm it
    ) {
      if (
        verification_source_location &&
        verification_source_location.value &&
        regex.test(verification_source_location.value)
      ) {
        isSourceValid = true;
      } else {
        isSourceValid = false;
      }
    } else {
      isSourceValid = true;
    }
    return isSourceValid && isHiringValid && isFromToValid;
  };

  myTrim = (x) => {
    if (x) {
      return x.replace(/^\s+|\s+$/gm, "");
    }
    return x;
  };

  checkValidationForCheck = () => {
    const { checkObj } = this.state;
    let isValid = true;
    checkObj.check_fields.forEach((element) => {
      if (
        element.is_mandatory &&
        element.antecedent_type !== "Verified" &&
        !this.myTrim(element.antecedent_value) &&
        element.antecedent_value !== 0
      ) {
        isValid = false;
      }
      // course name validation
      if (
        element.antecedent_id ===
          constants.antecedantId.EDUCATION_VERIFICATION_COURSE_NAME &&
        !this.myTrim(element.antecedent_value) &&
        element.antecedent_value !== 0
      ) {
        isValid = false;
      }
    });
    const isSourceValid = this.isSourceValid();

    isValid = isValid && isSourceValid ? true : false;
    return isValid;
  };

  getCheckCommentHistory = async () => {
    const { checkObj } = this.state;
    const comments = checkObj.comments.map((commentObj, index) => {
      return {
        commentId: commentObj.id || index,
        createdAt: commentObj.created_at,
        comment: commentObj.comment,
        userName: commentObj.user_name,
        roleName: "",
      };
    });
    return Promise.resolve(comments);
  };

  submitCheckComment = async (obj) => {
    const { userInfo } = this.props;
    const { checkObj, selectedDoc } = this.state;
    let checkHistory = [...checkObj.comments];
    const commentObj = {
      comment: obj.comment,
      special_comment: false,
      user_name: userInfo.userFirstName,
      created_at: new Date(),
      user_id: userInfo.userId,
    };
    checkHistory.push(commentObj);
    return this.props.saveCheckList({
      ...checkObj,
      comments: checkHistory,
      check_documents: selectedDoc,
    });
  };

  openCheckHistoryModel = () => {
    const { userInfo, pvtId } = this.props;
    modalManager.open({
      isHeader: false,
      component: () => (
        <CaseHistory
          isCheck={false}
          pvtId={pvtId}
          userInfo={userInfo}
          getCommentHistory={() => {
            return this.getCheckCommentHistory();
          }}
          label="Check History"
          buttonName="Add To Comment"
          submitComment={this.submitCheckComment}
          closeCommentHistoryModal={() => modalManager.close({})}
          successMessage={"comment added successfully"}
          errorMessage={"There is some error in adding comment"}
        />
      ),
    });
  };

  onInsufficiencyRaised = async (obj) => {
    const { userInfo } = this.props;
    const { checkObj, selectedDoc } = this.state;
    const tagDocuments = selectedDoc.filter((document) => document.id);
    let checkHistory = [...checkObj.comments];
    const commentObj = {
      comment: obj.comment,
      special_comment: true,
      user_name: userInfo.userFirstName,
      created_at: new Date(),
      user_id: userInfo.userId,
    };
    checkHistory.push(commentObj);
    try {
      let updatedCheckObj = {
        ...checkObj,
        is_research_raised: false,
        comments: checkHistory,
        is_frozen: true,
        check_documents: tagDocuments,
      };
      if (obj.isDisable) {
        updatedCheckObj.is_disabled = true;
      } else {
        updatedCheckObj.is_insufficiency_raised = true;
      }
      await this.props.saveCheckList(updatedCheckObj);
      modalManager.close({});
    } catch (e) {
      console.log(e);
    }
  };

  setDisableCheck = (e) => {
    const obj = {
      label: "Disabling",
      btnLabel: "Disable",
      isDisable: true,
    };
    this.raiseInsufficiencyCheck(e, obj);
  };

  raiseInsufficiencyCheck = (e, obj = {}) => {
    const { userInfo, pvtId } = this.props;
    e.preventDefault();
    modalManager.open({
      isHeader: false,
      component: () => (
        <Escalation
          data={{
            label: obj.label || "Raising Insufficiency",
            userName: `${userInfo.userFirstName} ${userInfo.userLastName}`,
            userId: userInfo.userId,
            id: pvtId,
            isDisable: obj.isDisable || false,
          }}
          btnLabel={obj.btnLabel || "Raise Insufficiency"}
          isCheck={true}
          callback={this.onInsufficiencyRaised}
        />
      ),
    });
  };
  getResearchText = () => {
    const { researchType, checkObj } = this.state;
    const checkId = checkObj.check_id;
    const sourceType =
      researchType === "verificationSource"
        ? "verification_source"
        : "verification_source_location";
    const commentPrefix =
      researchType === "verificationSource"
        ? "Verification Source - "
        : "Location - ";
    if (
      (checkId === constants.checkIds.EDUCATION_W_CHECK_ID ||
        checkId === constants.checkIds.EDUCATION_CHECK_ID ||
        checkId ===
          constants.checkIds.PREVIOUS_EMPLOYMENT_VERIFICATION_CHECK_ID ||
        checkId ===
          constants.checkIds.CURRENT_EMPLOYMENT_VERIFICATION_CHECK_ID) &&
      researchType === "verificationSourceLocation"
    ) {
      return commentPrefix + " " + checkObj.verification_source_address;
    } else {
      return (
        commentPrefix +
        " " +
        (checkObj[sourceType] && checkObj[sourceType].label)
      );
    }
  };

  preSubmitCheckAfterRaiseResearch = (isRaiseResearch, comment) => {
    let { checkObj } = this.state;
    const { userInfo } = this.props;
    checkObj.is_research_raised = isRaiseResearch;
    if (checkObj.is_research_raised) {
      let checkHistory = [...checkObj.comments];
      const commentObj = {
        comment: comment,
        special_comment: true,
        user_name: userInfo.userFirstName,
        created_at: new Date(),
        user_id: userInfo.userId,
      };
      checkHistory.push(commentObj);
      checkObj.comments = [...checkHistory];
    }
    this.setState({ checkObj }, async () => {
      try {
        await this.submitCheck();
        modalManager.close({});
      } catch (e) {
        console.log(e);
      }
    });
  };
  saveList = () => {
    const { selectedDoc, checkObj } = this.state;
    if (this.checkValidationForCheck()) {
      /**
       * special characator issue
       */
      const encodeObj = getEncodeError(checkObj);
      if (encodeObj.isEncodeError) {
        alert(`please type manually in following antecedent---
        ${encodeObj.s}`);
        return Promise.reject({});
      }
      /**
       * end
       */

      let isPincodeValid = true;
      /**
       * pincode validation
       */
      if (
        checkObj.check_id === constants.checkIds.ADDRESS_VERIFICATION_CHECK_ID
      ) {
        checkObj.check_fields.forEach((element) => {
          if (
            element.antecedent_id === constants.antecedantId.ADDRESS_PINCODE &&
            element.antecedent_value &&
            element.antecedent_value.length !== 6
          ) {
            isPincodeValid = window.confirm(
              "Pincode should be 6 digit.Click OK to submit check, else click Cancel"
            );
          }
        });
      }
      /**
       *end
       */

      if (isPincodeValid) {
        if (checkObj.is_research_raised) {
          modalManager.open({
            isHeader: false,
            component: () => (
              <RaiseResearchModal
                callback={this.preSubmitCheckAfterRaiseResearch}
                researchText={this.getResearchText()}
                closeModal={() => modalManager.close({})}
              ></RaiseResearchModal>
            ),
          });
        } else {
          this.setState({ isSubmitCheckLoader: true });
          this.submitCheck();
        }
      }
    } else {
      alert(
        "Please ensure that all mandatory fields are filled and valid verificarion source and from date can not be greater than to date  before submitting the check "
      );
    }
  };

  submitCheck = async (isFrozen = true) => {
    const { selectedDoc, checkObj } = this.state;
    const tagDocuments = selectedDoc.filter((document) => document.id);
    try {
      const res = await this.props.saveCheckList({
        ...checkObj,
        check_documents: isFrozen ? tagDocuments : selectedDoc,
        is_frozen: isFrozen,
      });
      return Promise.resolve(res);
    } catch (e) {
      this.setState({ isSubmitCheckLoader: false });
      return Promise.reject(e);
    }
  };

  openHiringModal = (entity, serachString) => {
    modalManager.open({
      isHeader: false,
      width: "980px",
      component: () => (
        <HiringModal
          searchString={serachString}
          entity={entity}
          closeModal={() => {
            modalManager.close({});
          }}
        />
      ),
    });
  };

  getLocationBasedOnEntity = (searchString, callbackToAsyncSelect) => {
    const { checkObj } = this.state;
    api
      .post(`${SEARCH_BY_ENTITY_ID}company/`, {
        company_id: checkObj.verification_source.value,
      })
      .then((response) => {
        let locationObj = response.data;
        let locationArray = [];
        // locationArray = locationArray.map((location) => ({
        locationArray.push({
          label: locationObj.city_name,
          value: locationObj.city_id,
          address: locationObj.address,
        });
        // }));
        locationArray.push({
          label: "others",
          value: "-1",
          address: "",
        });
        callbackToAsyncSelect(locationArray);
        // this.setState({ locationOptionBasedOnSearch: locationArray });
      })
      .catch((err) =>
        console.log("error in fetching location based on search string", err)
      );
  };

  getCompaniesBasedOnSearch = (searchString = "", callbackToAsyncSelect) => {
    api
      .post(`${DROPDOWN_SEARCH}company/`, { search_string: searchString })
      .then((response) => {
        let companyArray = response.data;
        companyArray = companyArray.map((company) => ({
          label: company.company_name,
          value: company.company_id,
        }));
        callbackToAsyncSelect(companyArray);
      })
      .catch((err) =>
        console.log("error in fetching company based on search string", err)
      );
  };

  getCountryLocationOptionBasedOnSearch = (
    searchString = "",
    callbackToAsyncSelect
  ) => {
    api
      .post(`${DROPDOWN_SEARCH}country/`, { search_string: searchString })
      .then((response) => {
        let locationArray = response.data;
        locationArray = locationArray.map((location) => ({
          label: location.country_name,
          value: location.country_id,
        }));
        callbackToAsyncSelect(locationArray);
      })
      .catch((err) =>
        console.log("error in fetching location based on search string", err)
      );
  };
  getLocationBasedOnInstituteId = (searchString, callbackToAsyncSelect) => {
    const { checkObj } = this.state;
    api
      .post(`${SEARCH_BY_ENTITY_ID}institute/`, {
        institute_id: checkObj.verification_source.value,
      })
      .then((response) => {
        let locationObj = response.data;
        let locationArray = [];
        // locationArray = locationArray.map((location) => ({
        locationArray.push({
          label: locationObj.city_name,
          value: locationObj.city_id,
          address: locationObj.address,
        });
        // }));
        locationArray.push({
          label: "others",
          value: "-1",
          address: "",
        });
        //this.props.handleCheckChange(this.checkObject);
        callbackToAsyncSelect(locationArray);
      })
      .catch((err) =>
        console.log("error in fetching location based on search string", err)
      );
  };

  getInstituteBasedOnSearch = (searchString = "", callbackToAsyncSelect) => {
    api
      .post(`${DROPDOWN_SEARCH}institute/`, { search_string: searchString })
      .then((response) => {
        let instituteArray = response.data;
        instituteArray = instituteArray.map((institute) => ({
          label: institute.institute_name,
          value: institute.institute_id,
        }));
        callbackToAsyncSelect(instituteArray);
      })
      .catch((err) =>
        console.log("error in fetching company based on search string", err)
      );
  };

  getInstituteBasedOnLocation = (searchString = "", callbackToAsyncSelect) => {
    api
      .post(`${SEARCH_BY_CITY_ID}institute/`, {
        search_string: searchString,
        city_id: this.state.filterLocation.value,
      })
      .then((response) => {
        let instituteArray = response.data;
        instituteArray = instituteArray.map((institute) => ({
          label: institute.institute_name,
          value: institute.institute_id,
        }));
        callbackToAsyncSelect(instituteArray);
        // this.setState({ instituteOptionBasedOnSearch: instituteArray });
      })
      .catch((err) =>
        console.log("error in fetching institute based on search string", err)
      );
  };

  getLocationOptionBasedOnSearch = (
    searchString = "",
    callbackToAsyncSelect
  ) => {
    api
      .post(`${DROPDOWN_SEARCH}city/`, { search_string: searchString })
      .then((response) => {
        let locationArray = response.data;
        locationArray = locationArray.map((location) => ({
          label: location.city_name,
          value: location.city_id,
        }));
        callbackToAsyncSelect(locationArray);
      })
      .catch((err) =>
        console.log("error in fetching location based on search string", err)
      );
  };

  getCourseOptionBasedOnSearch = (searchString = "", callbackToAsyncSelect) => {
    api
      .post(`${COURSE_LIST}course-name/`, { search_string: searchString })
      .then((response) => {
        let courseArray = response.data;
        courseArray = courseArray.map((course) => ({
          label: course.course_name,
          value: course.course_full_name,
        }));
        callbackToAsyncSelect(courseArray);
      })
      .catch((err) =>
        console.log("error in fetching course based on search string", err)
      );
  };

  getBorderClass = () => {
    const {
      is_frozen = false,
      is_disabled = false,
      is_insufficiency_raised = false,
    } = this.state.checkObj;
    if (is_frozen) {
      if (is_insufficiency_raised) {
        return "border-insuff";
      } else if (is_disabled) {
        return "border-disable";
      } else {
        return "border-submit";
      }
    } else {
      return "border-primary";
    }
  };

  getTextClass = () => {
    const {
      is_frozen = false,
      is_disabled = false,
      is_insufficiency_raised = false,
    } = this.state.checkObj;

    if (is_frozen) {
      if (is_insufficiency_raised) {
        return "text-insuff";
      } else if (is_disabled) {
        return "text-disable";
      } else {
        return "text-submit";
      }
    } else {
      return "text-blue";
    }
  };

  getAdditionalTenuresVal = (checkObj) => {
    this.setState({ checkObj });
    let additionalTenuresVal = "";
    if (checkObj.additional_tenures) {
      for (var i in checkObj.additional_tenures) {
        if (
          checkObj.additional_tenures[i].from &&
          checkObj.additional_tenures[i].to
        ) {
          let fromToDuration = "";
          if (
            checkObj.check_id ===
              constants.checkIds
                .GAP_CHECK_POLICE_RECORD_VERIFICATION_THROUGH_LAW_FIRM_CHECK_ID ||
            checkObj.check_id ===
              constants.checkIds
                .GAP_CHECK_COURT_RECORD_CHECK_VERIFICATION_THROUGH_LAW_FIRM_CHECK_ID
          ) {
            fromToDuration = this.setDateFromToHelper(
              checkObj.additional_tenures[i].from,
              checkObj.additional_tenures[i].to,
              true,
              true
            );
          } else {
            fromToDuration = this.setFullDateFromToHelper(
              checkObj.additional_tenures[i].from,
              checkObj.additional_tenures[i].to
            );
          }
          additionalTenuresVal = additionalTenuresVal + " & " + fromToDuration;
        }
      }
    }
    return additionalTenuresVal;
  };

  setFromToDuration = (fromToDuration, index) => {
    const { checkObj } = this.state;
    const { selectedPackage } = this.props;
    let fromToDurationObj = constants.fromToDurationCTS.find(
      (obj) => obj.checkId === checkObj.check_id
    );

    /*Exception for TSS Standard */
    // if (selectedPackage.value === constants.packageIds.TSS_STANDARD) {
    //   if (
    //     fromToDurationObj.checkId ===
    //     constants.checkIds.INDIA_COURT_RECORD_CHECK_THROUGH_LAW_FIRM_CHECK_ID
    //   ) {
    //     fromToDurationObj.antecedentId = [
    //       constants.antecedantId.INDIA_COURT_RECORD_PERIOD_OF_STAY_TSS,
    //     ];
    //   }
    //   if (
    //     fromToDurationObj.checkId ===
    //     constants.checkIds
    //       .INDIAN_POLICE_RECORD_VERIFICATION_THROUGH_LAW_FIRM_CHECK_ID
    //   ) {
    //     fromToDurationObj.antecedentId = [
    //       constants.antecedantId
    //         .INDIA_POLICE_RECORD_THROUGH_LAW_FIRM_PERIOD_ID_TSS,
    //     ];
    //   }
    // }

    for (var i in checkObj.check_fields) {
      if (
        fromToDurationObj &&
        fromToDurationObj.antecedentId.includes(
          checkObj.check_fields[i].antecedent_id
        )
      ) {
        let val = "";
        /*For default tenure*/
        if (index === 0) {
          let str = checkObj.check_fields[i].antecedent_value;
          if (!str || !str.includes("&")) {
            val = fromToDuration;
          } else {
            /*Setting the additional tenures string as secondStr */
            let secondStr = str.substr(str.indexOf("&") + 1);
            val = fromToDuration + " &" + secondStr;
          }
        } else {
          /*For additional tenures */
          let str = checkObj.check_fields[i].antecedent_value;
          if (str.includes("&")) {
            /*Setting the default tenures string as firstStr */
            let firstStr = str.substr(0, str.indexOf("&"));
            val = (firstStr ? firstStr.trim() : firstStr) + fromToDuration;
          } else {
            val = (str ? str.trim() : str) + fromToDuration;
          }
        }
        checkObj.check_fields[i].antecedent_value = val;
      }
    }
    this.setState({ checkObj });
  };

  setDateFromToHelper = (
    dateFrom,
    dateTo,
    isFormat = false,
    isFullMonth = false
  ) => {
    const [yearFrom, monthFrom] = dateFrom.split("-");

    const periodFrom = `${
      isFullMonth
        ? fullMonthNameFromNumber(monthFrom)
        : monthNameFromNumber(monthFrom)
    }${isFormat ? " " : ","}${yearFrom}`;
    const [yearTo, monthTo] = dateTo.split("-");
    const periodTo = `${
      isFullMonth
        ? fullMonthNameFromNumber(monthTo)
        : monthNameFromNumber(monthTo)
    }${isFormat ? " " : ","}${yearTo}`;
    let fromToDuration = periodFrom + " - " + periodTo;
    return fromToDuration;
  };

  setFullDateFromToHelper = (dateFrom, dateTo) => {
    const { checkObj } = this.state;
    const [yearFrom, monthFrom, dayFrom] = dateFrom.split("-");
    const periodFrom = `${fullMonthNameFromNumber(
      monthFrom
    )} ${dayFrom}, ${yearFrom}`;
    const [yearTo, monthTo, dayTo] = dateTo.split("-");
    const periodTo = `${fullMonthNameFromNumber(monthTo)} ${dayTo}, ${yearTo}`;
    let s =
      checkObj.check_id ===
      constants.checkIds.CURRENT_EMPLOYMENT_VERIFICATION_CHECK_ID
        ? " To "
        : " - ";
    let fromToDuration = periodFrom + s + periodTo;
    return fromToDuration;
  };
  setDateFrom = (e, index) => {
    const { checkObj } = this.state;
    checkObj.date_from = e.target.value;
    let fromToDuration = "";
    this.setState({ checkObj });
    if (checkObj.date_to && checkObj.date_from) {
      // let fromToDuration = "";
      if (
        checkObj.check_id ===
          constants.checkIds
            .GAP_CHECK_POLICE_RECORD_VERIFICATION_THROUGH_LAW_FIRM_CHECK_ID ||
        checkObj.check_id ===
          constants.checkIds
            .GAP_CHECK_COURT_RECORD_CHECK_VERIFICATION_THROUGH_LAW_FIRM_CHECK_ID
      ) {
        fromToDuration = this.setDateFromToHelper(
          checkObj.date_from,
          checkObj.date_to,
          true,
          true
        );
      } else {
        fromToDuration = this.setFullDateFromToHelper(
          checkObj.date_from,
          checkObj.date_to
        );
      }

      // this.setFromToDuration(fromToDuration, index);
    }
    this.setFromToDuration(fromToDuration, index);
  };

  setDateTo = (e, index) => {
    const { checkObj } = this.state;
    checkObj.date_to = e.target.value;
    let fromToDuration = "";
    this.setState({ checkObj });
    if (checkObj.date_from && checkObj.date_to) {
      // let fromToDuration = "";
      if (
        checkObj.check_id ===
          constants.checkIds
            .GAP_CHECK_POLICE_RECORD_VERIFICATION_THROUGH_LAW_FIRM_CHECK_ID ||
        checkObj.check_id ===
          constants.checkIds
            .GAP_CHECK_COURT_RECORD_CHECK_VERIFICATION_THROUGH_LAW_FIRM_CHECK_ID
      ) {
        fromToDuration = this.setDateFromToHelper(
          checkObj.date_from,
          checkObj.date_to,
          true,
          true
        );
      } else {
        fromToDuration = this.setFullDateFromToHelper(
          checkObj.date_from,
          checkObj.date_to
        );
      }
      // this.setFromToDuration(fromToDuration, index);
    }
    this.setFromToDuration(fromToDuration, index);
  };

  addTenure = (newTenure, index) => {
    this.setFromToDuration(newTenure, index);
  };

  updateAdditionalTenures = (checkObj) => {
    this.setState({ checkObj }, () => {
      let additionalTenuresVal = this.getAdditionalTenuresVal(checkObj);
      if (!additionalTenuresVal.includes(null)) {
        this.setFromToDuration(additionalTenuresVal, 1);
      }
    });
  };

  getInstituteNameandLocation = (checkObj) => {
    const val = checkObj.check_fields.find(
      (obj) =>
        obj.antecedent_id ===
        constants.antecedantId.EDUCATION_COLLEGE_INSTITUTE_UNIVERSITY_LOCATION
    );
    val.antecedent_value =
      checkObj.verification_source.label &&
      checkObj.verification_source_location.label
        ? checkObj.verification_source.label +
          " , " +
          checkObj.verification_source_location.label
        : "";
  };

  render() {
    const {
      checkObj,
      docList,
      selectDropdown,
      selectedDoc,
      fromToDurationObj,
      isBankStatement,
      isSubmitCheckLoader,
    } = this.state;
    const { loadDocument, isDocDisable } = this.props;
    return (
      <div className="card-body small" key={checkObj.indexValue}>
        <div className="accordion" id="employment-check-accordioni">
          <div className={`card p-2 rounded ${this.getBorderClass()}`}>
            <div className="main-head collapsed d-flex align-items-center justify-content-between">
              <a
                className={`${this.getTextClass()}`}
                onClick={() => {
                  if (this.props.selectedCheckUID === checkObj.check_uid) {
                    this.props.onCheckSelected(null);
                  } else {
                    this.props.onCheckSelected(checkObj.check_uid);
                    this.getDocuments(checkObj.check_id);
                  }
                }}
              >
                {checkObj.check_name}
              </a>
            </div>

            <div
              id={`emp-veri-collapse${checkObj.check_uid}`}
              className={`${
                this.props.selectedCheckUID === checkObj.check_uid
                  ? "collapse show"
                  : "collapse"
              }`}
              aria-labelledby="headingOne"
            >
              <div className="row doc-container-block">
                <DocSelection
                  callback={this.getSelectDoc}
                  loadDocument={loadDocument}
                  docArray={selectedDoc}
                  documentCheck={docList}
                  selectDropdown={selectDropdown}
                  isDisable={isDocDisable}
                />
              </div>

              {/*  education sourceVerificationType  */}
              {checkObj.verification_source_id ===
                constants.EDUCATION_SOURCE_VERIFICATION_TYPE && (
                <React.Fragment>
                  <div className="form-group">
                    <label className="text-uppercase text-secondary">
                      Location
                    </label>
                    <div className="d-flex">
                      <div className="w-100">
                        <AsyncSelectBox
                          loadOptions={_debounce(
                            this.getLocationOptionBasedOnSearch,
                            300
                          )}
                          defaultValue={this.state.filterLocation}
                          onChange={(location) => {
                            this.setState({
                              filterLocation: location || {},
                            });
                          }}
                          isDisable={checkObj.is_frozen || this.props.isCSE}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="text-uppercase text-secondary">
                      Verification Source (Institute/ University Name)
                      <Asterisk />
                    </label>
                    <div className="d-flex">
                      <div className="w-100">
                        <AsyncSelectBox
                          loadOptions={_debounce(
                            this.state.filterLocation.value
                              ? this.getInstituteBasedOnLocation
                              : this.getInstituteBasedOnSearch,
                            300
                          )}
                          defaultValue={checkObj.verification_source}
                          onChange={(institute) => {
                            let { checkObj, researchType } = this.state;
                            checkObj.verification_source = institute || null;
                            checkObj.verification_source_location = null;
                            if (
                              !(
                                checkObj.verification_source &&
                                checkObj.verification_source.value
                              )
                            ) {
                              checkObj.is_research_raised = true;
                              researchType = "verificationSource";
                            } else {
                              checkObj.is_research_raised = false;
                              researchType = null;
                            }
                            checkObj.organisation_type = 2;
                            this.setState({ checkObj, researchType });
                          }}
                          isDisable={checkObj.is_frozen || this.props.isCSE}
                        />
                      </div>
                    </div>
                  </div>

                  {checkObj.verification_source &&
                    checkObj.verification_source.value && (
                      <div
                        className="form-group"
                        key={
                          checkObj.verification_source &&
                          checkObj.verification_source.value
                        }
                      >
                        <label className="text-uppercase text-secondary">
                          Institute/ University Location
                          <Asterisk />
                        </label>

                        <AsyncSelectBox
                          key={
                            checkObj.verification_source &&
                            checkObj.verification_source.value
                          }
                          loadOptions={_debounce(
                            this.getLocationBasedOnInstituteId,
                            300
                          )}
                          defaultOptions={true}
                          isClearable={false}
                          // isError={
                          //   this.state.isVerificationSourceLocationError
                          // }
                          defaultValue={checkObj.verification_source_location}
                          onChange={(location) => {
                            let { checkObj, researchType } = this.state;
                            if (location && location.label !== "others") {
                              checkObj.verification_source_location =
                                location || null;
                              checkObj.verification_source_address = location
                                ? location.address
                                : null;
                              checkObj.is_research_raised = false;
                            } else if (
                              location &&
                              location.label === "others"
                            ) {
                              checkObj.verification_source_location = {
                                label: "others",
                              };
                              checkObj.is_research_raised = true;
                              researchType = "verificationSourceLocation";
                              checkObj.organisation_type = 2;
                              checkObj.verification_source_address = null;
                            }
                            this.getInstituteNameandLocation(checkObj);
                            this.setState({ checkObj, researchType });
                          }}
                          isDisable={checkObj.is_frozen || this.props.isCSE}
                        />
                      </div>
                    )}

                  {checkObj.verification_source &&
                    checkObj.verification_source.value &&
                    checkObj.verification_source_location &&
                    checkObj.verification_source_location.label && (
                      <div
                        className="form-group"
                        key={checkObj.verification_source_location.label}
                      >
                        <label className="text-uppercase text-secondary">
                          Address-
                          <Asterisk />
                        </label>
                        <textarea
                          type="text"
                          className={`form-control input-section`}
                          value={checkObj.verification_source_address}
                          readOnly={
                            checkObj.verification_source_location.label !==
                            "others"
                          }
                          onChange={(e) => {
                            let { checkObj } = this.state;
                            checkObj.verification_source_address =
                              e.target.value;
                            this.setState({ checkObj });
                          }}
                        ></textarea>
                      </div>
                    )}
                </React.Fragment>
              )}

              {/*  city sourceVerificationType  case */}
              {checkObj.verification_source_id ===
                constants.CITY_SOURCE_VERIFICATION_TYPE && (
                <React.Fragment>
                  <div class="row">
                    <div class="col-md-12">
                      <div className="form-group">
                        <label className="text-uppercase text-secondary">
                          Verification Source (City Name)
                          <Asterisk />
                        </label>
                        <div className="d-flex">
                          <div className="w-100">
                            <AsyncSelectBox
                              loadOptions={_debounce(
                                this.getLocationOptionBasedOnSearch,
                                300
                              )}
                              defaultValue={
                                checkObj.verification_source_location
                              }
                              onChange={(location) => {
                                let { checkObj } = this.state;
                                checkObj.verification_source_location =
                                  location || null;
                                checkObj.verification_source = location || null;
                                this.setState({ checkObj });
                              }}
                              isDisable={checkObj.is_frozen || this.props.isCSE}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}

              {/*  country sourceVerificationType case */}

              {checkObj.verification_source_id ===
                constants.COUNTRY_SOURCE_VERIFICATION_TYPE && (
                <React.Fragment>
                  <div class="row">
                    <div class="col-md-12">
                      <div className="form-group">
                        <label className="text-uppercase text-secondary">
                          Verification Source (Country Name)
                          <Asterisk />
                        </label>
                        <div className="d-flex">
                          <div className="w-100">
                            <AsyncSelectBox
                              loadOptions={_debounce(
                                this.getCountryLocationOptionBasedOnSearch,
                                300
                              )}
                              defaultValue={
                                checkObj.verification_source_location
                              }
                              onChange={(location) => {
                                let { checkObj } = this.state;
                                checkObj.verification_source_location =
                                  location || null;
                                checkObj.verification_source = location || null;
                                this.setState({ checkObj });
                              }}
                              isDisable={checkObj.is_frozen || this.props.isCSE}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}

              {/*  company sourceVerificationType case */}
              {checkObj.verification_source_id ===
                constants.COMPANY_SOURCE_VERIFICATION_TYPE && (
                <React.Fragment>
                  <div className="form-group">
                    <label className="text-uppercase text-secondary">
                      Verification Source (Company Name)
                      <Asterisk />
                    </label>
                    <div className="d-flex">
                      <div className="w-100">
                        <AsyncSelectBox
                          loadOptions={_debounce(
                            this.getCompaniesBasedOnSearch,
                            300
                          )}
                          defaultValue={checkObj.verification_source}
                          onChange={(company) => {
                            let { checkObj, researchType } = this.state;
                            checkObj.verification_source = company || null;
                            checkObj.verification_source_location = null;

                            if (
                              !(
                                checkObj.verification_source &&
                                checkObj.verification_source.value
                              )
                            ) {
                              checkObj.is_research_raised = true;
                              researchType = "verificationSource";
                            } else {
                              checkObj.is_research_raised = false;
                              researchType = null;
                            }
                            checkObj.organisation_type = 1;
                            this.setState({ checkObj, researchType });
                          }}
                          isDisable={checkObj.is_frozen || this.props.isCSE}
                        />
                      </div>
                    </div>
                  </div>

                  {checkObj.verification_source &&
                    checkObj.verification_source.value && (
                      <div
                        key={
                          checkObj.verification_source &&
                          checkObj.verification_source.value
                        }
                        className="form-group"
                      >
                        <label className="text-uppercase text-secondary">
                          Company Location
                          <Asterisk />
                        </label>

                        <AsyncSelectBox
                          key={
                            checkObj.verification_source &&
                            checkObj.verification_source.value
                          }
                          loadOptions={_debounce(
                            this.getLocationBasedOnEntity,
                            300
                          )}
                          defaultOptions={true}
                          isClearable={false}
                          defaultValue={checkObj.verification_source_location}
                          onChange={(location) => {
                            let { checkObj, researchType } = this.state;
                            if (location && location.label !== "others") {
                              checkObj.verification_source_location =
                                location || null;
                              checkObj.verification_source_address = location
                                ? location.address
                                : null;
                              checkObj.is_research_raised = false;
                            } else if (
                              location &&
                              location.label === "others"
                            ) {
                              checkObj.verification_source_location = {
                                label: "others",
                              };
                              checkObj.is_research_raised = true;
                              researchType = "verificationSourceLocation";
                              checkObj.organisation_type = 1;
                              checkObj.verification_source_address = null;
                            }
                            this.setState({ checkObj, researchType });
                          }}
                          isDisable={checkObj.is_frozen || this.props.isCSE}
                        />
                      </div>
                    )}

                  {checkObj.verification_source &&
                    checkObj.verification_source.value &&
                    checkObj.verification_source_location &&
                    checkObj.verification_source_location.label && (
                      <div className="form-group">
                        <label className="text-uppercase text-secondary">
                          Address-
                          <Asterisk />
                        </label>
                        <textarea
                          className={`form-control input-section`}
                          type="text"
                          value={checkObj.verification_source_address}
                          readOnly={
                            checkObj.verification_source_location.label !==
                            "others"
                          }
                          onChange={(e) => {
                            let { checkObj } = this.state;
                            checkObj.verification_source_address =
                              e.target.value;
                            this.setState({ checkObj });
                          }}
                        ></textarea>
                      </div>
                    )}
                </React.Fragment>
              )}
              {(checkObj.check_id === constants.checkIds.EDUCATION_W_CHECK_ID ||
                checkObj.check_id ===
                  constants.checkIds.EDUCATION_CHECK_ID) && (
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="text-uppercase text-secondary">
                        Date from
                        <Asterisk />
                      </label>
                      <CustomInput
                        type={"Date"}
                        onChange={(e) => {
                          checkObj.date_from = e.target.value;
                          this.setState({ checkObj });
                        }}
                        value={checkObj.date_from}
                        isDisable={checkObj.is_frozen || this.props.isCSE}
                      ></CustomInput>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="text-uppercase text-secondary">
                        Date to <Asterisk />
                      </label>
                      <CustomInput
                        type={"Date"}
                        onChange={(e) => {
                          checkObj.date_to = e.target.value;
                          this.setState({ checkObj });
                        }}
                        value={checkObj.date_to}
                        isDisable={checkObj.is_frozen || this.props.isCSE}
                      ></CustomInput>
                    </div>
                  </div>
                </div>
              )}

              {(checkObj.check_id ===
                constants.checkIds.CURRENT_EMPLOYMENT_VERIFICATION_CHECK_ID ||
                checkObj.check_id ===
                  constants.checkIds
                    .PREVIOUS_EMPLOYMENT_VERIFICATION_CHECK_ID ||
                checkObj.check_id ===
                  constants.checkIds.ADDRESS_VERIFICATION_CHECK_ID ||
                checkObj.check_id ===
                  constants.checkIds
                    .INDIA_COURT_RECORD_CHECK_THROUGH_LAW_FIRM_CHECK_ID ||
                checkObj.check_id ===
                  constants.checkIds
                    .GAP_CHECK_COURT_RECORD_CHECK_VERIFICATION_THROUGH_LAW_FIRM_CHECK_ID ||
                checkObj.check_id ===
                  constants.checkIds
                    .GAP_CHECK_POLICE_RECORD_VERIFICATION_THROUGH_LAW_FIRM_CHECK_ID ||
                checkObj.check_id ===
                  constants.checkIds.PERMANENT_VERIFICATION_CHECK_ID ||
                checkObj.check_id ===
                  constants.checkIds
                    .INDIAN_POLICE_RECORD_VERIFICATION_THROUGH_LAW_FIRM_CHECK_ID) && (
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="text-uppercase text-secondary">
                        Date from
                        <Asterisk />
                      </label>
                      <CustomInput
                        type={"Date"}
                        onChange={(e) => this.setDateFrom(e, 0)}
                        value={checkObj.date_from}
                        isDisable={checkObj.is_frozen || this.props.isCSE}
                      ></CustomInput>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="text-uppercase text-secondary">
                        Date to <Asterisk />
                      </label>
                      <CustomInput
                        type={"Date"}
                        onChange={(e) => this.setDateTo(e, 0)}
                        value={checkObj.date_to}
                        isDisable={checkObj.is_frozen || this.props.isCSE}
                      ></CustomInput>
                    </div>
                  </div>
                </div>
              )}

              {checkObj.check_id === constants.checkIds.EDUCATION_W_CHECK_ID &&
                checkObj.check_fields &&
                checkObj.check_fields.map((checkField) =>
                  checkField.antecedent_id ===
                  constants.antecedantId.EDUCATION_VERIFICATION_COURSE_NAME ? (
                    <div className="form-group">
                      <label className="text-uppercase text-secondary">
                        {checkField.antecedent_name}
                        <Asterisk />
                      </label>
                      <div className="d-flex">
                        <div className="w-100">
                          <AsyncSelectBox
                            loadOptions={_debounce(
                              this.getCourseOptionBasedOnSearch,
                              300
                            )}
                            defaultValue={checkObj.course_name}
                            onChange={(val) => {
                              let { checkObj } = this.state;
                              checkObj.course_name = val;
                              checkField.antecedent_value = val
                                ? val.value
                                : "";
                              this.setState({ checkObj: checkObj });
                            }}
                            isDisable={checkObj.is_frozen || this.props.isCSE}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null
                )}

              <div class="row">
                {checkObj.check_fields &&
                  checkObj.check_fields.map((checkField) =>
                    checkField.antecedent_type === "Verified" ||
                    constants.exclude_antecedent_CTS.includes(
                      checkField.antecedent_id
                    ) ? null : (
                      <div
                        className={
                          checkField.antecedantInputType === "Text Area"
                            ? "col-md-12"
                            : "col-md-6"
                        }
                      >
                        <div
                          className="form-group "
                          key={checkField.antecedent_id}
                        >
                          <label className="text-uppercase text-secondary">
                            {checkField.antecedent_name}
                            {checkField.is_mandatory && <Asterisk />}
                          </label>
                          {
                            <CustomInput
                              type={checkField.antecedent_input_type}
                              antecedantId={checkField.antecedent_id}
                              checkId={checkObj.check_id}
                              onChange={(e) => {
                                let { isBankStatement } = this.state;
                                checkField.antecedent_value = e.target.value;
                                if (
                                  checkField.antecedent_id ===
                                  constants.antecedantId.DOCUMENT_TYPE_ID
                                ) {
                                  if (
                                    checkField.antecedent_value ==
                                    "Bank Statement"
                                  ) {
                                    isBankStatement = true;
                                  } else {
                                    isBankStatement = false;
                                  }
                                }
                                this.setState({ checkObj, isBankStatement });
                              }}
                              value={checkField.antecedent_value}
                              option={checkField.drop_down_values}
                              required={checkField.is_mandatory}
                              isDisable={
                                checkObj.is_frozen ||
                                this.props.isCSE ||
                                (checkObj.check_id !==
                                  constants.checkIds
                                    .CURRENT_EMPLOYMENT_VERIFICATION_CHECK_ID &&
                                  fromToDurationObj &&
                                  fromToDurationObj.antecedentId.includes(
                                    checkField.antecedent_id
                                  ))
                              }
                              maxlength={
                                checkField.antecedent_id ===
                                  constants.antecedantId.PASSPORT_MRZ1 ||
                                checkField.antecedent_id ===
                                  constants.antecedantId.PASSPORT_MR2 ||
                                checkField.antecedent_id ===
                                  constants.antecedantId
                                    .NATIONAL_IDENTITY_CHECK_MRZ1 ||
                                checkField.antecedent_id ===
                                  constants.antecedantId
                                    .NATIONAL_IDENTITY_CHECK_MRZ2
                                  ? 44
                                  : ""
                              }
                              //   isHidden={checkField.isHidden}
                              //   antecedantId={checkField.antecedantId}
                              //   isError={checkField.isError}
                              //   minlength={checkField.minlength}
                              //   maxlength={checkField.maxlength}
                            ></CustomInput>
                          }
                        </div>
                      </div>
                    )
                  )}
              </div>

              {/* Hiring  employement verification case */}
              {(checkObj.check_id ===
                constants.checkIds.PREVIOUS_EMPLOYMENT_VERIFICATION_CHECK_ID ||
                checkObj.check_id ===
                  constants.checkIds
                    .CURRENT_EMPLOYMENT_VERIFICATION_CHECK_ID) && (
                <HiringList
                  checkObj={checkObj}
                  callback={(data) => {
                    let { checkObj } = this.state;
                    checkObj.is_in_hiring_list = data.value;
                    this.setState({ checkObj });
                  }}
                  getHiringList={() => {
                    this.openHiringModal(
                      "company",
                      checkObj.verification_source.label
                    );
                  }}
                />
              )}

              {/* Hiring education verification case */}
              {(checkObj.check_id === constants.checkIds.EDUCATION_W_CHECK_ID ||
                checkObj.check_id ===
                  constants.checkIds.EDUCATION_CHECK_ID) && (
                <HiringList
                  checkObj={checkObj}
                  callback={(data) => {
                    let { checkObj } = this.state;
                    checkObj.is_in_hiring_list = data.value;
                    this.setState({ checkObj });
                  }}
                  getHiringList={() => {
                    this.openHiringModal(
                      "institute",
                      checkObj.verification_source.label
                    );
                  }}
                />
              )}
              {(checkObj.check_id ===
                constants.checkIds.CURRENT_EMPLOYMENT_VERIFICATION_CHECK_ID ||
                checkObj.check_id ===
                  constants.checkIds
                    .PREVIOUS_EMPLOYMENT_VERIFICATION_CHECK_ID ||
                checkObj.check_id ===
                  constants.checkIds.ADDRESS_VERIFICATION_CHECK_ID ||
                checkObj.check_id ===
                  constants.checkIds
                    .INDIA_COURT_RECORD_CHECK_THROUGH_LAW_FIRM_CHECK_ID ||
                checkObj.check_id ===
                  constants.checkIds
                    .GAP_CHECK_COURT_RECORD_CHECK_VERIFICATION_THROUGH_LAW_FIRM_CHECK_ID ||
                checkObj.check_id ===
                  constants.checkIds.PERMANENT_VERIFICATION_CHECK_ID ||
                checkObj.check_id ===
                  constants.checkIds
                    .GAP_CHECK_POLICE_RECORD_VERIFICATION_THROUGH_LAW_FIRM_CHECK_ID ||
                checkObj.check_id ===
                  constants.checkIds
                    .INDIAN_POLICE_RECORD_VERIFICATION_THROUGH_LAW_FIRM_CHECK_ID) && (
                <MultipleTenure
                  checkObject={checkObj}
                  addTenure={this.addTenure}
                  updateAdditionalTenures={this.updateAdditionalTenures}
                  isCSE={this.props.isCSE}
                />
              )}
              <div class="row">
                <div class="col-md-12 d-flex justify-content-between align-items-center">
                  {isSubmitCheckLoader ? (
                    <div
                      class="spinner-border text-info float-right m-2 spinner-block"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <button
                      className="btn btn-primary btn-sm "
                      disabled={checkObj.is_frozen || this.props.isCSE}
                      onClick={() => {
                        this.saveList();
                      }}
                    >
                      Submit Check
                    </button>
                  )}

                  <button
                    className="btn btn-primary btn-sm "
                    disabled={checkObj.is_frozen || this.props.isCSE}
                    onClick={() => {
                      this.submitCheck(false);
                    }}
                  >
                    Save Check
                  </button>
                  <button
                    className="transparent-borderless check-close-btn"
                    onClick={() => this.props.onCheckSelected(null)}
                  >
                    <i
                      className="fa fa-caret-up text-muted"
                      style={{ fontSize: "36px" }}
                    ></i>
                  </button>
                  <PopupMenuCheck
                    raiseInsufficiencyCheck={this.raiseInsufficiencyCheck}
                    openCheckHistoryModel={this.openCheckHistoryModel}
                    setDisableCheck={this.setDisableCheck}
                    checkObject={{ ...checkObj, isSubmit: checkObj.is_frozen }}
                    //  submitCheck={this.submitCheck}
                    isCSE={this.props.isCSE}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
