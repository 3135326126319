import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import api from "./../../../apiBaseConfig";
import _groupBy from "lodash/groupBy";
import _cloneDeep from "lodash/cloneDeep";
import {
  PACKAGE_LIST,
  BUCKET_CASES,
  DROPDOWN_SEARCH,
  CHANGE_STATUS_CASE,
  GET_DOCUMENTS,
  CASE_HISTORY_COMMENT,
  SUBMIT_COMMENT,
  GET_CHECK_LIST,
  RAISE_CASE_INSUFF,
  CHECK_BY_PACKAGE,
  ANTECEDENT_BY_PACKAGE,
  FINAL_SUBMISSION_CASE,
  DOCUMENT_UPLOAD,
} from "./../../../apiurl";
import { deleteAction } from "./../../../components/buckettimer/state/timer.action";
import LoadingScreen from "./../../../components/loadingScreen/loadingScreen";
import CustomInput from "./../../../components/customInput/customInput";
import Selectbox from "./../../../components/Selectbox/selectbox";
import AsyncSelectBox from "./../../../components/asyncSelectBox/asyncSelectBox";
import ClientDetail from "./../../components/clientDetail/clientDetail";
import { getFileType } from "./../../../utils/commonutils";
import BUCKETTIMER from "./../../../accenture/components/bucketTimer/bucketTimer";
import _debounce from "lodash/debounce";
import { connect } from "react-redux";
import DocumentViewer from "./../../../components/documentViewer/documentViewer";
import PopupMenu from "./../../../components/popupMenu/popupMenu";
import Escalation from "./../../../components/Escalation/escalation";
import "./qcScreen.scss";
import FileInput from "../../../components/fileInput/fileInput";
import CaseHistory from "./../../../components/caseHistory/caseHistory";
import CheckListCard from "./../../../accenture/components/checkListCard/checkListCard";
import Document from "./../../components/documentTagPopup/documentTagPopup";
import Toggle from "./../../../screens/bucketDetails/components/toggle/toggle";
import { constants } from "./../../../constants";
import { Toast } from "../../../utils/toastutils";
import { getTimeArray } from "./../../../utils/commonutils";
import { modalManager } from "../../../utils/modalutils";
import { Redirect } from "react-router-dom";
import { get } from "lodash";
class Qcscreen extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      packages: [],
      caseDetails: {},
      selectedPackage: {},
      selectedLocation: {},
      documentList: [],
      activeDocId: "",
      currentDocumentURL: "",
      currentDocumentType: "",
      checkListArray: [],
      groupCheckListArray: [],
      selectedCheckUID: null,
      isToggleOn: false,
      isFinalSubmit: false,
    };
  }

  componentDidMount = () => {
    this.getCaseDetails();
    this.getPackages();
    this.getDocumentList();
  };

  getCaseDetails = async () => {
    const { id } = this.props.match.params;
    this.setState({ isLoading: true });
    let { selectedLocation, selectedPackage } = this.state;
    try {
      const response = await api.get(`${BUCKET_CASES}${id}`);
      selectedLocation = { label: response.data.location, id: "" };
      selectedPackage = {
        label: response.data.package_name,
        value: response.data.package_id,
        priorityFlag: 0,
      };
      this.setState(
        {
          caseDetails: response.data,
          selectedLocation,
          selectedPackage,
        },
        () => {
          const { caseDetails } = this.state;
          if (caseDetails.bridge_ars_no) {
            this.getCheckList();
          }
        }
      );
    } catch (e) {
      console.log(e);
    }
    this.setState({ isLoading: false });
  };

  getCheckList = async () => {
    const { id } = this.props.match.params;
    try {
      const checkList = await api.get(`${GET_CHECK_LIST}${id}`);
      this.setState(
        {
          checkListArray: checkList.data,
          selectedCheckUID: null,
          isToggleOn: false,
        },
        () => {
          this.getGroupCheckList();
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  getGroupCheckList = (checkArray = this.state.checkListArray) => {
    let groupByCheckList = _groupBy([...checkArray], "check_id");
    groupByCheckList = Object.values(groupByCheckList);
    // groupByCheckList.sort((arr1, arr2) => arr1[0].priority - arr2[0].priority);
    //inserting check number
    // groupByCheckList.forEach(group => {
    //   let checkNumber = 1;
    //   for (let check of group) {
    //     check.checkNumber = checkNumber;
    //     checkNumber++;
    //   }
    // });

    this.setState({
      groupCheckListArray: Object.values(groupByCheckList),
    });
  };

  getDocumentList = async () => {
    const { id } = this.props.match.params;
    let { documentList } = this.state;
    try {
      const response = await api.get(`${GET_DOCUMENTS}?pvt_id=${id}`);
      documentList = response.data.map((documentObj) => {
        return {
          path: documentObj.gcs_blob_name,
          id: documentObj.id,
          gcspath: documentObj.gcs_signed_url,
          type: documentObj.name,
          documentType: documentObj.document_type,
        };
      });
      this.setState({ documentList });
    } catch (e) {
      console.log(e);
    }
  };

  getCountryLocationOptionBasedOnSearch = (
    searchString = "",
    callbackToAsyncSelect
  ) => {
    api
      .post(`${DROPDOWN_SEARCH}city/`, { search_string: searchString })
      .then((response) => {
        let locationArray = response.data;
        locationArray = locationArray.map((location) => ({
          label: location.city_name,
          value: location.city_id,
        }));
        callbackToAsyncSelect(locationArray);
      })
      .catch((err) =>
        console.log("error in fetching location based on search string", err)
      );
  };
  getPackages = () => {
    api
      .get(PACKAGE_LIST)
      .then((response) => {
        const packages = response.data;
        const selectPackages = packages.map((item) => ({
          label: item.process_name,
          value: item.process_id,
          priorityFlag: item.priority_flag,
        }));
        this.setState({
          packages: selectPackages,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handlePackageChange = (selectedPackage) => {
    const body = {
      package_id: selectedPackage.value,
      package_name: selectedPackage.label,
    };
    this.saveData(body, this.getCaseDetails);
  };

  saveDeatils = async (
    string,
    clientFormData,
    selectedDoc,
    updatedFormData = {}
  ) => {
    const { caseDetails } = this.state;
    const body = {
      ...updatedFormData,
      date_of_initiation: caseDetails.date_of_initiation,
      time_of_initiation: caseDetails.time_of_initiation,
    };
    // if (!updatedFormData.offer_made_date) {
    //   body.offer_made_date = "Not mentioned";
    // }
    if (selectedDoc && selectedDoc.length) {
      body.case_documents = selectedDoc;
    }
    return this.saveData(body, () => {}, true);
  };

  saveCheckList = async (checkObject) => {
    const { checkListArray } = this.state;
    let checkListData = _cloneDeep(checkListArray);
    const index = checkListArray.findIndex(
      (check) => check.check_uid === checkObject.check_uid
    );
    if (index !== -1) {
      checkListData[index] = checkObject;
      try {
        const body = { check_list_json: checkListData };
        const response = await this.saveData(body, () => {});
        this.getCheckList();
        return Promise.resolve(response);
      } catch (e) {
        console.log(e);
        return Promise.reject(e);
      }
    } else {
      console.log("cannot find index of the checkObject ");
      Promise.reject("cannot find index of the checkObject");
    }
  };

  saveData = async (body, callback, flag) => {
    const { id } = this.props.match.params;
    try {
      const result = await api.patch(`${CHANGE_STATUS_CASE}${id}/`, body);

      Toast.success({ description: "update successfully" });
      callback();
      return Promise.resolve(result);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  };

  setLoadingValue = (value) => {
    this.setState({ isLoading: value });
  };

  /**
   * this is use for getting case history comment
   */
  getCaseCommentHistory = async (pvtId) => {
    let response;
    try {
      response = await api.get(`${CASE_HISTORY_COMMENT}?pvt_id=${pvtId}`);
      const comments = response.data.map((commentObj) => {
        return {
          commentId: commentObj.id,
          createdAt: commentObj.created_at,
          comment: commentObj.comment && commentObj.comment.message,
          action: commentObj.name,
          userName: "",
          roleName: "",
        };
      });
      //response = response.data.comments;
      return Promise.resolve(comments);
    } catch (error) {
      console.log("error", error);
      return Promise.reject(error);
    }
  };

  openCaseHistoryModel = () => {
    const { id } = this.props.match.params;
    const { userInfo } = this.props;
    modalManager.open({
      isHeader: false,
      component: () => (
        <CaseHistory
          isCheck={false}
          pvtId={id}
          userInfo={userInfo}
          getCommentHistory={() => {
            return this.getCaseCommentHistory(id);
          }}
          label="Case History"
          buttonName="Add To Comment"
          submitComment={this.submitCaseComment}
          closeCommentHistoryModal={() => modalManager.close({})}
          successMessage={"comment added successfully"}
          errorMessage={"There is some error in adding comment"}
        />
      ),
    });
  };

  raiseEscalation = () => {
    const { userInfo } = this.props;
    const { id } = this.props.match.params;
    const escalationObject = {
      label: "esclating",
      userName: `${userInfo.userFirstName} ${userInfo.userLastName}`,
      userId: userInfo.userId,
      status: constants.caseStatus.ESCALATION_RAISED,
      action: "Escalation Raised",
      id: id,
    };
    modalManager.open({
      isHeader: false,
      component: () => (
        <Escalation
          data={escalationObject}
          callback={this.changeStatus}
          btnLabel="Esclation"
        />
      ),
    });
  };

  raiseInsufficiency = () => {
    const { userInfo } = this.props;
    const { id } = this.props.match.params;
    const escalationObject = {
      label: "raise insuffiency",
      userName: `${userInfo.userFirstName} ${userInfo.userLastName}`,
      userId: userInfo.userId,
      status: constants.caseStatus.INSUFFICIENT,
      action: "Insufficient",
      id: id,
    };
    modalManager.open({
      isHeader: false,
      component: () => (
        <Escalation
          data={escalationObject}
          callback={this.raiseInsuff}
          btnLabel="raise insuffiency"
        />
      ),
    });
  };

  raiseInsuff = async (obj) => {
    const body = {
      pvt_id: obj.id,
      comment: obj.comment,
    };
    try {
      const response = await api.post(RAISE_CASE_INSUFF, body);
      Toast.success({
        description: "insuff raised successfully",
      });
      modalManager.close({});
      this.getCaseDetails();
    } catch (e) {
      console.log(e);
    }
  };

  changeStatus = async (obj) => {
    const { userInfo } = this.props;
    const body = {
      status: obj.status,
    };
    try {
      const commentObj = {
        comment: obj.comment,
        pvtId: obj.id,
        email: userInfo.email,
        action: obj.action,
      };
      const responseObj = await this.submitCaseComment(commentObj);
      const response = await api.patch(`${CHANGE_STATUS_CASE}${obj.id}/`, body);
      Toast.success({
        description: "change status successfully",
      });
      modalManager.close({});
      this.getCaseDetails();
    } catch (e) {
      console.log(e);
    }
  };
  submitCaseComment = async (commentObject) => {
    const { userInfo } = this.props;
    const { caseDetails } = this.state;
    try {
      const action = await api.post(
        `${CASE_HISTORY_COMMENT}?pvt_id=${commentObject.pvtId}`,
        {
          pvt_id: commentObject.pvtId,
          name: commentObject.action
            ? commentObject.action
            : "Comment Added on case",
          created_by: commentObject.email,
        }
      );
      const actionId = action.data.id;
      const response = await api.post(SUBMIT_COMMENT, {
        message: commentObject.comment,
        action: actionId,
        bridge_case_id: caseDetails.bridge_case_id,
      });
      return Promise.resolve(response);
    } catch (error) {
      console.log("error", error);
      return Promise.reject(error);
    }
  };

  handleSubmittedChecksToggleChange = (showOnlySubmittedChecks) => {
    const { isToggleOn, checkListArray } = this.state;
    const check = JSON.parse(JSON.stringify(checkListArray));
    const submittedChecks = check.filter(
      (check) => check.is_frozen && !check.is_disabled
    );
    this.setState({ isToggleOn: !isToggleOn }, () => {
      const { isToggleOn } = this.state;
      if (isToggleOn) {
        this.getGroupCheckList(submittedChecks);
      } else {
        this.getGroupCheckList(check);
      }
    });
  };

  getDocumentType = (url) => {
    const type = url.split("/");
    return type[type.length - 1].toLowerCase();
  };

  //REACT_APP_FILE_URL
  loadDocument = (path, gcspath, id = "") => {
    const fileURL = `${gcspath}`;
    const fileType = this.getDocumentType(path);
    if (fileType === "doc") {
      this.setState({ iframeDocumentLoading: true });
    } else {
      this.setState({ iframeDocumentLoading: false });
    }
    this.setState({
      currentDocumentURL: fileURL,
      currentDocumentType: fileType,
      activeDocId: id,
    });
  };

  addCheckWraper = async (id) => {
    const { selectedPackage, checkListArray } = this.state;
    try {
      const check = await api.get(
        `${ANTECEDENT_BY_PACKAGE}${selectedPackage.value}/${id}/`
      );
      const groupCheckArray =
        checkListArray.filter(
          (checkObject) => checkObject.check_id === check.data.check_id
        ) || [];
      check.data.check_name = `${check.data.check_name}(${
        groupCheckArray.length + 1
      })`;
      check.data.check_number = groupCheckArray.length + 1;
      return Promise.resolve(check.data);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  };
  addCheck = async (data) => {
    const { selectedPackage, checkListArray } = this.state;
    const obj = {
      41: constants.checkIds.PREVIOUS_COMPANY_CHECK_ID,
      58: constants.checkIds.CURRENT_COMPANY_CHECK_ID,
    };
    try {
      let updateCheckListArray = JSON.parse(JSON.stringify(checkListArray));
      const check = await this.addCheckWraper(data.check_id);
      updateCheckListArray.push(check);
      if (
        data.check_id ===
          constants.checkIds.PREVIOUS_EMPLOYMENT_VERIFICATION_CHECK_ID ||
        data.check_id ===
          constants.checkIds.CURRENT_EMPLOYMENT_VERIFICATION_CHECK_ID
      ) {
        const id = obj[data.check_id];
        const mirrorCheck = await this.addCheckWraper(id);
        updateCheckListArray.push(mirrorCheck);
      }
      const body = { check_list_json: updateCheckListArray };
      const response = await this.saveData(body, () => {});
      this.getCheckList();
    } catch (e) {
      console.log(e);
    }
  };

  openCheckPopup = async () => {
    const { selectedPackage } = this.state;
    try {
      const response = await api.get(
        `${CHECK_BY_PACKAGE}${selectedPackage.value}/`
      );
      const documentCheck = response.data.map((checkObj) => {
        return { ...checkObj, bridge_name: checkObj.check_name };
      });
      modalManager.open({
        isHeader: false,
        width: "small",
        component: () => (
          <Document
            document={documentCheck}
            callback={(data) => {
              this.addCheck(data);
            }}
          />
        ),
      });
    } catch (e) {
      console.log(e);
    }
  };

  onDocumentSelected = (file) => {
    const { id } = this.props.match.params;
    if (!file) {
      return;
    }
    let formdata = new FormData();
    formdata.append("document", file, file.name);
    formdata.append("pvt_id", id);
    api
      .post(DOCUMENT_UPLOAD, formdata)
      .then(() => {
        this.getDocumentList();
        this.getCaseDetails();
      })
      .catch((err) => console.log("error in uploading doucment", err));
  };

  handleFormSubmit = async () => {
    const { id } = this.props.match.params;
    const body = {
      pvt_id: id,
    };
    try {
      this.setState({ isFinalSubmit: true });
      const response = await api.post(FINAL_SUBMISSION_CASE, body);
      Toast.success({ description: "final submit successfully" });
      this.props.deleteAction({ pvtId: id });
      this.props.history.push("/qcdashboard");
    } catch (e) {
      this.setState({ isFinalSubmit: false });
      console.log(e);
    }
  };

  render() {
    const {
      isLoading,
      packages,
      caseDetails,
      selectedPackage,
      selectedLocation,
      documentList,
      activeDocId,
      currentDocumentURL,
      currentDocumentType,
      groupCheckListArray,
      checkListArray,
      isToggleOn,
      isFinalSubmit,
    } = this.state;
    // console.log("group", groupCheckListArray);
    const { userInfo } = this.props;
    const isCSE =
      this.props.location.state && this.props.location.state.isCSE
        ? this.props.location.state.isCSE
        : false;
    const isCaseInsuff =
      caseDetails.status === constants.caseStatus.INSUFFICIENT;
    const isCaseComplete =
      caseDetails.status === constants.caseStatus.QC_COMPLETE;
    if (isCaseComplete) {
      return <Redirect to="/qcdashboard" />;
    }
    return (
      <div className="qc-screen-block">
        {isLoading ? (
          <LoadingScreen isLoading={isLoading} />
        ) : (
          <div className="container-fluid">
            <div className="timer-section">
              <BUCKETTIMER
                userInfo={userInfo}
                userId={userInfo.userId}
                pvtId={this.props.match.params.id}
                status={caseDetails && caseDetails.status}
                bridgeCaseId={caseDetails.bridge_case_id || null}
                getCaseDetail={this.getCaseDetails}
                userName={userInfo.userFirstName}
                verificationType={caseDetails.verificationType}
                handleSubmittedChecksToggleChange={
                  this.handleSubmittedChecksToggleChange
                }
                isToggleOn={isToggleOn}
                isCse={isCSE}
              />
            </div>
            <div className="row">
              <div className="col-md-5 scrollable">
                <div
                  className={`card bg-white mb-3
                  ${isCaseInsuff ? "border-red" : ""}`}
                >
                  <h6
                    className={`${
                      isCaseInsuff ? "text-danger" : "text-muted"
                    } font-weight-bold`}
                    style={{ margin: "10px" }}
                  >
                    Case Details
                  </h6>
                  <div className="card-body pb-0">
                    <div className="card-text">
                      <div className="row justify-content-between small mb-2">
                        <div class="col-md-6">
                          <div className="form-group ng-star-inserted">
                            <label className="text-muted text-uppercase label-header">
                              Client Name
                            </label>
                            <CustomInput
                              type={"text"}
                              value={userInfo.clientName}
                              required={false}
                              isDisable={true}
                              onChange={(e) => {}}
                            ></CustomInput>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label className="text-muted text-uppercase label-header">
                            Process<sup className="staric-data">*</sup>
                          </label>
                          <div>
                            <Selectbox
                              option={packages}
                              defaultValue={
                                selectedPackage.value ? selectedPackage : null
                              }
                              callback={(selectedPackage) =>
                                this.handlePackageChange(selectedPackage)
                              }
                              isDisable={
                                isCSE ||
                                isCaseInsuff ||
                                caseDetails.bridge_ars_no
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row justify-content-between small mb-1">
                        <div class="col-md-6">
                          <div class="form-group ng-star-inserted">
                            <label className="text-muted text-uppercase label-header">
                              Location
                            </label>
                            <CustomInput
                              type={"text"}
                              value={caseDetails.location}
                              required={false}
                              isDisable={true}
                              onChange={(e) => {}}
                            ></CustomInput>
                            {/* <div>
                              <AsyncSelectBox
                                loadOptions={_debounce(
                                  this.getCountryLocationOptionBasedOnSearch,
                                  300
                                )}
                                defaultValue={selectedLocation}
                                onChange={(location) => {
                                  this.setState({ selectedLocation: location });
                                }}
                                isDisable={
                                  isCSE ||
                                  isCaseInsuff ||
                                  caseDetails.bridge_ars_no
                                }
                              />
                            </div> */}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group ng-star-inserted">
                            <label className="text-muted text-uppercase label-header">
                              ARS Number
                            </label>
                            <CustomInput
                              type={"text"}
                              value={caseDetails.bridge_ars_no}
                              required={true}
                              isDisable={true}
                              onChange={(e) => {}}
                            ></CustomInput>
                          </div>
                        </div>
                      </div>
                      <div class="row justify-content-between small mb-1">
                        <div class="col-md-6">
                          <div class="form-group ng-star-inserted">
                            <label className="text-muted text-uppercase label-header">
                              Case Received Date
                            </label>
                            <CustomInput
                              type={"Date"}
                              value={caseDetails.date_of_initiation}
                              required={true}
                              onChange={(e) => {
                                let { caseDetails } = this.state;
                                caseDetails.date_of_initiation = e.target.value;
                                this.setState({
                                  caseDetails,
                                });
                              }}
                              isDisable={
                                isCSE ||
                                isCaseInsuff ||
                                caseDetails.bridge_ars_no
                              }
                            ></CustomInput>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group ng-star-inserted">
                            <label className="text-muted text-uppercase label-header">
                              Case received time
                            </label>
                            <div>
                              <Selectbox
                                option={getTimeArray()}
                                defaultValue={{
                                  label: caseDetails.time_of_initiation,
                                }}
                                callback={(selectedPackage) => {
                                  let { caseDetails } = this.state;
                                  caseDetails.time_of_initiation =
                                    selectedPackage.label;
                                  this.setState({ caseDetails });
                                }}
                                isDisable={
                                  isCSE ||
                                  isCaseInsuff ||
                                  caseDetails.bridge_ars_no
                                }
                              />
                            </div>

                            {/* <CustomInput
                              type={"text"}
                              value={"unknown"}
                              onChange={(e) => {}}
                              required={true}
                              isDisable={true}
                            ></CustomInput> */}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          {caseDetails.bridge_ars_no && (
                            <PopupMenu
                              raiseInsufficiency={this.raiseInsufficiency}
                              isCaseComplete={isCaseComplete}
                              raiseEscalation={this.raiseEscalation}
                              openCaseHistoryModel={this.openCaseHistoryModel}
                              isCSE={isCSE}
                              isCaseInsuff={isCaseInsuff}
                              arsNo={caseDetails.bridge_ars_no}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    {
                      <div class="accordion" id="clientDetailsAccordion">
                        <ClientDetail
                          setLoadingValue={this.setLoadingValue}
                          selectedLocation={selectedLocation}
                          saveDeatils={this.saveDeatils}
                          userId={this.props.userInfo.userId}
                          pvtId={this.props.match.params.id}
                          packageName={this.state.selectedPackage.label}
                          packageId={this.state.selectedPackage.value}
                          //priorityFlag={this.state.caseDetails.priorityFlag}
                          documentList={documentList}
                          caseDetails={caseDetails}
                          loadDocument={this.loadDocument}
                          callback={this.getCaseDetails}
                          //getArs={this.getArs}
                          isCSE={isCSE}
                          isCaseInsuff={isCaseInsuff}
                          isDocDisable={
                            isCSE ||
                            caseDetails.bridge_ars_no ||
                            isCaseInsuff ||
                            false
                          }
                        />
                      </div>
                    }
                  </div>
                </div>

                {/* checkList component */}
                {groupCheckListArray.map((checkArr) => (
                  <div
                    className="card bg-white mb-3"
                    //key={checkArr[0].check_id}
                  >
                    <div className="card-body small">
                      <h6 className="text-muted font-weight-bold">
                        {checkArr[0].sourceVerification ||
                          checkArr[0].check_name}
                      </h6>
                      {checkArr.map((item, index) => (
                        <CheckListCard
                          //key={`${item.check_uid}`}
                          documentList={documentList}
                          saveCheckList={this.saveCheckList}
                          // ref={instance => {
                          //   this[
                          //     `${item.checkId}-${item.checkNumber}`
                          //   ] = instance;
                          // }}
                          //isSubmitAllChecks={this.state.isSubmitAllChecks}
                          //handleAntecedantSync={this.handleAntecedantSync}
                          //checkNumber={item.checkNumber}
                          checkObject={item}
                          loadDocument={this.loadDocument}
                          pvtId={this.props.match.params.id}
                          userInfo={this.props.userInfo}
                          isDocDisable={isCSE || item.is_frozen}
                          // selectedPackage={this.state.selectedPackage}
                          // caseDetails={this.state.caseDetails}
                          // handleCheckChange={(checkObject, isSaveCheckList) =>
                          //   this.handleCheckChange(checkObject, isSaveCheckList)
                          // }
                          // antecedantIdNameMap={antecedantIdNameMap}
                          // openCheckHistoryModel={this.openCheckHistoryModel}
                          // documents={this.state.documentList || []}
                          // submitCheckComment={this.submitCheckComment}
                          selectedCheckUID={this.state.selectedCheckUID}
                          onCheckSelected={(checkUID) =>
                            this.setState({ selectedCheckUID: checkUID })
                          }
                          // saveCheckList={this.saveCheckList}
                          isCSE={isCSE}
                          // isLoading={isLoading => this.setState({ isLoading })}
                        />
                      ))}
                    </div>
                  </div>
                ))}

                {caseDetails.bridge_ars_no && (
                  <div className="row justify-content-between mb-3">
                    <div class="col-auto">
                      <Toggle
                        callback={this.handleSubmittedChecksToggleChange}
                        isToggleOn={isToggleOn}
                      />
                    </div>
                    <div className="col-auto d-flex justify-content-end">
                      <div
                        className="transparent-borderless scroll-top-icon"
                        onClick={() => {
                          window.scroll({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <i
                          className="fa fa-arrow-up text-muted"
                          style={{ fontSize: "36px" }}
                        ></i>
                      </div>
                      <div className="row">
                        {/* {!this.state.isCSE && this.state.showGapCheckButton && (
                          <div
                            className="btn btn-outline-primary text-uppercase btn-sm btn-radius-9 mr-2"
                            onClick={() => {
                              this.openGapcheck();
                            }}
                          >
                            Gap Check
                          </div>
                        )} */}

                        {!isCSE && (
                          <div
                            className="btn btn-outline-primary text-uppercase btn-sm btn-radius-9 mr-2"
                            onClick={() => this.openCheckPopup()}
                          >
                            <i className="fa fa-plus-circle"></i> Add Check
                          </div>
                        )}
                        {!isFinalSubmit ? (
                          <button
                            className="btn btn-primary fadeIn fourth text-uppercase btn-sm btn-radius-9 pl-4 pr-4 mr-2"
                            onClick={(e) => {
                              this.handleFormSubmit(e);
                            }}
                            disabled={isCSE}
                          >
                            {this.state.isSaving && (
                              <span className="spinner-border spinner-border-sm mr-1"></span>
                            )}
                            Submit Case
                          </button>
                        ) : (
                          <div
                            class="spinner-border text-info float-right m-2 spinner-block"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-7 scrollable position-relative">
                <div>
                  <sup className="staric-data">
                    {" "}
                    Note: All documents will expire in 7 days
                  </sup>
                </div>
                <div id="labels" className="row border labels-section">
                  <ul>
                    <li style={{ backgroundColor: "transparent" }}>
                      <FileInput
                        onChange={(file) => this.onDocumentSelected(file)}
                      />
                    </li>
                    {documentList.map((document) => (
                      <li
                        className={
                          "rounded" +
                          (activeDocId === document.id ? " active-doc" : "")
                        }
                      >
                        <div className="d-flex align-items-center">
                          <div
                            onClick={() => {
                              this.loadDocument(
                                document.documentType,
                                document.gcspath,
                                document.id
                              );
                            }}
                          >
                            {document.type + "_" + document.id}
                          </div>
                          <button class="download-document-btn">
                            <a href={document.gcspath} download target="_blank">
                              <i
                                class="fa fa-arrow-down"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <DocumentViewer
                  currentDocumentURL={this.state.currentDocumentURL}
                  currentDocumentType={this.state.currentDocumentType}
                  onIframeLoad={() =>
                    this.setState({ iframeDocumentLoading: false })
                  }
                  iframeDocumentLoading={this.state.iframeDocumentLoading}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.login.userInfo,
  // gapAddressArray: state.gapcheck.gapAddressArray,
  // gapEmploymentEducationArray: state.gapcheck.gapEmploymentEducationArray,
  // educationAddressMismatchArray: state.gapcheck.educationAddressMismatchArray,
  // employmentAddressMismatchArray: state.gapcheck.employmentAddressMismatchArray
});

const mapDispatchToProps = (dispatch) => ({
  // updateAddressArray: gapAddressArray =>
  //   dispatch(updateAddressArray(gapAddressArray)),
  // updateEmploymentEducationArray: gapEmploymentEducationArray =>
  //   dispatch(updateEmploymentEducationArray(gapEmploymentEducationArray)),
  // updateEducationAddressMismatchArray: educationAddressMismatchArray =>
  //   dispatch(
  //     updateEducationAddressMismatchArray(educationAddressMismatchArray)
  //   ),
  // updateEmploymentAddressMismatchArray: employmentAddressMismatchArray =>
  //   dispatch(
  //     updateEmploymentAddressMismatchArray(employmentAddressMismatchArray)
  //   ),
  deleteAction: (data) => dispatch(deleteAction(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Qcscreen)
);
