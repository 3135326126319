import React, { Component } from "react";
import api from "../../apiBaseConfig";
import { UPLOAD_BGV_ID } from "../../apiurl";
import { Toast } from "../../utils/toastutils";
import { modalManager } from "../../utils/modalutils";
import { getFileType } from "./../../utils/commonutils";
import FileInput from "../fileInput/fileInput";
import "./uploadnewcases.scss";

export default class Uploadnewcases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: {},
      isLoader: false,
    };
  }

  uploadNewCasesExcelFile = (fileObj) => {
    this.setState({ file: fileObj });
  };

  uploadFile = async () => {
    const { file } = this.state;
    console.log("onDocumentSelected", file);
    if (!file) {
      return;
    }
    let formdata = new FormData();
    const type = getFileType(file.name);
    let name = file.name;
    name = name.replace(`.${type}`, "");
    try {
      this.setState({ isLoader: true });
      await this.props.callback(file, name);
      this.setState({ isLoader: false });
    } catch (e) {
      this.setState({ isLoader: false });
    }
  };
  render() {
    const { file, isLoader } = this.state;
    return (
      <div className="upload-new-case-nlock">
        <ul className="upload-info">
          <li>Case Id Column is mandatory</li>
          {/* <li>Sheet1 for sheet name is mandatory</li> */}
          <li>.xlsx extension is mandatory.</li>
        </ul>
        <div className="file-upload-case">
          <div>
            <FileInput
              onChange={(file) => this.uploadNewCasesExcelFile(file)}
              text="Upload Excel File"
              type=".xls, .xlsx"
            />
          </div>
          <div className="file-name-case">{file.name}</div>
        </div>

        <div className="upload-case-footer">
          <button
            class="btn btn-danger btn-sm float-left mr-2"
            data-dismiss="modal"
            onClick={() => {
              modalManager.close({});
            }}
          >
            Close
          </button>
          {!isLoader ? (
            <button
              class="btn btn-primary btn-sm float-right ml-2"
              disabled={!file.name}
              onClick={(e) => {
                this.uploadFile();
              }}
            >
              Submit
            </button>
          ) : (
            <div
              class="spinner-border text-info float-right m-2 spinner-block"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}
