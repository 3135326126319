import React, { Component } from "react";
import Selectbox from "../../../components/Selectbox/selectbox";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { dateToReverseHyphenFormat } from "../../../utils/commonutils";
import "./custumInput.scss";
import { constants } from "../../../constants";
import moment from "moment";
const MonthsData = {
  jan: '01',
  feb: '02',
  mar: '03',
  apr: '04',
  may: '05',
  jun: '06',
  jul: '07',
  aug: '08',
  sep: '09',
  oct: '10',
  nov: '11',
  dec: '12',
};
export default class CustomInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "text",
      option: [],
      startDate: new Date(),
    };
  }

  //From May,1994 To Till Date
  componentDidMount() {
    if (this.props.antecedantId && this.props.antecedantId === 60) {
      this.setState({ type: "date" });
    } else if (
      (this.props.antecedantId && this.props.antecedantId === 3728) ||
      (this.props.antecedantId &&
        this.props.antecedantId ===
          constants.antecedantId.INDIA_COURT_RECORD_PERIOD_OF_STAY_TSS) ||
      this.props.antecedantId ===
        constants.antecedantId.INDIA_COURT_RECORD_DATABASE_PERIOD_OF_STAY ||
      this.props.antecedantId ===
        constants.antecedantId.INDIA_COURT_RECORD_PERIOD_OF_STAY_TSS
    ) {
      //for period of bank statement , text instead of date
      this.setState({ type: "text" });
    } else {
      this.setType();
    }
  }

  setType = () => {
    let type;
    switch (this.props.type) {
      case "Text Field":
        type = "text";
        break;
      case "Date":
        type = "date";
        break;
      case "Text Area":
        type = "textArea";
        break;
      case "Drop Down":
        type = "dropDown";
        this.formatOption();
        break;

      default:
        type = "text";
    }

    this.setState({ type });
  };

  formatOption = () => {
    let option = this.props.option;
    option =
      option && option.map((option) => ({ label: option, value: option }));
    this.setState({ option });
  };

  months = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };
  
  formatDate = (date = "") => {
    let browserName = this.browserDetect()
    if (date) {
      date = date.toString();
      if (!date) {
        return "";
      }
      if (!date.includes("-")) {
        if (date.includes(".")) {
          if (date.indexOf(".") < 3) {
            date = date.split(".").reverse().join("-");
          } else {
            date = date.split(".").join("-");
          }
        } else if (date.includes("/")) {
          if (date.indexOf("/") < 3) {
            date = date.split("/").reverse().join("-");
          } else {
            date = date.split("/").join("-");
          }
        } else {
          if (date.indexOf(" ") < 3) {
            const arr = date.split(" ");
            let month = arr.length >= 2 ? arr[1] : "";
            month = this.months[month];
            arr[1] = month;
            date = arr.reverse().join("-");
          } else {
            date = date.split(" ").join("-");
          }
        }
      } else {
        if (date.indexOf("-") < 3) {
          date = date.split("-").reverse().join("-");
        }
      }
    }
    if(browserName=='firefox' && date){
      try{
        let dateArr = date.split('-')
      if(MonthsData[dateArr[1].toLowerCase()]){
        dateArr[1]=MonthsData[dateArr[1].toLowerCase()]
      }
      let dStr = dateArr.join('-')
      let momentDate = moment(dStr).format('YYYY-MM-DD HH:mm:ss')
      return momentDate
      ? new Date(momentDate) == "Invalid Date"
        ? null
        : new Date(momentDate)
      : null;
      }
      catch {
        return null
      }
      
    }
    return date
      ? new Date(date) == "Invalid Date"
        ? null
        : new Date(date)
      : null;
  };


 browserDetect=()=>{
                 
    let userAgent = navigator.userAgent;
    let browserName;
    
    if(userAgent.match(/chrome|chromium|crios/i)){
        browserName = "chrome";
      }else if(userAgent.match(/firefox|fxios/i)){
        browserName = "firefox";
      }  else if(userAgent.match(/safari/i)){
        browserName = "safari";
      }else if(userAgent.match(/opr\//i)){
        browserName = "opera";
      } else if(userAgent.match(/edg/i)){
        browserName = "edge";
      }else{
        browserName="No browser detection";
      }
    return browserName
}

  onDateChange = (date) => {
    const hyphenDate = dateToReverseHyphenFormat(date);

    this.props.onChange({ target: { value: hyphenDate } });
  };

  isAntecedantExcludedFromFormatText = (antecedantId) => {
    const excludedAntecedants = [
      constants.antecedantId.PASSPORT_MRZ1,
      constants.antecedantId.PASSPORT_MR2,
      constants.antecedantId.PASSPORT_TYPE,
      constants.antecedantId.PASSPORT_NUMBER,
      constants.antecedantId.PAN_NUMBER,
      constants.antecedantId.DRIVING_LICENSE_NUMBER,
      constants.antecedantId.NATIONAL_IDENTITY_CARD_NUMBER,
      constants.antecedantId.EDUCATION_W_ROLL_NO,
      constants.antecedantId.EDUCATION_ROLL_NO,
      constants.antecedantId.PREVIOUS_EMPLOYMENT_CODE,
      constants.antecedantId.CURRENT_EMPLOYMENT_CODE,
      constants.antecedantId.COUNTRY_CODE,
      constants.antecedantId.TAN,
      constants.antecedantId.PAN,
      constants.antecedantId.FORM_PAN,
      constants.antecedantId.FORM_TAN,
      constants.antecedantId.CURRENT_EMPLOYMENT_LAST_DRAWN_SALARY,
      constants.antecedantId.PREVIOUS_EMPLOYMENT_LAST_DRAWN_SALARY,
      constants.antecedantId.EDUCATION_VERIFICATION_WRITTEN_DEGREE_NAME,
      constants.antecedantId.PREVIOUS_EMPLOYMENT_DATE_OF_JOINING,
      constants.antecedantId.PREVIOUS_EMPLOYMENT_DATE_OF_LEAVING,
      constants.antecedantId.CURRENT_EMPLOYMENT_DATE_OF_JOINING,
      constants.antecedantId.CURRENT_EMPLOYMENT_DATE_OF_LEAVING,
      constants.antecedantId.INDIA_COURT_RECORD_PERIOD_OF_STAY_TSS,
      constants.antecedantId.CURRENT_EMPLOYMENT_DESIGNATION,
      constants.antecedantId.CURRENT_EMPLOYMENT_EMPLOYEE_NAME,
      constants.antecedantId.CURRENT_EMPLOYMENT_PERIOD_OF_EMPLOYMENT,
      constants.antecedantId.PREVIOUS_EMPLOYMENT_DESIGNATION,
      constants.antecedantId.PREVIOUS_EMPLOYMENT_EMPLOYEE_NAME,
    ];
    if (excludedAntecedants.find((id) => id === antecedantId)) {
      return true;
    }
    return false;
  };

  formatText = (text, antecedantId = null, formatDisable) => {
    if (!text) {
      return "";
    }

    if (
      this.isAntecedantExcludedFromFormatText(antecedantId) ||
      formatDisable
    ) {
      return text;
    }

    let words = text.split(" ");
    words = words.filter((word) => word !== "");

    words.forEach((word, index) => {
      if (word.length) {
        let temp = word.toLowerCase();
        words[index] = temp[0].toUpperCase() + temp.slice(1);
      }
    });
    const formattedText = words.join(" ");
    return formattedText;
  };

  onTextChange = (e, antecedantId = null, formatDisable = false) => {
    const text = e.target.value;
    const formattedText = this.formatText(text, antecedantId, formatDisable);
    e.target.value = formattedText;
    if (this.props.onChange && text) {
      this.props.onChange(e);
    }
  };

  render() {
    const { type } = this.state;
    const {
      value,
      required,
      isDisable = false,
      minlength,
      maxlength,
      uniqueIdForInput,
      antecedantId = null,
      formatDisable = true,
    } = this.props;
    return (
      <React.Fragment>
        {type === "text" && (
          <input
            type="text"
            id={uniqueIdForInput}
            spellCheck="true"
            className={`form-control text-primary input-section ${
              this.props.isDisable ? "row-disabled" : ""
            } ${this.props.isError ? "border border-danger" : ""}`}
            onChange={(e) => this.props.onChange(e)}
            onBlur={(e) => this.onTextChange(e, antecedantId, formatDisable)}
            value={value}
            required={required}
            readOnly={isDisable}
            minlength={minlength}
            maxlength={maxlength}
          ></input>
        )}
        {type === "date" && (
          <DatePicker
            popperModifiers={{
              preventOverflow: {
                enabled: true,
              },
            }}
            dateFormat="dd-MMM-yyyy"
            disabled={isDisable}
            placeholderText="dd-mmm-yyyy"
            selected={this.formatDate(value)}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            className={`form-control text-primary input-section ${
              this.props.isDisable ? "row-disabled" : ""
            } ${this.props.isError ? "border border-danger" : ""}`}
            onChange={(date) => this.onDateChange(date)}
          />
        )}
        {type === "textArea" && (
          <textarea
            className={`form-control transparent-input text-primary input-section w-100 ${
              this.props.isDisable ? "row-disabled" : ""
            } ${this.props.isError ? "border border-danger" : ""}`}
            id={uniqueIdForInput}
            onChange={(e) => this.props.onChange(e)}
            onBlur={(e) => this.onTextChange(e, antecedantId, formatDisable)}
            value={value}
            spellCheck="true"
            required={required}
            disabled={isDisable}
            minlength={minlength}
            maxlength={maxlength}
          ></textarea>
        )}
        {type === "dropDown" && (
          <Selectbox
            option={this.state.option}
            onChange={(e) => this.props.onChange(e)}
            defaultValue={{ label: value, value }}
            callback={(selectedPackage) =>
              this.props.onChange({ target: { value: selectedPackage.value } })
            }
            isDisable={isDisable}
          ></Selectbox>
        )}
      </React.Fragment>
    );
  }
}

// import React, { Component } from "react";
// import Selectbox from "./../../../components/Selectbox/selectbox";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { dateToReverseHyphenFormat } from "./../../../utils/commonutils";
// import "./custumInput.scss";
// import { constants } from "./../../../constants";

// export default class CustomInputBox extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       type: "text",
//       option: [],
//       startDate: new Date(),
//     };
//   }

//   //From May,1994 To Till Date
//   componentDidMount() {
//     if (this.props.antecedantId && this.props.antecedantId === 60) {
//       this.setState({ type: "date" });
//     } else if (this.props.antecedantId && this.props.antecedantId === 3728) {
//       //for period of bank statement , text instead of date
//       this.setState({ type: "text" });
//     } else {
//       this.setType();
//     }
//   }

//   setType = () => {
//     let type;
//     const { value, antecedantId = null, formatDisable = false } = this.props;
//     switch (this.props.type) {
//       case "Text Field":
//         type = "text";

//         break;
//       case "Date":
//         type = "date";
//         break;
//       case "Text Area":
//         type = "textArea";
//         break;
//       case "Drop Down":
//         type = "dropDown";
//         this.formatOption();
//         break;

//       default:
//         type = "text";
//     }

//     this.setState({ type }, () => {
//       if (this.state.type === "text" || this.state.type === "textArea") {
//         this.onTextChange({ target: { value } }, antecedantId, formatDisable);
//       }
//     });
//   };

//   formatOption = () => {
//     let option = this.props.option;
//     option = option.map((option) => ({ label: option, value: option }));
//     this.setState({ option });
//   };

//   months = {
//     Jan: "01",
//     Feb: "02",
//     Mar: "03",
//     Apr: "04",
//     May: "05",
//     Jun: "06",
//     Jul: "07",
//     Aug: "08",
//     Sep: "09",
//     Oct: "10",
//     Nov: "11",
//     Dec: "12",
//   };

//   formatDate = (date = "") => {
//     if (date) {
//       date = date.toString();
//       if (!date) {
//         return "";
//       }
//       if (!date.includes("-")) {
//         if (date.includes(".")) {
//           if (date.indexOf(".") < 3) {
//             date = date.split(".").reverse().join("-");
//           } else {
//             date = date.split(".").join("-");
//           }
//         } else if (date.includes("/")) {
//           if (date.indexOf("/") < 3) {
//             date = date.split("/").reverse().join("-");
//           } else {
//             date = date.split("/").join("-");
//           }
//         } else {
//           if (date.indexOf(" ") < 3) {
//             const arr = date.split(" ");
//             let month = arr.length >= 2 ? arr[1] : "";
//             month = this.months[month];
//             arr[1] = month;
//             date = arr.reverse().join("-");
//           } else {
//             date = date.split(" ").join("-");
//           }
//         }
//       } else {
//         if (date.indexOf("-") < 3) {
//           date = date.split("-").reverse().join("-");
//         }
//       }
//     }
//     return date ? new Date(date) : null;
//   };

//   onDateChange = (date) => {
//     const hyphenDate = dateToReverseHyphenFormat(date);

//     this.props.onChange({ target: { value: hyphenDate } });
//   };

//   isAntecedantExcludedFromFormatText = (antecedantId) => {
//     const excludedAntecedants = [
//       constants.antecedantId.PASSPORT_MRZ1,
//       constants.antecedantId.PASSPORT_MR2,
//       constants.antecedantId.PASSPORT_TYPE,
//       constants.antecedantId.PASSPORT_NUMBER,
//       constants.antecedantId.PAN_NUMBER,
//       constants.antecedantId.DRIVING_LICENSE_NUMBER,
//       constants.antecedantId.NATIONAL_IDENTITY_CARD_NUMBER,
//       constants.antecedantId.EDUCATION_W_ROLL_NO,
//       constants.antecedantId.EDUCATION_ROLL_NO,
//       constants.antecedantId.PREVIOUS_EMPLOYMENT_CODE,
//       constants.antecedantId.CURRENT_EMPLOYMENT_CODE,
//     ];
//     if (excludedAntecedants.find((id) => id === antecedantId)) {
//       return true;
//     }
//     return false;
//   };

//   formatText = (text, antecedantId = null, formatDisable) => {
//     if (!text) {
//       return "";
//     }

//     if (
//       this.isAntecedantExcludedFromFormatText(antecedantId) ||
//       formatDisable
//     ) {
//       return text;
//     }

//     let words = text.split(" ");
//     words = words.filter((word) => word !== "");

//     words.forEach((word, index) => {
//       if (word.length) {
//         let temp = word.toLowerCase();
//         words[index] = temp[0].toUpperCase() + temp.slice(1);
//       }
//     });
//     const formattedText = words.join(" ");
//     return formattedText;
//   };

//   onTextChange = (e, antecedantId = null, formatDisable = false) => {
//     const text = e.target.value;
//     const formattedText = this.formatText(text, antecedantId, formatDisable);
//     e.target.value = formattedText;
//     if (this.props.onChange) {
//       this.props.onChange(e);
//     }
//   };

//   render() {
//     const { type } = this.state;
//     const {
//       value,
//       required,
//       isDisable = false,
//       minlength,
//       maxlength,
//       uniqueIdForInput,
//       antecedantId = null,
//       formatDisable = false,
//     } = this.props;
//     return (
//       <React.Fragment>
//         {type === "text" && (
//           <input
//             type="text"
//             id={uniqueIdForInput}
//             spellCheck="true"
//             className={`form-control text-primary input-section custom-input-text ${
//               this.props.isDisable ? "row-disabled" : ""
//             } ${this.props.isError ? "border border-danger" : ""}`}
//             onChange={(e) => this.props.onChange(e)}
//             onBlur={(e) => this.onTextChange(e, antecedantId, formatDisable)}
//             value={value}
//             required={required}
//             readOnly={isDisable}
//             minlength={minlength}
//             maxlength={maxlength}
//           ></input>
//         )}
//         {type === "date" && (
//           <DatePicker
//             dateFormat="dd-MMM-yyyy"
//             disabled={isDisable}
//             placeholderText="dd-mmm-yyyy"
//             selected={this.formatDate(value)}
//             peekNextMonth
//             showMonthDropdown
//             showYearDropdown
//             dropdownMode="select"
//             className={`form-control text-primary input-section ${
//               this.props.isDisable ? "row-disabled" : ""
//             } ${this.props.isError ? "border border-danger" : ""}`}
//             onChange={(date) => this.onDateChange(date)}
//           />
//         )}
//         {type === "textArea" && (
//           <textarea
//             className={`form-control transparent-input text-primary input-section custom-input-text w-100 ${
//               this.props.isDisable ? "row-disabled" : ""
//             } ${this.props.isError ? "border border-danger" : ""}`}
//             id={uniqueIdForInput}
//             onChange={(e) => this.props.onChange(e)}
//             onBlur={(e) => this.onTextChange(e, antecedantId, formatDisable)}
//             value={value}
//             spellCheck="true"
//             required={required}
//             disabled={isDisable}
//             minlength={minlength}
//             maxlength={maxlength}
//           ></textarea>
//         )}
//         {type === "dropDown" && (
//           <Selectbox
//             option={this.state.option}
//             onChange={(e) => this.props.onChange(e)}
//             defaultValue={{ label: value, value }}
//             callback={(selectedPackage) =>
//               this.props.onChange({ target: { value: selectedPackage.value } })
//             }
//             isDisable={isDisable}
//           ></Selectbox>
//         )}
//       </React.Fragment>
//     );
//   }
// }
