import React, { Component } from "react";
import { getGapInMinutes } from "../../utils/commonutils";

const ALERT_COLOR = "#f8d7da";
const ALERT_TIME = 15;

const TableWrapper = props => {
  const getBackgroundStyle = rowData => {
    if (rowData.last_assigned_ts) {
      const caseAssignedTime = new Date(rowData.last_assigned_ts);
      const currentTime = new Date();
      const gapInMinutes = getGapInMinutes(caseAssignedTime, currentTime);
      if (gapInMinutes > ALERT_TIME && !rowData.ars_num) {
        return { backgroundColor: ALERT_COLOR };
      }
    }
    return {};
  };

  return (
    <div>
      <table className="table table-bordered table-striped small">
        <thead>
          <tr>
            {props.column.map(data => {
              return <th width={data.width ? data.width : ""}>{data.name}</th>;
            })}
          </tr>
        </thead>

        <tbody>
          {props.data.map((value, index) => {
            return (
              <tr style={getBackgroundStyle(value)}>
                {props.column.map(data => {
                  if (data.template) {
                    return <td>{data.template(value, index)}</td>;
                  } else {
                    return <td>{value[data["field"]]}</td>;
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TableWrapper;
