import React, { Component } from "react";
import { modalManager } from "./../../../utils/modalutils";
import "./gapCheckModal.scss";

export default class gapCheckModal extends Component {
  close = () => {
    modalManager.close({});
    this.props.onClose && this.props.onClose();
  };

  render() {
    const {
      addstr,
      crcstr,
      insuffAddress,
      insuffCRC,
    } = this.props;
    return (
      <div className="gapIdentified">
        Raise Insufficiency Checks for Gap
        {insuffAddress.length < addstr[1] ? (
          <>
            <div className="add-check">
              {addstr[0]}
              <p> The number of Address gaps identified are {addstr[1]}</p>
            </div>
          </>
        ) : (
          ""
        )}
        {insuffCRC.length < crcstr[1] ? (
          <>
            <div className="crc-check">{crcstr[0]}</div>
            <div>The number of CRC gaps identified are {crcstr[1]}</div>
          </>
        ) : (
          ""
        )}
        <div className="block">
          <div class="row">
            <div class="col-md-12 footer">
              <button
                class="btn btn-danger btn-sm float-left mr-2"
                data-dismiss="modal"
                onClick={this.close}
              >
                Close
              </button>
              <button
                class="btn btn-primary btn-sm float-right ml-2 send-btn"
                onClick={(e) => {
                  this.props.gapAddCheck(
                    addstr[1],
                    crcstr[1],
                  );
                  this.close();
                }}
              >
                Add Gap Checks
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
