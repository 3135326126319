/**
 * client details component
 * which contain case details,client details
 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Selectbox from "./../../../components/Selectbox/selectbox";
import api from "./../../../apiBaseConfig";
import { HCL_GENERATE_ARS, HCL_DUPLICITY_CHECK } from "./../../../apiurl";
import DocSelection from "./../../../components/docselection/docselection";
import { Toast } from "./../../../utils/toastutils";
import {
  uniqueId,
  getDocuments,
  getHclDuplicityData,
} from "./../../../utils/commonutils";
import CustomInput from "./../../../hcl/components/custumInput/custumInput";
import { constants } from "../../../constants";

class ClientDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientFormData: {},
      candidateFormData: {},
      selectedDoc: [],
      isExpand: true,
      selectDropdown: [],
      updatedFormData: {},
      isAddCaseLoader: false,
    };
  }

  componentDidMount() {
    let {
      candidateFormData,
      clientFormData,
      selectedDoc,
      selectDropdown,
    } = this.state;
    const { caseDetails, isDocDisable = false, documentList } = this.props;
    clientFormData = { ...caseDetails };
    if (caseDetails.case_documents && caseDetails.case_documents.length) {
      selectedDoc = caseDetails.case_documents.map((data, index) => {
        return { ...data, indexNumber: uniqueId(), isDocDisable };
      });
    }
    selectDropdown = documentList.map((data) => {
      return {
        type: data.type,
        id: data.id,
        label: data.type + "_" + data.id,
        path: data.path,
        gcspath: data.gcspath,
        documentType: data.documentType,
      };
    });
    this.setState({
      updatedFormData: {},
      clientFormData,
      selectedDoc,
      isExpand: true,
      selectDropdown,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    let { clientFormData, selectedDoc, selectDropdown } = this.state;
    const { caseDetails, isDocDisable = false, documentList } = this.props;
    if (
      prevProps.caseDetails !== this.props.caseDetails ||
      prevProps.isDocDisable !== this.props.isDocDisable
    ) {
      clientFormData = { ...caseDetails };
      if (caseDetails.case_documents && caseDetails.case_documents.length) {
        selectedDoc = caseDetails.case_documents.map((data, index) => {
          return { ...data, indexNumber: uniqueId(), isDocDisable };
        });
      }
      selectDropdown = documentList.map((data) => {
        return {
          type: data.type,
          id: data.id,
          label: data.type + "_" + data.id,
          path: data.path,
          gcspath: data.gcspath,
          documentType: data.documentType,
        };
      });
      this.setState({
        updatedFormData: {},
        clientFormData,
        selectDropdown,
        selectedDoc,
        isExpand: true,
      });
    }
  }

  /**
   * this functioon use for modify the client details
   */
  setClintData = (key, value) => {
    let { clientFormData } = this.state;
    clientFormData[key] = value;
    this.setState({ clientFormData });
  };

  /**
   * this function use for modify the candidate details
   */

  setCandidateData = (key, value) => {
    let { candidateFormData } = this.state;
    candidateFormData[key] = value;
    this.setState({ candidateFormData });
  };

  /**
   * this function is used for document tagging in case label
   */
  getSelectDoc = (array) => {
    this.setState({ selectedDoc: array });
  };

  saveCaseDetails = async (e, flag = "") => {
    const { selectedDoc, clientFormData, updatedFormData } = this.state;
    //const tagDocuments = selectedDoc.filter((document) => document.id);
    const re = /\S+@\S+\.\S+/;
    e.preventDefault();
    if (
      clientFormData.email_address &&
      !re.test(clientFormData.email_address)
    ) {
      Toast.info({
        description: "Please enter valid email format (abc@def.xx)",
      });
      return Promise.reject(null);
    }
    for (let key in updatedFormData) {
      if (!updatedFormData[key]) {
        delete updatedFormData[key];
      }
    }
    return this.props.saveDeatils(
      flag,
      this.state.clientFormData,
      selectedDoc,
      updatedFormData
    );
  };

  getCaseDocument = () => {
    let caseDocumentArray = getDocuments() || [];
    caseDocumentArray.push({
      end_page: null,
      id: null,
      id_bridge: 138,
      bridge_name: "Document referred for DOB ",
      actual_name: null,
      start_page: null,
      gcs_signed_url: null,
      document_type: null,
    });
    return caseDocumentArray;
  };

  /**
   * this function use for generate the ars number
   * and check the duplicity
   */
  submit = async (e) => {
    e.preventDefault();
    const { pvtId, userId, packageName, selectedLocation } = this.props;
    const { clientFormData, selectedDoc = [] } = this.state;

    let isTagDocument = true;
    let tagDocumentobj = {};
    selectedDoc.forEach((document) => {
      if (
        document.id_bridge ===
          constants.caseDocumentBridgeIds.APPLICATION_FORM_PDF ||
        document.id_bridge === constants.caseDocumentBridgeIds.SIGN_OFF ||
        document.id_bridge ===
          constants.caseDocumentBridgeIds.ANY_OTHER_CASE_DOCUMENT ||
        document.id_bridge ===
          constants.caseDocumentBridgeIds.DOCUMENT_REFERRED_FOR_DOB
      ) {
        if (!document.id) {
          isTagDocument = false;
        } else {
          tagDocumentobj[document.id_bridge] = true;
        }
      }
    });
    if (Object.keys(tagDocumentobj).length !== 4) {
      isTagDocument = false;
    }

    const f = [
      { key: "firstName", value: clientFormData.first_name },
      { key: "lastName", value: clientFormData.last_name },
      { key: "dob", value: clientFormData.date_of_birth },
      { key: "gender", value: clientFormData.gender },
      { key: "mobile", value: clientFormData.mobile_number },
      { key: "email", value: clientFormData.email_address },
      { key: "fatherName", value: clientFormData.fathers_name },
      { key: "packageName", value: packageName },
      { key: "location", value: selectedLocation.label },
      {
        key: "Document referred for dob",
        value: clientFormData.document_referred_for_dob,
      },
    ];
    let s = "";
    for (let i = 0; i < f.length; i++) {
      if (!f[i].value) {
        s = s + f[i].key + ",";
      }
    }
    const isDisabled =
      clientFormData.first_name &&
      clientFormData.last_name &&
      clientFormData.date_of_birth &&
      clientFormData.gender &&
      clientFormData.mobile_number &&
      clientFormData.email_address &&
      clientFormData.fathers_name &&
      packageName &&
      selectedLocation.label &&
      clientFormData.document_referred_for_dob;

    if (clientFormData.bridge_ars_no) {
      Toast.info({
        description: "ARS number has already been generated for this case",
        title: "info",
      });
    } else {
      if (!isDisabled) {
        Toast.info({
          hideAfter: 8,
          description: `Please ensure that following mandatory fields are filled before clicking Add Case:${s}`,
          title: "info",
        });
      } else {
        if (isTagDocument) {
          try {
            this.setState({ isAddCaseLoader: true });
            await this.saveCaseDetails(e);
            const response = await api.get(`${HCL_DUPLICITY_CHECK}${pvtId}`);
            if (response.status !== 200) {
              try {
                const result = await api.post(`${HCL_GENERATE_ARS}`, {
                  pvt_id: pvtId,
                });
                Toast.success({
                  description: "ARS number generated",
                  title: "Success",
                });
                this.props.callback();
                this.setState({ isExpand: false });
              } catch (e) {
                this.setState({ isAddCaseLoader: false });
                console.log(e);
              }
            } else {
              const actualData = getHclDuplicityData(clientFormData);
              this.props.history.push({
                pathname: "/duplicates",
                state: {
                  duplicates: response.data,
                  actualData,
                  userId: userId,
                  pvtId,
                },
              });
            }
          } catch (e) {
            this.setState({ isAddCaseLoader: false });
            console.log(e);
          }
        } else {
          Toast.info({
            hideAfter: 6,
            description: `Please ensure that you have tagged the mandatory documents (JAF/Signoff/Any
              other case document/document referred for dob)`,
            title: "info",
          });
        }
      }
    }
  };

  setAlternateMobile = () => {
    let { clientFormData } = this.state;
    clientFormData.alternate_mobile_numbers.push("");
    this.setState({ clientFormData });
  };

  render() {
    const {
      updatedFormData,
      clientFormData,
      selectedDoc,
      isExpand,
      selectDropdown,
      isAddCaseLoader,
    } = this.state;
    const {
      loadDocument,
      documentList,
      isCSE,
      isCaseInsuff,
      packageName,
      mandatoryFields,
    } = this.props;
    let isDisable = isCSE || isCaseInsuff || clientFormData.bridge_ars_no;
    return (
      <div>
        {isExpand ? (
          <div>
            <div className="row">
              <div>
                <hr className="brdr-dashed" />
                <div className="col">
                  <div className="small">
                    <h6
                      className={`${
                        isCaseInsuff ? "text-danger" : "text-muted"
                      } font-weight-bold mb-3`}
                    >
                      Client Details
                    </h6>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            BGV ID prejoining
                          </label>
                          <CustomInput
                            type={"text"}
                            value={
                              clientFormData.bgv_id_type === 2
                                ? clientFormData.bgv_id
                                : ""
                            }
                            onChange={(e) => {}}
                            required={true}
                            isDisable={true}
                          ></CustomInput>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Verification Type
                          </label>
                          <CustomInput
                            type={"text"}
                            value={clientFormData.verification_type}
                            onChange={(e) => {}}
                            required={true}
                            isDisable={true}
                          ></CustomInput>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Employee ID
                          </label>

                          <CustomInput
                            type={"text"}
                            value={
                              clientFormData.employee_id
                                ? clientFormData.employee_id
                                : ""
                            }
                            onChange={(e) => {}}
                            required={true}
                            isDisable={true}
                          ></CustomInput>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Candidate ID
                          </label>
                          <CustomInput
                            type={"text"}
                            value={
                              clientFormData.candidate_id
                                ? clientFormData.candidate_id
                                : ""
                            }
                            required={false}
                            isDisable={true}
                            onChange={(e) => {}}
                          ></CustomInput>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            BGV ID 2 Post Joining
                          </label>
                          <CustomInput
                            type={"text"}
                            value={
                              clientFormData.bgv_id_type === 1
                                ? clientFormData.bgv_id
                                : ""
                            }
                            onChange={(e) => {}}
                            required={false}
                            isDisable={true}
                          ></CustomInput>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Date of reintiation
                          </label>
                          <CustomInput
                            type={"text"}
                            value={clientFormData.date_of_reinitiation}
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.date_of_reinitiation =
                                e.target.value;
                              updatedFormData.date_of_reinitiation =
                                e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={false}
                            isDisable={isDisable}
                          ></CustomInput>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Process Name
                          </label>

                          <CustomInput
                            type={"text"}
                            value={clientFormData.package_name}
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.package_name = e.target.value;
                              updatedFormData.package_name = e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={false}
                            isDisable={isDisable}
                          ></CustomInput>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Fast track accounts
                          </label>

                          <Selectbox
                            defaultValue={{
                              value: clientFormData.priority_flag
                                ? "Yes"
                                : "No",
                              label: clientFormData.priority_flag
                                ? "Yes"
                                : "No",
                            }}
                            option={[
                              { value: "Yes", label: "Yes" },
                              { value: "No", label: "No" },
                            ]}
                            callback={(selectedOption) => {
                              let { clientFormData } = this.state;
                              clientFormData.priority_flag =
                                selectedOption.value === "Yes" ? 1 : 0;
                              updatedFormData.priority_flag =
                                selectedOption.value === "Yes" ? 1 : 0;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={false}
                            isDisable={isDisable}
                          />
                          {/* <CustomInput
                            type="Drop Down"
                            option={["yes", "no"]}
                            value={this.props.priorityFlag === 1 ? "yes" : "no"}
                            className={`input-section form-control text-primary ${
                              isDisable ? "row-disabled" : ""
                            }`}
                            // isDisable={true}
                            onChange={(e) => {
                              const flagValue =
                                e.target.value === "yes" ? 1 : 0;

                              this.setClintData("priorityFlag", flagValue);
                            }}
                          ></CustomInput> */}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Data Source
                          </label>

                          <CustomInput
                            type={"text"}
                            value={"Client Portal"}
                            onChange={(e) => {}}
                            required={false}
                            isDisable={true}
                          ></CustomInput>
                        </div>
                      </div>
                      <div class="col-md-4">
                            <div class="form-group ng-star-inserted">
                              <label className="text-muted text-uppercase label-header">
                                SV Case Initiation date
                              </label>
                              <CustomInput
                                type={"Date"}
                                value={clientFormData.sv_date_of_initiation}
                                required={true}
                                isDisable={true}
                                onChange={(e) => { 
                                  let { clientFormData } = this.state;
                                  clientFormData.sv_date_of_initiation = e.target.value;
                                  updatedFormData.sv_date_of_initiation =
                                    e.target.value;
                                  this.setState({
                                    clientFormData,
                                    updatedFormData,
                                  });
                                }}
                              ></CustomInput>
                            </div>
                      </div>
                      <div class="col-md-4">
                          <div class="form-group ng-star-inserted">
                            <label className="text-muted text-uppercase label-header">
                            Name As per CV
                            </label>
                            <CustomInput
                              type={"text"}
                              value={clientFormData.name_as_per_cv}
                              onChange={(e) => {
                                let { clientFormData } = this.state;
                                clientFormData.name_as_per_cv = e.target.value;
                                updatedFormData.name_as_per_cv =
                                e.target.value;
                                this.setState({
                                  clientFormData,
                                  updatedFormData
                                });
                              }}
                              
                            ></CustomInput>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group ng-star-inserted">
                            <label className="text-muted text-uppercase label-header">
                            LOA Date
                            </label>
                            <CustomInput
                              type={"Date"}
                              value={clientFormData.loa_date}
                              onChange={(e) => {
                                let { clientFormData } = this.state;
                                clientFormData.loa_date = e.target.value;
                                updatedFormData.loa_date =
                                e.target.value;
                                this.setState({
                                  clientFormData,
                                });
                              }}
                            ></CustomInput>
                          </div>
                        </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <DocSelection
                          callback={this.getSelectDoc}
                          loadDocument={loadDocument}
                          docArray={selectedDoc}
                          documentCheck={this.getCaseDocument()}
                          selectDropdown={selectDropdown}
                          isDisable={isDisable}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <hr className="brdr-dashed" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <h6
                          className={`${
                            isCaseInsuff ? "text-danger" : "text-muted"
                          } font-weight-bold mb-3`}
                        >
                          Candidate Details
                        </h6>
                        <div
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginBottom: "20px",
                          }}
                        >
                          All (*) fields are required!
                        </div>
                        <div className="row">
                          <div
                            className="col-md-4"
                            data-for="getFname"
                            data-tip=""
                          >
                            <ReactTooltip
                              place="top"
                              type="dark"
                              effect="solid"
                              id="getFname"
                              getContent={() => mandatoryFields.first_name}
                            />
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                First Name<sup className="staric-data">*</sup>
                              </label>
                              <CustomInput
                                type={"text"}
                                value={clientFormData.first_name}
                                onChange={(e) => {
                                  let { clientFormData } = this.state;
                                  clientFormData.first_name = e.target.value;
                                  updatedFormData.first_name = e.target.value;
                                  this.setState({
                                    clientFormData,
                                    updatedFormData,
                                  });
                                }}
                                required={false}
                                isDisable={isDisable}
                              ></CustomInput>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Middle Name
                              </label>
                              <CustomInput
                                type={"text"}
                                value={clientFormData.middle_name}
                                onChange={(e) => {
                                  let { clientFormData } = this.state;
                                  clientFormData.middle_name = e.target.value;
                                  updatedFormData.middle_name = e.target.value;
                                  this.setState({
                                    clientFormData,
                                    updatedFormData,
                                  });
                                }}
                                required={true}
                                isDisable={isDisable}
                              ></CustomInput>
                            </div>
                          </div>
                          <div
                            className="col-md-4"
                            data-tip=""
                            data-for="getLastName"
                          >
                            <ReactTooltip
                              place="top"
                              type="dark"
                              effect="solid"
                              id="getLastName"
                              getContent={() => mandatoryFields.last_name}
                            />
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Last Name <sup className="staric-data">*</sup>
                              </label>
                              <CustomInput
                                type={"text"}
                                value={clientFormData.last_name}
                                onChange={(e) => {
                                  let { clientFormData } = this.state;
                                  clientFormData.last_name = e.target.value;
                                  updatedFormData.last_name = e.target.value;
                                  this.setState({
                                    clientFormData,
                                    updatedFormData,
                                  });
                                }}
                                required={true}
                                isDisable={isDisable}
                              ></CustomInput>
                            </div>
                            {/* </toolTip> */}
                          </div>
                        </div>

                        <div className="row">
                          <div
                            className="col-md-4"
                            data-for="getdob"
                            data-tip=""
                          >
                            <ReactTooltip
                              place="top"
                              type="dark"
                              effect="solid"
                              id="getdob"
                              getContent={() => mandatoryFields.date_of_birth}
                            />
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Date of Birth
                                <sup className="staric-data">*</sup>
                              </label>
                              <CustomInput
                                type={"Date"}
                                value={clientFormData.date_of_birth}
                                onChange={(e) => {
                                  let { clientFormData } = this.state;
                                  clientFormData.date_of_birth = e.target.value;
                                  updatedFormData.date_of_birth =
                                    e.target.value;
                                  this.setState({
                                    clientFormData,
                                    updatedFormData,
                                  });
                                }}
                                required={false}
                                isDisable={isDisable}
                              ></CustomInput>
                            </div>
                          </div>

                          <div
                            className="col-md-4"
                            data-for="getDoc"
                            data-tip=""
                          >
                            <ReactTooltip
                              place="top"
                              type="dark"
                              effect="solid"
                              id="getDoc"
                              getContent={() =>
                                mandatoryFields.document_referred_for_dob
                              }
                            />
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Document for dob{" "}
                                <sup className="staric-data">*</sup>
                              </label>

                              <Selectbox
                                defaultValue={{
                                  value: clientFormData.document_referred_for_dob
                                    ? clientFormData.document_referred_for_dob
                                    : null,
                                  label: clientFormData.document_referred_for_dob
                                    ? clientFormData.document_referred_for_dob
                                    : null,
                                }}
                                option={[
                                  {
                                    value: "10th Marksheet",
                                    label: "10th Marksheet",
                                  },
                                  { value: "PAN", label: "PAN" },
                                  { value: "Passport", label: "Passport" },
                                  { value: "Other ID", label: "Other ID" },
                                  { value: "JAF", label: "JAF" },
                                ]}
                                callback={(selectedOption) => {
                                  let { clientFormData } = this.state;
                                  clientFormData.document_referred_for_dob =
                                    selectedOption.value;
                                  updatedFormData.document_referred_for_dob =
                                    selectedOption.value;
                                  this.setState({
                                    clientFormData,
                                    updatedFormData,
                                  });
                                }}
                                required={true}
                                isDisable={isDisable}
                              />
                            </div>
                          </div>

                          <div
                            className="col-md-4"
                            data-for="getGender"
                            data-tip=""
                          >
                            <ReactTooltip
                              place="top"
                              type="dark"
                              effect="solid"
                              id="getGender"
                              getContent={() => mandatoryFields.gender}
                            />
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Gender <sup className="staric-data">*</sup>
                              </label>

                              <Selectbox
                                defaultValue={{
                                  value: clientFormData.gender
                                    ? clientFormData.gender
                                    : null,
                                  label: clientFormData.gender
                                    ? clientFormData.gender
                                    : null,
                                }}
                                option={[
                                  { value: "Male", label: "Male" },
                                  { value: "Female", label: "Female" },
                                ]}
                                callback={(selectedOption) => {
                                  let { clientFormData } = this.state;
                                  clientFormData.gender = selectedOption.value;
                                  updatedFormData.gender = selectedOption.value;
                                  this.setState({
                                    clientFormData,
                                    updatedFormData,
                                  });
                                }}
                                required={true}
                                isDisable={isDisable}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div
                            class="col-md-4"
                            data-for="getFatherName"
                            data-tip=""
                          >
                            <ReactTooltip
                              place="top"
                              type="dark"
                              effect="solid"
                              id="getFatherName"
                              getContent={() => mandatoryFields.fathers_name}
                            />
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Father Name<sup className="staric-data">*</sup>
                              </label>
                              <CustomInput
                                type={"text"}
                                value={clientFormData.fathers_name}
                                onChange={(e) => {
                                  let { clientFormData } = this.state;
                                  clientFormData.fathers_name = e.target.value;
                                  updatedFormData.fathers_name = e.target.value;
                                  this.setState({
                                    clientFormData,
                                    updatedFormData,
                                  });
                                }}
                                required={true}
                                isDisable={isDisable}
                              ></CustomInput>
                            </div>
                          </div>
                          <div
                            className="col-md-4"
                            data-for="getMobileNum"
                            data-tip=""
                          >
                            <ReactTooltip
                              place="top"
                              type="dark"
                              effect="solid"
                              id="getMobileNum"
                              getContent={() => mandatoryFields.mobile_number}
                            />
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Mobile Number{" "}
                                <sup className="staric-data">*</sup>
                              </label>

                              <CustomInput
                                type={"text"}
                                value={clientFormData.mobile_number}
                                onChange={(e) => {
                                  let { clientFormData } = this.state;
                                  clientFormData.mobile_number = e.target.value;
                                  updatedFormData.mobile_number =
                                    e.target.value;
                                  this.setState({
                                    clientFormData,
                                    updatedFormData,
                                  });
                                }}
                                required={true}
                                isDisable={isDisable}
                              ></CustomInput>
                            </div>
                          </div>

                          <div
                            className="col-md-4"
                            data-for="getEmail"
                            data-tip=""
                          >
                            <ReactTooltip
                              place="top"
                              type="dark"
                              effect="solid"
                              id="getEmail"
                              getContent={() => mandatoryFields.email_address}
                            />
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Email
                                <sup className="staric-data">*</sup>
                              </label>

                              <CustomInput
                                type={"text"}
                                value={clientFormData.email_address}
                                onChange={(e) => {
                                  let { clientFormData } = this.state;
                                  clientFormData.email_address = e.target.value;
                                  updatedFormData.email_address =
                                    e.target.value;
                                  this.setState({
                                    clientFormData,
                                    updatedFormData,
                                  });
                                }}
                                required={true}
                                isDisable={isDisable}
                              ></CustomInput>
                            </div>
                          </div>
                        </div>
                        {/* alternaate mobile */}
                        <div class="row">
                          <div className="col-md-6">
                            <label className="text-muted text-uppercase label-header">
                              Alternate Mobile Number{" "}
                              <button
                                className="transparent-borderless"
                                onClick={() => {
                                  this.setAlternateMobile();
                                }}
                                disabled={isDisable}
                              >
                                <i className="fa fa-plus icon-primary alter-plus"></i>
                              </button>
                            </label>
                          </div>
                        </div>

                        <div className="row">
                          {clientFormData.alternate_mobile_numbers &&
                            clientFormData.alternate_mobile_numbers.map(
                              (value, index) => {
                                return (
                                  <div className="col-md-4" key={index}>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className={`input-section form-control text-primary ${
                                          isDisable ? "row-disabled" : ""
                                        }`}
                                        value={value}
                                        onChange={(e) => {
                                          let { clientFormData } = this.state;
                                          clientFormData.alternate_mobile_numbers[
                                            index
                                          ] = e.target.value;
                                          updatedFormData.alternate_mobile_numbers = [
                                            ...clientFormData.alternate_mobile_numbers,
                                          ];
                                          this.setState({
                                            clientFormData,
                                            updatedFormData,
                                          });
                                        }}
                                        disabled={isDisable}
                                      ></input>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>

                        <div class="row">
                          <div class="col">
                            <hr class="brdr-dashed" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <h6
                              className={`${
                                isCaseInsuff ? "text-danger" : "text-muted"
                              } font-weight-bold mb-3`}
                            >
                              National Identity Document
                            </h6>
                            <div class="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className="text-muted text-uppercase label-header">
                                    Pan Card No.
                                    {/* <sup>*</sup> */}
                                  </label>
                                  <CustomInput
                                    type={"text"}
                                    value={clientFormData.pan}
                                    onChange={(e) => {
                                      let { clientFormData } = this.state;
                                      clientFormData.pan = e.target.value;
                                      updatedFormData.pan = e.target.value;
                                      this.setState({
                                        clientFormData,
                                        updatedFormData,
                                      });
                                    }}
                                    required={true}
                                    isDisable={isDisable}
                                  ></CustomInput>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className="text-muted text-uppercase label-header">
                                    Driving License
                                    {/* <sup>*</sup> */}
                                  </label>

                                  <CustomInput
                                    type={"text"}
                                    value={clientFormData.driving_license}
                                    onChange={(e) => {
                                      let { clientFormData } = this.state;
                                      clientFormData.driving_license =
                                        e.target.value;
                                      updatedFormData.driving_license =
                                        e.target.value;
                                      this.setState({
                                        clientFormData,
                                        updatedFormData,
                                      });
                                    }}
                                    required={true}
                                    isDisable={isDisable}
                                  ></CustomInput>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className="text-muted text-uppercase label-header">
                                    Voter ID
                                    {/* <sup>*</sup> */}
                                  </label>

                                  <CustomInput
                                    type={"text"}
                                    value={clientFormData.voter}
                                    onChange={(e) => {
                                      let { clientFormData } = this.state;
                                      clientFormData.voter = e.target.value;
                                      updatedFormData.voter = e.target.value;
                                      this.setState({
                                        clientFormData,
                                        updatedFormData,
                                      });
                                    }}
                                    required={true}
                                    isDisable={isDisable}
                                  ></CustomInput>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className="text-muted text-uppercase label-header">
                                    Passport Number
                                    {/* <sup>*</sup> */}
                                  </label>

                                  <CustomInput
                                    type={"text"}
                                    value={clientFormData.passport}
                                    onChange={(e) => {
                                      let { clientFormData } = this.state;
                                      clientFormData.passport = e.target.value;
                                      updatedFormData.passport = e.target.value;
                                      this.setState({
                                        clientFormData,
                                        updatedFormData,
                                      });
                                    }}
                                    required={true}
                                    isDisable={isDisable}
                                  ></CustomInput>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <hr className="brdr-dashed" />
                      <div className="col">
                        {!isAddCaseLoader ? (
                          <button
                            className="btn btn-primary btn-sm float-right m-2"
                            // type="submit"
                            disabled={this.props.isCaseInsuff || isCSE}
                            onClick={(e) => {
                              this.submit(e);
                            }}
                          >
                            Add Case
                          </button>
                        ) : (
                          <div
                            class="spinner-border text-info float-right m-2"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        )}
                        <button
                          className="btn btn-primary btn-sm float-right m-2 "
                          disabled={this.props.isCaseInsuff || isCSE}
                          onClick={(e) => {
                            this.saveCaseDetails(e, "save");
                          }}
                        >
                          Save Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto text-center expand-link text-primary small position-relative">
                &nbsp;&nbsp;
                <i
                  className="fa fa-caret-up text-muted"
                  style={{ fontSize: "36px" }}
                  onClick={() => {
                    this.setState({ isExpand: !isExpand });
                  }}
                ></i>
              </div>
            </div>
          </div>
        ) : (
          <div className="row justify-content-center">
            <div className="col-auto text-center expand-link text-primary small position-relative">
              &nbsp;&nbsp;
              <i
                className="fa fa-caret-down text-muted"
                style={{ fontSize: "36px" }}
                onClick={() => {
                  this.setState({ isExpand: !isExpand });
                }}
              ></i>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(ClientDetail);
