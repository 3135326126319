import React, { Component } from "react";
import Comment from "../comment/comment";
import api from "../../../../apiBaseConfig";
import {
  QCDASHBOARD_COMMENT_HISTORY,
  QCDASHBOARD_ADD_COMMENT
} from "../../../../apiurl";
import _get from "lodash/get";

export default class CommentBox extends Component {
  constructor(props) {
    super(props);
    this.state = { comments: [], newCommentObject: {}, isManualComment: false };
  }

  componentDidMount() {
    this.getCommentHistory();
  }

  getCommentHistory = () => {
    this.props
      .getCommentHistory()
      .then(comments => {
        this.reverseComments(comments);
      })
      .catch(err => console.log(err));
  };

  reverseComments = (comments = []) => {
    this.setState({
      comments: comments.reverse((a, b) => b.commentId - a.commentId)
    });
  };

  addNewComment = () => {
    if (this.state.isManualComment) {
      alert("dialog already opened ,Please Submit it.");
    } else {
      const { userInfo = {} } = this.props;
      this.setState({
        isManualComment: true,
        newCommentObject: {
          userName: `${userInfo.userFirstName} ${userInfo.userLastName}`,
          roleName: userInfo.roleName,
          isManualComment: true
        }
      });
    }
  };

  handleSubmit = (e, newComment) => {
    e.preventDefault();

    this.props
      .submitComment({
        userId: this.props.userInfo.userId,
        pvtId: parseInt(this.props.pvtId),
        comment: newComment
      })
      .then(() => {
        alert(this.props.successMessage);

        this.setState({ isManualComment: false });
        this.props.closeCommentHistoryModal();
      })
      .catch(err => {
        alert(this.props.errorMessage);

        console.log(err);
      });
  };

  render() {
    const { comments, isManualComment, newCommentObject } = this.state;
    const { buttonName = "Add Comment" } = this.props;
    return (
      <div>
        <div className="row">
          <div className="col">
            <button
              type="button"
              className="close"
              onClick={() => this.props.closeCommentHistoryModal()}
            >
              &times;
            </button>
          </div>
        </div>
        <div className="p-4">
          <div className="row">
            <div className="col">
              <h5 className="text-muted">{this.props.label}</h5>
            </div>
            <div className="col text-right">
              <button
                className="btn btn-primary mb-2 rounded btn-sm"
                onClick={this.addNewComment}
              >
                <span className="float-left wh20 btn bg-white rounded-circle p-1 d-flex align-items-center justify-content-center">
                  <i className="fa fa-plus icon-primary"></i>
                </span>
                <span className="text-uppercase">&nbsp;{buttonName}</span>
              </button>
            </div>
          </div>
          <hr className="border-top-0 border-bottom" />
          <div className="timeline">
            {isManualComment && (
              <Comment
                commentObject={newCommentObject}
                handleSubmit={this.handleSubmit}
                isCheck={this.props.isCheck}
              />
            )}

            {comments.map(commentObject => (
              <Comment
                key={commentObject.commentId}
                commentObject={commentObject}
                isCheck={this.props.isCheck}
              />
            ))}
          </div>

          <div className="row">
            <div className="col-md-4">&nbsp;</div>
            <div className="col-md-8 text-center">
              <a className="position-relative text-muted legend-link">
                See more
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
