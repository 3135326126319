import { ActionTypes } from "./gapcheck.types";

export const updateAddressArray = payload => ({
  type: ActionTypes.UPDATE_ADDRESS_ARRAY,
  payload: payload
});

export const updateEmploymentEducationArray = payload => ({
  type: ActionTypes.UPDATE_EMPLOYMENT_EDUCATION_ARRAY,
  payload: payload
});

export const updateEducationAddressMismatchArray = payload => ({
  type: ActionTypes.UPDATE_EDUCATION_ADDRESS_MISMATCH,
  payload: payload
});

export const updateEmploymentAddressMismatchArray = payload => ({
  type: ActionTypes.UPDATE_EMPLOYMENT_ADDRESS_MISMATCH,
  payload: payload
});
