import React, { Component } from "react";
import "./bucketDetails.scss";
import {
  QCDASHBOARD_CASE_DETAIL,
  QCDASHBOARD_CHECKLIST_DETAIL,
  ESCALATION_END_POINT,
  GET_PACKAGES,
  CHECKS_PER_PROCESS,
  UPDATE_PROCESS_ID,
  QCDASHBOARD_COMMENT_HISTORY,
  QCDASHBOARD_ADD_COMMENT,
  ANTECEDANTS_PER_CHECK_PER_PROCESS,
  SAVE_CHECK_LIST,
  LOAD_ANTECEDANTS_ID_NAME_MAP,
  LOAD_DOCUMENT,
  UPLOAD_FILE,
  FETCH_DOCUMENTS_MAP,
  SAVE_CASE_DETAILS,
  CHANGE_STATUS,
  DEFAULT_DOCUMENTS_PER_CHECK,
  RAISE_CHECK_INSUFF,
  FINAL_SUBMIT,
  SUBMIT_CHECK,
  ADD_RESEARCH,
  Insuff_Raised,
} from "../../apiurl";
import api from "../../apiBaseConfig";
import _get from "lodash/get";
import _groupBy from "lodash/groupBy";
import CommentBox from "./components/commentBox/commentBox";
import ModalDialog, { ModalTransition } from "@atlaskit/modal-dialog";
import LoadingScreen from "../../components/loadingScreen/loadingScreen";
import Escalation from "../../components/Escalation/escalation";
import { connect } from "react-redux";
import Selectbox from "../../components/Selectbox/selectbox";
import CheckCard from "./components/checkCard/checkCard";
import PopupMenu from "../../components/popupMenu/popupMenu";
import {
  isWithinTimePeriod,
  getFormatDate,
  getCurrentLocalIsoTime,
  convertPassingYearToDate,
  monthNameFromNumber,
} from "../../utils/commonutils";
import ClientDetailAccordion from "./components/clientDetailAccordion/clientDetailAccordion";
import BUCKETTIMER from "./../../components/buckettimer/buckettimer";
import CustomInput from "./../../components/customInput/customInput";
import { deleteAction } from "./../../components/buckettimer/state/timer.action";
import FileInput from "../../components/fileInput/fileInput";
import { Toast } from "../../utils/toastutils";
import AddMoreCheckModal from "./components/modals/addMoreCheckModal/addMoreCheckModal";
import Gapcheck from "./../../hcl/components/gapcheck/gapcheck";
import { withRouter } from "react-router-dom";
import DocumentViewer from "../../components/documentViewer/documentViewer";
import { modalManager } from "../../utils/modalutils";
import Toggle from "../../screens/bucketDetails/components/toggle/toggle";
import { constants } from "../../constants";
import {
  updateAddressArray,
  updateEmploymentEducationArray,
  updateEducationAddressMismatchArray,
  updateEmploymentAddressMismatchArray,
} from "./../../hcl/components/gapcheck/state/gapcheck.actions";
import _cloneDeep from "lodash/cloneDeep";

class BucketDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pvtId: null,
      caseDetails: {},
      checkList: [],
      documentList: [],
      personalData: {},
      isCaseHistoryModalOpen: false,
      isCheckHistoryModalOpen: false,
      isInsuffEscalationModalOpen: false,
      isEscalationModalOpen: false,
      insuffEscaltionObject: {},
      escalationObject: {},
      isLoading: true,
      packages: [],
      activeDocId: "",
      selectedPackage: {
        label: null,
        value: null,
        priorityFlag: null,
      },
      selectedCheckUID: null,
      antecedantIdNameMap: {},
      checkListPerPackage: [],
      currentDocumentURL: "",
      currentDocumentType: "",
      documentMap: [],
      arsObj: {},
      groupByCheckList: [],
      selectedLocation: {
        // officeId: 211315,
        // officeName: "Delhi",
        // label: "Delhi",
      },
      location: [],
      caseReceivedTime: "",
      showGapCheckButton: false,
      submittedChecks: [],
    };
  }

  componentDidMount() {
    if (
      this.state.caseDetails.caseStatus === constants.caseStatus.QC_COMPLETE
    ) {
      this.props.history.push("/qcdashboard");
    }

    this.setState(
      {
        pvtId: this.props.id || this.props.match.params.id,
        isCSE:
          this.props.isCSE !== undefined
            ? this.props.isCSE
            : this.props.history.location.isCSE,
      },
      () => {
        this.loadAntecedantIdNameMap();
        this.fetchDocumentsMap();
      }
    );
    this.getPackages();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.caseDetails.caseStatus === constants.caseStatus.QC_COMPLETE
    ) {
      this.props.history.push("/qcdashboard");
    }
  }

  getPackageName = (packageId) => {
    const process =
      this.state.packages.find((process) => process.value === packageId) || {};
    return process.label;
  };
  loadAntecedantIdNameMap = () => {
    this.setState({ isLoading: true });

    const antecedantIdNameMap = {};
    api
      .get(LOAD_ANTECEDANTS_ID_NAME_MAP)
      .then((response) => {
        const antencedents = response.data.antecedants;
        antencedents.forEach((object) => {
          antecedantIdNameMap[object.antecedantId] = object;
        });
        this.setState({ antecedantIdNameMap });
        this.getCaseDetail();
      })
      .catch((err) => {
        console.log(err);

        this.setState({ isLoading: false });
      });
  };

  fetchDocumentsMap = () => {
    api
      .get(FETCH_DOCUMENTS_MAP)
      .then((response) =>
        this.setState({ documentMap: response.data.documents })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  getCaseDetail = () => {
    this.setState({ isLoading: true });
    api
      .get(QCDASHBOARD_CASE_DETAIL + `?pvtId=${this.state.pvtId}`)
      .then((response) => {
        const { caseDetails, documentList, personalData } = response.data.data;

        if (
          caseDetails.packageId &&
          caseDetails.packageId !== this.state.selectedPackage.value
        ) {
          const selectedPackage = {
            value: caseDetails.packageId,
            label: caseDetails.packageNameQc,
            priorityFlag: caseDetails.priorityFlag,
          };
          this.setState({ selectedPackage: selectedPackage });
        }

        const location = caseDetails.location.map((data) => {
          return { ...data, label: data.officeName };
        });

        const selectedLocation = {
          officeName: caseDetails.locationName || "",
          label: caseDetails.locationName || "",
        };
        this.setState(
          {
            caseReceivedTime: caseDetails.caseReceivedTime,
            location,
            selectedLocation,
            caseDetails,
            documentList,
            personalData,
            arsObj: {
              ...this.state.arsObj,
              arsNo: caseDetails.arsNo,
              caseId: caseDetails.caseId,
            },
            isLoading: false,
          },
          () => {
            if (this.state.arsObj.arsNo) {
              this.getCheckListDetail();
            }
          }
        );
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.log(err);
      });
  };

  getCheckListDetail = async () => {
    // this.setState({ isLoading: true });
    if (!this.state.arsObj.arsNo) {
      return;
    }

    try {
      //CHECKS_PER_PROCESS
      const response = await api.get(
        CHECKS_PER_PROCESS + this.state.selectedPackage.value
      );
      const checks = response.data.result.checks;
      this.setState({ checkListPerPackage: checks }, () => {
        //checkListDetail
        api
          .get(
            QCDASHBOARD_CHECKLIST_DETAIL +
              `?pvtId=${this.state.pvtId}&processId=${this.state.selectedPackage.value}`
          )
          .then((response) => {
            const { checkList } = response.data;
            let isReformatted = false;
            checkList.forEach((object, index) => {
              if (!checkList[index].checkId) {
                isReformatted = true;
                checkList[index] = this.reformatCheck(checkList[index]);
              }
            });
            if (isReformatted) {
              this.saveCheckList(checkList);
              return;
            }

            this.updateGroupList(checkList);

            this.setState(
              {
                checkList,
                // isLoading: false
              },
              () => {
                console.log("updated", this.state.checkList);
              }
            );
          })
          .catch((err) => {
            //  this.setState({ isLoading: false });
            console.log(err);
          });
      });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  updateGroupList = (checkList = this.state.checkList) => {
    let groupByCheckList = _groupBy(checkList, "checkId");
    groupByCheckList = Object.values(groupByCheckList);
    groupByCheckList.sort((arr1, arr2) => arr1[0].priority - arr2[0].priority);
    //inserting check number
    groupByCheckList.forEach((group) => {
      let checkNumber = 1;
      for (let check of group) {
        check.checkNumber = checkNumber;
        checkNumber++;
      }
    });

    this.setState({
      groupByCheckList: Object.values(groupByCheckList),
    });
  };

  getCaseCommentHistory = async () => {
    let response;
    try {
      response = await api.get(
        QCDASHBOARD_COMMENT_HISTORY + `${this.state.pvtId}/`
      );
      response = response.data.comments;
      return Promise.resolve(response);
    } catch (error) {
      console.log("error", error);
      return Promise.reject(error);
    }
  };

  getCheckCommentHistory = async () => {
    const checkList = this.state.checkList;

    const check = checkList.filter(
      (item) => item.checkUID === this.state.selectedCheckUID
    );
    const comments = _get(check, "[0].checkHistory.comments", []);

    return Promise.resolve(comments);
  };

  submitCaseComment = async (commentObject) => {
    const { arsObj } = this.state;
    try {
      let response = await api.post(QCDASHBOARD_ADD_COMMENT, {
        userId: this.props.userInfo.userId,
        pvtId: parseInt(this.state.pvtId),
        comment: commentObject.comment,
        caseId: arsObj.caseId,
      });
      this.props.isCSE && this.changeStatus(parseInt(this.state.pvtId));
      return Promise.resolve(response);
    } catch (error) {
      console.log("error", error);
      return Promise.reject(error);
    }
  };

  submitCheckComment = async (commentObject, checkUID) => {
    const { arsObj } = this.state;

    const checkList = this.state.checkList;
    const { userInfo = {} } = this.props;
    let check;
    if (checkUID) {
      check = checkList.filter((item) => item.checkUID === checkUID);
    } else {
      check = checkList.filter(
        (item) => item.checkUID === this.state.selectedCheckUID
      );
    }
    const payload = {
      comment: commentObject.comment,
      caseId: arsObj.caseId,

      createdAt: new Date().toISOString(),
      roleName: userInfo.roleName,
      userId: parseInt(userInfo.userId),
      userName: `${userInfo.userFirstName || ""} ${
        userInfo.userLastName || ""
      }`,
    };
    if (Array.isArray(check[0].checkHistory)) {
      check[0].checkHistory = {};
    }
    if (check[0].checkHistory.comments) {
      check[0].checkHistory.comments.push(payload);
    } else {
      check[0].checkHistory.comments = [payload];
    }

    this.setState({ checkList }, async () => {
      try {
        await this.saveCheckList(checkList);

        return Promise.resolve();
      } catch (error) {
        console.log("error", error);
        return Promise.reject(error);
      }
    });
  };

  saveCheckList = async (checkList = this.state.checkList) => {
    try {
      let response = await api.post(SAVE_CHECK_LIST, {
        pvtId: parseInt(this.state.pvtId),
        checkList,
      });
      await this.getCheckListDetail();
      return Promise.resolve(response);
    } catch (error) {
      console.log("error", error);

      return Promise.reject(error);
    }
  };

  handleFormSubmit = async (e) => {
    e.preventDefault();
    const body = { pvtId: this.state.pvtId };
    this.setState({ isSaving: true });
    const { checkList } = this.state;
    checkList.forEach((check) => {
      const cseDocuments = check.cseDocuments || [];

      check.documents = [...check.documentList, ...cseDocuments];
      check.cseDocuments = [];
    });
    try {
      await this.saveCheckList(checkList);
      const allSubmittedChecksPromises = [];
      checkList.forEach((checkObject) => {
        if (checkObject.isInsuff) {
          allSubmittedChecksPromises.push(
            this.handleAllCheckInsuffSubmitted(checkObject)
          );
        } else if (checkObject.isSubmit) {
          if (checkObject.hasUserRaisedResearch) {
            allSubmittedChecksPromises.push(
              this.raiseResearchAndSubmitCheck(
                true,
                checkObject.userCommentForResearch,
                checkObject
              )
            );
          } else {
            allSubmittedChecksPromises.push(
              this.raiseResearchAndSubmitCheck(false, "", checkObject)
            );
          }
        }
      });
      await Promise.all(allSubmittedChecksPromises);
      await this.saveCheckList();

      const response = await api.post(`${FINAL_SUBMIT}`, body);
      Toast.success({
        description: "Case has been submitted successfully",
        header: "success",
      });
      this.setState({ isLoading: false });

      this.props.deleteAction({ pvtId: this.state.pvtId });
      this.props.history.push("/qcdashboard");
      this.setState({ isSaving: false });
    } catch (error) {
      console.log("error", error);
      Toast.error({
        description: "Error in submitting the case",
        title: "error",
      });
      this.setState({ isLoading: false });

      this.setState({ isSaving: false });
    }
  };

  handleAllCheckInsuffSubmitted = async (checkObject) => {
    let documentList = checkObject.documentList || [];
    const commentObject = checkObject.insuffCommentObject;
    this.submitCheckComment(commentObject, checkObject.checkUID);

    let documents = documentList.filter(
      (document) => document.doc_gcspath && document.doc_gcspath.length
    );
    let documentsFormatedForInsuff = documents.map((document) => {
      return {
        ...document,
        doc_path: document.doc_gcspath,
        page: document.page || null,
      };
    });

    const payload = {
      pvt_id: this.state.pvtId,
      user_id: this.props.userInfo.userId,
      remarks: commentObject.comment,
      comment: commentObject.comment,
      process_id: this.state.selectedPackage.value,
      verification_source_id: _get(
        checkObject,
        "verificationSource.value",
        null
      ),
      location_id: _get(checkObject, "verificationSourceLocation.value", null),
      candidate_id: this.state.caseDetails.candidateId,
      document_list: documentsFormatedForInsuff,
      check_id: checkObject.checkId,
      case_id: this.state.caseDetails.caseId,
      field_dict: checkObject.checkFields,
      nameOfCheck: `${checkObject.checkName} (${checkObject.checkNumber})`,
      comments: _get(checkObject, "checkHistory.comments", []),
    };
    try {
      await api.post(RAISE_CHECK_INSUFF, payload);
      checkObject.documentList = documents;
      this.handleCheckChange(checkObject, true);
      this.setState({ selectedCheckUID: null });
    } catch (error) {
      console.log("error in check insuff", error);
      Toast.error({
        description: "There is an error in raising insufficiency",
        header: "error",
      });
    }
  };

  raiseResearchAndSubmitCheck = async (
    shouldRaiseResearch,
    comment,
    checkObject
  ) => {
    this.setState({ isLoading: true });
    let documentList = checkObject.documentList || [];
    let documents = documentList.filter(
      (document) => document.doc_gcspath && document.doc_gcspath.length
    );
    const documentsFormatedForSubmitCheck = documents.map((document) => {
      return {
        ...document,
        doc_path: document.doc_gcspath,
        page: document.page || null,
      };
    });

    let field_dict = checkObject.checkFields;

    this.handleMultiplePeriodOfAddresses(checkObject);
    this.handleHiddenFields(checkObject);

    const payload = {
      pvt_id: this.state.pvtId,
      user_id: this.props.userInfo.userId,
      nameOfCheck: `${checkObject.checkName} (${checkObject.checkNumber})`,
      remarks: "Test Remarks", //needs to clarify
      process_id: this.state.selectedPackage.value,
      verification_source_id: checkObject.verificationSource.value,
      location_id: checkObject.verificationSourceLocation.value,
      candidate_id: this.state.caseDetails.candidateId,
      document_list: documentsFormatedForSubmitCheck,
      documented_by: this.props.userInfo.userId,
      check_id: checkObject.checkId,
      case_id: this.state.caseDetails.caseId,
      field_dict: field_dict,
      comments: _get(checkObject, "checkHistory.comments", []),
    };

    try {
      const response = await api.post(SUBMIT_CHECK, payload);
      checkObject.hasSubmitted = true;
      checkObject.documentList = documents;
      this.handleCheckChange(checkObject);
      if (shouldRaiseResearch) {
        const orgType = checkObject.orgType === "company" ? 1 : 2;
        //call research api
        api
          .post(ADD_RESEARCH, {
            pvt_id: parseInt(this.state.pvtId),
            case_check_id: response.data.caseCheckId,
            org_id: checkObject.verificationSource.value || null,
            org_name: checkObject.verificationSource.label || null,
            org_type: orgType,
            comment: this.getResearchText(checkObject),
          })
          .then(() =>
            Toast.success({
              description: "Research raised successfully",
              title: "Success",
            })
          )
          .catch((err) => {
            console.log("error in raising research", err);
          });
        this.submitCheckComment(
          {
            comment,
          },
          checkObject.checkUID
        );
      }
      this.setState({ isLoading: false });

      Toast.success({
        description: "check submitted successfully",
        header: "success",
      });
    } catch (error) {
      console.log(error);
      checkObject.hasSubmitted = false;
      this.setState({ isLoading: false });
      Toast.error({
        description: "error in  submitting check",
        header: "error",
      });
    }
  };

  getResearchText = (checkObject) => {
    const { checkId, researchType } = checkObject;
    const commentPrefix =
      researchType === "verificationSource"
        ? "Verification Source - "
        : "Location - ";
    if (
      (checkId === constants.checkIds.EDUCATION_W_CHECK_ID ||
        checkId === constants.checkIds.EDUCATION_CHECK_ID ||
        checkId ===
          constants.checkIds.PREVIOUS_EMPLOYMENT_VERIFICATION_CHECK_ID ||
        checkId ===
          constants.checkIds.CURRENT_EMPLOYMENT_VERIFICATION_CHECK_ID) &&
      checkObject.researchType === "verificationSourceLocation"
    ) {
      return commentPrefix + " " + checkObject.verificationSourceAddress;
    } else {
      return commentPrefix + " " + checkObject[checkObject.researchType].label;
    }
  };

  handleMultiplePeriodOfAddresses = (checkObject) => {
    if (checkObject.from2 && checkObject.to2) {
      const [year, month] = checkObject.from2.split("-");
      const [year2, month2] = checkObject.to2.split("-");
      const period = `From ${monthNameFromNumber(
        month
      )},${year} To ${monthNameFromNumber(month2)},${year2}`;

      const fieldForSubmitCheck = checkObject.checkFields.find(
        (checkField) =>
          checkField.antecedantName ===
          this.getPrimaryAddressFieldNameForCheck(checkObject.checkId)
      );

      fieldForSubmitCheck.antecedantValue = `${fieldForSubmitCheck.antecedantValue} and ${period}`;
    }
  };
  getPrimaryAddressFieldNameForCheck = (checkId) => {
    switch (checkId) {
      case constants.checkIds.ADDRESS_VERIFICATION_CHECK_ID:
        return constants.PERIOD_OF_STAY;
      case constants.checkIds
        .INDIA_COURT_RECORD_CHECK_THROUGH_LAW_FIRM_CHECK_ID:
        return constants.PERIOD_OF_STAY_COURT_VERIFICATION;
    }
  };

  handleHiddenFields = (checkObject) => {
    if (
      checkObject.checkId === constants.checkIds.DOCUMENT_VERIFICATION_CHECK_ID
    ) {
      if (checkObject.periodOfBankFrom && checkObject.periodOfBankTo) {
        const [year, month, date] = checkObject.periodOfBankFrom.split("-");
        const [year2, month2, date2] = checkObject.periodOfBankTo.split("-");
        const period = `From ${date} ${monthNameFromNumber(
          month
        )},${year} To ${date2} ${monthNameFromNumber(month2)},${year2}`;

        const fieldForSubmitCheck = checkObject.checkFields.find(
          (checkField) =>
            checkField.antecedantId ===
            constants.antecedantId.PERIOD_OF_BANK_STATEMENT_ID
        );

        fieldForSubmitCheck.antecedantValue = period;
      } else if (checkObject.periodOfBankFrom) {
        const [year, month, date] = checkObject.periodOfBankFrom.split("-");
        const period = `From ${date} ${monthNameFromNumber(month)},${year} `;

        const fieldForSubmitCheck = checkObject.checkFields.find(
          (checkField) =>
            checkField.antecedantId ===
            constants.antecedantId.PERIOD_OF_BANK_STATEMENT_ID
        );

        fieldForSubmitCheck.antecedantValue = period;
      } else if (checkObject.periodOfBankTo) {
        const [year2, month2, date2] = checkObject.periodOfBankTo.split("-");
        const period = ` To ${date2} ${monthNameFromNumber(month2)},${year2}`;

        const fieldForSubmitCheck = checkObject.checkFields.find(
          (checkField) =>
            checkField.antecedantId ===
            constants.antecedantId.PERIOD_OF_BANK_STATEMENT_ID
        );

        fieldForSubmitCheck.antecedantValue = period;
      }
    }
  };

  getPackages = () => {
    api
      .get(GET_PACKAGES)
      .then((response) => {
        const packages = response.data.packages;
        const selectPackages = packages.map((item) => ({
          label: item.processName,
          value: item.processId,
          priorityFlag: item.priorityFlag,
        }));
        this.setState({
          packages: selectPackages,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getChecksPerProcess = (processId) => {
    api
      .get(
        CHECKS_PER_PROCESS +
          `${processId ? processId : this.state.selectedPackage.value}`
      )
      .then((response) => {
        const checks = response.data.result.checks;
        this.setState({ checkListPerPackage: checks });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  convertPeriodToDates = (checkObject, antecedantName, isFrom) => {
    const periodCheck = checkObject.checkFields.find(
      (checkField) => checkField.antecedantName === antecedantName
    );
    const [fromDate, toDate] = getFormatDate(periodCheck.antecedantValue);

    if (isFrom) {
      return fromDate;
    } else {
      return toDate;
    }
  };

  isAntecedantDisable = (antecedantId) => {
    const disabledAntecedants = [];

    return disabledAntecedants.find((item) => {
      if (antecedantId === item) {
        return true;
      }
      return false;
    });
  };

  isAntecedantHidden = (antecedantId) => {
    const removedAntecedants = [
      constants.antecedantId.PERIOD_OF_BANK_STATEMENT_ID,
    ];

    return removedAntecedants.find((item) => {
      if (antecedantId === item) {
        return true;
      }
      return false;
    });
  };

  getCheckPriority = (checkId) => {
    switch (checkId) {
      case constants.checkIds.PASSPORT_VERIFICATION_CHECK_ID:
        return 10;
      case constants.checkIds.PAN_NUMBER_VERIFICATION_CHECK_ID:
        return 20;
      case constants.checkIds.DRIVING_LICENCE_VERIFICATION_CHECK_ID:
        return 30;
      case constants.checkIds.NATIONAL_IDENTITY_CHECK_ID:
        return 40;
      case constants.checkIds.EDUCATION_W_CHECK_ID:
        return 50;
      case constants.checkIds.EDUCATION_CHECK_ID:
        return 60;
      case constants.checkIds.PREVIOUS_EMPLOYMENT_VERIFICATION_CHECK_ID:
        return 70;
      case constants.checkIds.CURRENT_EMPLOYMENT_VERIFICATION_CHECK_ID:
        return 80;
      case constants.checkIds.REFERENCE_CHECK_ID:
        return 90;
      case constants.checkIds.CURRENT_ADDRESS_VERIFICATION_CHECK_ID:
        return 100;
      case constants.checkIds.ADDRESS_VERIFICATION_CHECK_ID:
        return 110;
      case constants.checkIds
        .INDIA_COURT_RECORD_CHECK_THROUGH_LAW_FIRM_CHECK_ID:
        return 120;
      case constants.checkIds.PERMANENT_VERIFICATION_CHECK_ID:
        return 130;
      case constants.checkIds.FACIS_LEVEL_3_DATABASE_VERIFICATION_CHECK_ID:
        return 140;
      case constants.checkIds.GLOBAL_DATABASE_CHECK_ID:
        return 150;
      case constants.checkIds.GLOBAL_DATABASE_OFAC_CHECK_ID:
        return 160;
      case constants.checkIds.GSA_OIG_MEDICAL_DATABASE_CHECK_ID:
        return 170;
      case constants.checkIds.INDIA_COURT_RECORD_DATABASE_CHECK_ID:
        return 180;
      case constants.checkIds.INDIAN_DATABASE_CREDIT_AND_BANKRUPTCY_CHECK_ID:
        return 190;
      case constants.checkIds.INDIAN_DATABASE_VERIFICATION_CHECK_ID:
        return 200;
      case constants.checkIds.SOCIAL_MEDIA_CHECK_ID:
        return 210;
      default:
        return 500;
    }
  };

  reformatCheck = (checkObject) => {
    const checkIdNameObject =
      this.state.checkListPerPackage.find(
        (check) => check.checkId === checkObject.type
      ) || {};
    const oldCheckFields = Object.entries(checkObject.checkFields);

    const newCheckFields = oldCheckFields.map(([key, antecedantValue]) => {
      const antecedantObject = this.state.antecedantIdNameMap[key] || {};

      let minlength = 0,
        maxlength = Infinity;
      if (
        antecedantObject.antecedantId === 2849 ||
        antecedantObject.antecedantId === 2850
      ) {
        //passport mrz line
        minlength = 44;
        maxlength = 44;
      }

      return {
        antecedantId: antecedantObject.antecedantId,
        antecedantName: antecedantObject.antecedantName,
        antecedantValue: antecedantValue,
        antecedantInputType: antecedantObject.antecedantInputType,
        isMandatory: antecedantObject.isMandatory,
        isDisable: this.isAntecedantDisable(antecedantObject.antecedantId),
        isHidden: this.isAntecedantHidden(antecedantObject.antecedantId),
        dropDownValues: antecedantObject.dropDownValues,
        minlength: minlength,
        maxlength: maxlength,
      };
    });

    const newCheckObject = {
      checkId: checkObject.type,
      checkName: checkIdNameObject.checkName,
      checkUID: checkObject.checkUID,
      priority: this.getCheckPriority(checkObject.type),
      sourceVerification: checkObject.sourceVerification,
      isSubmit: (checkObject.isSubmit = false),
      sourceAddress: checkObject.sourceAddress,
      checkFields: newCheckFields,
      documents: [],
      verificationSource: "",
      verificationSourceLocation: {},
      verificationSourceEducation: "",
      verificationSourceAddress: "",
      verificationAddressLocation: [],
      sourceVerificationType: checkObject.sourceVerificationType,
      checkHistory: {},
      isInHiringList: checkObject.isInHiringList,
      documentList: checkObject.documentList || [],
    };
    if (checkObject.type === constants.checkIds.ADDRESS_VERIFICATION_CHECK_ID) {
      // address verification
      newCheckObject.from = this.convertPeriodToDates(
        newCheckObject,
        constants.PERIOD_OF_STAY,
        true
      );

      newCheckObject.to = this.convertPeriodToDates(
        newCheckObject,
        constants.PERIOD_OF_STAY,
        false
      );
    }
    if (
      checkObject.type ===
      constants.checkIds.INDIA_COURT_RECORD_CHECK_THROUGH_LAW_FIRM_CHECK_ID
    ) {
      newCheckObject.from = this.convertPeriodToDates(
        newCheckObject,
        constants.PERIOD_OF_STAY_COURT_VERIFICATION,
        true
      );

      newCheckObject.to = this.convertPeriodToDates(
        newCheckObject,
        constants.PERIOD_OF_STAY_COURT_VERIFICATION,
        false
      );
    }

    if (
      checkObject.type ===
        constants.checkIds.PREVIOUS_EMPLOYMENT_VERIFICATION_CHECK_ID ||
      checkObject.type ===
        constants.checkIds.CURRENT_EMPLOYMENT_VERIFICATION_CHECK_ID
    ) {
      //employment verification
      newCheckObject.from = this.convertPeriodToDates(
        newCheckObject,
        constants.PERIOD_OF_EMPLOYMENT,
        true
      );

      newCheckObject.to = this.convertPeriodToDates(
        newCheckObject,
        constants.PERIOD_OF_EMPLOYMENT,
        false
      );
    }
    if (checkObject.type === 83 || checkObject.type === 25) {
      //education verification
      newCheckObject.to = convertPassingYearToDate(
        newCheckObject,
        constants.YEAR_OF_PASSING,
        this.handleCheckChange
      );
      newCheckObject.from = "";
    }

    return newCheckObject;
  };

  addNewCheck = async ({ checkId, sourceVerificationType }) => {
    this.setState({ isLoading: true });
    try {
      const response = await api.get(DEFAULT_DOCUMENTS_PER_CHECK + checkId);
      const defaultDocs = response.data.result.documents;
      let jsonCheckListsArr;
      var list = this.state.checkList.filter(
        (x) => x.checkId === parseInt(checkId)
      );

      if (list.length > 0) {
        var jsonCheckFieldsArr = [];
        Object.keys(list[0].checkFields).map((key) => {
          jsonCheckFieldsArr.push({
            antecedantId: list[0].checkFields[key].antecedantId,
            antecedantName: list[0].checkFields[key].antecedantName,
            antecedantValue: null,
            antecedantInputType: list[0].checkFields[key].antecedantInputType,
            dropDownValues: list[0].checkFields[key].dropDownValues,
            isDisable: this.isAntecedantDisable(
              list[0].checkFields[key].antecedantName
            ),
            isHidden: this.isAntecedantHidden(
              list[0].checkFields[key].antecedantId
            ),
            isMandatory: list[0].checkFields[key].isMandatory,
          });
        });
        jsonCheckListsArr = {
          checkId: list[0].checkId,
          priority: this.getCheckPriority(list[0].checkId),
          checkName: list[0].checkName,
          checkUID: this.getCheckUID(),
          sourceVerification: "",
          isSubmit: false,
          sourceAddress: "",
          checkFields: jsonCheckFieldsArr,
          documentList: defaultDocs || [],
          verificationSource: "",
          verificationSourceLocation: {},
          verificationSourceEducation: "",
          verificationSourceAddress: "",
          verificationAddressLocation: [],
          sourceVerificationType: sourceVerificationType,
          checkHistory: {},
          isInHiringList: null,
        };
        const checkList = [...this.state.checkList, jsonCheckListsArr];
        this.setState(
          {
            checkList,
          },
          async () => {
            await this.saveCheckList();
            this.setState({ isLoading: false }, () =>
              Toast.success({
                description: "Check added successfully",
                header: "success",
              })
            );
          }
        );
        return jsonCheckListsArr;
      } else {
        return this.getCheckDetailsDynamically(
          parseInt(checkId),
          defaultDocs,
          sourceVerificationType
        );
      }
    } catch (error) {
      this.setState({ isLoading: false }, () =>
        Toast.error({
          description: "error while adding check",
          header: "error",
        })
      );

      console.log("error while adding check", error);
    }
  };

  // addMoreAnticidant(checkFields, checkId) {
  //   this.dynamicCheckFields = checkFields;
  //   api
  //     .get(ANTECEDANTS_PER_CHECK_PER_PROCESS + checkId)
  //     .then(response => {
  //       //  this.setState({ antecedents: response.data.antecedents });
  //     })
  //     .catch(err => console.log(err));
  // }

  getCheckUID = () => {
    let maxCheckUID = 1;
    this.state.checkList.forEach((check) => {
      if (typeof check.checkUID && check.checkUID > maxCheckUID) {
        maxCheckUID = check.checkUID;
      }
    });
    return maxCheckUID + 1;
  };

  getCheckDetailsDynamically = async (
    checkId,
    defaultDocs,
    sourceVerificationType
  ) => {
    var jsonCheckFieldTempArr = [];
    var jsonCheckListArr;
    api
      .get(
        ANTECEDANTS_PER_CHECK_PER_PROCESS +
          this.state.selectedPackage.value +
          "/" +
          checkId
      )
      .then((response) => {
        const result = response.data.result;
        result.fields.forEach((key) => {
          jsonCheckFieldTempArr.push({
            antecedantId: Number(key.antecedantId),
            antecedantName: key.antecedantName,
            antecedantValue: null,
            isMandatory: key.isMandatory,
            antecedantInputType: key.antecedantInputType,
            dropDownValues: key.dropDownValues,
            isDisable: this.isAntecedantDisable(key.antecedantName),
            isHidden: this.isAntecedantHidden(key.antecedantId),
          });
        });
        jsonCheckListArr = {
          checkId: result.checkId,
          priority: this.getCheckPriority(result.checkId),
          checkName: result.checkName,
          checkUID: this.getCheckUID(),
          sourceVerification: null,
          sourceAddress: null,
          checkFields: jsonCheckFieldTempArr,
          isSubmit: false,
          documentList: defaultDocs || [],
          sourceVerificationType: sourceVerificationType,
          verificationSource: "",
          verificationSourceLocation: {},
          verificationSourceEducation: "",
          verificationSourceAddress: "",
          verificationAddressLocation: [],
          checkHistory: {},
          isInHiringList: null,
        };
        this.setState(
          {
            checkList: [...this.state.checkList, jsonCheckListArr],
          },
          async () => {
            await this.saveCheckList();
            this.setState({ isLoading: false }, () =>
              Toast.success({
                description: "Check added successfully",
                header: "success",
              })
            );
          }
        );
        return jsonCheckListArr;
      })
      .catch((err) => {
        console.log(err);

        this.setState({ isLoading: false }, () =>
          Toast.error({
            description: "error while adding check",
            header: "error",
          })
        );
      });
  };

  updateProcessId = () => {
    api
      .post(UPDATE_PROCESS_ID, {
        processId: parseInt(this.state.selectedPackage.value),
        pvtId: parseInt(this.state.pvtId),
      })
      .then((response) => {
        //const checks = response.data.result.checks;
        //this.setState({ checkList: checks });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handlePackageChange = async (selectedPackage) => {
    console.log("selectedPackage", selectedPackage);
    const body = {
      pvtId: this.state.pvtId,
      packageId: selectedPackage.value,
      packageName: selectedPackage.label, //this get stored in packageQc at backend
      priorityFlag: selectedPackage.priorityFlag, // priority flag will remain according to initial package
    };
    try {
      await api.post(`${SAVE_CASE_DETAILS}`, body);
      this.getCaseDetail();

      Toast.success({
        description: "package changed successfully",
        title: "Success",
      });
    } catch (e) {
      Toast.error({
        description: "something went wrong in changing package",
        title: "error",
      });
    }
  };

  openCaseHistoryModel = (e) => {
    e.preventDefault();

    this.setState({ isCaseHistoryModalOpen: true });
  };

  openCheckHistoryModel = (checkUID) => {
    this.setState({
      isCheckHistoryModalOpen: true,
      selectedCheckUID: checkUID,
    });
  };

  raiseInsufficiency = (e, isCheck = false) => {
    e.preventDefault();
    const { userInfo = {} } = this.props;
    const insuffEscaltionObject = {
      label: "Raising Insufficiency",
      userName: `${userInfo.userFirstName} ${userInfo.userLastName}`,
      userId: userInfo.userId,
      status: "Insufficient",
      id: this.state.pvtId,
    };
    this.setState({
      insuffEscaltionObject,
      isInsuffEscalationModalOpen: true,
      isCheckModal: isCheck,
    });
  };

  onInsufficiencyRaised = (data) => {
    data.e.preventDefault();
    this.setState({ isLoading: true });
    if (data.comment) {
      // this.submitCaseComment(data);
      const payload = {
        comment: data.comment,
        pvt_id: parseInt(data.id),
        // userId: parseInt(data.userId),
        // status: data.status
      };

      api
        .post(Insuff_Raised, payload)
        .then(() => {
          this.setState({ isLoading: false });
          Toast.success({
            description: "Insufficiency Raised",
            header: "success",
          });
          this.getCaseDetail();
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoading: false });
          Toast.error({
            description: "There is an error in raising insufficiency",
            header: "error",
          });
        })
        .finally(() => {
          this.setState({ isInsuffEscalationModalOpen: false });
        });
    }
  };

  onInsufficiencyRaisedCheckForGap = (
    commentObject,
    checkObject,
    gapObject,
    closeModal = true
  ) => {
    checkObject.isSubmit = true;
    checkObject.isInsuff = true;
    checkObject.insuffCommentObject = commentObject;
    this.handleCheckChange(checkObject);
    this.setState({
      selectedCheckUID: null,
      isInsuffEscalationModalOpen: false,
    });
    Toast.success({
      description: "Check Insufficiency raised successfully",
      header: "success",
    });

    closeModal && modalManager.close({});

    if (gapObject.gap.gapType === constants.ADDRESS_TO_ADDRESS_GAP) {
      const gapAddressArray = this.props.gapAddressArray;
      gapAddressArray.forEach((addressObject) => {
        if (addressObject.id === gapObject.id) {
          addressObject.status = constants.GAP_OBJECT_STATUS_CHECK_RAISED;
        }
      });

      this.props.updateAddressArray(gapAddressArray);
    }

    if (gapObject.gap.gapType === constants.ADDRESS_MISMATCH_GAP) {
      let educationAddressMismatchArray = this.props
        .educationAddressMismatchArray;
      educationAddressMismatchArray.forEach((object) => {
        if (object.id === gapObject.id) {
          object.status = constants.GAP_OBJECT_STATUS_CHECK_RAISED;
        }
      });
      let employmentAddressMismatchArray = this.props
        .employmentAddressMismatchArray;
      employmentAddressMismatchArray.forEach((object) => {
        if (object.id === gapObject.id) {
          object.status = constants.GAP_OBJECT_STATUS_CHECK_RAISED;
        }
      });

      this.props.updateEducationAddressMismatchArray(
        educationAddressMismatchArray
      );
      this.props.updateEmploymentAddressMismatchArray(
        employmentAddressMismatchArray
      );
    }
    Toast.success({
      description: "Check Insufficiency raised successfully",
      title: "success",
    });
  };

  changeStatus = async (
    pvtId,
    status = "escalation_responded",
    redirect = "/cseResourceDashboard"
  ) => {
    const body = {
      pvtId: parseInt(pvtId),
    };
    try {
      const response = await api.post(
        `${CHANGE_STATUS}?status=${status}`,
        body
      );
      Toast.success({
        description: "change status successfully",
        header: "success",
      });
      this.props.history.push(redirect);
    } catch (e) {
      Toast.error({
        description: "Something went wrong",
        header: "Error",
      });
      console.log(e);
    }
  };

  raiseEscalation = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    const { userInfo = {} } = this.props;
    const escalationObject = {
      label: "Escalating",
      userName: `${userInfo.userFirstName} ${userInfo.userLastName}`,
      userId: userInfo.userId,
      status: "Escalation",
      id: this.state.pvtId,
    };
    this.setState({ escalationObject, isEscalationModalOpen: true });
  };

  onEscalationRaised = (data) => {
    data.e.preventDefault();
    if (data.comment) {
      // this.submitCaseComment(data);
      // const payload = {
      //   comment: data.comment,
      //   pvtId: parseInt(data.id),
      //   userId: parseInt(data.userId),
      //   status: data.status
      // };
      const body = {
        pvtId: parseInt(data.id),
        comment: data.comment,
      };
      api
        .post(`${CHANGE_STATUS}?status=${data.status}`, body)
        .then(() => {
          this.setState({ isLoading: false });
          Toast.success({
            description: "Case escalated successfully",
            header: "success",
          });

          this.props.history.push("/qcdashboard");
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoading: false });
          Toast.error({
            description: "There is an error in escalating the the case",
            header: "error",
          });
        })
        .finally(() => {
          this.setState({ isEscalationModalOpen: false });
        });
    }
  };

  getFileType = (url) => {
    let type = "";

    if (url) {
      for (let i = url.length - 1; i >= 0; i--) {
        if (url[i] === ".") {
          break;
        }
        type = type.concat(url[i]);
      }

      type = type.split("").reverse().join("").toLowerCase();

      return type;
    }
  };

  handleCheckChange = (checkObject = {}, isSaveCheckList) => {
    const checkList = this.state.checkList;
    const index = checkList.findIndex(
      (check) => check.checkUID === checkObject.checkUID
    );

    if (index !== -1) {
      checkList[index] = checkObject;
      this.setState({ checkList: [...checkList] }, () => {
        this.updateGroupList(checkList);

        isSaveCheckList && this.saveCheckList(checkList);
      });
    } else {
      console.log("cannot find index of the checkObject ", checkObject);
    }
  };

  //REACT_APP_FILE_URL
  loadDocument = (path, id = "") => {
    const fileURL = `${process.env.REACT_APP_FILE_URL}${path}`;
    const fileType = this.getFileType(fileURL);
    if (fileType === "doc") {
      this.setState({ iframeDocumentLoading: true });
    } else {
      this.setState({ iframeDocumentLoading: false });
    }
    this.setState({
      currentDocumentURL: fileURL,
      currentDocumentType: fileType,
      activeDocId: id,
    });
  };

  getStaticURL = async (publicURL = "") => {
    try {
      const response = await api.post(LOAD_DOCUMENT, {
        filepath: publicURL,
      });

      return response.data.staticUrl;
    } catch (error) {
      console.log("error in fetching static url", error);
      return Promise.reject();
    }
  };

  getArs = (data, personalData) => {
    this.setState({ arsObj: data, personalData: personalData }, () => {
      this.getCaseDetail();
      this.getCheckListDetail();
    });
  };

  openGapcheck = () => {
    const { arsObj } = this.state;
    modalManager.open({
      width: "100%",
      isHeader: true,
      component: () => {
        return (
          <Gapcheck
            addresses={this.getAddressesForGapCheck()}
            employmentList={this.getEmploymentListForGapCheck()}
            highestEducationCheck={this.getHighestEducationCheckWithinTimePeriod()}
            pvtId={this.state.pvtId}
            userId={this.props.userInfo.userId}
            caseId={arsObj.caseId}
            caseReceivedOn={this.state.caseDetails.caseReceivedOn}
            handleGapRaiseInsuff={this.handleGapRaiseInsuff}
          />
        );
      },
    });
  };

  convertFromAndToToPeriodOfStay = (antecedantName, checkObject) => {
    if (checkObject.from && checkObject.to) {
      const [year, month] = checkObject.from.split("-");
      const [year2, month2] = checkObject.to.split("-");
      const period = `From ${monthNameFromNumber(
        month
      )},${year} To ${monthNameFromNumber(month2)},${year2}`;

      const field = checkObject.checkFields.find(
        (checkField) => checkField.antecedantName === antecedantName
      );
      field.antecedantValue = period;
      this.handleCheckChange(checkObject);
    }
  };

  fillAntecedantValueInTheObject(checkObject, antecedantName, antecedantValue) {
    const field = checkObject.checkFields.find(
      (checkField) => checkField.antecedantName === antecedantName
    );

    field.antecedantValue = antecedantValue;
  }

  handleGapRaiseInsuff = async (
    gap,
    comment = "test",
    fromDate,
    toDate,
    gapObject
  ) => {
    //const gap = [...this.props.gapAddressArray];
    let checkIdArray = [];

    if (gap.gapType === constants.ADDRESS_TO_ADDRESS_GAP) {
      checkIdArray = [
        constants.checkIds.ADDRESS_VERIFICATION_CHECK_ID,
        constants.checkIds.INDIA_COURT_RECORD_CHECK_THROUGH_LAW_FIRM_CHECK_ID,
      ];

      for (let [index, checkId] of checkIdArray.entries()) {

        let antecedantName;
        let sourceVerificationType;

        // switch (checkId) {
        //   case constants.checkIds.ADDRESS_VERIFICATION_CHECK_ID:
        //     antecedantName = constants.PERIOD_OF_STAY;
        //     sourceVerificationType =
        //       constants.SOURCE_VERIFICATION_TYPE_ADDRESS_VERIFICATION_CHECK;
        //     break;
        //   case constants.checkIds
        //     .INDIA_COURT_RECORD_CHECK_THROUGH_LAW_FIRM_CHECK_ID:
        //     antecedantName = constants.PERIOD_OF_STAY_COURT_VERIFICATION;
        //     sourceVerificationType =
        //       constants.SOURCE_VERIFICATION_TYPE_INDIAN_COURT_CHECK;

        //     break;
        //   default:
        //     antecedantName = constants.PERIOD_OF_STAY;
        //     sourceVerificationType =
        //       constants.SOURCE_VERIFICATION_TYPE_ADDRESS_VERIFICATION_CHECK;
        // }

        const checkObject = await this.addNewCheck({
          checkId,
          sourceVerificationType,
        });

        checkObject.from = fromDate;
        checkObject.to = toDate;

        this.convertFromAndToToPeriodOfStay(antecedantName, checkObject);


        this.onInsufficiencyRaisedCheckForGap(
          { comment },
          checkObject,
          gapObject,
          index === 0 ? false : true
        );
      }

    } else if (
      gap.gapType === constants.EDUCATION_TO_EMPLOYMENT_GAP ||
      gap.gapType === constants.EMPLOYMENT_TO_EMPLOYMENT_GAP
    ) {
      checkIdArray = [constants.checkIds.GAP_CHECK_ID];


      for (let checkId of checkIdArray) {
        const checkObject = await this.addNewCheck(
          { checkId },
          constants.SOURCE_VERIFICATION_TYPE_GAP_CHECK
        );

        const gapEmploymentEducationArray = this.props
          .gapEmploymentEducationArray;
        gapEmploymentEducationArray.forEach((object) => {
          if (object.id === gapObject.id) {
            object.status = constants.GAP_OBJECT_STATUS_CHECK_RAISED;
          }
        });


        this.props.updateEmploymentEducationArray(gapEmploymentEducationArray);

        this.fillAntecedantValueInTheObject(
          checkObject,
          "Period from",
          fromDate
        );
        this.fillAntecedantValueInTheObject(checkObject, "Period to", toDate);
        this.fillAntecedantValueInTheObject(
          checkObject,
          "Gap Between",
          gap.gapBetweenForGapCheck
        );
        this.fillAntecedantValueInTheObject(
          checkObject,
          "Gap Type",
          gap.gapType === constants.EDUCATION_TO_EMPLOYMENT_GAP
            ? "Between Education to Employment"
            : "Between two Employment"
        );
        this.handleCheckChange(checkObject);
        modalManager.close({});
        // this.onInsufficiencyRaisedCheckForGap({ comment }, checkObject, gapObject);
      }
    } else if (gap.gapType === constants.ADDRESS_MISMATCH_GAP) {
      checkIdArray = [
        constants.checkIds.ADDRESS_VERIFICATION_CHECK_ID,
        constants.checkIds.INDIA_COURT_RECORD_CHECK_THROUGH_LAW_FIRM_CHECK_ID,
      ];

      for (let checkId of checkIdArray) {

        let antecedantName;
        let sourceVerificationType;
        // switch (checkId) {
        //   case constants.checkIds.ADDRESS_VERIFICATION_CHECK_ID:
        //     antecedantName = constants.PERIOD_OF_STAY;
        //     sourceVerificationType =
        //       constants.SOURCE_VERIFICATION_TYPE_ADDRESS_VERIFICATION_CHECK;
        //     break;
        //   case constants.checkIds
        //     .INDIA_COURT_RECORD_CHECK_THROUGH_LAW_FIRM_CHECK_ID:
        //     antecedantName = constants.PERIOD_OF_STAY_COURT_VERIFICATION;
        //     sourceVerificationType =
        //       constants.SOURCE_VERIFICATION_TYPE_INDIAN_COURT_CHECK;

        //     break;
        //   default:
        //     antecedantName = constants.PERIOD_OF_STAY;
        //     sourceVerificationType =
        //       constants.SOURCE_VERIFICATION_TYPE_ADDRESS_VERIFICATION_CHECK;
        // }
        const checkObject = await this.addNewCheck({
          checkId,
          sourceVerificationType,
        });

        checkObject.from = fromDate;
        checkObject.to = toDate;

        this.convertFromAndToToPeriodOfStay(antecedantName, checkObject);
        if (checkId === constants.checkIds.ADDRESS_VERIFICATION_CHECK_ID) {
          this.onInsufficiencyRaisedCheckForGap(
            { comment },
            checkObject,
            gapObject,
            false
          ); //don't close modal
        } else {
          this.onInsufficiencyRaisedCheckForGap(
            { comment },
            checkObject,
            gapObject
          );
        }
      }
    }
  };

  onDocumentSelected = (file) => {
    if (!file) {
      return;
    }
    let formdata = new FormData();
    const type = this.getFileType(file.name);
    let name = file.name;
    name = name.replace(`.${type}`, "");
    formdata.append("file", file, file.name);
    formdata.append("pvt_id", this.state.pvtId);
    formdata.append("user_id", this.props.userInfo.userId);
    formdata.append("doc_type", name);
    this.setState({ isLoading: true });
    api
      .post(UPLOAD_FILE, formdata)
      .then(() => {
        this.saveCheckList().then(() => {
          this.getCaseDetail();
        });
      })
      .catch((err) => console.log("error in uploading doucment", err));
  };

  handleSubmittedChecksToggleChange = (showOnlySubmittedChecks) => {
    if (showOnlySubmittedChecks) {
      this.setState({ showGapCheckButton: true });
      const submittedChecks = this.state.checkList.filter(
        (check) => check.isSubmit
      );

      this.setState({ submittedChecks });
      this.updateGroupList(submittedChecks);
    } else {
      this.setState({ showGapCheckButton: false });
      this.updateGroupList();
    }
  };

  setLoadingValue = (value) => {
    this.setState({ isLoading: value });
  };
  saveDeatils = async (string, clientFormData) => {
    const {
      selectedLocation,
      caseDetails,
      caseReceivedTime,
      selectedPackage,
      pvtId,
    } = this.state;
    const body = {
      pvtId: pvtId,
      packageId: selectedPackage.value,
      packageName: selectedPackage.label,
      priorityFlag: clientFormData.priorityFlag,
      caseReceivedTime: caseReceivedTime,
      caseReceivedOn: caseDetails.caseReceivedOn,
      locationName: selectedLocation.officeName,
    };
    try {
      const result = await api.post(`${SAVE_CASE_DETAILS}`, body);
      // if (string) {
      //   this.getCaseDetail();
      // }
    } catch (e) {
      console.log(e);
    }
  };

  getAddressesForGapCheck = () => {
    let submittedAddressesChecks = this.state.submittedChecks.filter(
      (check) => check.checkId === 60 && check.isInsuff !== true
    );

    const doubleTenureAddressChecks = [];
    for (let check of submittedAddressesChecks) {
      if (check.from2 && check.to2) {
        const newCheck = _cloneDeep(check);
        newCheck.from = check.from2;
        newCheck.to = check.to2;
        newCheck.from2 = "";
        newCheck.to2 = "";
        doubleTenureAddressChecks.push(newCheck);
      }
    }
    submittedAddressesChecks = [
      ...submittedAddressesChecks,
      ...doubleTenureAddressChecks,
    ];
    let formattedAddressesCheck = submittedAddressesChecks.map((check) => ({
      from: check.from,
      to: check.to,
      location: {
        address: check.verificationSourceLocation.label,
        locationId: check.verificationSourceLocation.value,
      },
      verificationSource: check.verificationSource,
      checkNumber: check.checkNumber,
      checkName: check.checkName,
      checkUID: check.checkUID,
    }));

    formattedAddressesCheck = formattedAddressesCheck
      .filter((check) => isWithinTimePeriod(check.to))
      .sort((check, check2) => new Date(check.from) - new Date(check2.from));

    return formattedAddressesCheck;
  };

  getHighestEducationCheckWithinTimePeriod = () => {
    let submittedEducationChecks = this.state.submittedChecks
      .filter((check) => check.checkId === 83 || check.checkId === 25)
      .filter((check) => isWithinTimePeriod(check.to));
    let highestDate = new Date(null); // 1 jan 1970
    let highestEducationCheck = null;

    submittedEducationChecks.forEach((check) => {
      if (new Date(check.to) > highestDate) {
        highestDate = new Date(check.to);
        highestEducationCheck = check;
      }
    });
    let formattedHighestEducationCheck = null;
    if (highestEducationCheck) {
      formattedHighestEducationCheck = {
        from: highestEducationCheck.from,
        to: highestEducationCheck.to,

        location: {
          address: highestEducationCheck.verificationSourceLocation.label,
          locationId: highestEducationCheck.verificationSourceLocation.value,
        },
        verificationSource: highestEducationCheck.verificationSource,
        checkNumber: highestEducationCheck.checkNumber,
        checkName: highestEducationCheck.checkName,
        checkUID: highestEducationCheck.checkUID,
      };
    }

    return formattedHighestEducationCheck;
  };

  getEmploymentListForGapCheck = () => {
    let submittedEmploymentChecks = this.state.submittedChecks
      .filter(
        (check) =>
          check.checkId ===
            constants.checkIds.PREVIOUS_EMPLOYMENT_VERIFICATION_CHECK_ID ||
          check.checkId ===
            constants.checkIds.CURRENT_EMPLOYMENT_VERIFICATION_CHECK_ID
      )
      .filter((check) => isWithinTimePeriod(check.to))
      .sort((check, check2) => new Date(check.from) - new Date(check2.from));
    const formattedEmploymentChecks = submittedEmploymentChecks.map(
      (check) => ({
        from: check.from,
        to: check.to,
        location: {
          address: check.verificationSourceLocation.label,
          locationId: check.verificationSourceLocation.value,
        },
        verificationSource: check.verificationSource,
        checkNumber: check.checkNumber,
        checkName: check.checkName,
        checkUID: check.checkUID,
      })
    );

    return formattedEmploymentChecks;
  };

  getAntecedantIdForSyncedInput = (antecedantId) => {
    switch (antecedantId) {
      case constants.antecedantId.ADDRESS_PERIOD_OF_STAY:
        return `${constants.antecedantId.INDIA_COURT_RECORD_PERIOD_OF_STAY}`;
      case constants.antecedantId.INDIA_COURT_RECORD_PERIOD_OF_STAY:
        return `${constants.antecedantId.ADDRESS_PERIOD_OF_STAY}`;
      case constants.antecedantId.ADDRESS_ADDRESS:
        return `${constants.antecedantId.INDIA_COURT_RECORD_ADDRESS}`;
      case constants.antecedantId.INDIA_COURT_RECORD_ADDRESS:
        return `${constants.antecedantId.ADDRESS_ADDRESS}`;
      default:
        return null;
    }
  };

  getCheckIdForSyncedInput = (checkId) => {
    switch (checkId) {
      case constants.checkIds.ADDRESS_VERIFICATION_CHECK_ID:
        return `${constants.checkIds.INDIA_COURT_RECORD_CHECK_THROUGH_LAW_FIRM_CHECK_ID}`;
      case constants.checkIds
        .INDIA_COURT_RECORD_CHECK_THROUGH_LAW_FIRM_CHECK_ID:
        return `${constants.checkIds.ADDRESS_VERIFICATION_CHECK_ID}`;
      default:
        return null;
    }
  };

  handleErrorInSubmittingCheck = (err) => {
    this.setState({ isSubmitAllChecks: false });
  };

  handleAntecedantSync = (
    event,
    checkId,
    checkNumber,
    antecedantId = null,
    additionalAntecedantName = ""
  ) => {
    const secondCheckId = parseInt(this.getCheckIdForSyncedInput(checkId));
    if (secondCheckId === null) {
      return;
    }
    const checkList = Object.assign([], this.state.checkList);
    const checkObject = checkList.find(
      (check) =>
        check.checkId === secondCheckId && check.checkNumber === checkNumber
    );

    if (checkObject && !checkObject.isSubmit) {
      if (additionalAntecedantName) {
        if (Array.isArray(additionalAntecedantName)) {
          //verification source
          checkObject[additionalAntecedantName[0]] = event.target.value;
          checkObject[additionalAntecedantName[1]] = event.target.value;
          checkObject.overrideVerificationSourceSelectedOption = true;
        } else {
          checkObject[additionalAntecedantName] = event.target.value;
        }
      } else {
        const checkFields = checkObject.checkFields;

        const secondAntecedantId = parseInt(
          this.getAntecedantIdForSyncedInput(antecedantId)
        );
        if (!secondAntecedantId) {
          return;
        }
        const object = checkFields.find(
          (object) => object.antecedantId === secondAntecedantId
        );
        object.antecedantValue = event.target.value;
      }
    }
    this.setState({ checkList: checkList });
  };

  render() {
    const {
      caseDetails,
      isCaseHistoryModalOpen,
      isCheckHistoryModalOpen,
      pvtId,
      isLoading,
      isInsuffEscalationModalOpen,
      insuffEscaltionObject,
      isEscalationModalOpen,
      escalationObject,
      personalData = {},
      packages,
      selectedPackage,
      checkList,
      antecedantIdNameMap,
      arsObj,
      selectedLocation,
      location,
      caseReceivedTime,
      activeDocId,
    } = this.state;
    const isCaseInsuff =
      this.state.caseDetails.caseStatus === constants.caseStatus.INSUFFICIENT;
    const isCaseComplete =
      this.state.caseDetails.caseStatus === constants.caseStatus.QC_COMPLETE;
    return (
      <div className="container-fluid p-0 h-100">
        <LoadingScreen
          isLoading={isLoading || this.state.isSaving}
        ></LoadingScreen>
        <main>
          <div className="container-fluid pt-3">
            {!isLoading ? (
              <div>
                <BUCKETTIMER
                  userId={this.props.userInfo.userId}
                  pvtId={pvtId}
                  status={caseDetails && caseDetails.caseStatus}
                  getCaseDetail={this.getCaseDetail}
                  userName={this.props.userInfo.userFirstName}
                  verificationType={caseDetails.verificationType}
                  handleSubmittedChecksToggleChange={
                    this.handleSubmittedChecksToggleChange
                  }
                  isToggleOn={this.state.showGapCheckButton}
                  isCse={this.state.isCSE}
                />
              </div>
            ) : (
              <div style={{ width: "100%", height: 60 }}></div>
            )}

            <div className="row">
              <div className="col-md-5 scrollable">
                {/* <form> */}
                <div
                  className={`card bg-white mb-3 ${
                    isCaseInsuff ? "border-red" : ""
                  }`}
                >
                  <h6
                    className={`${
                      isCaseInsuff ? "text-danger" : "text-muted"
                    } font-weight-bold`}
                    style={{ margin: "10px" }}
                  >
                    Case Details
                  </h6>
                  <div className="card-body pb-0">
                    <div className="card-text">
                      <div className="row justify-content-between small mb-2">
                        <div class="col-md-6">
                          <div className="form-group ng-star-inserted">
                            <label className="text-muted text-uppercase label-header">
                              Client Name
                            </label>
                            <input
                              type="text"
                              className={`form-control input-section text-primary ${
                                isCaseInsuff || this.props.isCSE
                                  ? "row-disabled"
                                  : ""
                              }`}
                              value="HCL Technology Pvt Ltd"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label className="text-muted text-uppercase label-header">
                            Process<sup className="staric-data">*</sup>
                          </label>
                          <div>
                            <Selectbox
                              option={packages}
                              defaultValue={
                                selectedPackage.value ? selectedPackage : null
                              }
                              callback={(selectedPackage) =>
                                this.handlePackageChange(selectedPackage)
                              }
                              isDisable={
                                this.props.isCSE || isCaseInsuff || arsObj.arsNo
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row justify-content-between small mb-1">
                        <div class="col-md-6">
                          <div class="form-group ng-star-inserted">
                            <label className="text-muted text-uppercase label-header">
                              Location<sup className="staric-data">*</sup>
                            </label>
                            <div>
                              <Selectbox
                                option={location}
                                defaultValue={selectedLocation}
                                callback={(data) =>
                                  this.setState({ selectedLocation: data })
                                }
                                isDisable={
                                  this.props.isCSE ||
                                  isCaseInsuff ||
                                  arsObj.arsNo
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group ng-star-inserted">
                            <label className="text-muted text-uppercase label-header">
                              ARS Number
                            </label>
                            <input
                              type="text"
                              className={`form-control input-section text-primary ${
                                isCaseInsuff || this.props.isCSE
                                  ? "row-disabled"
                                  : ""
                              }`}
                              value={arsObj.arsNo}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row justify-content-between small mb-1">
                        <div class="col-md-4">
                          <div class="form-group ng-star-inserted">
                            <label className="text-muted text-uppercase label-header">
                              Case Received On
                            </label>
                            <CustomInput
                              type={"Date"}
                              value={caseDetails.caseReceivedOn}
                              required={false}
                              onChange={(e) => {
                                let { caseDetails } = this.state;
                                caseDetails.caseReceivedOn = e.target.value
                                  .split("-")
                                  .reverse()
                                  .join("-");
                                this.setState({
                                  caseDetails,
                                });
                              }}
                              isDisable={
                                this.props.isCSE || isCaseInsuff || arsObj.arsNo
                              }
                            ></CustomInput>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group ng-star-inserted">
                            <label className="text-muted text-uppercase label-header">
                              SLA Start Date
                            </label>
                            <CustomInput
                              type={"Date"}
                              value={caseDetails.slaStartDate}
                              required={false}
                              isDisable={true}
                            ></CustomInput>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group ng-star-inserted">
                            <label className="text-muted text-uppercase label-header">
                              Case Created On
                            </label>
                            <CustomInput
                              type={"Date"}
                              value={caseDetails.arsCreatedAt}
                              required={false}
                              isDisable={true}
                            ></CustomInput>
                          </div>
                        </div>
                      </div>
                      <div class="row small mb-2">
                        <div class="col-md-4">
                          <div class="form-group ng-star-inserted">
                            <label className="text-muted text-uppercase label-header">
                              Case Received Time
                            </label>
                            <input
                              type="text"
                              className={`form-control input-section text-primary ${
                                isCaseInsuff || this.props.isCSE
                                  ? "row-disabled"
                                  : ""
                              }`}
                              value={caseReceivedTime}
                              onChange={(e) => {
                                let { caseReceivedTime } = this.state;
                                caseReceivedTime = e.target.value;
                                this.setState({ caseReceivedTime });
                              }}
                              disabled={
                                this.props.isCSE || isCaseInsuff || arsObj.arsNo
                              }
                            />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group ng-star-inserted">
                            <label className="text-muted text-uppercase label-header">
                              SLA Start Time
                            </label>
                            <CustomInput
                              type={"text"}
                              value={caseDetails.caseReceivedTime}
                              required={true}
                              isDisable={true}
                            ></CustomInput>
                            {/* <input
                              type="text"
                              className={`form-control input-section text-primary ${
                                isCaseInsuff || this.props.isCSE
                                  ? "row-disabled"
                                  : ""
                              }`}
                              value={caseDetails.caseReceivedTime}
                              disabled
                            /> */}
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group ng-star-inserted">
                            <label className="text-muted text-uppercase label-header">
                              Closure Date
                            </label>
                            <CustomInput
                              type={"Date"}
                              value={caseDetails.caseClosureDate}
                              required={false}
                              isDisable={true}
                            ></CustomInput>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          {this.state.arsObj.arsNo && (
                            <PopupMenu
                              raiseInsufficiency={this.raiseInsufficiency}
                              isCaseComplete={isCaseComplete}
                              raiseEscalation={this.raiseEscalation}
                              openCaseHistoryModel={this.openCaseHistoryModel}
                              isCSE={this.props.isCSE}
                              isCaseInsuff={isCaseInsuff}
                              arsNo={this.state.arsObj.arsNo}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    {
                      <div class="accordion" id="clientDetailsAccordion">
                        {/* clientDetailsAccordion */}
                        <ClientDetailAccordion
                          setLoadingValue={this.setLoadingValue}
                          selectedLocation={selectedLocation}
                          saveDeatils={this.saveDeatils}
                          userId={this.props.userInfo.userId}
                          pvtId={this.state.pvtId}
                          packageName={this.state.selectedPackage.label}
                          packageId={this.state.selectedPackage.value}
                          priorityFlag={this.state.caseDetails.priorityFlag}
                          personalData={this.state.personalData}
                          documentList={this.state.documentList}
                          caseDetails={this.state.caseDetails}
                          loadDocument={this.loadDocument}
                          callback={this.getCaseDetail}
                          getArs={this.getArs}
                          arsObj={this.state.arsObj}
                          isCSE={this.props.isCSE}
                          isCaseInsuff={isCaseInsuff}
                          isDocDisable={
                            this.props.isCSE || arsObj.arsNo || false
                          }
                        />
                      </div>
                    }
                  </div>
                </div>

                {/* checkList component */}
                {this.state.groupByCheckList.map((checkArr) => (
                  <div className="card bg-white mb-3" key={checkArr[0].checkId}>
                    <div className="card-body small">
                      <h6 className="text-muted font-weight-bold">
                        {checkArr[0].sourceVerification ||
                          checkArr[0].checkName}
                      </h6>
                      {checkArr.map((item, index) => (
                        <CheckCard
                          key={`${item.checkUID}`}
                          ref={(instance) => {
                            this[
                              `${item.checkId}-${item.checkNumber}`
                            ] = instance;
                          }}
                          isSubmitAllChecks={this.state.isSubmitAllChecks}
                          handleAntecedantSync={this.handleAntecedantSync}
                          checkNumber={item.checkNumber}
                          checkObject={item}
                          loadDocument={this.loadDocument}
                          pvtId={this.state.pvtId}
                          userInfo={this.props.userInfo}
                          selectedPackage={this.state.selectedPackage}
                          caseDetails={this.state.caseDetails}
                          handleCheckChange={(checkObject, isSaveCheckList) =>
                            this.handleCheckChange(checkObject, isSaveCheckList)
                          }
                          antecedantIdNameMap={antecedantIdNameMap}
                          openCheckHistoryModel={this.openCheckHistoryModel}
                          documents={this.state.documentList || []}
                          submitCheckComment={this.submitCheckComment}
                          selectedCheckUID={this.state.selectedCheckUID}
                          onCheckSelected={(checkUID) =>
                            this.setState({ selectedCheckUID: checkUID })
                          }
                          saveCheckList={this.saveCheckList}
                          isCSE={this.state.isCSE}
                          isLoading={(isLoading) =>
                            this.setState({ isLoading })
                          }
                        />
                      ))}
                    </div>
                  </div>
                ))}

                {checkList.length > 0 && ( //remove this condition
                  <div className="row justify-content-between mb-3">
                    <div class="col-auto">
                      <Toggle
                        callback={this.handleSubmittedChecksToggleChange}
                        isToggleOn={this.state.showGapCheckButton}
                      />
                    </div>
                    <div className="col-auto d-flex justify-content-end">
                      <div
                        className="transparent-borderless scroll-top-icon"
                        onClick={() => {
                          window.scroll({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <i
                          className="fa fa-arrow-up text-muted"
                          style={{ fontSize: "36px" }}
                        ></i>
                      </div>
                      <div className="row">
                        {!this.state.isCSE && this.state.showGapCheckButton && (
                          <div
                            className="btn btn-outline-primary text-uppercase btn-sm btn-radius-9 mr-2"
                            onClick={() => {
                              this.openGapcheck();
                            }}
                          >
                            Gap Check
                          </div>
                        )}

                        {!this.state.isCSE && (
                          <div
                            className="btn btn-outline-primary text-uppercase btn-sm btn-radius-9 mr-2"
                            onClick={() =>
                              this.setState({
                                isAddMoreCheckModalVisible: true,
                              })
                            }
                          >
                            <i className="fa fa-plus-circle"></i> Add Check
                          </div>
                        )}

                        <button
                          className="btn btn-primary fadeIn fourth text-uppercase btn-sm btn-radius-9 pl-4 pr-4 mr-2"
                          onClick={(e) => {
                            this.handleFormSubmit(e);
                          }}
                          disabled={this.state.isSaving}
                        >
                          {this.state.isSaving && (
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                          )}
                          Submit Case
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {/* </form> */}
              </div>

              {/* documents link button column */}
              <div className="col-md-7 position-relative scrollable">
                <div id="labels" className="row border labels-section">
                  <ul>
                    <li style={{ backgroundColor: "transparent" }}>
                      <FileInput
                        onChange={(file) => this.onDocumentSelected(file)}
                      />
                    </li>
                    {this.state.documentList.map((document) => (
                      <li
                        className={
                          "rounded" +
                          (activeDocId === document.id ? " active-doc" : "")
                        }
                      >
                        <div className="d-flex align-items-center">
                          <div
                            onClick={() => {
                              this.loadDocument(document.path, document.id);
                            }}
                          >
                            {document.type + "_" + document.id}
                          </div>
                          <button class="download-document-btn">
                            <a href={`/media/${document.path}`} download>
                              <i
                                class="fa fa-arrow-down"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <DocumentViewer
                  currentDocumentURL={this.state.currentDocumentURL}
                  currentDocumentType={this.state.currentDocumentType}
                  onIframeLoad={() =>
                    this.setState({ iframeDocumentLoading: false })
                  }
                  iframeDocumentLoading={this.state.iframeDocumentLoading}
                />
              </div>
            </div>
          </div>
        </main>

        {/* MODALS */}
        <AddMoreCheckModal
          isAddMoreCheckModalVisible={this.state.isAddMoreCheckModalVisible}
          closeModal={() =>
            this.setState({ isAddMoreCheckModalVisible: false })
          }
          addNewCheck={this.addNewCheck}
          getCheckIdForSyncedInput={this.getCheckIdForSyncedInput}
          checkListPerPackage={this.state.checkListPerPackage}
        />
        <ModalTransition>
          {isCaseHistoryModalOpen && (
            <ModalDialog>
              <CommentBox
                isCheck={false}
                pvtId={pvtId}
                userInfo={this.props.userInfo}
                getCommentHistory={this.getCaseCommentHistory}
                submitComment={this.submitCaseComment}
                closeCommentHistoryModal={() =>
                  this.setState({ isCaseHistoryModalOpen: false })
                }
                label="Case History"
                buttonName={
                  this.props.isCSE ? " Respond to escalation" : " Add Comment"
                }
                successMessage={
                  this.props.isCSE
                    ? "Responded to escalation successfully"
                    : "Comment added"
                }
                errorMessage={
                  this.props.isCSE
                    ? "There is some error in responding to escalation"
                    : "There is some error in adding the comment"
                }
              />
            </ModalDialog>
          )}
        </ModalTransition>

        <ModalTransition>
          {isCheckHistoryModalOpen && (
            <ModalDialog>
              <CommentBox
                isCheck={true}
                pvtId={pvtId}
                userInfo={this.props.userInfo}
                getCommentHistory={this.getCheckCommentHistory}
                submitComment={this.submitCheckComment}
                closeCommentHistoryModal={() =>
                  this.setState({
                    isCheckHistoryModalOpen: false,
                  })
                }
                label="Check History"
                buttonName="Add Comment"
                successMessage={"Comment added"}
                errorMessage={"There is some error in adding the comment"}
              />
            </ModalDialog>
          )}
        </ModalTransition>

        <ModalTransition>
          {isInsuffEscalationModalOpen && (
            <ModalDialog>
              <Escalation
                data={insuffEscaltionObject}
                isCheck={this.state.isCheckModal}
                callback={this.onInsufficiencyRaised}
                onClose={() =>
                  this.setState({ isInsuffEscalationModalOpen: false })
                }
                btnLabel="Raise Insufficiency"
              />
            </ModalDialog>
          )}
        </ModalTransition>
        <ModalTransition>
          {isEscalationModalOpen && (
            <ModalDialog>
              <Escalation
                data={escalationObject}
                callback={this.onEscalationRaised}
                onClose={() => this.setState({ isEscalationModalOpen: false })}
                btnLabel="Escalate"
              />
            </ModalDialog>
          )}
        </ModalTransition>
        <div
          className="modal fade"
          id="addCommentPopup"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
            role="document"
          >
            <div className="modal-content">
              {/* <form> */}
              <div className="modal-body small">
                <h5 className="text-muted"></h5>
                <hr className="border-top-0 border-bottom" />
                <div className="row  form-group">
                  <div className="col-md-12">
                    <textarea
                      className="form-control"
                      name="comment"
                      tabIndex="1"
                      className="form-control mb-3"
                      id=""
                      rows="5"
                      placeholder="Use this area to add comments for the request"
                    ></textarea>
                  </div>
                  <div className="text-danger">Comment is required.</div>
                </div>
                <div className="row">
                  <div className="col-md-12 d-flex align-items-center justify-content-between">
                    <button
                      className="btn btn-danger btn-sm float-left"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <span>
                      <span className="text-primary font-weight-bold">
                        today | date:'fullDate' [today | date:'h:mm:ss a']
                      </span>{" "}
                      comment initiated by fullName
                    </span>
                    <div className="form-group">
                      <button
                        className="btn btn-primary btn-sm float-right"
                        // type="submit"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="addMoreCheck"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content"></div>
          </div>
        </div>
        <div
          className="modal fade"
          id="addMoreAnticidant"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body small">
                <h5 className="text-primary">
                  Add More Anticidant for Tagging
                </h5>
                <hr className="border-top-0 border-bottom" />
                <div className="row">
                  <div className="col-md-12">
                    <button className="btn btn-primary mb-2 rounded btn-block transparent-borderless">
                      <span className="float-left wh20 btn bg-white rounded-circle p-1 d-flex align-items-center justify-content-center">
                        <i className="fa fa-plus icon-primary"></i>
                      </span>
                      <span>
                        {" "}
                        Check doc<sup>*</sup>
                      </span>
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <button
                      onClick={() =>
                        this.setState({ isAddMoreCheckModalVisible: false })
                      }
                      className="btn btn-outline-primary mb-2 rounded btn-block transparent-borderless"
                    >
                      <span
                        className="float-left wh20 btn bg-white rounded-circle p-1 d-flex align-items-center justify-content-center"
                        style={{ display: "none" }}
                      >
                        <i className="fa fa-plus icon-primary"></i>
                      </span>
                      <span> Anticidant.antecedantName</span>
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <button
                      className="btn btn-primary btn-sm float-right"
                      data-dismiss="modal"
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.login.userInfo,
  gapAddressArray: state.gapcheck.gapAddressArray,
  gapEmploymentEducationArray: state.gapcheck.gapEmploymentEducationArray,
  educationAddressMismatchArray: state.gapcheck.educationAddressMismatchArray,
  employmentAddressMismatchArray: state.gapcheck.employmentAddressMismatchArray,
});

const mapDispatchToProps = (dispatch) => ({
  updateAddressArray: (gapAddressArray) =>
    dispatch(updateAddressArray(gapAddressArray)),
  updateEmploymentEducationArray: (gapEmploymentEducationArray) =>
    dispatch(updateEmploymentEducationArray(gapEmploymentEducationArray)),
  updateEducationAddressMismatchArray: (educationAddressMismatchArray) =>
    dispatch(
      updateEducationAddressMismatchArray(educationAddressMismatchArray)
    ),
  updateEmploymentAddressMismatchArray: (employmentAddressMismatchArray) =>
    dispatch(
      updateEmploymentAddressMismatchArray(employmentAddressMismatchArray)
    ),
  deleteAction: (data) => dispatch(deleteAction(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BucketDetails)
);
