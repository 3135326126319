/**
 * duplicate ars modal component
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { modalManager } from "./../../../../utils/modalutils";
import DROPDOWN from "./../../../../components/dropdown/dropdown";
import LoadingScreen from "./../../../../components/loadingScreen/loadingScreen";
import "./duplicatearsnumber.scss";
import { Toast } from "../../../../utils/toastutils";

class DUPLICATEARSCASE extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      selectedArs: [],
      selectedType: {},
      option: [
        {
          value: "Duplicacy",
          label: "Create new case",
        },
        {
          value: "Internal error",
          label: "Internal error",
        },
        {
          value: "Client error",
          label: "Client error",
        },
        {
          value: "Insuff Fulfilment",
          label: "Insuff Fulfilment",
        },
      ],
    };
  }
  /**
   * this function use for map the ars numner and create new ars number
   */
  submit = async (e) => {
    e.preventDefault();
    const { selectedType, selectedArs } = this.state;
    const { data } = this.props;
    this.setState({ isLoading: true });
    await this.props.callback(selectedType, selectedArs, data);
    this.setState({ isLoading: false });
  };

  /**
   * this method use for select the ars number
   */
  selectedArsNumber = (e, data) => {
    const { duplicateCases } = this.props;
    e.preventDefault();
    data.isEnable = !data.isEnable;
    const arsArray = duplicateCases.filter((ob) => ob.isEnable);
    this.setState({ selectedArs: arsArray, duplicateCases });
  };

  render() {
    const { option, selectedType, selectedArs, isLoading } = this.state;
    const { data, duplicateCases } = this.props;
    return (
      <div className="duplicate-ars-block">
        <LoadingScreen isLoading={isLoading}></LoadingScreen>
        <div className="box">
          <DROPDOWN
            isDropdown={true}
            option={option}
            callback={(data, e) => {
              this.setState({ selectedType: data }, () => {
                const { selectedArs } = this.state;
                if (selectedArs.length) {
                  this.submit(e);
                } else {
                  Toast.info({
                    description: "select duplicate ars number",
                    header: "info",
                  });
                }
              });
            }}
          />
        </div>
        <div class="pt-4">
          <div class="row">
            <div class="col">
              <h5 class="text-muted">Please select an ARS no to map to</h5>
            </div>
          </div>
          <hr class="brdr-dashed" />
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-borderless">
                  {duplicateCases.map((data) => {
                    return (
                      <tr>
                        <td>
                          <input
                            type="checkbox"
                            name="mapNumber"
                            checked={data.isEnable}
                            onChange={(e) => {
                              this.selectedArsNumber(e, data);
                            }}
                          />
                        </td>
                        <td class="text-primary font-weight-bold">
                          {data.arsNumber}
                        </td>
                        <td class="text-muted" align="right">
                          <i>{data.arsGenerateDate}</i>
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
          <hr class="brdr-dashed" />
          <div class="row">
            <div class="col-md-12 text-center" style={{ marginBottom: "25px" }}>
              <button
                class="btn btn-outline-secondary btn-sm"
                onClick={() => {
                  modalManager.close({});
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.login.userInfo,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DUPLICATEARSCASE);
