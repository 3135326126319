import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import api from "./../../../apiBaseConfig";
import _groupBy from "lodash/groupBy";
import _cloneDeep from "lodash/cloneDeep";
import {
  PACKAGE_LIST_TCS,
  TCS_BUCKET_CASES,
  DROPDOWN_SEARCH_TCS,
  TCS_CHANGE_STATUS_CASE,
  GET_DOCUMENTS,
  CASE_HISTORY_COMMENT,
  SUBMIT_COMMENT,
  TCS_GET_CHECK_LIST,
  TCS_RAISE_CASE_INSUFF,
  TCS_CHECK_BY_PACKAGE,
  ANTECEDENT_BY_PACKAGE_TCS,
  TCS_FINAL_SUBMISSION_CASE,
  TCS_DOCUMENT_UPLOAD,
  LOCATION_LIST_TCS,
  GET_ACTION_LOG_HISTORY,
} from "./../../../apiurl";
import { deleteAction } from "./../../../components/buckettimer/state/timer.action";
import LoadingScreen from "./../../../components/loadingScreen/loadingScreen";
import CustomInput from "./../../../tcs/components/custumInput/custumInput";
import Selectbox from "./../../../components/Selectbox/selectbox";
import AsyncSelectBox from "./../../../components/asyncSelectBox/asyncSelectBox";
import ClientDetail from "./../../components/clientDetail/clientDetail";
import { getFileType, getOverlapDate } from "./../../../utils/commonutils";
import BUCKETTIMER from "../../components/bucketTimer/bucketTimer";
import _debounce from "lodash/debounce";
import { connect } from "react-redux";
import DocumentViewer from "./../../../components/documentViewer/documentViewer";
import PopupMenu from "./../../../components/popupMenu/popupMenu";
import Escalation from "./../../../components/Escalation/escalation";
import "./qcScreen.scss";
import FileInput from "../../../components/fileInput/fileInput";
import CaseHistory from "./../../../components/caseHistory/caseHistory";
import CheckListCard from "../../components/checkListCard/checkListCard";
import Document from "./../../components/documentTagPopup/documentTagPopup";
import Toggle from "./../../../screens/bucketDetails/components/toggle/toggle";
import { constants } from "./../../../constants";
import { Toast } from "../../../utils/toastutils";
import { Redirect } from "react-router-dom";
import {
  getTimeArray,
  isWithinTimePeriod,
  monthNameFromNumber,
  getCheckPriority,
} from "./../../../utils/commonutils";
import Gapcheck from "./../../components/gapcheck/gapcheck";
import { modalManager } from "../../../utils/modalutils";
import {
  updateAddressArray,
  updateEmploymentEducationArray,
  updateEducationAddressMismatchArray,
  updateEmploymentAddressMismatchArray,
} from "./../../../hcl/components/gapcheck/state/gapcheck.actions";
import { get } from "lodash";
class Qcscreen extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      packages: [],
      locationList: [],
      caseDetails: {},
      selectedPackage: {},
      selectedLocation: {},
      documentList: [],
      activeDocId: "",
      currentDocumentURL: "",
      currentDocumentType: "",
      checkListArray: [],
      groupCheckListArray: [],
      selectedCheckUID: null,
      isToggleOn: false,
      isFinalSubmit: false,
    };
  }

  componentDidMount = () => {
    this.getCaseDetails();
    this.getPackages();
    this.getDocumentList();
    this.getLocationList();
  };

  getLocationList = async () => {
    try {
      const response = await api.get(LOCATION_LIST_TCS);
      const list =
        response.data &&
        response.data.map((location) => {
          return { ...location, label: location.location_name };
        });
      this.setState({ locationList: list });
    } catch (e) {
      console.log(e);
    }
  };

  getCaseDetails = async () => {
    const { id } = this.props.match.params;
    this.setState({ isLoading: true });
    let { selectedLocation, selectedPackage } = this.state;
    try {
      const response = await api.get(`${TCS_BUCKET_CASES}${id}`);
      selectedLocation = { label: response.data.location, id: "" };
      selectedPackage = {
        label: response.data.package_name,
        value: response.data.package_id,
        // priorityFlag: response.data.priority_flag,
      };
      this.setState(
        {
          caseDetails: response.data,
          selectedLocation,
          selectedPackage,
        },
        () => {
          const { caseDetails } = this.state;
          if (caseDetails.bridge_ars_no) {
            this.getCheckList();
          }
        }
      );
    } catch (e) {
      console.log(e);
    }
    this.setState({ isLoading: false });
  };

  getCheckList = async () => {
    const { id } = this.props.match.params;
    try {
      const checkList = await api.get(`${TCS_GET_CHECK_LIST}${id}`);
      const formatCheckListArray =
        checkList &&
        checkList.data.map((check) => {
          return { ...check, priority: getCheckPriority(check.check_id) };
        });
      this.setState(
        {
          checkListArray: formatCheckListArray,
          selectedCheckUID: null,
          isToggleOn: false,
        },
        () => {
          this.getGroupCheckList();
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  getGroupCheckList = (checkArray = this.state.checkListArray) => {
    let groupByCheckList = _groupBy([...checkArray], "check_id");
    groupByCheckList = Object.values(groupByCheckList);
    groupByCheckList.sort((arr1, arr2) => arr1[0].priority - arr2[0].priority);
    this.setState({
      groupCheckListArray: Object.values(groupByCheckList),
    });
  };

  getDocumentList = async () => {
    const { id } = this.props.match.params;
    let { documentList } = this.state;
    try {
      const response = await api.get(`${GET_DOCUMENTS}?pvt_id=${id}`);
      documentList = response.data.map((documentObj) => {
        return {
          path: documentObj.gcs_blob_name,
          id: documentObj.id,
          gcspath: documentObj.gcs_signed_url,
          type: documentObj.name,
          documentType: documentObj.document_type,
        };
      });
      this.setState({ documentList });
    } catch (e) {
      console.log(e);
    }
  };

  getCountryLocationOptionBasedOnSearch = (
    searchString = "",
    callbackToAsyncSelect
  ) => {
    api
      .post(`${DROPDOWN_SEARCH_TCS}city/`, { search_string: searchString })
      .then((response) => {
        let locationArray = response.data;
        locationArray = locationArray.map((location) => ({
          label: location.city_name,
          value: location.city_id,
        }));
        callbackToAsyncSelect(locationArray);
      })
      .catch((err) =>
        console.log("error in fetching location based on search string", err)
      );
  };
  getPackages = () => {
    api
      .get(PACKAGE_LIST_TCS)
      .then((response) => {
        const packages = response.data;
        const selectPackages = packages.map((item) => ({
          label: item.process_name,
          value: item.process_id,
          // priorityFlag: item.priority_flag,
        }));
        this.setState({
          packages: selectPackages,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handlePackageChange = (selectedPackage) => {
    const body = {
      package_id: selectedPackage.value,
      package_name: selectedPackage.label,
      // priority_flag: selectedPackage.priorityFlag,
    };
    this.saveData(body, this.getCaseDetails);
  };

  saveDeatils = async (
    string,
    clientFormData,
    selectedDoc,
    updatedFormData = {}
  ) => {
    const { caseDetails, selectedLocation } = this.state;
    const body = {
      ...updatedFormData,
      date_of_initiation: caseDetails.date_of_initiation,
      time_of_initiation: caseDetails.time_of_initiation,
    };
    if (selectedLocation.label) {
      body.location = selectedLocation.label;
    }
    if (selectedDoc && selectedDoc.length) {
      body.case_documents = selectedDoc;
    }
    return this.saveData(body, () => {});
  };

  // saveEditCheckList = async (checkObject) => {
  //   const { checkListArray, selectedPackage } = this.state;
  //   let checkListData = _cloneDeep(checkListArray);
  //   const index = checkListArray.findIndex(
  //     (check) => check.check_uid === checkObject.check_uid
  //   );

  //   if (index !== -1) {
  //     checkListData[index] = checkObject;
  //     try {
  //       const body = { check_list_json: checkListData };
  //       const response = await this.saveData(body, () => {});
  //       this.getCheckList();
  //       return Promise.resolve(response);
  //     } catch (e) {
  //       console.log(e);
  //       return Promise.reject(e);
  //     }
  //   } else {
  //     console.log("cannot find index of the checkObject ");
  //     Promise.reject("cannot find index of the checkObject");
  //   }
  // };

  saveCheckList = async (
    checkObject,
    isSubmit = false,
    isCheckOverlap = false
  ) => {
    const { checkListArray, selectedPackage } = this.state;

    let checkListData = _cloneDeep(checkListArray);
    const index = checkListArray.findIndex(
      (check) => check.check_uid === checkObject.check_uid
    );

    let mirrorIndex = -1;
    let mirrorCheckObj = constants.mirrorCheckIds.find(
      (obj) => obj.checkId === checkListArray[index].check_id
    );
    if (mirrorCheckObj) {
      mirrorIndex = checkListArray.findIndex(
        (check) =>
          check.check_id === mirrorCheckObj.mirrorId &&
          check.check_number === checkListArray[index].check_number
      );
    }

    /**
     * overlapping functionolity
     */

    // let isOverLap = false;
    // let isOverLapString = "Overlapping DateFrom and DateTo with given checks ";
    // const checkIdsArray = [
    //   constants.checkIds.PREVIOUS_EMPLOYMENT_VERIFICATION_CHECK_ID,
    //   constants.checkIds.CURRENT_EMPLOYMENT_VERIFICATION_CHECK_ID,
    //   constants.checkIds.EDUCATION_W_CHECK_ID,
    //   constants.checkIds.EDUCATION_CHECK_ID,
    // ];
    // if (isCheckOverlap) {
    //   if (
    //     checkObject.check_id ===
    //       constants.checkIds.PREVIOUS_EMPLOYMENT_VERIFICATION_CHECK_ID ||
    //     checkObject.check_id ===
    //       constants.checkIds.CURRENT_EMPLOYMENT_VERIFICATION_CHECK_ID ||
    //     checkObject.check_id === constants.checkIds.EDUCATION_W_CHECK_ID ||
    //     checkObject.check_id === constants.checkIds.EDUCATION_CHECK_ID
    //   ) {
    //     checkListData.forEach((check) => {
    //       if (
    //         checkIdsArray.includes(check.check_id) &&
    //         checkObject.check_uid !== check.check_uid
    //       ) {
    //         const overLapcheck = getOverlapDate(check, checkObject);
    //         if (overLapcheck) {
    //           isOverLap = true;
    //           isOverLapString = isOverLapString + overLapcheck.checkName + ",";
    //         }
    //       }
    //     });
    //   }

    //   //for database check
    //   if (
    //     checkObject.check_id ===
    //       constants.checkIds
    //         .INDIA_COURT_RECORD_CHECK_THROUGH_LAW_FIRM_CHECK_ID ||
    //     checkObject.check_id ===
    //       constants.checkIds
    //         .INDIAN_POLICE_RECORD_VERIFICATION_THROUGH_LAW_FIRM_CHECK_ID
    //   ) {
    //     checkListData.forEach((check) => {
    //       if (
    //         checkObject.check_id === check.check_id &&
    //         checkObject.check_uid !== check.check_uid
    //       ) {
    //         const overLapcheck = getOverlapDate(check, checkObject);
    //         if (overLapcheck) {
    //           isOverLap = true;
    //           isOverLapString = isOverLapString + overLapcheck.checkName + ",";
    //         }
    //       }
    //     });
    //   }

    //   //  address logic for overlapping
    //   if (
    //     checkObject.check_id ===
    //     constants.checkIds.ADDRESS_VERIFICATION_CHECK_ID
    //   ) {
    //     const addressType = checkObject.check_fields.filter(
    //       (antecedantObj) =>
    //         antecedantObj.antecedent_id === constants.antecedantId.ADDRESS_TYPE
    //     );

    //     const addressTypeValueArray = [
    //       "Current Address",
    //       "Permanent Address",
    //       "Current & Permanent are same",
    //     ];
    //     if (
    //       addressType &&
    //       addressType.length &&
    //       addressType[0].antecedent_value &&
    //       !addressTypeValueArray.includes(addressType[0].antecedent_value)
    //     ) {
    //       checkListData.forEach((check) => {
    //         if (
    //           checkObject.check_id === check.check_id &&
    //           checkObject.check_uid !== check.check_uid
    //         ) {
    //           const overLapcheck = getOverlapDate(check, checkObject);
    //           if (overLapcheck) {
    //             isOverLap = true;
    //             isOverLapString =
    //               isOverLapString + overLapcheck.checkName + ",";
    //           }
    //         }
    //       });
    //     }
    //   }
    // }
    // if (isOverLap) {
    //   alert(
    //     isOverLapString + "\n" + "raise insufficiency in overlapping checks"
    //   );
    //   return Promise.reject({});
    // }

    /**
     * end
     */

    /**
     *  conditional submit check with form 16 and other check
     */

    // if (isSubmit) {
    //   if (
    //     checkObject.check_id ===
    //       constants.checkIds.BANK_VERIFICATION_CHECK_ID ||
    //     checkObject.check_id ===
    //       constants.checkIds.FORM_16_VERIFICATION_CHECK_ID ||
    //     checkObject.check_id ===
    //       constants.checkIds.STATEMENT_OF_FACT_EMPLOYMENT_CHECK_ID
    //   ) {
    //     const parentCheck = checkListData.filter(
    //       (check) => check.check_uid === checkObject.parent_check_uid
    //     );
    //     if (parentCheck && parentCheck.length) {
    //       if (!parentCheck[0].is_frozen) {
    //         Toast.error({
    //           description: `please submit ${parentCheck[0].check_name} check before this check`,
    //         });
    //         return Promise.reject({});
    //       }
    //     }
    //   }
    // }
    /**
     * end
     */

    //NA value implimentation
    if (checkObject.is_frozen && checkObject.check_fields) {
      let checkFieldsData = checkObject.check_fields;
      checkFieldsData.forEach((checkFieldsDataObj) => {
        if (checkFieldsDataObj.antecedent_type !== "Verified") {
          if (!checkFieldsDataObj.antecedent_value) {
            if (checkFieldsDataObj.antecedent_input_type !== "Date") {
              checkFieldsDataObj.antecedent_value = "NA";
            }
          }
        }
      });
    }

    if (index !== -1) {
      checkListData[index] = checkObject;
      // if (
      //   mirrorIndex !== -1 &&
      //   checkListData[mirrorIndex] &&
      //   !checkListData[mirrorIndex].is_frozen
      // ) {
      //   let antecedentIndexArray = [];
      //   let antecedentMirrorIndexArray = [];
      //   for (var i in checkObject.check_fields) {
      //     if (
      //       mirrorCheckObj.antecedentIdArray.includes(
      //         checkObject.check_fields[i].antecedent_id
      //       )
      //     ) {
      //       antecedentIndexArray.push(i);
      //     }
      //   }
      //   for (var j in checkListData[mirrorIndex].check_fields) {
      //     if (
      //       mirrorCheckObj.mirrorAntecedentIdArray.includes(
      //         checkListData[mirrorIndex].check_fields[j].antecedent_id
      //       )
      //     ) {
      //       antecedentMirrorIndexArray.push(j);
      //     }
      //   }
      //   for (var k in antecedentIndexArray) {
      //     if (
      //       checkListData[mirrorIndex].check_fields[
      //         antecedentMirrorIndexArray[k]
      //       ] &&
      //       checkObject.check_fields[antecedentIndexArray[k]]
      //     ) {
      //       checkListData[mirrorIndex].check_fields[
      //         antecedentMirrorIndexArray[k]
      //       ].antecedent_value =
      //         checkObject.check_fields[
      //           antecedentIndexArray[k]
      //         ].antecedent_value;
      //     }
      //   }
      //   checkListData[mirrorIndex].date_from = checkObject.date_from;
      //   checkListData[mirrorIndex].date_to = checkObject.date_to;
      //   if (checkObject.additional_tenures) {
      //     checkListData[mirrorIndex].additional_tenures =
      //       checkObject.additional_tenures;
      //   }
      //   if (
      //     checkObject.verification_source &&
      //     checkObject.verification_source_location
      //   ) {
      //     checkListData[mirrorIndex].verification_source =
      //       checkObject.verification_source;
      //     checkListData[mirrorIndex].verification_source_location =
      //       checkObject.verification_source_location;
      //   }
      // }
      try {
        const body = { check_list_json: checkListData };
        const response = await this.saveData(body, () => {});
        this.getCheckList();
        return Promise.resolve(response);
      } catch (e) {
        console.log(e);
        return Promise.reject(e);
      }
    } else {
      console.log("cannot find index of the checkObject ");
      Promise.reject("cannot find index of the checkObject");
    }
  };

  saveData = async (body, callback, comment = "update successfully") => {
    const { id } = this.props.match.params;
    try {
      const result = await api.patch(`${TCS_CHANGE_STATUS_CASE}${id}/`, body);
      if (comment) {
        Toast.success({ description: comment });
      }
      callback();
      return Promise.resolve(result);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  };

  setLoadingValue = (value) => {
    this.setState({ isLoading: value });
  };

  /**
   * this is use for getting case history comment
   */
  getCaseCommentHistory = async (pvtId) => {
    let response;
    try {
      response = await api.get(`${CASE_HISTORY_COMMENT}?pvt_id=${pvtId}`);
      const comments = response.data.map((commentObj) => {
        return {
          commentId: commentObj.id,
          createdAt: commentObj.created_at,
          comment: commentObj.comment && commentObj.comment.message,
          action: commentObj.name,
          userName: "",
          roleName: "",
        };
      });
      //response = response.data.comments;
      return Promise.resolve(comments);
    } catch (error) {
      console.log("error", error);
      return Promise.reject(error);
    }
  };

  openCaseHistoryModel = () => {
    const { id } = this.props.match.params;
    const { userInfo } = this.props;
    modalManager.open({
      isHeader: false,
      component: () => (
        <CaseHistory
          isCheck={false}
          pvtId={id}
          userInfo={userInfo}
          getCommentHistory={() => {
            return this.getCaseCommentHistory(id);
          }}
          label="Case History"
          buttonName="Add To Comment"
          submitComment={this.submitCaseComment}
          closeCommentHistoryModal={() => modalManager.close({})}
          successMessage={"comment added successfully"}
          errorMessage={"There is some error in adding comment"}
        />
      ),
    });
  };

  raiseEscalation = () => {
    const { userInfo } = this.props;
    const { id } = this.props.match.params;
    const escalationObject = {
      label: "esclating",
      userName: `${userInfo.userFirstName} ${userInfo.userLastName}`,
      userId: userInfo.userId,
      status: constants.caseStatus.ESCALATION_RAISED,
      action: "Escalation Raised",
      id: id,
    };
    modalManager.open({
      isHeader: false,
      component: () => (
        <Escalation
          data={escalationObject}
          callback={this.changeStatus}
          btnLabel="Esclation"
        />
      ),
    });
  };

  raiseInsufficiency = () => {
    const { userInfo } = this.props;
    const { id } = this.props.match.params;
    const escalationObject = {
      label: "raise insuffiency",
      userName: `${userInfo.userFirstName} ${userInfo.userLastName}`,
      userId: userInfo.userId,
      status: constants.caseStatus.INSUFFICIENT,
      action: "Insufficient",
      id: id,
    };
    modalManager.open({
      isHeader: false,
      component: () => (
        <Escalation
          data={escalationObject}
          callback={this.raiseInsuff}
          btnLabel="raise insuffiency"
        />
      ),
    });
  };

  raiseInsuff = async (obj) => {
    const body = {
      pvt_id: obj.id,
      comment: obj.comment,
    };
    try {
      const response = await api.post(TCS_RAISE_CASE_INSUFF, body);
      Toast.success({
        description: "insuff raised successfully",
      });
      modalManager.close({});
      this.getCaseDetails();
    } catch (e) {
      console.log(e);
    }
  };

  changeStatus = async (obj) => {
    const { userInfo } = this.props;
    const body = {
      status: obj.status,
      comment: obj.comment,
    };
    try {
      const commentObj = {
        comment: obj.comment,
        pvtId: obj.id,
        email: userInfo.email,
        action: obj.action,
      };
      const responseObj = await this.submitCaseComment(commentObj);
      const response = await api.patch(
        `${TCS_CHANGE_STATUS_CASE}${obj.id}/`,
        body
      );
      Toast.success({
        description: "change status successfully",
      });
      modalManager.close({});
      this.getCaseDetails();
    } catch (e) {
      console.log(e);
    }
  };
  submitCaseComment = async (commentObject) => {
    const { userInfo } = this.props;
    const { caseDetails } = this.state;
    try {
      const action = await api.post(
        `${CASE_HISTORY_COMMENT}?pvt_id=${commentObject.pvtId}`,
        {
          pvt_id: commentObject.pvtId,
          name: commentObject.action
            ? commentObject.action
            : "Comment Added on case",
          created_by: commentObject.email,
        }
      );
      const actionId = action.data.id;
      const response = await api.post(SUBMIT_COMMENT, {
        message: commentObject.comment,
        action: actionId,
        bridge_case_id: caseDetails.bridge_case_id,
      });
      return Promise.resolve(response);
    } catch (error) {
      console.log("error", error);
      return Promise.reject(error);
    }
  };

  handleSubmittedChecksToggleChange = (showOnlySubmittedChecks) => {
    const { isToggleOn, checkListArray } = this.state;
    const check = JSON.parse(JSON.stringify(checkListArray));
    const submittedChecks = check.filter(
      (check) => check.is_frozen && !check.is_disabled
    );
    this.setState({ isToggleOn: !isToggleOn }, () => {
      const { isToggleOn } = this.state;
      if (isToggleOn) {
        this.getGroupCheckList(submittedChecks);
      } else {
        this.getGroupCheckList(check);
      }
    });
  };

  getDocumentType = (url) => {
    const type = url.split("/");
    return type[type.length - 1].toLowerCase();
  };

  //REACT_APP_FILE_URL
  loadDocument = (path, gcspath, id = "") => {
    const fileURL = `${gcspath}`;
    const fileType = this.getDocumentType(path);
    if (fileType === "doc") {
      this.setState({ iframeDocumentLoading: true });
    } else {
      this.setState({ iframeDocumentLoading: false });
    }
    this.setState({
      currentDocumentURL: fileURL,
      currentDocumentType: fileType,
      activeDocId: id,
    });
  };

  addCheckWraper = async (id) => {
    const { selectedPackage, checkListArray } = this.state;
    try {
      const check = await api.get(
        `${ANTECEDENT_BY_PACKAGE_TCS}${selectedPackage.value}/${id}/`
      );
      const groupCheckArray =
        checkListArray.filter(
          (checkObject) => checkObject.check_id === check.data.check_id
        ) || [];
      check.data.check_name = `${check.data.check_name}(${
        groupCheckArray.length + 1
      })`;
      check.data.check_number = groupCheckArray.length + 1;
      return Promise.resolve(check.data);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  };
  addCheck = async (data) => {
    const { selectedPackage, checkListArray } = this.state;
    try {
      let updateCheckListArray = JSON.parse(JSON.stringify(checkListArray));
      const check = await this.addCheckWraper(data.check_id);
      updateCheckListArray.push(check);

      // if (
      //   data.check_id === constants.checkIds.ADDRESS_VERIFICATION_CHECK_ID ||
      //   data.check_id ===
      //     constants.checkIds.INDIA_COURT_RECORD_CHECK_THROUGH_LAW_FIRM_CHECK_ID
      // ) {
      //   const id =
      //     constants.checkIds.ADDRESS_VERIFICATION_CHECK_ID === data.check_id
      //       ? constants.checkIds
      //           .INDIA_COURT_RECORD_CHECK_THROUGH_LAW_FIRM_CHECK_ID
      //       : constants.checkIds.ADDRESS_VERIFICATION_CHECK_ID;
      //   const mirrorCheck = await this.addCheckWraper(id);
      //   updateCheckListArray.push(mirrorCheck);
      // }

      /**
       * add multiple check with previous employment check and current employment
       */
      // if (
      //   data.check_id ===
      //     constants.checkIds.CURRENT_EMPLOYMENT_VERIFICATION_CHECK_ID ||
      //   data.check_id ===
      //     constants.checkIds.PREVIOUS_EMPLOYMENT_VERIFICATION_CHECK_ID
      // ) {
      //   let formCheck = await this.addCheckWraper(
      //     constants.checkIds.FORM_16_VERIFICATION_CHECK_ID
      //   );
      //   formCheck.parent_check_uid = check.check_uid;
      //   updateCheckListArray.push(formCheck);

      //   let bankVerificationCheck = await this.addCheckWraper(
      //     constants.checkIds.BANK_VERIFICATION_CHECK_ID
      //   );
      //   bankVerificationCheck.parent_check_uid = check.check_uid;
      //   updateCheckListArray.push(bankVerificationCheck);

      //   let statementFactCheck = await this.addCheckWraper(
      //     constants.checkIds.STATEMENT_OF_FACT_EMPLOYMENT_CHECK_ID
      //   );
      //   statementFactCheck.parent_check_uid = check.check_uid;
      //   updateCheckListArray.push(statementFactCheck);
      // }
      /**
       * end
       */

      const body = { check_list_json: updateCheckListArray };
      const response = await this.saveData(
        body,
        () => {},
        "add check successfully"
      );
      this.getCheckList();
    } catch (e) {
      console.log(e);
    }
  };

  openCheckPopup = async () => {
    const { selectedPackage } = this.state;
    try {
      const response = await api.get(
        `${TCS_CHECK_BY_PACKAGE}${selectedPackage.value}/`
      );
      const documentCheck = response.data.map((checkObj) => {
        return { ...checkObj, bridge_name: checkObj.check_name };
      });
      modalManager.open({
        isHeader: false,
        width: "small",
        component: () => (
          <Document
            document={documentCheck}
            callback={(data) => {
              this.addCheck(data);
            }}
          />
        ),
      });
    } catch (e) {
      console.log(e);
    }
  };

  onDocumentSelected = (file) => {
    const { id } = this.props.match.params;
    if (!file) {
      return;
    }
    let formdata = new FormData();
    formdata.append("document", file, file.name);
    formdata.append("pvt_id", id);
    api
      .post(TCS_DOCUMENT_UPLOAD, formdata)
      .then(() => {
        this.getDocumentList();
        this.getCaseDetails();
      })
      .catch((err) => console.log("error in uploading doucment", err));
  };

  handleFormSubmit = async () => {
    let isGapcheckSubmit = true;
    const { id } = this.props.match.params;
    const { userInfo } = this.props;
    const { checkListArray } = this.state;
    const body = {
      pvt_id: id,
    };

    const actionHistoryBody = {
      pvt_id: id,
      check_name: "NA(Case submit)",
      user_name: userInfo.userFirstName,
      user_action: "Final Submit",
      action_taken_by_user: "Ok",
      user_prompt: "Final case submitted by user ",
    };

    //null antecedantValue initialized with NA
    checkListArray.forEach((chechdata) => {
      if (
        chechdata.check_id === constants.checkIds.GAP_CHECK_ID &&
        !chechdata.is_frozen
      ) {
        isGapcheckSubmit = false;
      }

      // let checkFieldsData = chechdata.check_fields;
      // checkFieldsData.forEach((checkFieldsDataObj) => {
      //   if (checkFieldsDataObj.antecedent_type !== "Verified") {
      //     if (!checkFieldsDataObj.antecedent_value) {
      //       if (checkFieldsDataObj.antecedent_input_type !== "Date") {
      //         checkFieldsDataObj.antecedent_value = "NA";
      //       }
      //     }
      //   }
      // });
    });
    // const checkListBody = { check_list_json: checkListArray };

    if (isGapcheckSubmit) {
      try {
        this.setState({ isFinalSubmit: true });
        const response = await api.post(TCS_FINAL_SUBMISSION_CASE, body);
        // await api.post(GET_ACTION_LOG_HISTORY, actionHistoryBody);
        // const res = await this.saveData(checkListBody, () => {}, "");
        Toast.success({ description: "final submit successfully" });
        this.props.deleteAction({ pvtId: id });
        this.props.history.push("/qcdashboard");
      } catch (e) {
        this.setState({ isFinalSubmit: false });
        console.log(e);
      }
    } else {
      Toast.info({
        hideAfter: 6,
        description: `Please ensure that you have submitted or disabled the gap checks raised by the
      system`,
        title: "info",
      });
    }
  };

  getSubmitCheck = () => {
    const { checkListArray } = this.state;
    const submitCheck = checkListArray.filter(
      (check) =>
        check.is_frozen && !check.is_disabled && !check.is_insufficiency_raised
    );
    return submitCheck;
  };

  /**
   * gap check functionality
   */
  getAddressesForGapCheck = () => {
    const normalSubmittedChecks = this.getSubmitCheck();
    let submittedAddressesChecks = normalSubmittedChecks.filter(
      (check) =>
        check.check_id === constants.checkIds.ADDRESS_VERIFICATION_CHECK_ID
    );

    const multipleTenures = [];
    for (let check of submittedAddressesChecks) {
      if (check.additional_tenures && check.additional_tenures.length) {
        check.additional_tenures.forEach((tenure) => {
          const newCheck = _cloneDeep(check);
          newCheck.date_from = tenure.from;
          newCheck.date_to = tenure.to;
          newCheck.from2 = "";
          newCheck.to2 = "";
          multipleTenures.push(newCheck);
        });
      }
    }
    submittedAddressesChecks = [
      ...submittedAddressesChecks,
      ...multipleTenures,
    ];
    let formattedAddressesCheck = submittedAddressesChecks.map((check) => ({
      from: check.date_from,
      to: check.date_to,
      location: {
        address:
          check.verification_source_location &&
          check.verification_source_location.label,
        locationId:
          check.verification_source_location &&
          check.verification_source_location.value,
      },
      verificationSource: check.verification_source || {},
      checkNumber: check.check_number,
      checkName: check.check_name,
      checkUID: check.check_uid,
    }));
    formattedAddressesCheck = formattedAddressesCheck
      .filter((check) => isWithinTimePeriod(check.to))
      .sort((check, check2) => new Date(check.from) - new Date(check2.from));
    return formattedAddressesCheck;
  };

  getHighestEducationCheckWithinTimePeriod = () => {
    const normalSubmittedChecks = this.getSubmitCheck();
    let submittedEducationChecks = normalSubmittedChecks
      .filter(
        (check) =>
          check.check_id === constants.checkIds.EDUCATION_CHECK_ID ||
          check.check_id === constants.checkIds.EDUCATION_W_CHECK_ID
      )
      .filter((check) => isWithinTimePeriod(check.date_to));
    let highestDate = new Date(null); // 1 jan 1970
    let highestEducationCheck = null;
    submittedEducationChecks.forEach((check) => {
      if (new Date(check.date_to) > highestDate) {
        highestDate = new Date(check.date_to);
        highestEducationCheck = check;
      }
    });

    let formattedHighestEducationCheck = null;
    if (highestEducationCheck) {
      formattedHighestEducationCheck = {
        from: highestEducationCheck.date_from,
        to: highestEducationCheck.date_to,

        location: {
          address:
            highestEducationCheck.verification_source_location &&
            highestEducationCheck.verification_source_location.label,
          locationId:
            highestEducationCheck.verification_source_location &&
            highestEducationCheck.verification_source_location.value,
        },
        verificationSource: highestEducationCheck.verification_source,
        checkNumber: highestEducationCheck.check_number,
        checkName: highestEducationCheck.check_name,
        checkUID: highestEducationCheck.check_uid,
      };
    }
    return formattedHighestEducationCheck;
  };

  getEmploymentListForGapCheck = () => {
    const normalSubmittedChecks = this.getSubmitCheck();

    let submittedEmploymentChecks = normalSubmittedChecks
      .filter(
        (check) =>
          check.check_id ===
            constants.checkIds.PREVIOUS_EMPLOYMENT_VERIFICATION_CHECK_ID ||
          check.check_id ===
            constants.checkIds.CURRENT_EMPLOYMENT_VERIFICATION_CHECK_ID
      )
      .filter((check) => isWithinTimePeriod(check.date_to))
      .sort(
        (check, check2) =>
          new Date(check.date_from) - new Date(check2.date_from)
      );
    const formattedEmploymentChecks = submittedEmploymentChecks.map(
      (check) => ({
        from: check.date_from,
        to: check.date_to,
        location: {
          address:
            check.verification_source_location &&
            check.verification_source_location.label,
          locationId:
            check.verification_source_location &&
            check.verification_source_location.value,
        },
        verificationSource: check.verification_source,
        checkNumber: check.check_number,
        checkName: check.check_name,
        checkUID: check.check_uid,
      })
    );

    return formattedEmploymentChecks;
  };

  raiseCheckFromGap = async (updateCheckArray, comment) => {
    const { checkListArray } = this.state;
    let checkListData = _cloneDeep(checkListArray);
    updateCheckArray.forEach((obj) => {
      checkListData.push(obj);
    });
    const body = { check_list_json: checkListData };
    try {
      const response = await this.saveData(body, () => {}, comment);
      this.getCheckList();
      return Promise.resolve(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  setDateFromToHelper = (dateFrom, dateTo) => {
    const [yearFrom, monthFrom] = dateFrom.split("-");
    const periodFrom = `${monthNameFromNumber(monthFrom)},${yearFrom}`;
    const [yearTo, monthTo] = dateTo.split("-");
    const periodTo = `${monthNameFromNumber(monthTo)},${yearTo}`;
    let fromToDuration = periodFrom + "-" + periodTo;
    return fromToDuration;
  };
  setPeriodOfStayValue = (checkObj) => {
    const { selectedPackage } = this.state;
    let fromToDurationObj = constants.fromToDuration.find(
      (obj) => obj.checkId === checkObj.check_id
    );
    /*Exception for TSS Standard */
    if (selectedPackage.value === 3987 && fromToDurationObj.checkId === 90) {
      fromToDurationObj.antecedentId = 1674;
    }
    for (var i in checkObj.check_fields) {
      if (
        checkObj.check_fields[i].antecedent_id ===
        fromToDurationObj.antecedentId
      ) {
        checkObj.check_fields[i].antecedent_value = this.setDateFromToHelper(
          checkObj.date_from,
          checkObj.date_to
        );
      }
    }
    return checkObj;
  };

  getUpdatedCheck = (checkObject, fromDate, toDate, comment) => {
    const { userInfo } = this.props;
    checkObject.date_from = fromDate;
    checkObject.date_to = toDate;
    checkObject = this.setPeriodOfStayValue(checkObject);
    let checkHistory = [...checkObject.comments];
    const commentObj = {
      comment: comment,
      special_comment: true,
      user_name: userInfo.userFirstName,
      created_at: new Date(),
      user_id: userInfo.userId,
    };
    checkHistory.push(commentObj);
    let updatedCheckObj = {
      ...checkObject,
      comments: checkHistory,
      is_frozen: true,
      is_insufficiency_raised: true,
    };
    return updatedCheckObj;
  };

  updateGapcheck = (checkObj, fromDate, toDate, gap) => {
    for (var i in checkObj.check_fields) {
      switch (checkObj.check_fields[i].antecedent_id) {
        case constants.antecedantId.GAP_PERIOD_FORM:
          checkObj.check_fields[i].antecedent_value = fromDate;
          break;
        case constants.antecedantId.GAP_PERIOD_TO:
          checkObj.check_fields[i].antecedent_value = toDate;
          break;
        case constants.antecedantId.GAP_TYPE:
          checkObj.check_fields[i].antecedent_value =
            gap.gapType === constants.EDUCATION_TO_EMPLOYMENT_GAP
              ? "Between Education to Employment"
              : "Between two Employment";
          break;
        case constants.antecedantId.GAP_BETWEEN:
          checkObj.check_fields[i].antecedent_value = gap.gapBetweenForGapCheck;
          break;
        default:
          break;
      }
    }
    return checkObj;
  };
  /**
   * main gap raise check functionality
   */
  handleGapRaiseInsuff = async (
    gap,
    comment = "test",
    fromDate,
    toDate,
    gapObject
  ) => {
    const { userInfo } = this.props;
    console.log(comment, "fff", gap);
    let checkIdArray = [];
    let updateCheckListArray = [];
    if (
      gap.gapType === constants.ADDRESS_TO_ADDRESS_GAP ||
      gap.gapType === constants.ADDRESS_MISMATCH_GAP
    ) {
      checkIdArray = [
        constants.checkIds.ADDRESS_VERIFICATION_CHECK_ID,
        constants.checkIds.INDIA_COURT_RECORD_CHECK_THROUGH_LAW_FIRM_CHECK_ID,
      ];

      for (let [index, checkId] of checkIdArray.entries()) {
        try {
          let checkObject = await this.addCheckWraper(checkId);
          checkObject = this.getUpdatedCheck(
            checkObject,
            fromDate,
            toDate,
            comment
          );
          updateCheckListArray.push(checkObject);
        } catch (e) {
          return Promise.reject(e);
        }
      }
      try {
        await this.raiseCheckFromGap(
          updateCheckListArray,
          "Check Insufficiency raised successfully"
        );

        if (gap.gapType === constants.ADDRESS_TO_ADDRESS_GAP) {
          const gapAddressArray = this.props.gapAddressArray;
          gapAddressArray.forEach((addressObject) => {
            if (addressObject.id === gapObject.id) {
              addressObject.status = constants.GAP_OBJECT_STATUS_CHECK_RAISED;
            }
          });
          this.props.updateAddressArray(gapAddressArray);
        } else {
          let educationAddressMismatchArray = this.props
            .educationAddressMismatchArray;
          educationAddressMismatchArray.forEach((object) => {
            if (object.id === gapObject.id) {
              object.status = constants.GAP_OBJECT_STATUS_CHECK_RAISED;
            }
          });
          let employmentAddressMismatchArray = this.props
            .employmentAddressMismatchArray;
          employmentAddressMismatchArray.forEach((object) => {
            if (object.id === gapObject.id) {
              object.status = constants.GAP_OBJECT_STATUS_CHECK_RAISED;
            }
          });

          this.props.updateEducationAddressMismatchArray(
            educationAddressMismatchArray
          );
          this.props.updateEmploymentAddressMismatchArray(
            employmentAddressMismatchArray
          );
        }
        modalManager.close({});
        return Promise.resolve("ddd");
      } catch (e) {
        return Promise.reject(e);
      }
    } else if (
      gap.gapType === constants.EDUCATION_TO_EMPLOYMENT_GAP ||
      gap.gapType === constants.EMPLOYMENT_TO_EMPLOYMENT_GAP
    ) {
      checkIdArray = [constants.checkIds.GAP_CHECK_ID];
      for (let checkId of checkIdArray) {
        try {
          let checkObject = await this.addCheckWraper(checkId);
          checkObject = this.updateGapcheck(checkObject, fromDate, toDate, gap);
          updateCheckListArray.push(checkObject);
        } catch (e) {
          return Promise.reject(e);
        }
      }
      try {
        await this.raiseCheckFromGap(
          updateCheckListArray,
          "gap raise successfully"
        );
        const gapEmploymentEducationArray = this.props
          .gapEmploymentEducationArray;
        gapEmploymentEducationArray.forEach((object) => {
          if (object.id === gapObject.id) {
            object.status = constants.GAP_OBJECT_STATUS_CHECK_RAISED;
          }
        });
        this.props.updateEmploymentEducationArray(gapEmploymentEducationArray);
        modalManager.close({});
        return Promise.resolve("fff");
      } catch (e) {
        return Promise.reject();
      }
    }
  };

  openGapcheck = () => {
    const { caseDetails } = this.state;
    modalManager.open({
      width: "100%",
      isHeader: true,
      component: () => {
        return (
          <Gapcheck
            addresses={this.getAddressesForGapCheck()}
            employmentList={this.getEmploymentListForGapCheck()}
            highestEducationCheck={this.getHighestEducationCheckWithinTimePeriod()}
            pvtId={caseDetails.id}
            userId={this.props.userInfo.userId}
            email={this.props.userInfo.email}
            caseId={caseDetails.bridge_case_id}
            caseReceivedOn={caseDetails.date_of_initiation}
            handleGapRaiseInsuff={this.handleGapRaiseInsuff}
          />
        );
      },
    });
  };

  /**
   * gap check end
   */

  render() {
    const {
      isLoading,
      packages,
      caseDetails,
      selectedPackage,
      selectedLocation,
      documentList,
      activeDocId,
      currentDocumentURL,
      currentDocumentType,
      groupCheckListArray,
      checkListArray,
      isToggleOn,
      isFinalSubmit,
      locationList,
    } = this.state;
    const { userInfo } = this.props;
    const isCSE =
      this.props.location.state && this.props.location.state.isCSE
        ? this.props.location.state.isCSE
        : false;
    const isCaseInsuff =
      caseDetails.status === constants.caseStatus.INSUFFICIENT;
    const isCaseComplete =
      caseDetails.status === constants.caseStatus.QC_COMPLETE;
    const isEscalation =
      caseDetails.status === constants.caseStatus.ESCALATION_RAISED;

    if (isCaseComplete) {
      return <Redirect to="/qcdashboard" />;
    }
    return (
      <div className="qc-screen-block">
        {isLoading ? (
          <LoadingScreen isLoading={isLoading} />
        ) : (
          <div className="container-fluid">
            <div className="timer-section">
              <BUCKETTIMER
                userInfo={userInfo}
                userId={userInfo.userId}
                pvtId={this.props.match.params.id}
                status={caseDetails && caseDetails.status}
                bridgeCaseId={caseDetails.bridge_case_id || null}
                getCaseDetail={this.getCaseDetails}
                userName={userInfo.userFirstName}
                verificationType={caseDetails.verificationType}
                handleSubmittedChecksToggleChange={
                  this.handleSubmittedChecksToggleChange
                }
                documentList={documentList}
                loadDocument={this.loadDocument}
                caseDetails={caseDetails}
                isToggleOn={isToggleOn}
                isCse={isCSE}
              />
            </div>
            <div className="row">
              <div className="col-md-5 scrollable">
                <div
                  className={`card bg-white mb-3
                  ${isCaseInsuff ? "border-red" : ""}`}
                >
                  <h6
                    className={`${
                      isCaseInsuff ? "text-danger" : "text-muted"
                    } font-weight-bold`}
                    style={{ margin: "10px" }}
                  >
                    Case Details
                  </h6>
                  <div className="card-body pb-0">
                    <div className="card-text">
                      <div className="row justify-content-between small mb-2">
                        <div class="col-md-6">
                          <div className="form-group ng-star-inserted">
                            <label className="text-muted text-uppercase label-header">
                              Client Name
                              <sup className="staric-data">*</sup>
                            </label>
                            <CustomInput
                              type={"text"}
                              value={userInfo.clientName}
                              required={true}
                              isDisable={true}
                              onChange={(e) => {}}
                            ></CustomInput>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label className="text-muted text-uppercase label-header">
                            Process<sup className="staric-data">*</sup>
                          </label>
                          <div>
                            <Selectbox
                              option={packages}
                              defaultValue={
                                selectedPackage.value ? selectedPackage : null
                              }
                              callback={(selectedPackage) =>
                                this.handlePackageChange(selectedPackage)
                              }
                              isDisable={
                                isCSE ||
                                isCaseInsuff ||
                                caseDetails.bridge_ars_no
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row justify-content-between small mb-1">
                        <div class="col-md-6">
                          <div class="form-group ng-star-inserted">
                            <label className="text-muted text-uppercase label-header">
                              Location<sup className="staric-data">*</sup>
                            </label>
                            <div>
                              <Selectbox
                                option={locationList}
                                defaultValue={selectedLocation}
                                callback={(location) =>
                                  this.setState({ selectedLocation: location })
                                }
                                isDisable={
                                  isCSE ||
                                  isCaseInsuff ||
                                  caseDetails.bridge_ars_no
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group ng-star-inserted">
                            <label className="text-muted text-uppercase label-header">
                              ARS Number
                            </label>
                            <CustomInput
                              type={"text"}
                              value={caseDetails.bridge_ars_no}
                              required={true}
                              isDisable={true}
                              onChange={(e) => {}}
                            ></CustomInput>
                          </div>
                        </div>
                      </div>
                      <div class="row small mb-1">
                        <div class="col-md-6">
                          <div class="form-group ng-star-inserted">
                            <label className="text-muted text-uppercase label-header">
                              Case Received Date
                            </label>
                            <CustomInput
                              type={"Date"}
                              value={caseDetails.date_of_initiation}
                              required={true}
                              onChange={(e) => {
                                let { caseDetails } = this.state;
                                caseDetails.date_of_initiation = e.target.value;
                                this.setState({
                                  caseDetails,
                                });
                              }}
                              isDisable={
                                isCSE ||
                                isCaseInsuff ||
                                caseDetails.bridge_ars_no
                              }
                            ></CustomInput>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group ng-star-inserted">
                            <label className="text-muted text-uppercase label-header">
                              Case Created Date
                            </label>
                            <CustomInput
                              type={"Date"}
                              value={caseDetails.created_at}
                              required={true}
                              isDisable={true}
                              onChange={(e) => {}}
                            ></CustomInput>
                          </div>
                        </div>
                        {/* <div class="col-md-4">
                          <div class="form-group ng-star-inserted">
                            <label className="text-muted text-uppercase label-header">
                               SLA Start Date 
                              Case Created Date
                            </label>
                            <CustomInput
                              type={"Date"}
                              value={caseDetails.sla_start_date}
                              required={true}
                              isDisable={true}
                              onChange={(e) => {}}
                            ></CustomInput>
                          </div>
                        </div> */}

                        {/* <div class="col-md-4">
                          <div class="form-group ng-star-inserted">
                            <label className="text-muted text-uppercase label-header">
                              Case Received Time
                            </label>
                            <Selectbox
                              option={getTimeArray()}
                              defaultValue={{
                                label: caseDetails.time_of_initiation,
                              }}
                              callback={(selectedPackage) => {
                                let { caseDetails } = this.state;
                                caseDetails.time_of_initiation =
                                  selectedPackage.label;
                                this.setState({ caseDetails });
                              }}
                              isDisable={
                                isCSE ||
                                isCaseInsuff ||
                                caseDetails.bridge_ars_no
                              }
                            ></Selectbox>
                          </div>
                        </div> */}
                        {/* <div class="col-md-4">
                          <div class="form-group ng-star-inserted">
                            <label className="text-muted text-uppercase label-header">
                              SLA Start Time
                            </label>
                            <CustomInput
                              type={"text"}
                              value={caseDetails.sla_start_time}
                              required={true}
                              isDisable={true}
                              onChange={(e) => {}}
                            ></CustomInput>
                          </div>
                        </div> */}
                        {/* <div class="col-md-4">
                          <div class="form-group ng-star-inserted">
                            <label className="text-muted text-uppercase label-header">
                              Closure Date
                            </label>
                            <CustomInput
                              type={"Date"}
                              value={""}
                              required={true}
                              isDisable={true}
                              onChange={(e) => {}}
                            ></CustomInput>
                          </div>
                        </div> */}
                      </div>
                      <div class="row">
                        <div class="col">
                          {caseDetails.bridge_ars_no && (
                            <PopupMenu
                              raiseInsufficiency={this.raiseInsufficiency}
                              isCaseComplete={isCaseComplete}
                              raiseEscalation={this.raiseEscalation}
                              openCaseHistoryModel={this.openCaseHistoryModel}
                              isCSE={isCSE}
                              isCaseInsuff={isCaseInsuff}
                              arsNo={caseDetails.bridge_ars_no}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    {
                      <div class="accordion" id="clientDetailsAccordion">
                        <ClientDetail
                          setLoadingValue={this.setLoadingValue}
                          selectedLocation={selectedLocation}
                          saveDeatils={this.saveDeatils}
                          userId={this.props.userInfo.userId}
                          pvtId={this.props.match.params.id}
                          packageName={this.state.selectedPackage.label}
                          packageId={this.state.selectedPackage.value}
                          documentList={documentList}
                          caseDetails={caseDetails}
                          loadDocument={this.loadDocument}
                          callback={this.getCaseDetails}
                          isCSE={isCSE}
                          isCaseInsuff={isCaseInsuff}
                          isDocDisable={
                            isCSE ||
                            caseDetails.bridge_ars_no ||
                            isCaseInsuff ||
                            false
                          }
                        />
                      </div>
                    }
                  </div>
                </div>

                {/* checkList component */}
                {groupCheckListArray.map((checkArr) => (
                  <div
                    className="card bg-white mb-3"
                    //key={checkArr[0].check_id}
                  >
                    <div className="card-body small">
                      <h6 className="text-muted font-weight-bold">
                        {checkArr[0].sourceVerification ||
                          checkArr[0].check_name}
                      </h6>
                      {checkArr.map((item, index) => (
                        <CheckListCard
                          documentList={documentList}
                          saveCheckList={this.saveCheckList}
                          // saveEditCheckList={this.saveEditCheckList}
                          checkObject={item}
                          loadDocument={this.loadDocument}
                          pvtId={this.props.match.params.id}
                          userInfo={this.props.userInfo}
                          isDocDisable={isCSE || item.is_frozen}
                          selectedPackage={this.state.selectedPackage}
                          selectedCheckUID={this.state.selectedCheckUID}
                          onCheckSelected={(checkUID) =>
                            this.setState({ selectedCheckUID: checkUID })
                          }
                          isCSE={isCSE}
                        />
                      ))}
                    </div>
                  </div>
                ))}

                {caseDetails.bridge_ars_no && ( //remove this condition
                  <div className="row justify-content-between mb-3">
                    <div class="col-auto">
                      <Toggle
                        callback={this.handleSubmittedChecksToggleChange}
                        isToggleOn={isToggleOn}
                      />
                    </div>
                    <div className="col-auto d-flex justify-content-end">
                      <div
                        className="transparent-borderless scroll-top-icon"
                        onClick={() => {
                          window.scroll({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <i
                          className="fa fa-arrow-up text-muted"
                          style={{ fontSize: "36px" }}
                        ></i>
                      </div>
                      <div className="row">
                        {/* {!isCSE && isToggleOn && (
                          <div
                            className="btn btn-outline-primary text-uppercase btn-sm btn-radius-9 mr-2 default-font-size"
                            onClick={() => {
                              this.openGapcheck();
                            }}
                          >
                            Gap Check
                          </div>
                        )} */}

                        {!isCSE && (
                          <div
                            className="btn btn-outline-primary text-uppercase btn-sm btn-radius-9 mr-2 default-font-size"
                            onClick={() => this.openCheckPopup()}
                          >
                            <i className="fa fa-plus-circle"></i> Add Check
                          </div>
                        )}
                        {!isFinalSubmit ? (
                          <button
                            className="btn btn-primary fadeIn fourth text-uppercase btn-sm btn-radius-9 pl-4 pr-4 mr-2 default-font-size"
                            onClick={(e) => {
                              this.handleFormSubmit(e);
                            }}
                            disabled={isCSE || isEscalation}
                          >
                            {this.state.isSaving && (
                              <span className="spinner-border spinner-border-sm mr-1"></span>
                            )}
                            Submit Case
                          </button>
                        ) : (
                          <div
                            class="spinner-border text-info float-right m-2 spinner-block"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-7 scrollable position-relative">
                <div>
                  <sup className="staric-data">
                    {" "}
                    Note: All documents will expire in 7 days
                  </sup>
                </div>
                <div id="labels" className="row border labels-section">
                  <ul>
                    <li style={{ backgroundColor: "transparent" }}>
                      <FileInput
                        onChange={(file) => this.onDocumentSelected(file)}
                      />
                    </li>
                    {documentList.map((document) => (
                      <li
                        className={
                          "rounded" +
                          (activeDocId === document.id ? " active-doc" : "")
                        }
                      >
                        <div className="d-flex align-items-center">
                          <div
                            onClick={() => {
                              this.loadDocument(
                                document.documentType,
                                document.gcspath,
                                document.id
                              );
                            }}
                          >
                            {document.type + "_" + document.id}
                          </div>
                          <button class="download-document-btn">
                            <a href={document.gcspath} download target="_blank">
                              <i
                                class="fa fa-arrow-down"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <DocumentViewer
                  currentDocumentURL={this.state.currentDocumentURL}
                  currentDocumentType={this.state.currentDocumentType}
                  onIframeLoad={() =>
                    this.setState({ iframeDocumentLoading: false })
                  }
                  iframeDocumentLoading={this.state.iframeDocumentLoading}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.login.userInfo,
  gapAddressArray: state.gapcheck.gapAddressArray,
  gapEmploymentEducationArray: state.gapcheck.gapEmploymentEducationArray,
  educationAddressMismatchArray: state.gapcheck.educationAddressMismatchArray,
  employmentAddressMismatchArray: state.gapcheck.employmentAddressMismatchArray,
});

const mapDispatchToProps = (dispatch) => ({
  updateAddressArray: (gapAddressArray) =>
    dispatch(updateAddressArray(gapAddressArray)),
  updateEmploymentEducationArray: (gapEmploymentEducationArray) =>
    dispatch(updateEmploymentEducationArray(gapEmploymentEducationArray)),
  updateEducationAddressMismatchArray: (educationAddressMismatchArray) =>
    dispatch(
      updateEducationAddressMismatchArray(educationAddressMismatchArray)
    ),
  updateEmploymentAddressMismatchArray: (employmentAddressMismatchArray) =>
    dispatch(
      updateEmploymentAddressMismatchArray(employmentAddressMismatchArray)
    ),
  deleteAction: (data) => dispatch(deleteAction(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Qcscreen)
);
