/***********
 * Modal Wrapper Component
 ***********/

import React, { Component } from "react";
import { modalManager } from "./../../utils/modalutils";
import { getCommentTime } from "./../../utils/commonutils";
import "./escalation.scss";

export default class Escalation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
      dateString: "",
      isLoader: false,
    };
  }

  componentDidMount = () => {
    this.currentTimeId = setInterval(this.getCommentTime, 1000);
  };
  getCommentTime = () => {
    let dateString = getCommentTime();
    this.setState({ dateString });
  };
  getComment = (value) => {
    this.setState({ comment: value });
  };

  submit = async (e) => {
    const { comment } = this.state;
    const { data = {} } = this.props;
    try {
      this.setState({ isLoader: true });
      await this.props.callback({ ...data, comment, e });
      this.setState({ isLoader: false });
    } catch (e) {
      this.setState({ isLoader: false });
      console.log(e);
    }
  };

  close = () => {
    modalManager.close({});
    this.props.onClose && this.props.onClose();
  };

  render() {
    const { data = {}, isCheck = false, btnLabel } = this.props;
    const { comment, dateString, isLoader } = this.state;
    return (
      <div className="block">
        <div>
          <div>
            <div class="small">
              <h5 class="text-muted">
                Please comment on the reason for{" "}
                <span class="text-danger">{data.label} </span> on this{" "}
                {isCheck ? "Check" : "Case"} .
              </h5>
              <hr class="border-top-0 border-bottom" />
              <div class="row  form-group">
                <div class="col-md-12">
                  <textarea
                    formControlName="comment"
                    tabindex="1"
                    class="form-control mb-3"
                    id=""
                    rows="5"
                    name="comment"
                    placeholder="Use this area to add comments for the request"
                    onChange={(event) => {
                      this.getComment(event.target.value);
                    }}
                  ></textarea>
                </div>
                {!comment && (
                  <div className="comment">Comment is required.</div>
                )}
              </div>
              <div class="row">
                <div class="col-md-12 footer">
                  <button
                    class="btn btn-danger btn-sm float-left mr-2"
                    data-dismiss="modal"
                    onClick={this.close}
                  >
                    Close
                  </button>
                  <p className="mb-0">
                    <span className="text-primary font-weight-bold">
                      {dateString}
                    </span>
                    comment initiated by {data.userName}
                  </p>

                  <div>
                    {isLoader ? (
                      <div
                        class="spinner-border text-info float-right m-2 spinner-block"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <button
                        class="btn btn-primary btn-sm float-right ml-2 send-btn"
                        disabled={!comment}
                        onClick={(e) => {
                          this.submit(e);
                        }}
                      >
                        {btnLabel}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
