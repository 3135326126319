import React, { Component } from "react";
import Selectbox from "./../../../../components/Selectbox/selectbox";
import DROPDOWN from "./../../../../components/dropdown/dropdown";
import { modalManager } from "./../../../../utils/modalutils";
export const columnData = (option, flag = "", obj) => {
  return [
    {
      name: "Status",
      field: "status",
      width: "4%",
    },
    {
      name: "ARS Number",
      field: "",
      template: (listing) => {
        return (
          <div>
            <span
              class={
                "font-weight-bold" +
                (flag === "duplicate" ? " text-muted" : " text-primary")
              }
            >
              {listing.ars_number}
            </span>
            <br />
            <span class="text-muted">
              <i>Date {listing.ars_generate_date}</i>
            </span>
          </div>
        );
      },
      width: "10%",
    },
    {
      name: "Client",
      field: "",
      template: (listing) => {
        return (
          <div>
            <span
              class={
                "font-weight-bold" +
                (flag === "duplicate" ? " text-muted" : " text-primary")
              }
            >
              Client Name:{" "}
            </span>
            <span class="text-muted">{listing.client_name}</span>
            <br />
            <span
              class={
                "font-weight-bold" +
                (flag === "duplicate" ? " text-muted" : " text-primary")
              }
            >
              Package Name:{" "}
            </span>
            <span class="text-muted">{listing.package_name}</span>
            <br />
            <span
              class={
                "font-weight-bold" +
                (flag === "duplicate" ? " text-muted" : " text-primary")
              }
            >
              Received Date:{" "}
            </span>
            <span class="text-muted">{listing.received_date}</span>
          </div>
        );
      },
      width: "19%",
    },
    {
      name: "Candidate Name",
      field: "",
      template: (listing) => {
        return (
          <div>
            <div
              style={{
                backgroundColor: `${
                  flag == "duplicate" &&
                  listing.candidate_name &&
                  listing.candidate_name != "Not Mentioned" &&
                  listing.candidate_name != "Not Applicable" &&
                  listing.candidate_name === obj.candidate_name
                    ? "#ffbd28"
                    : ""
                }`,
              }}
            >
              <span
                class={
                  "font-weight-bold" +
                  (flag === "duplicate" ? " text-muted" : " text-primary")
                }
              >
                Candidate Name:{" "}
              </span>
              <span class="text-muted">{listing.candidate_name}</span>
            </div>
            <div>
              <span
                class={
                  "font-weight-bold" +
                  (flag === "duplicate" ? " text-muted" : " text-primary")
                }
              >
                Father's Name:{" "}
              </span>
              <span class="text-muted">{listing.father_name}</span>
            </div>
            <span
              class={
                "font-weight-bold" +
                (flag === "duplicate" ? " text-muted" : " text-primary")
              }
            >
              Mobile Number:{" "}
            </span>
            <span class="text-muted">{listing.mobile_number}</span>
            <br />
            <div>
              <span
                class={
                  "font-weight-bold" +
                  (flag === "duplicate" ? " text-muted" : " text-primary")
                }
              >
                Email ID:{" "}
              </span>
              <span class="text-muted">{listing.email_id}</span>
            </div>
            <div
              style={{
                backgroundColor: `${
                  flag == "duplicate" &&
                  listing.dob &&
                  listing.dob != "Not Mentioned" &&
                  listing.dob != "Not Applicable" &&
                  listing.dob === obj.dob
                    ? "#ffbd28"
                    : ""
                }`,
              }}
            >
              <span
                class={
                  "font-weight-bold" +
                  (flag === "duplicate" ? " text-muted" : " text-primary")
                }
              >
                DOB:{" "}
              </span>
              <span class="text-muted">{listing.dob}</span>
            </div>
          </div>
        );
      },
      width: "19%",
    },
    {
      name: "NID",
      field: "",
      template: (listing) => {
        return (
          <div>
            <div
              style={{
                backgroundColor: `${
                  flag == "duplicate" &&
                  listing.pan_number &&
                  listing.pan_number != "Not Mentioned" &&
                  listing.pan_number != "Not Applicable" &&
                  listing.pan_number === obj.pan_number
                    ? "#ffbd28"
                    : ""
                }`,
              }}
            >
              <span
                class={
                  "font-weight-bold" +
                  (flag === "duplicate" ? " text-muted" : " text-primary")
                }
              >
                PAN Card Number:{" "}
              </span>
              <span class="text-muted">{listing.pan_number}</span>
            </div>
            {/* <br /> */}
            <div
              style={{
                backgroundColor: `${
                  flag == "duplicate" &&
                  listing.aadhar_card &&
                  listing.aadhar_card != "Not Mentioned" &&
                  listing.aadhar_card != "Not Applicable" &&
                  listing.aadhar_card === obj.aadhar_card
                    ? "#ffbd28"
                    : ""
                }`,
              }}
            >
              <span
                class={
                  "font-weight-bold" +
                  (flag === "duplicate" ? " text-muted" : " text-primary")
                }
              >
                Aadhar Card Number:{" "}
              </span>
              <span class="text-muted">{listing.aadhar_card}</span>
            </div>
            {/* <br /> */}
            <div
              style={{
                backgroundColor: `${
                  flag == "duplicate" &&
                  listing.driving_licence &&
                  listing.driving_licence != "Not Mentioned" &&
                  listing.driving_licence != "Not Applicable" &&
                  listing.driving_licence === obj.driving_licence
                    ? "#ffbd28"
                    : ""
                }`,
              }}
            >
              <span
                class={
                  "font-weight-bold" +
                  (flag === "duplicate" ? " text-muted" : " text-primary")
                }
              >
                Driving License:{" "}
              </span>
              <span class="text-muted">{listing.driving_licence}</span>
            </div>
            {/* <br /> */}
            <div
              style={{
                backgroundColor: `${
                  flag == "duplicate" &&
                  listing.passport_number &&
                  listing.passport_number != "Not Mentioned" &&
                  listing.passport_number != "Not Applicable" &&
                  listing.passport_number === obj.passport_number
                    ? "#ffbd28"
                    : ""
                }`,
              }}
            >
              <span
                class={
                  "font-weight-bold" +
                  (flag === "duplicate" ? " text-muted" : " text-primary")
                }
              >
                Passport Number:{" "}
              </span>
              <span class="text-muted">{listing.passport_number}</span>
            </div>
          </div>
        );
      },
      width: "19%",
    },
    {
      name: " Client Specific Fields",
      field: "",
      template: (listing) => {
        return (
          <div>
            <span
              class={
                "font-weight-bold" +
                (flag === "duplicate" ? " text-muted" : " text-primary")
              }
            >
              BGV ID1:{" "}
            </span>
            <span class="text-muted">{listing.bgv_id1}</span>
            <br />
            <span
              class={
                "font-weight-bold" +
                (flag === "duplicate" ? " text-muted" : " text-primary")
              }
            >
              Verification Type:{" "}
            </span>
            <span class="text-muted">{listing.verification_type}</span>
            <br />
            <div
              style={{
                backgroundColor: `${
                  flag == "duplicate" &&
                  listing.employee_id &&
                  listing.employee_id != "Not Mentioned" &&
                  listing.employee_id != "Not Applicable" &&
                  listing.employee_id === obj.employee_id
                    ? "#ffbd28"
                    : ""
                }`,
              }}
            >
              <span
                class={
                  "font-weight-bold" +
                  (flag === "duplicate" ? " text-muted" : " text-primary")
                }
              >
                Employee ID:{" "}
              </span>
              <span class="text-muted">{listing.employee_id}</span>
            </div>
            {/* <br /> */}
            <div
              style={{
                backgroundColor: `${
                  flag == "duplicate" &&
                  listing.candidate_id &&
                  listing.candidate_id != "Not Mentioned" &&
                  listing.candidate_id != "Not Applicable" &&
                  listing.candidate_id === obj.candidate_id
                    ? "#ffbd28"
                    : ""
                }`,
              }}
            >
              <span
                class={
                  "font-weight-bold" +
                  (flag === "duplicate" ? " text-muted" : " text-primary")
                }
              >
                Candidate ID:{" "}
              </span>
              <span class="text-muted">{listing.candidate_id}</span>
            </div>
            {/* <br /> */}
            <span
              class={
                "font-weight-bold" +
                (flag === "duplicate" ? " text-muted" : " text-primary")
              }
            >
              BGV ID2:{" "}
            </span>
            <span class="text-muted">{listing.bgv_id2}</span>
            <br />
            <span
              class={
                "font-weight-bold" +
                (flag === "duplicate" ? " text-muted" : " text-primary")
              }
            >
              Date of Reinitation:{" "}
            </span>
            <span class="text-muted">{listing.date_of_reinitiation}</span>
          </div>
        );
      },
      width: "19%",
    },
    {
      name: "Action",
      field: "",
      template: (listing) => {
        return (
          <DROPDOWN
            option={option}
            callback={(data) => {
              const result = {
                label: data.value,
                ...listing,
              };
              if (data.label === "Map Duplicate case") {
                modalManager.open({
                  isHeader: false,
                  component: () => data.template(result),
                });
              } else {
                data.template(result);
              }
            }}
          />
        );
      },
      width: "10%",
    },
  ];
};
