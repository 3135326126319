import React, { Component } from "react";
import PopupMenuCheck from "../../../../components/popupMenuCheck/popupMenuCheck";
import api from "../../../../apiBaseConfig";
import {
  GET_DOCUMENTS_PER_CHECK,
  SUBMIT_CHECK,
  ENTITY_DROPDOWN,
  LOCATION_ADDED_SEARCH,
  ENTITY_ID_ADDRESS_SEARCH,
  HIRING_LIST,
  RAISE_CHECK_INSUFF,
  ADD_RESEARCH
} from "../../../../apiurl";
import Selectbox from "../../../../components/Selectbox/selectbox";
import _get from "lodash/get";
import ModalDialog, { ModalTransition } from "@atlaskit/modal-dialog";
import CustomInput from "../../../../components/customInput/customInput";
import AsyncSelect from "react-select/async";
import AsyncSelectBox from "../../../../components/asyncSelectBox/asyncSelectBox";
import _debounce from "lodash/debounce";
import InputCheckbox from "../inputCheckbox/inputCheckbox";
import RaiseResearchModal from "../../../../components/raiseResearchModal/raiseResearchModal";
import "./checkCard.scss";
import {
  getFormatDate,
  monthNameFromNumber,
  convertPassingYearToDate
} from "../../../../utils/commonutils";
import Escalation from "../../../../components/Escalation/escalation";
import { getCommentTime } from "../../../../utils/commonutils";
import DocPicker from "../docPicker/docPicker";
import HiringModal from "../hiringModal/hiringModal";
import AddMoreDocumentsModal from "../modals/addMoreDocumentsModal/addMoreDocumentsModal";
import Asterisk from "../asterisk/asterisk";
import { Toast } from "../../../../utils/toastutils";
import FromToAntecedants from "../../fromToAntecedants/fromToAntecedants";
import { constants } from "../../../../constants";
import Toggle from "../toggle/toggle";

export default class CheckCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentsPerCheck: [],
      isDocumentListModalOpen: false,
      isCheckValid: false,
      isSourceValid: false,
      institute: null,
      hiringList: [],
      ishiringListModalOpen: false,
      loading: true,
      hiringCheckName: null,
      isRaiseResearch: false,
      RaisedresearchType: "",
      isRaiseResearchModal: false,
      isInsuffEscalationModalOpen: false,
      isVerificationSourceError: false,
      isVerificationSourceLocationError: false,
      isVerificationSourceAddressError: false,
      isFromTimePeriodError: false,
      isToTimePeriodError: false,
      filterLocation: {}
    };

    this.checkObject = this.props.checkObject;
  }

  componentDidUpdate(prevProps, prevState) {
    this.checkObject = this.props.checkObject;
  }

  getLocationOptionBasedOnSearch = (
    searchString = "",
    callbackToAsyncSelect
  ) => {
    api
      .post(`${ENTITY_DROPDOWN}city/`, { searchString })
      .then(response => {
        let locationArray = response.data.result;
        locationArray = locationArray.map(location => ({
          label: location.cityName,
          value: location.cityId
        }));
        callbackToAsyncSelect(locationArray);
      })
      .catch(err =>
        console.log("error in fetching location based on search string", err)
      );
  };

  getCountryLocationOptionBasedOnSearch = (
    searchString = "",
    callbackToAsyncSelect
  ) => {
    api
      .post(`${ENTITY_DROPDOWN}country/`, { searchString })
      .then(response => {
        let locationArray = response.data.result;
        locationArray = locationArray.map(location => ({
          label: location.locationName,
          value: location.locationId
        }));
        callbackToAsyncSelect(locationArray);
      })
      .catch(err =>
        console.log("error in fetching location based on search string", err)
      );
  };

  getCompaniesBasedOnSearch = (searchString = "", callbackToAsyncSelect) => {
    api
      .post(`${ENTITY_DROPDOWN}company/`, { searchString })
      .then(response => {
        let companyArray = response.data.result;
        companyArray = companyArray.map(company => ({
          label: company.companyName,
          value: company.companyId
        }));
        callbackToAsyncSelect(companyArray);
      })
      .catch(err =>
        console.log("error in fetching company based on search string", err)
      );
  };

  getInstituteBasedOnSearch = (searchString = "", callbackToAsyncSelect) => {
    api
      .post(`${ENTITY_DROPDOWN}institute/`, { searchString })
      .then(response => {
        let instituteArray = response.data.result;
        instituteArray = instituteArray.map(institute => ({
          label: institute.instituteName,
          value: institute.instituteId
        }));
        callbackToAsyncSelect(instituteArray);
      })
      .catch(err =>
        console.log("error in fetching company based on search string", err)
      );
  };

  getInstituteBasedOnLocation = (searchString = "", callbackToAsyncSelect) => {
    api
      .post(`${LOCATION_ADDED_SEARCH}institute/`, {
        searchString,
        locationId: this.state.filterLocation.value
      })
      .then(response => {
        let instituteArray = response.data.result;
        instituteArray = instituteArray.map(institute => ({
          label: institute.instituteName,
          value: institute.instituteId
        }));
        callbackToAsyncSelect(instituteArray);
        // this.setState({ instituteOptionBasedOnSearch: instituteArray });
      })
      .catch(err =>
        console.log("error in fetching institute based on search string", err)
      );
  };

  getLocationBasedOnEntity = (searchString, callbackToAsyncSelect) => {
    api
      .post(`${ENTITY_ID_ADDRESS_SEARCH}company/`, {
        entityId: this.checkObject.verificationSource.value
      })
      .then(response => {
        let locationArray = response.data.result;
        locationArray = locationArray.map(location => ({
          label: location.cityName,
          value: location.cityId,
          address: location.address
        }));
        locationArray.push({
          label: "others",
          value: "-1",
          address: ""
        });
        this.props.handleCheckChange(this.checkObject);

        callbackToAsyncSelect(locationArray);
        // this.setState({ locationOptionBasedOnSearch: locationArray });
      })
      .catch(err =>
        console.log("error in fetching location based on search string", err)
      );
  };

  raiseInsufficiencyCheck = e => {
    e.preventDefault();
    this.setState({ isInsuffEscalationModalOpen: true });
  };

  onInsufficiencyRaised = commentObject => {
    this.checkObject.isSubmit = true;
    this.checkObject.isInsuff = true;
    this.checkObject.insuffCommentObject = commentObject;
    this.props.onCheckSelected(null);
    this.setState({ isInsuffEscalationModalOpen: false });
    Toast.success({
      description: "Check Insufficiency raised successfully",
      header: "success"
    });
  };

  submitCheckComment = async (commentObject, checkUID) => {
    const { userInfo = {} } = this.props;

    const payload = {
      comment: commentObject.comment,
      createdAt: new Date().toISOString(),
      roleName: userInfo.roleName,
      userId: parseInt(userInfo.userId),
      userName: `${userInfo.userFirstName || ""} ${userInfo.userLastName || ""}`
    };
    if (this.checkObject.checkHistory.comments) {
      this.checkObject.checkHistory.comments.push(payload);
    } else {
      this.checkObject.checkHistory.comments = [payload];
    }
    this.props.handleCheckChange(this.checkObject, true);
  };

  getLocationBasedOnInstituteId = (searchString, callbackToAsyncSelect) => {
    api
      .post(`${ENTITY_ID_ADDRESS_SEARCH}institute/`, {
        entityId: this.checkObject.verificationSource.value
      })
      .then(response => {
        let locationArray = response.data.result;
        locationArray = locationArray.map(location => ({
          label: location.cityName,
          value: location.cityId,
          address: location.address
        }));
        locationArray.push({
          label: "others",
          value: "-1",
          address: ""
        });

        this.props.handleCheckChange(this.checkObject);
        callbackToAsyncSelect(locationArray);
      })
      .catch(err =>
        console.log("error in fetching location based on search string", err)
      );
  };

  // addMoreAnticidant(checkFields, checkId) {
  //   this.dynamicCheckFields = checkFields;
  //   api
  //     .get(ANTECEDANTS_PER_CHECK_PER_PROCESS + checkId)
  //     .then(response => {
  //       // console.log("antencedents", response);
  //       //  this.setState({ antecedents: response.data.antecedents });
  //     })
  //     .catch(err => console.log(err));
  // }

  addDocumentToTheCheck = document => {
    const documents = this.checkObject.documentList || [];
    documents.push({
      documentName: document.documentName,
      documentId: document.documentId
    });
    this.checkObject.documentList = documents;
    this.props.handleCheckChange(this.checkObject);
  };

  addDocumentToTheCSE = document => {
    const cseDocuments = this.checkObject.cseDocuments || [];
    cseDocuments.push({
      documentName: document.documentName,
      documentId: document.documentId
    });
    this.checkObject.cseDocuments = cseDocuments;
    this.props.handleCheckChange(this.checkObject);
  };

  // getPathForTheDocument = documentName => {
  //   const document = this.props.documents.filter(
  //     document => document.id === documentName
  //   );
  //   if (document.length) {
  //     return document[0].path;
  //   }
  //   return "";
  // };

  getDocumentsPerCheck = checkObject => {
    api
      .get(GET_DOCUMENTS_PER_CHECK + `${checkObject.checkId}`)
      .then(response => {
        this.setState({
          documentsPerCheck: _get(response, "data.result.documents", [])
        });
      })
      .catch(err => console.log(err));
  };

  isSourceValid = () => {
    const {
      checkId,
      verificationSource,
      verificationSourceLocation,
      verificationSourceAddress,
      sourceVerificationType
    } = this.checkObject;
    let isSourceValid = false;
    let isHiringValid = true;
    let isFromToValid = true;

    // from to validation
    if (
      checkId === constants.checkIds.EDUCATION_W_CHECK_ID ||
      checkId === constants.checkIds.EDUCATION_CHECK_ID ||
      checkId ===
        constants.checkIds.PREVIOUS_EMPLOYMENT_VERIFICATION_CHECK_ID ||
      checkId === constants.checkIds.CURRENT_EMPLOYMENT_VERIFICATION_CHECK_ID ||
      checkId === constants.checkIds.ADDRESS_VERIFICATION_CHECK_ID ||
      checkId ===
        constants.checkIds.INDIA_COURT_RECORD_CHECK_THROUGH_LAW_FIRM_CHECK_ID
    ) {
      if (this.checkObject.from) {
        this.setState({ isFromTimePeriodError: false });
      } else {
        this.setState({ isFromTimePeriodError: true });
        isFromToValid = false;
      }

      if (this.checkObject.to) {
        this.setState({ isToTimePeriodError: false });
      } else {
        this.setState({ isToTimePeriodError: true });
        isFromToValid = false;
      }
    }

    //for education and employment checks
    if (
      sourceVerificationType === constants.EDUCATION_SOURCE_VERIFICATION_TYPE ||
      sourceVerificationType === constants.COMPANY_SOURCE_VERIFICATION_TYPE
    ) {
      //hiring list validation
      if (this.checkObject.isInHiringList === null) {
        this.checkObject.isHiringListError = true;
        isHiringValid = false;
      } else {
        this.checkObject.isHiringListError = false;
      }

      {
      }
      if (verificationSource && verificationSource.label) {
        this.setState({ isVerificationSourceError: false });

        if (verificationSource.value) {
          if (
            verificationSourceLocation &&
            verificationSourceLocation.label &&
            verificationSourceAddress
          ) {
            this.setState({
              isVerificationSourceLocationError: false,
              isVerificationSourceAddressError: false
            });

            isSourceValid = true;
          } else {
            if (_get(verificationSourceLocation, "label")) {
              if (verificationSourceAddress) {
                this.setState({
                  isVerificationSourceLocationError: false,
                  isVerificationSourceAddressError: false
                });
              } else {
                this.setState({
                  isVerificationSourceLocationError: false,
                  isVerificationSourceAddressError: true
                });
              }
            } else {
              this.setState({
                isVerificationSourceLocationError: true,
                isVerificationSourceAddressError: true
              });
            }

            isSourceValid = false;
          }
        } else {
          isSourceValid = true;
        }
      } else {
        this.setState({ isVerificationSourceError: true });
      }
    } else if (
      // city sourceVerificationType  and india police verification through law firm checks
      sourceVerificationType === constants.CITY_SOURCE_VERIFICATION_TYPE ||
      sourceVerificationType === constants.COUNTRY_SOURCE_VERIFICATION_TYPE ||
      checkId === 84 //need to confirm it
    ) {
      if (verificationSourceLocation && verificationSourceLocation.label) {
        isSourceValid = true;
        this.setState({ isVerificationSourceError: false });
      } else {
        isSourceValid = false;
        this.setState({ isVerificationSourceError: true });
      }
    } else {
      isSourceValid = true;
    }
    this.setState({ isSourceError: !isSourceValid });
    return isSourceValid && isHiringValid && isFromToValid;
  };
  

  checkValidationForCheck = () => {
    let isValid = true;
    this.checkObject.checkFields.forEach(element => {
      if (
        element.isMandatory &&
        !element.antecedantValue &&
        element.antecedantValue !== 0
      ) {
        element.isError = true;
        isValid = false;
      } else {
        element.isError = false;
      }
    });
    this.props.handleCheckChange(this.checkObject);
    const isSourceValid = this.isSourceValid();
    isValid = isValid && isSourceValid ? true : false;
    return isValid;
  };

  // getPublicURL = staticPath => {
  //   let path = staticPath || "";
  //   path = path.replace("download_file/", "");
  //   let storageURL = process.env.REACT_APP_FILE_STORAGE;
  //   path = storageURL.concat(path);
  //   return path;
  // };

  getResearchText = () => {
    const { checkId, researchType } = this.checkObject;
    const commentPrefix =
      researchType === "verificationSource"
        ? "Verification Source - "
        : "Location - ";
    if (
      (checkId === constants.checkIds.EDUCATION_W_CHECK_ID ||
        checkId === constants.checkIds.EDUCATION_CHECK_ID ||
        checkId ===
          constants.checkIds.PREVIOUS_EMPLOYMENT_VERIFICATION_CHECK_ID ||
        checkId ===
          constants.checkIds.CURRENT_EMPLOYMENT_VERIFICATION_CHECK_ID) &&
      this.checkObject.researchType === "verificationSourceLocation"
    ) {
      return commentPrefix + " " + this.checkObject.verificationSourceAddress;
    } else {
      return (
        commentPrefix +
        " " +
        this.checkObject[this.checkObject.researchType].label
      );
    }
  };

  // It gives exact name for period of stay field  for the check

  // handleMultiplePeriodOfAddresses = () => {
  //   if (
  //     this.state.isSecondTenure &&
  //     this.checkObject.from2 &&
  //     this.checkObject.to2
  //   ) {
  //     const [year, month] = this.checkObject.from2.split("-");
  //     const [year2, month2] = this.checkObject.to2.split("-");
  //     const period = `From ${monthNameFromNumber(
  //       month
  //     )},${year} To ${monthNameFromNumber(month2)},${year2}`;

  //     const fieldForSubmitCheck = this.checkObject.checkFields.find(
  //       checkField =>
  //         checkField.antecedantName ===
  //         this.getPrimaryAddressFieldNameForCheck(this.checkObject.checkId)
  //     );

  //     fieldForSubmitCheck.antecedantValue = `${fieldForSubmitCheck.antecedantValue} and ${period}`;
  //   }
  // };

  // handleHiddenFields = () => {
  //   if (
  //     this.checkObject.checkId ===
  //     constants.checkIds.DOCUMENT_VERIFICATION_CHECK_ID
  //   ) {
  //     if (
  //       this.checkObject.periodOfBankFrom &&
  //       this.checkObject.periodOfBankTo
  //     ) {
  //       const [year, month, date] = this.checkObject.periodOfBankFrom.split(
  //         "-"
  //       );
  //       const [year2, month2, date2] = this.checkObject.periodOfBankTo.split(
  //         "-"
  //       );
  //       const period = `From ${date} ${monthNameFromNumber(
  //         month
  //       )},${year} To ${date2} ${monthNameFromNumber(month2)},${year2}`;

  //       const fieldForSubmitCheck = this.checkObject.checkFields.find(
  //         checkField =>
  //           checkField.antecedantId ===
  //           constants.antecedantId.PERIOD_OF_BANK_STATEMENT_ID
  //       );

  //       fieldForSubmitCheck.antecedantValue = period;
  //     } else if (this.checkObject.periodOfBankFrom) {
  //       const [year, month, date] = this.checkObject.periodOfBankFrom.split(
  //         "-"
  //       );
  //       const period = `From ${date} ${monthNameFromNumber(month)},${year} `;

  //       const fieldForSubmitCheck = this.checkObject.checkFields.find(
  //         checkField =>
  //           checkField.antecedantId ===
  //           constants.antecedantId.PERIOD_OF_BANK_STATEMENT_ID
  //       );

  //       fieldForSubmitCheck.antecedantValue = period;
  //     } else if (this.checkObject.periodOfBankTo) {
  //       const [year2, month2, date2] = this.checkObject.periodOfBankTo.split(
  //         "-"
  //       );
  //       const period = ` To ${date2} ${monthNameFromNumber(month2)},${year2}`;

  //       const fieldForSubmitCheck = this.checkObject.checkFields.find(
  //         checkField =>
  //           checkField.antecedantId ===
  //           constants.antecedantId.PERIOD_OF_BANK_STATEMENT_ID
  //       );

  //       fieldForSubmitCheck.antecedantValue = period;
  //     }
  //   }
  // };

  // raiseResearchAndSubmitCheck = async (shouldRaiseResearch, comment) => {
  //   this.props.isLoading(true);
  //   let documentList = this.checkObject.documentList || [];
  //   let documents = documentList.filter(
  //     document => document.doc_gcspath && document.doc_gcspath.length
  //   );
  //   const documentsFormatedForSubmitCheck = documents.map(document => {
  //     return {
  //       ...document,
  //       doc_path: document.doc_gcspath,
  //       page: document.page || null
  //     };
  //   });

  //   let field_dict = this.checkObject.checkFields;

  //   this.handleMultiplePeriodOfAddresses();
  //   this.handleHiddenFields();

  //   const payload = {
  //     pvt_id: this.props.pvtId,
  //     user_id: this.props.userInfo.userId,
  //     nameOfCheck: `${this.checkObject.checkName} (${this.props.checkNumber})`,
  //     remarks: "Test Remarks", //needs to clarify
  //     process_id: this.props.selectedPackage.value,
  //     verification_source_id: this.checkObject.verificationSource.value,
  //     location_id: this.checkObject.verificationSourceLocation.value,
  //     candidate_id: this.props.caseDetails.candidateId,
  //     document_list: documentsFormatedForSubmitCheck,
  //     documented_by: this.props.userInfo.userId,
  //     check_id: this.checkObject.checkId,
  //     case_id: this.props.caseDetails.caseId,
  //     field_dict: field_dict,
  //     comments: _get(this.checkObject, "checkHistory.comments", [])
  //   };

  //   api
  //     .post(SUBMIT_CHECK, payload)
  //     .then(async response => {
  //       this.checkObject.hasSubmitted = true;
  //       this.checkObject.documentList = documents;
  //       this.props.handleCheckChange(this.checkObject);
  //       await this.props.saveCheckList();
  //       // this.props.onCheckSelected(null);

  //       if (shouldRaiseResearch) {
  //         const orgType = this.checkObject.orgType === "company" ? 1 : 2;
  //         //call research api
  //         api
  //           .post(ADD_RESEARCH, {
  //             pvt_id: parseInt(this.props.pvtId),
  //             case_check_id: response.data.caseCheckId,
  //             org_id: this.checkObject.verificationSource.value || null,
  //             org_name: this.checkObject.verificationSource.label || null,
  //             org_type: orgType,
  //             comment: this.getResearchText()
  //           })
  //           .then(() =>
  //             Toast.success({
  //               description: "Research raised successfully",
  //               title: "Success"
  //             })
  //           )
  //           .catch(err => {
  //             console.log("error in raising research", err);
  //           });
  //         this.props.submitCheckComment(
  //           {
  //             comment
  //           },
  //           this.checkObject.checkUID
  //         );
  //       }
  //       this.props.isLoading(false);

  //       Toast.success({
  //         description: "check submitted successfully",
  //         header: "success"
  //       });
  //     })
  //     .catch(err => {
  //       console.log(err);
  //       this.checkObject.hasSubmitted = false;
  //       this.props.handleErrorInSubmittingCheck(err);
  //       this.props.isLoading(false);
  //       Toast.error({
  //         description: "error in  submitting check",
  //         header: "error"
  //       });
  //     });
  // };

  // submitCheck = async () => {
  //   if (this.checkObject.hasUserRaisedResearch) {
  //     this.raiseResearchAndSubmitCheck(true, this.checkObject.comment);
  //   } else {
  //     this.raiseResearchAndSubmitCheck(false);
  //   }
  // };

  preSubmitCheck = () => {
    if (this.checkValidationForCheck()) {
      const { isRaiseResearch } = this.checkObject;
      if (isRaiseResearch) {
        this.setState({ isRaiseResearchModal: true });
      } else {
        this.checkObject.isSubmit = true;
        this.props.handleCheckChange(this.checkObject, true);
        this.props.onCheckSelected(null);

        Toast.success({
          description: "check submitted successfully",
          header: "success"
        });
      }
    } else {
      alert(
        "Please ensure that all mandatory fields are filled before submitting the check"
      );
    }
  };

  preSubmitCheckAfterRaiseResearch = (
    hasUserRaisedResearch,
    userCommentForResearch = ""
  ) => {
    this.checkObject.isSubmit = true;
    this.checkObject.hasUserRaisedResearch = hasUserRaisedResearch;
    this.checkObject.userCommentForResearch = userCommentForResearch;
    this.props.handleCheckChange(this.checkObject, true);
    this.props.onCheckSelected(null);
    this.setState({ isRaiseResearchModal: false });

    Toast.success({
      description: "check submitted successfully",
      header: "success"
    });
  };

  getHiringList = (searchString = "", entity = "") => {
    searchString = searchString.split(" ")[0];

    let hiringCheckName;
    if (entity === "company") {
      hiringCheckName = "Employment Check";
    } else {
      hiringCheckName = "Education Check";
    }
    if (searchString !== "") {
      this.setState({
        hiringCheckName: hiringCheckName,
        loading: true,
        searchString: searchString,
        entity: entity
      });
      // api
      //   .post(`${HIRING_LIST}?entity=` + entity, {
      //     searchString
      //   })
      //   .then(response => {
      //     const hiringList = response.data.result;
      //     this.setState({
      //       hiringList: hiringList,
      //       loading: false
      //     });
      //   })
      //   .catch(err => {
      //     this.setState({ loading: false });
      //     console.log(err);
      //   });
    } else {
      this.setState({
        hiringCheckName: hiringCheckName,
        loading: false,
        searchString,
        entity
      });
    }
  };

  setHiringValue = (value, checkObject) => {
    checkObject.isInHiringList = value;

    this.props.handleCheckChange(checkObject);
  };

  convertPeriodToDates = (checkObject, antecedantName, isFrom) => {
    const periodCheck = checkObject.checkFields.find(
      checkField => checkField.antecedantName === antecedantName
    );
    if (!periodCheck) {
      return "";
    }
    const [fromDate, toDate] = getFormatDate(periodCheck.antecedantValue);
    if (
      periodCheck.antecedantValue &&
      (fromDate !== checkObject.from || toDate !== checkObject.to)
    ) {
      checkObject.from = fromDate;
      checkObject.to = toDate;
      this.props.handleCheckChange(checkObject);
    }

    if (isFrom) {
      return fromDate;
    } else {
      return toDate;
    }
  };

  convertDatesToPeriod = (date, isFrom, antecedantName, checkObject) => {
    if (!date) {
      return null;
    }
    if (checkObject.from && checkObject.to) {
      const [year, month] = checkObject.from.split("-");
      const [year2, month2] = checkObject.to.split("-");
      const period = `From ${monthNameFromNumber(
        month
      )},${year} To ${monthNameFromNumber(month2)},${year2}`;

      const field = checkObject.checkFields.find(
        checkField => checkField.antecedantName === antecedantName
      );
      field.antecedantValue = period;
      this.props.handleCheckChange(checkObject);
    }
  };

  convertDateToPassingYear = (date, antecedantName, checkObject) => {
    if (!date) {
      return null;
    }
    if (checkObject.to) {
      const [year, month] = checkObject.to.split("-");
      const period = `${monthNameFromNumber(month)},${year}`;

      const field = checkObject.checkFields.find(
        checkField => checkField.antecedantName === antecedantName
      );
      field.antecedantValue = period;
      this.props.handleCheckChange(checkObject);
    }
  };

  render() {
    const checkObject = this.checkObject || {};

    return (
      <div className="card-body small">
        <div className="accordion" id="employment-check-accordioni">
          <div
            className={`card border-primary p-2 rounded  ${
              this.checkObject.isSubmit
                ? `${
                    this.checkObject.isInsuff
                      ? "row-disabled insuff"
                      : "row-disabled"
                  }`
                : ""
            }`}
          >
            <div className="main-head collapsed d-flex align-items-center justify-content-between">
              <a
                className={`text-primary ${
                  this.checkObject.isSubmit
                    ? `${
                        this.checkObject.isInsuff
                          ? "row-disabled insuff"
                          : "row-disabled"
                      }`
                    : ""
                }`}
                // href="javascript:void(0);"
                onClick={() => {
                  if (this.props.selectedCheckUID === checkObject.checkUID) {
                    this.props.onCheckSelected(null);
                  } else {
                    this.props.onCheckSelected(checkObject.checkUID);
                  }
                }}
              >
                {checkObject.checkName} ({this.props.checkNumber})
              </a>
            </div>

            <div
              id={`emp-veri-collapse${checkObject.checkUID}`}
              className={`${
                this.props.selectedCheckUID === checkObject.checkUID
                  ? "collapse show"
                  : "collapse"
              }`}
              aria-labelledby="headingOne"
            >
              <div
                className={
                  checkObject.isSubmit === true
                    ? `${
                        this.checkObject.isInsuff
                          ? "row-disabled insuff"
                          : "row-disabled"
                      }`
                    : ""
                }
              >
                <div className="col-md-12">
                  {checkObject.checkUID === 0 && (
                    <div className="row mb-3 dn">
                      <div className="col-md-9 offset-md-1">
                        <button
                          className={`wh20 btn border  rounded-circle p-1 d-flex align-items-center justify-content-center${
                            checkObject.isSubmit || this.props.isCSE
                              ? "border-secondary"
                              : "border-primary"
                          }`}
                          onClick={() => {
                            this.addMoreAnticidant(
                              checkObject.checkFields,
                              checkObject.checkId
                            );
                          }}
                          disabled={checkObject.isSubmit || this.props.isCSE}
                        >
                          <i
                            className="fa fa-plus icon-primary"
                            data-toggle="modal"
                            data-target="#addMoreAnticidant"
                          ></i>
                        </button>
                      </div>
                    </div>
                  )}

                  <hr className="brdr-dashed" />

                  {/* Add antecedant column */}
                  <div className="row">
                    <div className="w-100">
                      <div className="col-md-12 mb-2">
                        <button
                          className={`wh20 btn border  rounded-circle p-1 d-flex align-items-center justify-content-center ${
                            checkObject.isSubmit
                              ? "border-secondary"
                              : "border-primary"
                          }`}
                          disabled={checkObject.isSubmit && !this.props.isCSE}
                          onClick={() => {
                            this.setState({
                              isDocumentListModalOpen: true
                            });
                            this.getDocumentsPerCheck(checkObject);
                          }}
                        >
                          <i className="fa fa-plus icon-primary"></i>
                        </button>
                      </div>

                      <DocPicker
                        selectedDocuments={
                          this.props.checkObject.documentList || []
                        }
                        selectedDocumentsChanged={documents => {
                          this.checkObject.documentList = documents;
                          this.props.handleCheckChange(this.checkObject);
                        }}
                        loadDocument={this.props.loadDocument}
                        isCSE={this.props.isCSE}
                        isSubmit={checkObject.isSubmit}
                        documents={this.props.documents}
                      />
                      {/* // for cse resource added documents */}
                      <DocPicker
                        selectedDocuments={
                          this.props.checkObject.cseDocuments || []
                        }
                        selectedDocumentsChanged={cseDocuments => {
                          this.checkObject.cseDocuments = cseDocuments;
                          this.props.handleCheckChange(this.checkObject);
                        }}
                        loadDocument={this.props.loadDocument}
                        documents={this.props.documents}
                      />
                    </div>
                  </div>

                  {/*  education sourceVerificationType  */}
                  {checkObject.sourceVerificationType ===
                    constants.EDUCATION_SOURCE_VERIFICATION_TYPE && (
                    <React.Fragment>
                      <div className="form-group">
                        <label className="text-uppercase text-secondary">
                          Location
                        </label>
                        <div className="d-flex">
                          <div className="w-100">
                            <AsyncSelectBox
                              loadOptions={_debounce(
                                this.getLocationOptionBasedOnSearch,
                                300
                              )}
                              defaultValue={this.state.filterLocation}
                              onChange={location => {
                                this.setState({
                                  filterLocation: location || {}
                                });
                              }}
                              isDisable={
                                checkObject.isSubmit || this.props.isCSE
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="text-uppercase text-secondary">
                          Verification Source (Institute/ University Name)
                          <Asterisk />
                        </label>
                        <div className="d-flex">
                          <div className="w-100">
                            <AsyncSelectBox
                              loadOptions={_debounce(
                                this.state.filterLocation.value
                                  ? this.getInstituteBasedOnLocation
                                  : this.getInstituteBasedOnSearch,
                                300
                              )}
                              defaultValue={checkObject.verificationSource}
                              isError={this.state.isVerificationSourceError}
                              onChange={institute => {
                                checkObject.verificationSource =
                                  institute || {};
                                checkObject.verificationSourceLocation = {};
                                if (!checkObject.verificationSource.value) {
                                  checkObject.isRaiseResearch = true;
                                  checkObject.researchType =
                                    "verificationSource";
                                } else {
                                  checkObject.isRaiseResearch = false;
                                  checkObject.researchType = null;
                                }
                                checkObject.orgType = "education";

                                this.props.handleCheckChange(checkObject);
                              }}
                              isDisable={
                                checkObject.isSubmit || this.props.isCSE
                              }
                            />
                          </div>
                        </div>
                      </div>

                      {checkObject.verificationSource.value && (
                        <div
                          className="form-group"
                          key={checkObject.verificationSource.value}
                        >
                          <label className="text-uppercase text-secondary">
                            Institute/ University Location
                            <Asterisk />
                          </label>

                          <AsyncSelectBox
                            key={checkObject.verificationSource.value}
                            loadOptions={_debounce(
                              this.getLocationBasedOnInstituteId,
                              300
                            )}
                            defaultOptions={true}
                            isClearable={false}
                            isError={
                              this.state.isVerificationSourceLocationError
                            }
                            defaultValue={
                              checkObject.verificationSourceLocation
                            }
                            onChange={location => {
                              if (location && location.label !== "others") {
                                checkObject.verificationSourceLocation =
                                  location || {};
                                checkObject.verificationSourceAddress = location
                                  ? location.address
                                  : "";
                                checkObject.isRaiseResearch = false;
                              } else if (
                                location &&
                                location.label === "others"
                              ) {
                                checkObject.verificationSourceLocation = {
                                  label: "others"
                                };
                                checkObject.isRaiseResearch = true;
                                checkObject.researchType =
                                  "verificationSourceLocation";
                                checkObject.orgType = "education";

                                checkObject.verificationSourceAddress = "";
                              }
                              this.props.handleCheckChange(checkObject);
                            }}
                            isDisable={checkObject.isSubmit || this.props.isCSE}
                          />
                        </div>
                      )}
                      {checkObject.verificationSource.value &&
                        checkObject.verificationSourceLocation.label && (
                          <div
                            className="form-group"
                            key={checkObject.verificationSourceLocation.label}
                          >
                            <label className="text-uppercase text-secondary">
                              Address-
                              <Asterisk />
                            </label>
                            <textarea
                              type="text"
                              className={`form-control input-section ${
                                this.state.isVerificationSourceAddressError
                                  ? "error-border"
                                  : ""
                              }`}
                              value={checkObject.verificationSourceAddress}
                              readOnly={
                                checkObject.verificationSourceLocation.label !==
                                "others"
                              }
                              onChange={e => {
                                checkObject.verificationSourceAddress =
                                  e.target.value;
                                this.props.handleCheckChange(checkObject);
                              }}
                            ></textarea>
                          </div>
                        )}
                    </React.Fragment>
                  )}
                  

                  {/*  city sourceVerificationType  case */}
                  {checkObject.sourceVerificationType ===
                    constants.CITY_SOURCE_VERIFICATION_TYPE && (
                    <React.Fragment>
                      <div class="row">
                        <div class="col-md-12">
                          <div className="form-group">
                            <label className="text-uppercase text-secondary">
                              Verification Source (City Name)
                              <Asterisk />
                            </label>
                            <div className="d-flex">
                              <div className="w-100">
                                <AsyncSelectBox
                                  loadOptions={_debounce(
                                    this.getLocationOptionBasedOnSearch,
                                    300
                                  )}
                                  defaultValue={
                                    checkObject.verificationSourceLocation
                                  }
                                  overrideVerificationSourceSelectedOption={
                                    checkObject.overrideVerificationSourceSelectedOption
                                  }
                                  overrideVerificationSourceSelectedOptionComplete={() => {
                                    checkObject.overrideVerificationSourceSelectedOption = false;
                                    this.props.handleCheckChange(checkObject);
                                  }}
                                  isError={this.state.isVerificationSourceError}
                                  onChange={location => {
                                    checkObject.verificationSourceLocation = location;
                                    checkObject.verificationSource =
                                      location || {};
                                    this.props.handleAntecedantSync(
                                      { target: { value: location } },
                                      checkObject.checkId,
                                      checkObject.checkNumber,
                                      null,
                                      [
                                        "verificationSourceLocation",
                                        "verificationSource"
                                      ]
                                    );
                                    this.props.handleCheckChange(checkObject);
                                  }}
                                  isDisable={
                                    checkObject.isSubmit || this.props.isCSE
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}

                  {/*  country sourceVerificationType case */}

                  {checkObject.sourceVerificationType ===
                    constants.COUNTRY_SOURCE_VERIFICATION_TYPE && (
                    <React.Fragment>
                      <div class="row">
                        <div class="col-md-12">
                          <div className="form-group">
                            <label className="text-uppercase text-secondary">
                              Verification Source (Country Name)
                              <Asterisk />
                            </label>
                            <div className="d-flex">
                              <div className="w-100">
                                <AsyncSelectBox
                                  loadOptions={_debounce(
                                    this.getCountryLocationOptionBasedOnSearch,
                                    300
                                  )}
                                  defaultValue={
                                    checkObject.verificationSourceLocation
                                  }
                                  isError={this.state.isVerificationSourceError}
                                  onChange={location => {
                                    checkObject.verificationSourceLocation = location;
                                    checkObject.verificationSource =
                                      location || {};
                                    this.props.handleCheckChange(checkObject);
                                  }}
                                  isDisable={
                                    checkObject.isSubmit || this.props.isCSE
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}

                  {/*  company sourceVerificationType case */}
                  {checkObject.sourceVerificationType ===
                    constants.COMPANY_SOURCE_VERIFICATION_TYPE && (
                    <React.Fragment>
                      <div className="form-group">
                        <label className="text-uppercase text-secondary">
                          Verification Source (Company Name)
                          <Asterisk />
                        </label>
                        <div className="d-flex">
                          <div className="w-100">
                            <AsyncSelectBox
                              loadOptions={_debounce(
                                this.getCompaniesBasedOnSearch,
                                300
                              )}
                              isError={this.state.isVerificationSourceError}
                              defaultValue={checkObject.verificationSource}
                              onChange={company => {
                                checkObject.verificationSource = company || {};
                                checkObject.verificationSourceLocation = {};

                                if (!checkObject.verificationSource.value) {
                                  checkObject.isRaiseResearch = true;
                                  checkObject.researchType =
                                    "verificationSource";
                                } else {
                                  checkObject.isRaiseResearch = false;
                                  checkObject.researchType = null;
                                }
                                checkObject.orgType = "company";

                                this.props.handleCheckChange(checkObject);
                              }}
                              isDisable={
                                checkObject.isSubmit || this.props.isCSE
                              }
                            />
                          </div>
                        </div>
                      </div>

                      {checkObject.verificationSource.value && (
                        <div
                          key={checkObject.verificationSource.value}
                          className="form-group"
                        >
                          <label className="text-uppercase text-secondary">
                            Company Location
                            <Asterisk />
                          </label>

                          <AsyncSelectBox
                            key={checkObject.verificationSource.value}
                            loadOptions={_debounce(
                              this.getLocationBasedOnEntity,
                              300
                            )}
                            defaultOptions={true}
                            isClearable={false}
                            isError={
                              this.state.isVerificationSourceLocationError
                            }
                            defaultValue={
                              checkObject.verificationSourceLocation
                            }
                            onChange={location => {
                              if (location && location.label !== "others") {
                                checkObject.verificationSourceLocation =
                                  location || {};
                                checkObject.verificationSourceAddress = location
                                  ? location.address
                                  : "";
                                checkObject.isRaiseResearch = false;
                              } else if (
                                location &&
                                location.label === "others"
                              ) {
                                checkObject.verificationSourceLocation = {
                                  label: "others"
                                };
                                checkObject.isRaiseResearch = true;
                                checkObject.researchType =
                                  "verificationSourceLocation";
                                checkObject.orgType = "company";

                                checkObject.verificationSourceAddress = "";
                              }
                              this.props.handleCheckChange(checkObject);
                            }}
                            isDisable={checkObject.isSubmit || this.props.isCSE}
                          />
                        </div>
                      )}
                      {checkObject.verificationSource.value &&
                        checkObject.verificationSourceLocation.label && (
                          <div className="form-group">
                            <label className="text-uppercase text-secondary">
                              Address-
                              <Asterisk />
                            </label>
                            <textarea
                              className={`form-control input-section ${
                                this.state.isVerificationSourceAddressError
                                  ? "error-border"
                                  : ""
                              }`}
                              type="text"
                              value={checkObject.verificationSourceAddress}
                              readOnly={
                                checkObject.verificationSourceLocation.label !==
                                "others"
                              }
                              onChange={e => {
                                checkObject.verificationSourceAddress =
                                  e.target.value;
                                this.props.handleCheckChange(checkObject);
                              }}
                            ></textarea>
                          </div>
                        )}
                    </React.Fragment>
                  )}

                  {/*  From to antecedant addition */}

                  {(checkObject.checkId ===
                    constants.checkIds.EDUCATION_W_CHECK_ID ||
                    checkObject.checkId ===
                      constants.checkIds.EDUCATION_CHECK_ID) && (
                    <div class="row">
                      <div class="col-md-6">
                        <div className="form-group">
                          <label className="text-uppercase text-secondary">
                            From
                            <Asterisk />
                          </label>
                          <div className="d-flex">
                            <div className="w-100">
                              <CustomInput
                                type="Date"
                                onChange={e => {
                                  checkObject.from = e.target.value;

                                  this.props.handleCheckChange(
                                    this.checkObject
                                  );
                                }}
                                isError={this.state.isFromTimePeriodError}
                                value={checkObject.from}
                                required={true}
                                isDisable={
                                  checkObject.isSubmit || this.props.isCSE
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div className="form-group">
                          <label className="text-uppercase text-secondary">
                            To
                            <Asterisk />
                          </label>
                          <div className="d-flex">
                            <div className="w-100">
                              <CustomInput
                                type="Date"
                                onChange={e => {
                                  checkObject.to = e.target.value;

                                  // this.convertDateToPassingYear(
                                  //   e.target.value,

                                  //   constants.YEAR_OF_PASSING,
                                  //   checkObject
                                  // );
                                  this.props.handleCheckChange(
                                    this.checkObject
                                  );
                                }}
                                isError={this.state.isToTimePeriodError}
                                value={checkObject.to}
                                required={true}
                                isDisable={
                                  checkObject.isSubmit || this.props.isCSE
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {checkObject.checkId ===
                    constants.checkIds.ADDRESS_VERIFICATION_CHECK_ID && (
                    <FromToAntecedants
                      convertDatesToPeriod={this.convertDatesToPeriod}
                      convertPeriodToDates={this.convertPeriodToDates}
                      antecedantName={constants.PERIOD_OF_STAY}
                      checkObject={this.checkObject}
                      handleCheckChange={this.props.handleCheckChange}
                      isCSE={this.props.isCSE}
                      isFromError={this.state.isFromTimePeriodError}
                      isToError={this.state.isToTimePeriodError}
                      handleAntecedantSync={this.props.handleAntecedantSync}
                    />
                  )}

                  {checkObject.checkId ===
                    constants.checkIds
                      .INDIA_COURT_RECORD_CHECK_THROUGH_LAW_FIRM_CHECK_ID && (
                    <FromToAntecedants
                      convertDatesToPeriod={this.convertDatesToPeriod}
                      convertPeriodToDates={this.convertPeriodToDates}
                      antecedantName={
                        constants.PERIOD_OF_STAY_COURT_VERIFICATION
                      }
                      checkObject={this.checkObject}
                      handleCheckChange={this.props.handleCheckChange}
                      isCSE={this.props.isCSE}
                      isFromError={this.state.isFromTimePeriodError}
                      isToError={this.state.isToTimePeriodError}
                      handleAntecedantSync={this.props.handleAntecedantSync}
                    />
                  )}

                  {(checkObject.checkId ===
                    constants.checkIds
                      .PREVIOUS_EMPLOYMENT_VERIFICATION_CHECK_ID ||
                    checkObject.checkId ===
                      constants.checkIds
                        .CURRENT_EMPLOYMENT_VERIFICATION_CHECK_ID) && (
                    <FromToAntecedants
                      convertDatesToPeriod={this.convertDatesToPeriod}
                      convertPeriodToDates={this.convertPeriodToDates}
                      antecedantName={constants.PERIOD_OF_EMPLOYMENT}
                      checkObject={this.checkObject}
                      handleCheckChange={this.props.handleCheckChange}
                      isCSE={this.props.isCSE}
                      isFromError={this.state.isFromTimePeriodError}
                      isToError={this.state.isToTimePeriodError}
                    />
                  )}

                  <div class="row">
                    {checkObject.checkFields.map(checkField =>
                      checkField.isHidden ? null : (
                        <div
                          className={
                            checkField.antecedantInputType === "Text Area"
                              ? "col-md-12"
                              : "col-md-6"
                          }
                        >
                          <div
                            className="form-group "
                            key={checkField.antecedantId}
                          >
                            <label className="text-uppercase text-secondary">
                              {checkField.antecedantName}
                              {checkField.isMandatory === 1 && <Asterisk />}
                            </label>
                            {
                              <CustomInput
                                type={checkField.antecedantInputType}
                                onChange={e => {
                                  checkField.antecedantValue = e.target.value;
                                  this.props.handleAntecedantSync(
                                    e,
                                    checkObject.checkId,
                                    checkObject.checkNumber,
                                    checkField.antecedantId
                                  );
                                  this.props.handleCheckChange(
                                    this.checkObject
                                  );
                                }}
                                uniqueIdForInput={`${checkObject.checkId}-${checkObject.checkNumber}-${checkField.antecedantId}`}
                                value={checkField.antecedantValue}
                                option={checkField.dropDownValues}
                                required={checkField.isMandatory === 1}
                                isDisable={
                                  checkObject.isSubmit ||
                                  this.props.isCSE ||
                                  checkField.isDisable
                                }
                                isHidden={checkField.isHidden}
                                antecedantId={checkField.antecedantId}
                                isError={checkField.isError}
                                minlength={checkField.minlength}
                                maxlength={checkField.maxlength}
                              ></CustomInput>
                            }
                          </div>
                        </div>
                      )
                    )}
                  </div>

                  {/* Hiring  employement verification case */}
                  {(checkObject.checkId ===
                    constants.checkIds
                      .PREVIOUS_EMPLOYMENT_VERIFICATION_CHECK_ID ||
                    checkObject.checkId ===
                      constants.checkIds
                        .CURRENT_EMPLOYMENT_VERIFICATION_CHECK_ID) && (
                    <div className="form-group">
                      <label className="text-uppercase text-secondary">
                        Found in Hiring List ?
                      </label>
                      <Asterisk />

                      <div class="row">
                        <div class="col-md-6">
                          <Selectbox
                            option={[
                              { value: true, label: "yes" },
                              { value: false, label: "no" }
                            ]}
                            callback={data => {
                              this.setHiringValue(data.value, checkObject);
                            }}
                            isError={checkObject.isHiringListError}
                            defaultValue={
                              checkObject.isInHiringList === null
                                ? null
                                : {
                                    value: checkObject.isInHiringList,
                                    label:
                                      checkObject.isInHiringList === true
                                        ? "yes"
                                        : "no"
                                  }
                            }
                          />
                        </div>
                        <div class="col-md-6 d-flex justify-content-end align-items-center">
                          <button
                            href="#"
                            className="m-2 transparent-borderless"
                            onClick={() => {
                              this.setState({ ishiringListModalOpen: true });
                              this.getHiringList(
                                checkObject.verificationSource.label,
                                "company"
                              );
                            }}
                          >
                            <i className="fa fa-eye fnt16"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Hiring education verification case */}
                  {(checkObject.checkId ===
                    constants.checkIds.EDUCATION_W_CHECK_ID ||
                    checkObject.checkId ===
                      constants.checkIds.EDUCATION_CHECK_ID) && (
                    <div className="form-group">
                      <label className="text-uppercase text-secondary">
                        Found in Hiring List ?{" "}
                      </label>
                      <Asterisk />
                      <div class="row">
                        <div class="col-md-6">
                          <Selectbox
                            option={[
                              { value: true, label: "yes" },
                              { value: false, label: "no" }
                            ]}
                            callback={data => {
                              this.setHiringValue(data.value, checkObject);
                            }}
                            isError={checkObject.isHiringListError}
                            defaultValue={
                              checkObject.isInHiringList === null
                                ? null
                                : {
                                    value: checkObject.isInHiringList,
                                    label:
                                      checkObject.isInHiringList === true
                                        ? "yes"
                                        : "no"
                                  }
                            }
                          />
                        </div>

                        <div class="col-md-6 d-flex justify-content-end align-items-center">
                          <button
                            href="#"
                            className=" transparent-borderless"
                            onClick={() => {
                              this.setState({ ishiringListModalOpen: true });
                              this.getHiringList(
                                checkObject.verificationSource.label,
                                "institute"
                              );
                            }}
                          >
                            <i className="fa fa-eye fnt16"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}


                  {/* Second tenure in address verification check and india court record check */}
                  {(checkObject.checkId ===
                    constants.checkIds.ADDRESS_VERIFICATION_CHECK_ID ||
                    checkObject.checkId ===
                      constants.checkIds
                        .INDIA_COURT_RECORD_CHECK_THROUGH_LAW_FIRM_CHECK_ID) && (
                    <div class="row">
                      <div class="col-md-12 second-tenure-addition-row">
                        <button
                          className={`wh20 btn border  rounded-circle p-1 d-flex align-items-center justify-content-center${
                            checkObject.isSubmit || this.props.isCSE
                              ? "border-secondary"
                              : "border-primary"
                          }`}
                          onClick={() =>
                            this.setState({ isSecondTenure: true })
                          }
                          disabled={checkObject.isSubmit || this.props.isCSE}
                        >
                          <i className="fa fa-plus icon-primary"></i>
                        </button>
                        <span className="text-uppercase text-secondary">
                          {" "}
                          Add second Tenure
                        </span>
                      </div>

                      {(this.state.isSecondTenure ||
                        checkObject.from2 ||
                        checkObject.to2) && (
                        <React.Fragment>
                          <div class="col-md-6">
                            <div className="form-group">
                              <label className="text-uppercase text-secondary">
                                Second tenure From
                              </label>
                              <div className="d-flex">
                                <div className="w-100">
                                  <CustomInput
                                    type="Date"
                                    onChange={e => {
                                      checkObject.from2 = e.target.value;
                                      this.props.handleAntecedantSync(
                                        e,
                                        checkObject.checkId,
                                        checkObject.checkNumber,
                                        null,
                                        "from2"
                                      );
                                      this.props.handleCheckChange(checkObject);
                                    }}
                                    value={checkObject.from2}
                                    isDisable={
                                      checkObject.isSubmit || this.props.isCSE
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div className="form-group">
                              <label className="text-uppercase text-secondary">
                                Second Tenure To
                              </label>
                              <div className="d-flex">
                                <div className="w-100">
                                  <CustomInput
                                    type="Date"
                                    onChange={e => {
                                      checkObject.to2 = e.target.value;
                                      this.props.handleAntecedantSync(
                                        e,
                                        checkObject.checkId,
                                        checkObject.checkNumber,
                                        null,
                                        "to2"
                                      );
                                      this.props.handleCheckChange(checkObject);
                                    }}
                                    value={checkObject.to2}
                                    isDisable={
                                      checkObject.isSubmit || this.props.isCSE
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  )}

                  {/* // DOCUMENT verification period of bank statement */}
                  {checkObject.checkId ===
                    constants.checkIds.DOCUMENT_VERIFICATION_CHECK_ID && (
                    <React.Fragment>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-uppercase text-secondary">
                              Period of bank statement From
                            </label>
                            <div className="d-flex">
                              <div className="w-100">
                                <CustomInput
                                  type="Date"
                                  onChange={e => {
                                    checkObject.periodOfBankFrom =
                                      e.target.value;

                                    this.props.handleCheckChange(checkObject);
                                  }}
                                  value={checkObject.periodOfBankFrom}
                                  isDisable={
                                    checkObject.isSubmit || this.props.isCSE
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-uppercase text-secondary">
                              Period of bank statement To
                            </label>
                            <div className="d-flex">
                              <div className="w-100">
                                <CustomInput
                                  type="Date"
                                  onChange={e => {
                                    checkObject.periodOfBankTo = e.target.value;

                                    this.props.handleCheckChange(checkObject);
                                  }}
                                  value={checkObject.periodOfBankTo}
                                  isDisable={
                                    checkObject.isSubmit || this.props.isCSE
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}

                  {/* Submit Button */}
                  <div class="row">
                    <div class="col-md-12 d-flex justify-content-between align-items-center">
                      <button
                        className="btn btn-primary btn-sm "
                        disabled={checkObject.isSubmit || this.props.isCSE}
                        onClick={() => {
                          // this.submitCheck()
                          this.preSubmitCheck();
                        }}
                      >
                        Submit Check
                      </button>
                      <button
                        className="transparent-borderless check-close-btn"
                        onClick={() => this.props.onCheckSelected(null)}
                      >
                        <i
                          className="fa fa-caret-up text-muted"
                          style={{ fontSize: "36px" }}
                        ></i>
                      </button>
                      <PopupMenuCheck
                        raiseInsufficiencyCheck={this.raiseInsufficiencyCheck}
                        openCheckHistoryModel={this.props.openCheckHistoryModel}
                        checkObject={checkObject}
                        //  submitCheck={this.submitCheck}
                        isCSE={this.props.isCSE}
                      />
                    </div>
                  </div>
                  
                </div>
              </div>
              {/* <div className="check-close-btn-container "></div> */}
            </div>
          </div>
        </div>

        {/* // <!--Modal Popup--> */}
        <AddMoreDocumentsModal
          isDocumentListModalOpen={this.state.isDocumentListModalOpen}
          documentsPerCheck={this.state.documentsPerCheck}
          addDocs={document => {
            this.setState({ isDocumentListModalOpen: false });
            this.props.isCSE
              ? this.addDocumentToTheCSE(document)
              : this.addDocumentToTheCheck(document);
          }}
          closeModal={() => this.setState({ isDocumentListModalOpen: false })}
        />
        <ModalTransition>
          {this.state.ishiringListModalOpen && (
            <ModalDialog width="980px" scrollBehavior="false">
              <HiringModal
                closeModal={() =>
                  this.setState({
                    ishiringListModalOpen: false
                    // hiringList: []
                  })
                }
                // hiringList={this.state.hiringList}
                // loading={this.state.loading}
                searchString={this.state.searchString || ""}
                entity={this.state.entity || ""}
              />
            </ModalDialog>
          )}
        </ModalTransition>

        <ModalTransition>
          {this.state.isRaiseResearchModal && (
            <ModalDialog>
              <RaiseResearchModal
                callback={this.preSubmitCheckAfterRaiseResearch}
                researchText={this.getResearchText()}
                closeModal={() =>
                  this.setState({ isRaiseResearchModal: false })
                }
              ></RaiseResearchModal>
            </ModalDialog>
          )}
        </ModalTransition>

        {/* Check Insuff Modal */}
        <ModalTransition>
          {this.state.isInsuffEscalationModalOpen && (
            <ModalDialog>
              <Escalation
                data={{
                  label: "Raising Insufficiency",
                  userName: `${this.props.userInfo.userFirstName} ${this.props.userInfo.userLastName}`,
                  userId: this.props.userInfo.userId,
                  status: "Insufficient",
                  id: this.props.pvtId
                }}
                btnLabel="Raise Insufficiency"
                isCheck={true}
                callback={this.onInsufficiencyRaised}
                onClose={() =>
                  this.setState({ isInsuffEscalationModalOpen: false })
                }
              />
            </ModalDialog>
          )}
        </ModalTransition>
      </div>
    );
  }
}
