import React, { Component } from "react";

import "./pdfViewer.scss";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default class PdfViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      url: null,
      showDocument: true,
      isFirstUpdateAfterHidingPrompt: false,
      scale: 1.0,
      rotate: 0,
    };
  }
  componentDidMount() {
    this.setState({
      pageNumber: this.props.pageNumber || 1,
      url: this.props.url,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.url !== prevProps.url) {
      this.setState({
        pageNumber: this.props.pageNumber || 1,
        url: this.props.url,
        showDocument: true,
        rotate:0,
      });
    } else if (this.state.isFirstUpdateAfterHidingPrompt) {
      this.state.isFirstUpdateAfterHidingPrompt = false;
    } else if (!this.state.showDocument) {
      this.setState({
        pageNumber: this.props.pageNumber || 1,
        url: this.props.url,
        showDocument: true,
        rotate:0,
      });
    }
  }

  decrementPageNumber = () => {
    this.setState({ pageNumber: this.state.pageNumber - 1 });
  };

  incrementPageNumber = () => {
    this.setState({ pageNumber: this.state.pageNumber + 1 });
  };

  incrementPageNumber = () => {
    this.setState({ pageNumber: this.state.pageNumber + 1 });
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  handlePassword = (updatePassword) => {
    var password = prompt("Please enter password", "");
    if (password === null) {
      this.setState({
        showDocument: false,
        isFirstUpdateAfterHidingPrompt: true,
      });
    } else {
      updatePassword(password);
    }
  };

  render() {
    const { pageNumber, url } = this.state;
    return (
      <div className="pdf-viewer-container">
        <div class="pdf-navigation">
          <button
            className="btn btn-primary btn-sm"
            onClick={() => {
              let { rotate } = this.state;
              rotate = rotate + 90;
              if (rotate > 360) {
                rotate = 90;
              }
              this.setState({ rotate });
            }}
            disabled={this.state.pageNumber < 1}
          >
            <i class="fa fa-repeat" aria-hidden="true"></i>
          </button>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => this.decrementPageNumber()}
            disabled={this.state.pageNumber <= 1}
          >
            Prev
          </button>

          <button
            className="btn btn-primary btn-sm"
            onClick={() => this.setState({ scale: this.state.scale - 0.5 })}
            disabled={this.state.scale <= 0.5}
          >
            Zoom -
          </button>

          <span>Page Number - {this.state.pageNumber}</span>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => this.setState({ scale: this.state.scale + 0.5 })}
            disabled={this.state.scale >= 3.0}
          >
            Zoom +
          </button>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => this.incrementPageNumber()}
            disabled={this.state.pageNumber >= this.state.numPages}
          >
            Next
          </button>
        </div>

        {this.state.showDocument && (
          <Document
            file={url}
            onLoadSuccess={this.onDocumentLoadSuccess}
            onPassword={(updatePassword) => this.handlePassword(updatePassword)}
            rotate={this.state.rotate}
          >
            <Page pageNumber={pageNumber} scale={this.state.scale} />
          </Document>
        )}
      </div>
    );
  }
}
