import React, { Component } from "react";
import "./app.scss";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "./screens/login/login";
import Header from "./components/header/header";
import ModalWrapper from "./components/Modalwrapper/modalwrapper";
import { modalManager } from "./utils/modalutils";
import Clientrole from "./components/clientRoleIdSelection/clientRoleIdSelection";
import { connect } from "react-redux";
import { constants } from "./constants";

/**
 * accenture import
 */
import QCDashboardAccenture from "./accenture/screens/qcDashboard/qcDashboard";
import AccentureDashboard from "./accenture/screens/dashboard/dashboard";
import Qcscreen from "./accenture/screens/qcScreen/qcScreen";
import Duplicity from "./accenture/screens/duplicity/duplicity";
import ActionLog from "./hcl/screens/actionLogHistory/actionLogHistory";
import EditDescription from "./hcl/screens/editDescription/editDescription";
// import HclDuplicity from "./hcl/screens/duplicity/duplicity";
import CseResourceDashboard from "./screens/cseResourceDashboard/cseResourceDashboard";
import CseSupervisorDashboard from "./screens/cseSupervisorDashboard/cseSupervisorDashboard";
import Accenturecsedashboard from "./accenture/screens/cseSupervisorDashboard/cseSupervisorDashboard";
import Accenturecseqcdashboard from "./accenture/screens/cseResourceDashboard/cseResourceDashboard";

/**
 * hcl import
 */
import QCDashboardHcl from "./hcl/screens/qcDashboard/qcDashboard";
import HclDashBoard from "./hcl/screens/dashboard/dashboard";
import HclQcscreen from "./hcl/screens/qcScreen/qcScreen";
import HclDuplicity from "./hcl/screens/duplicity/duplicity";
import Hclcseqcdashboard from "./hcl/screens/cseResourceDashboard/cseResourceDashboard";
import Hclcsedashboard from "./hcl/screens/cseSupervisorDashboard/cseSupervisorDashboard";

/**
 * cts import
 */
import QCDashboardCts from "./cts/screens/qcDashboard/qcDashboard";
import CtsDashBoard from "./cts/screens/dashboard/dashboard";
import CtsQcscreen from "./cts/screens/qcScreen/qcScreen";
import CtsDuplicity from "./cts/screens/duplicity/duplicity";
import Ctscseqcdashboard from "./cts/screens/cseResourceDashboard/cseResourceDashboard";
import Ctscsedashboard from "./cts/screens/cseSupervisorDashboard/cseSupervisorDashboard";

import CseBucketDetail from "./screens/cseBucketDetail/cseBucketDetail";

/*tcs import */

import QCDashboardTcs from "./tcs/screens/qcDashboard/qcDashboard";
import TcsDashBoard from "./tcs/screens/dashboard/dashboard";
import TcsQcscreen from "./tcs/screens/qcScreen/qcScreen";
import TcsDuplicity from "./tcs/screens/duplicity/duplicity";
import Tcscseqcdashboard from "./tcs/screens/cseResourceDashboard/cseResourceDashboard";
import Tcscsedashboard from "./tcs/screens/cseSupervisorDashboard/cseSupervisorDashboard";

/*infosys import */

import QCDashboardInfy from "./infosys/screens/qcDashboard/qcDashboard";
import InfyDashBoard from "./infosys/screens/dashboard/dashboard";
import InfyQcscreen from "./infosys/screens/qcScreen/qcScreen";
import InfyDuplicity from "./infosys/screens/duplicity/duplicity";
import Infycseqcdashboard from "./infosys/screens/cseResourceDashboard/cseResourceDashboard";
import Infycsedashboard from "./infosys/screens/cseSupervisorDashboard/cseSupervisorDashboard";

/*wipro import */

import QCDashboardWipro from "./wipro/screens/qcDashboard/qcDashboard";
import WiproDashBoard from "./wipro/screens/dashboard/dashboard";
import WiproQcscreen from "./wipro/screens/qcScreen/qcScreen";
import WiproDuplicity from "./wipro/screens/duplicity/duplicity";
import Wiprocseqcdashboard from "./wipro/screens/cseResourceDashboard/cseResourceDashboard";
import Wiprocsedashboard from "./wipro/screens/cseSupervisorDashboard/cseSupervisorDashboard";

const components = {
  QC_DASHBOARD: "qcDashboard",
  DASHBOARD: "dashboard",
  QC_SCREEN: "qcscreen",
  DUPLICITY: "duplicity",
  CSE_DASHBOARD: "csedashboard",
  CSE_QC_DASHBOARD: "cseqcdashboard",
};

class App extends Component {
  getComponentByClient = (clientId, componentName) => {
    if (componentName === components.QC_DASHBOARD) {
      switch (clientId) {
        case constants.clients.accenture.id:
          return QCDashboardAccenture;
        case constants.clients.hcl.id:
          return QCDashboardHcl;
        case constants.clients.cts.id:
          return QCDashboardCts;
        case constants.clients.tcs.id:
          return QCDashboardTcs;

        case constants.clients.infosys.id:
          return QCDashboardInfy;

        case constants.clients.wipro.id:
          return QCDashboardWipro;
      }
    }
    if (componentName === components.DASHBOARD) {
      switch (clientId) {
        case constants.clients.accenture.id:
          return AccentureDashboard;
        case constants.clients.hcl.id:
          return HclDashBoard;
        case constants.clients.cts.id:
          return CtsDashBoard;
        case constants.clients.tcs.id:
          return TcsDashBoard;

        case constants.clients.infosys.id:
          return InfyDashBoard;

        case constants.clients.wipro.id:
          return WiproDashBoard;
      }
    }
    if (componentName === components.QC_SCREEN) {
      switch (clientId) {
        case constants.clients.accenture.id:
          return Qcscreen;
        case constants.clients.hcl.id:
          return HclQcscreen;
        case constants.clients.cts.id:
          return CtsQcscreen;
        case constants.clients.tcs.id:
          return TcsQcscreen;
        case constants.clients.infosys.id:
          return InfyQcscreen;

        case constants.clients.wipro.id:
          return WiproQcscreen;
      }
    }
    if (componentName === components.DUPLICITY) {
      switch (clientId) {
        case constants.clients.accenture.id:
          return Duplicity;
        case constants.clients.hcl.id:
          return HclDuplicity;
        case constants.clients.cts.id:
          return CtsDuplicity;
        case constants.clients.tcs.id:
          return TcsDuplicity;

        case constants.clients.infosys.id:
          return InfyDuplicity;

        case constants.clients.wipro.id:
          return WiproDuplicity;
      }
    }
    if (componentName === components.CSE_DASHBOARD) {
      switch (clientId) {
        case constants.clients.accenture.id:
          return Accenturecsedashboard;
        case constants.clients.hcl.id:
          return Hclcsedashboard;
        case constants.clients.cts.id:
          return Ctscsedashboard;
        case constants.clients.tcs.id:
          return Tcscsedashboard;

        case constants.clients.infosys.id:
          return Infycsedashboard;

        case constants.clients.wipro.id:
          return Wiprocsedashboard;
      }
    }
    if (componentName === components.CSE_QC_DASHBOARD) {
      switch (clientId) {
        case constants.clients.accenture.id:
          return Accenturecseqcdashboard;
        case constants.clients.hcl.id:
          return Hclcseqcdashboard;
        case constants.clients.cts.id:
          return Ctscseqcdashboard;
        case constants.clients.tcs.id:
          return Tcscseqcdashboard;

        case constants.clients.infosys.id:
          return Infycseqcdashboard;

        case constants.clients.wipro.id:
          return Wiprocseqcdashboard;
      }
    }
  };

  render() {
    const { userInfo } = this.props;

    return (
      <div className="app">
        {userInfo.roleId && <Header></Header>}

        <Switch>
          <Route path="/login" component={Login} />
          <PrivateRoute
            userInfo={userInfo}
            path="/dashboard"
            component={this.getComponentByClient(
              userInfo.clientId,
              components.DASHBOARD
            )}
          />
          <PrivateRoute
            userInfo={userInfo}
            path="/qcdashboard"
            component={this.getComponentByClient(
              userInfo.clientId,
              components.QC_DASHBOARD
            )}
          />
          <PrivateRoute
            userInfo={userInfo}
            path="/cseResourceDashboard"
            component={this.getComponentByClient(
              userInfo.clientId,
              components.CSE_QC_DASHBOARD
            )}

            //component={CseResourceDashboard}
          />
          <PrivateRoute
            userInfo={userInfo}
            path="/cseSupervisorDashboard"
            component={this.getComponentByClient(
              userInfo.clientId,
              components.CSE_DASHBOARD
            )}
            //component={CseSupervisorDashboard}
          />
          <PrivateRoute
            userInfo={userInfo}
            path="/clientrole"
            component={Clientrole}
          />

          <PrivateRoute
            userInfo={userInfo}
            path="/bucketdetails/:id"
            component={this.getComponentByClient(
              userInfo.clientId,
              components.QC_SCREEN
            )}
          />

          <PrivateRoute
            userInfo={userInfo}
            path="/cseBucketDetail/:id"
            component={CseBucketDetail}
          />
          <PrivateRoute
            userInfo={userInfo}
            path="/duplicates"
            component={this.getComponentByClient(
              userInfo.clientId,
              components.DUPLICITY
            )}
            //component={DUPLICATECASE}
          />
          <Route path="/action_log/:id" component={ActionLog} />
          <Route path="/edit_description/" component={EditDescription} />
          <Redirect to="/qcdashboard" />
        </Switch>
        <ModalWrapper ref={(elem) => modalManager.registerModal(elem)} />
      </div>
    );
  }
}

const PrivateRoute = ({ component: Component, userInfo, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return userInfo.accessToken ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.login.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  // login: payload => dispatch(login(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
