/**
 * Qc dashboard component(supervisor)
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import TableWrapper from "./../../../components/Tablewrapper/tablewrapper";
import DROPDOWN from "./../../../components/dropdown/dropdown";
import api from "./../../../apiBaseConfig";
import { modalManager } from "./../../../utils/modalutils";
import Uploadnewcases from "../../../components/uploadnewcases/uploadnewcases";
import CaseDownloadOtp from "./../../components/caseDownloadOtp/caseDownloadOtp";
import Escalation from "./../../../components/Escalation/escalation";
import { genericFormatDateTime, caseCount } from "./../../../utils/commonutils";
import Userlist from "./../../../components/Userlist/userlist";
import { Toast } from "./../../../utils/toastutils";
import ExportCSV from "./../../../components/exporttoexcel/exporttoexcel";
import CaseHistory from "./../../../components/caseHistory/caseHistory";
import CustomInput from "./../../../components/customInput/customInput";
import Pagination from "./../../../components/pagination/pagination";
import { constants } from "./../../../constants";
import CasesCountCard from "./../../../components/casesCountCard/casesCountCard";
import ReactTooltip from "react-tooltip";
import {
  CTS_BUCKET_CASES,
  CTS_BUCKET_COUNT,
  USER_LIST_DATA,
  CASE_HISTORY_COMMENT,
  SUBMIT_COMMENT,
  CTS_EXPORT_TO_CSV,
  CTS_EXPORT_CASES_DOWNLOAD_CSV,
  CTS_CASE_DOWNLOAD_CASES,
  CTS_UPLOAD_CASE_BGV_ID,
  DOWNLOAD_READY_OTP,
} from "./../../../apiurl";

import "./dashboard.scss";
import SubmitOtpModal from "../../components/submitOtpModal";
let bucketCases = [];

const REFRESH_TIME = 60000; //milliseconds;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      downloadUser: "",
      isDownloadReadyOtp: true,
      pendingCount: "",
      column: [
        {
          name: "Request Id",
          field: "request_id",
        },
        {
          name: "Candidate Id",
          field: "candidate_id",
        },
        {
          name: "Candidate Name",
          field: "candidate_name",
        },
        {
          name: "Assign To",
          field: "user_name",
        },
        {
          name: "Bgv Type",
          field: "bgv_type",
        },
        {
          name: "Qc Status",
          field: "status_display",
          template: function (listing) {
            return (
              <div class="alert alert-info mb-0 p-2 text-center">
                {listing.status_display}
              </div>
            );
          },
        },
        {
          name: "Case Received Date",
          field: "date_of_initiation",
          template: (listing) => {
            return (
              <div>{genericFormatDateTime(listing.date_of_initiation)}</div>
            );
          },
        },
        {
          name: "Ars Id",
          field: "bridge_ars_no",
        },
        {
          name: "Last Action Time",
          field: "last_action_time",
          template: (listing) => {
            return <div>{genericFormatDateTime(listing.last_action_time)}</div>;
          },
        },
        {
          name: "Action",
          field: "",
          template: (listing) => {
            const option = [];
            const { userInfo } = props;
            if (
              listing.status != constants.caseStatus.DOWNLOAD_FAILED &&
              listing.status != constants.caseStatus.DOWNLOAD_IN_PROGRESS &&
              listing.status != constants.caseStatus.DOWNLOAD_PENDING
            ) {
              option.push({
                value: "escalating",
                label: "Case History",
                status: "Escalation",
                template: (data) => {
                  return (
                    <CaseHistory
                      bridgeCaseId={data.bridge_case_id || null}
                      isCheck={false}
                      pvtId={data.id}
                      userInfo={userInfo}
                      getCommentHistory={() => {
                        return this.getCaseCommentHistory(data.id);
                      }}
                      label="Case History"
                      buttonName="Add To Comment"
                      submitComment={this.submitCaseComment}
                      closeCommentHistoryModal={() => modalManager.close({})}
                      successMessage={"comment added successfully"}
                      errorMessage={"There is some error in adding comment"}
                    />
                  );
                },
              });
            }

            if (
              listing.status === constants.caseStatus.UNASSIGNED ||
              listing.status === constants.caseStatus.ESCALATION_RESPONDED ||
              listing.status === constants.caseStatus.DUBLICACY_UNASSIGNED ||
              listing.status === constants.caseStatus.ON_HOLD ||
              listing.status === constants.caseStatus.ASSIGNED
            ) {
              option.push({
                value: "assignto",
                label: "Assign To",
                status: "assignto",
                template: (data) => {
                  return (
                    <Userlist
                      data={data}
                      callback={this.assignToUser}
                      userList={this.state.userList}
                    />
                  );
                },
              });
            }

            if (listing.status === constants.caseStatus.ESCALATION_RAISED) {
              option.push({
                value: "escalating",
                label: "Respond To Esclation",
                status: "escalating",
                template: (data) => {
                  const { userInfo } = props;
                  const escalationObject = {
                    ...data,
                    label: "Esacalting",
                    userName: `${userInfo.userFirstName} ${userInfo.userLastName}`,
                    userId: userInfo.userId,
                    status: constants.caseStatus.ESCALATION_RESPONDED,
                    action: "Escalation Responded",
                  };
                  return (
                    <Escalation
                      data={escalationObject}
                      callback={this.changeStatus}
                      btnLabel="Respond To Esclation"
                    />
                  );
                },
              });
            }

            if (
              listing.bridge_ars_no &&
              listing.status != constants.caseStatus.DOWNLOAD_FAILED &&
              listing.status != constants.caseStatus.DOWNLOAD_PENDING &&
              listing.status != constants.caseStatus.DOWNLOAD_IN_PROGRESS &&
              listing.status != constants.caseStatus.SCRAPPED &&
              listing.status !== constants.caseStatus.QC_COMPLETE &&
              listing.status != constants.caseStatus.DISCARD
            ) {
              option.push({
                value: "scrap",
                label: "Scrap",
                status: "scrap",
                template: (data) => {
                  const { userInfo } = props;
                  const escalationObject = {
                    ...data,
                    label: "Scraping",
                    userName: `${userInfo.userFirstName} ${userInfo.userLastName}`,
                    userId: userInfo.userId,
                    status: constants.caseStatus.SCRAPPED,
                    action: "Scrapped",
                  };
                  return (
                    <Escalation
                      data={escalationObject}
                      callback={this.changeStatus}
                      btnLabel="Scrap"
                    />
                  );
                },
              });
            }

            if (
              !listing.bridge_ars_no &&
              listing.status != constants.caseStatus.DOWNLOAD_FAILED &&
              listing.status != constants.caseStatus.DOWNLOAD_PENDING &&
              listing.status != constants.caseStatus.DOWNLOAD_IN_PROGRESS &&
              listing.status != constants.caseStatus.DISCARD
            ) {
              option.push({
                value: "discard",
                label: "Discard",
                status: "discard",
                template: (data) => {
                  const { userInfo } = props;
                  const escalationObject = {
                    ...data,
                    label: "Discarding",
                    userName: `${userInfo.userFirstName} ${userInfo.userLastName}`,
                    userId: userInfo.userId,
                    status: constants.caseStatus.DISCARD,
                    action: "Discarded",
                  };
                  return (
                    <Escalation
                      data={escalationObject}
                      callback={this.changeStatus}
                      btnLabel="discard"
                    />
                  );
                },
              });
            }
            if (listing.status === constants.caseStatus.SCRAPPED) {
              option.push({
                value: "scrap",
                label: "Relive",
                status: "relive",
                template: async (data) => {
                  const body = {
                    relive: true,
                  };
                  try {
                    const response = await api.patch(
                      `${CTS_BUCKET_CASES}${data.id}/`,
                      body
                    );
                    Toast.success({
                      description: response.data.msg,
                    });
                    this.refresh();
                  } catch (e) {
                    console.log(e);
                  }
                },
              });
            }

            return (
              <div>
                <DROPDOWN
                  isDropdown={true}
                  option={option}
                  callback={(data) => {
                    const { userInfo } = props;
                    const result = {
                      label: data.value,
                      statusValue: data.status,
                      ...listing,
                      userName: userInfo.roleName,
                      userId: userInfo.userId,
                    };
                    if (data.label === "Relive") {
                      data.template(result);
                    } else {
                      if (data.label != "Assign To") {
                        modalManager.open({
                          isHeader: false,
                          component: () => data.template(result),
                        });
                      } else {
                        modalManager.open({
                          isHeader: false,
                          width: "small",
                          component: () => data.template(result),
                        });
                      }
                    }
                  }}
                />
              </div>
            );
          },
        },
      ],

      bucket: {},
      bucketName: {},
      cases: [],
      isFilter: false,
      searchBgvId: "",
      searchRequestId: "",
      maxDate: null,
      minDate: null,
      loading: true,
      statusType: constants.caseStatus.ASSIGNED,
      totalBucketCases: [],
      countArrExtracted: [],
      countArrInsuff: [],
      countArrOthers: [],
      countArrDownload: [],
      previous: "",
      next: "",
      page: 1,
      columToExport: [
        {
          name: "age",
          field: "age",
        },
        {
          name: "Request Id",
          field: "request_id",
        },
        {
          name: "Assigned To",
          field: "assigned_to",
        },
        {
          name: "Candidate Name",
          field: "candidate_name",
        },
        {
          name: "Case Received Date",
          field: "case_received_date",
        },
        {
          name: "Last Action Time",
          field: "last_action_time",
        },
        {
          name: "Status",
          field: "status",
        },
        {
          name: "ARS Number",
          field: "ars_id",
        },
      ],
    };
  }

  componentDidMount = () => {
    console.log("cts dashboard");
    this.getBucket();
    this.getCases(constants.caseStatus.ASSIGNED);
    this.getDownloadOTp();
    this.getUserList();
    const setIntervalId = setInterval(() => {
      this.refresh();
    }, REFRESH_TIME);
    this.setState({ setIntervalId });
  };

  componentWillUnmount() {
    clearInterval(this.state.setIntervalId);
  }

  getUserList = async () => {
    try {
      const response = await api.get(`${USER_LIST_DATA}`);
      const data = response.data.map((value) => {
        value.label = value.user_name;
        return value;
      });
      this.setState({ userList: data });
    } catch (e) {
      console.log(e);
    }
  };

  changeStatus = async (obj) => {
    const { userInfo } = this.props;
    const body = {
      status: obj.status,
    };
    if (constants.caseStatus.SCRAPPED === obj.status) {
      body.comment = "Scrapped";
    }
    try {
      const commentObj = {
        comment: obj.comment,
        pvtId: obj.id,
        email: userInfo.email,
        action: obj.action,
        bridgeCaseId: obj.bridge_case_id || null,
      };
      const responseObj = await this.submitCaseComment(commentObj);
      const response = await api.patch(`${CTS_BUCKET_CASES}${obj.id}/`, body);
      Toast.success({
        description: "change status successfully",
      });
      modalManager.close({});
      this.refresh();
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * this is use for getting case history comment
   */
  getCaseCommentHistory = async (pvtId) => {
    let response;
    try {
      response = await api.get(`${CASE_HISTORY_COMMENT}?pvt_id=${pvtId}`);
      const comments = response.data.map((commentObj) => {
        return {
          commentId: commentObj.id,
          createdAt: commentObj.created_at,
          comment: commentObj.comment && commentObj.comment.message,
          action: commentObj.name,
          userName: "",
          roleName: "",
        };
      });
      return Promise.resolve(comments);
    } catch (error) {
      console.log("error", error);
      return Promise.reject(error);
    }
  };

  /**
   * this is for submit the case history comment
   */
  submitCaseComment = async (commentObject) => {
    const { userInfo } = this.props;
    try {
      const action = await api.post(
        `${CASE_HISTORY_COMMENT}?pvt_id=${commentObject.pvtId}`,
        {
          pvt_id: commentObject.pvtId,
          name: commentObject.action
            ? commentObject.action
            : "Comment Added on case",
          created_by: commentObject.email,
        }
      );
      const actionId = action.data.id;
      const response = await api.post(SUBMIT_COMMENT, {
        message: commentObject.comment,
        action: actionId,
        bridge_case_id: commentObject.bridgeCaseId,
      });
      return Promise.resolve(response);
    } catch (error) {
      console.log("error", error);
      return Promise.reject(error);
    }
  };

  refresh = () => {
    const { statusType } = this.state;
    this.getBucket();
    this.getCases(statusType);
    this.getDownloadOTp();
  };

  /**
   * this is use for assign the user by supervisor
   */
  assignToUser = async (data) => {
    const body = {
      status: constants.caseStatus.ASSIGNED,
      user_id: data.userId,
      user_name: data.userName,
    };
    try {
      const response = await api.patch(`${CTS_BUCKET_CASES}${data.id}/`, body);
      modalManager.close({});
      this.refresh();
      Toast.success({
        description: "Assigned to user successfully",
        title: "Success",
      });
    } catch (e) {
      console.log(e);
      Toast.success({ description: "Something went wrong", title: "Error" });
    }
  };

  getDownloadOTp = async () => {
    try {
      const response = await api.get(`${DOWNLOAD_READY_OTP}1/`);
      const downloadReady = response.data.download_ready;
      const downloadUserEmail = response.data.email;
      this.setState({
        isDownloadReadyOtp: downloadReady,
        downloadUser: downloadUserEmail,
      });
    } catch (e) {}
  };

  /**
   * this is use for getting all cases
   */
  getCases = async (type, pageNumber = 1) => {
    this.setState({ loading: true });
    let result = [];
    let { page, previous, next } = this.state;
    try {
      let endPoint = CTS_BUCKET_CASES;
      if (
        type === constants.caseStatus.DOWNLOAD_FAILED ||
        type === constants.caseStatus.DOWNLOAD_PENDING ||
        type === constants.caseStatus.DOWNLOAD_IN_PROGRESS
      ) {
        endPoint = CTS_CASE_DOWNLOAD_CASES;
      }
      const response = await api.get(
        `${endPoint}?status=${type}&page=${pageNumber}`
      );
      result = response.data.results;
      bucketCases = [...result];
      page = pageNumber;
      previous = response.data.previous;
      next = response.data.next;
    } catch (e) {
      console.log(e);
    }
    this.setState({
      cases: result,
      loading: false,
      statusType: type,
      page,
      previous,
      next,
    });
  };

  getPaginationCase = (pageNumber) => {
    const { statusType } = this.state;
    this.getCases(statusType, pageNumber);
  };

  /**
   * getting for all bucket number
   */
  getBucket = async () => {
    let {
      bucket,
      bucketName,
      countArrExtracted,
      statusType,
      countArrInsuff = [],
      countArrDownload = [],
      countArrOthers = [],
      pendingCount,
    } = this.state;
    try {
      const response = await api.get(CTS_BUCKET_COUNT);
      bucket = {};
      response.data.map((bucketData) => {
        bucket[bucketData.id] = bucketData.count;
        bucketName[bucketData.id] = bucketData.name;
      });
      pendingCount = bucket[constants.caseStatus.DOWNLOAD_PENDING];
      countArrExtracted = [
        caseCount(
          bucket[constants.caseStatus.ASSIGNED],
          bucketName[constants.caseStatus.ASSIGNED],
          this.getCases,
          constants.caseStatus.ASSIGNED,
          statusType
        ),
        caseCount(
          bucket[constants.caseStatus.UNASSIGNED],
          bucketName[constants.caseStatus.UNASSIGNED],
          this.getCases,
          constants.caseStatus.UNASSIGNED,
          statusType
        ),
      ];

      countArrInsuff = [
        caseCount(
          bucket[constants.caseStatus.ESCALATION_ASSIGNED],
          bucketName[constants.caseStatus.ESCALATION_ASSIGNED],
          this.getCases,
          constants.caseStatus.ESCALATION_ASSIGNED,
          statusType
        ),
        caseCount(
          bucket[constants.caseStatus.INSUFFICIENT],
          bucketName[constants.caseStatus.INSUFFICIENT],
          this.getCases,
          constants.caseStatus.INSUFFICIENT,
          statusType
        ),
        caseCount(
          bucket[constants.caseStatus.ESCALATION_RAISED],
          bucketName[constants.caseStatus.ESCALATION_RAISED],
          this.getCases,
          constants.caseStatus.ESCALATION_RAISED,
          statusType
        ),
        caseCount(
          bucket[constants.caseStatus.ESCALATION_RESPONDED],
          bucketName[constants.caseStatus.ESCALATION_RESPONDED],
          this.getCases,
          constants.caseStatus.ESCALATION_RESPONDED,
          statusType
        ),
      ];

      countArrDownload = [
        caseCount(
          bucket[constants.caseStatus.DOWNLOAD_PENDING],
          bucketName[constants.caseStatus.DOWNLOAD_PENDING],
          this.getCases,
          constants.caseStatus.DOWNLOAD_PENDING,
          statusType
        ),
        caseCount(
          bucket[constants.caseStatus.DOWNLOAD_IN_PROGRESS],
          bucketName[constants.caseStatus.DOWNLOAD_IN_PROGRESS],
          this.getCases,
          constants.caseStatus.DOWNLOAD_IN_PROGRESS,
          statusType
        ),
        caseCount(
          bucket[constants.caseStatus.DOWNLOAD_FAILED],
          bucketName[constants.caseStatus.DOWNLOAD_FAILED],
          this.getCases,
          constants.caseStatus.DOWNLOAD_FAILED,
          statusType
        ),
      ];
      countArrOthers = [
        caseCount(
          bucket[constants.caseStatus.QC_COMPLETE],
          bucketName[constants.caseStatus.QC_COMPLETE],
          this.getCases,
          constants.caseStatus.QC_COMPLETE,
          statusType
        ),
        caseCount(
          bucket[constants.caseStatus.DUBLICACY_UNASSIGNED],
          bucketName[constants.caseStatus.DUBLICACY_UNASSIGNED],
          this.getCases,
          constants.caseStatus.DUBLICACY_UNASSIGNED,
          statusType
        ),
        caseCount(
          bucket[constants.caseStatus.ON_HOLD],
          bucketName[constants.caseStatus.ON_HOLD],
          this.getCases,
          constants.caseStatus.ON_HOLD,
          statusType
        ),
        caseCount(
          bucket[constants.caseStatus.SCRAPPED],
          bucketName[constants.caseStatus.SCRAPPED],
          this.getCases,
          constants.caseStatus.SCRAPPED,
          statusType
        ),
        caseCount(
          bucket[constants.caseStatus.DISCARD],
          bucketName[constants.caseStatus.DISCARD],
          this.getCases,
          constants.caseStatus.DISCARD,
          statusType
        ),
      ];

      this.setState({
        bucket,
        bucketName,
        countArrExtracted,
        countArrInsuff,
        countArrDownload,
        countArrOthers,
        pendingCount,
      });
    } catch (e) {
      console.log(e);
    }
  };

  onFilterClick = () => {
    const { isFilter, caseType } = this.state;
    this.setState({ isFilter: !isFilter, cases: bucketCases });
  };

  updateBgvId = (value) => {
    this.setState({ searchBgvId: value });
  };

  updateRequestId = (value) => {
    this.setState({ searchRequestId: value });
  };

  /**
   * this help for search the cases according to bgv id
   */
  searchFilter = async (isRequest = false) => {
    const { searchBgvId, statusType, searchRequestId } = this.state;
    try {
      let endPoint = CTS_BUCKET_CASES;
      if (
        statusType === constants.caseStatus.DOWNLOAD_FAILED ||
        statusType === constants.caseStatus.DOWNLOAD_PENDING ||
        statusType === constants.caseStatus.DOWNLOAD_IN_PROGRESS
      ) {
        endPoint = CTS_CASE_DOWNLOAD_CASES;
      }
      const searchQuery = isRequest
        ? "request_id__iexact"
        : "candidate_id__iexact";
      const response = await api.get(
        `${endPoint}?${searchQuery}=${
          isRequest ? searchRequestId : searchBgvId
        }`
      );
      this.setState({ cases: response.data.results });
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * set the from date for search the case
   */
  addEventFromDate = (event) => {
    this.setState({ minDate: event.target.value }, () => {
      const { minDate, maxDate } = this.state;
      if (maxDate === null) {
      } else if (minDate && maxDate >= minDate) {
        this.getDataBetweenDates();
      } else {
        alert("From Date can not be greater than To Date!");
      }
    });
  };

  /**
   * set the to date for search the cases
   */
  addEventToDate = (event) => {
    this.setState({ maxDate: event.target.value }, () => {
      const { minDate, maxDate } = this.state;
      if (minDate === null) {
      } else if (maxDate >= minDate) {
        this.getDataBetweenDates();
      } else {
        alert("From Date can not be greater than To Date!");
      }
    });
  };

  /**
   * search the cases according to from date and to date
   */
  getDataBetweenDates = async () => {
    const { minDate, maxDate, statusType } = this.state;
    try {
      let endPoint = CTS_BUCKET_CASES;
      if (
        statusType === constants.caseStatus.DOWNLOAD_FAILED ||
        statusType === constants.caseStatus.DOWNLOAD_PENDING ||
        statusType === constants.caseStatus.DOWNLOAD_IN_PROGRESS
      ) {
        endPoint = CTS_CASE_DOWNLOAD_CASES;
      }
      const response = await api.get(
        `${endPoint}?date_of_initiation__gte=${minDate}T00:00:00&date_of_initiation__lte=${maxDate}T00:00:00`
      );
      this.setState({ cases: response.data.results });
    } catch (e) {
      console.log(e);
    }
  };

  uploadCase = async (file, name) => {
    let formdata = new FormData();
    formdata.append("uploaded_file", file, file.name);
    try {
      const res = await api.post(CTS_UPLOAD_CASE_BGV_ID, formdata);
      modalManager.close({});
      this.refresh();
      Toast.success({ description: res.data.msg });
      return Promise.resolve(res);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  };
  openModalforNewCases = () => {
    modalManager.open({
      isHeader: true,
      heading: "Upload New Cases",
      component: () => <Uploadnewcases callback={this.uploadCase} />,
    });
  };

  openModalOtp = () => {
    modalManager.open({
      isHeader: true,
      heading: "",
      width: "small",
      component: () => (
        <CaseDownloadOtp
          callback={this.submitOtp}
          updateUser={this.updateUser}
          currentUser={this.state.downloadUser}
        />
      ),
    });
  };
  openSubmitOtp = () => {
    modalManager.open({
      isHeader: true,
      heading: "",
      width: "small",
      component: () => (
        <SubmitOtpModal />
      ),
    });
  };
  submitOtp = async (credentialData, otp) => {
    // console.log(credentialData, otp, "ddddd");
    const body = {
      otp: parseInt(otp),
    };
    console.log(body);
    try {
      const response = await api.patch(`${DOWNLOAD_READY_OTP}1/`, body);
      Toast.success({
        description: "OTP taken for Submission",
      });
      modalManager.close({});
      this.refresh();
    } catch (e) {
      console.log(e);
    }
  };
  updateUser = async (user) => {
    this.setState({ downloadUser: user });
  };

  render() {
    const {
      statusType,
      bucketName,
      data,
      bucket,
      cases,
      isFilter,
      searchBgvId,
      column,
      loading,
      maxDate,
      minDate,
      totalBucketCases,
      countArrExtracted,
      countArrInsuff,
      countArrDownload,
      countArrOthers,
      page,
      previous,
      next,
      columToExport,
      isDownloadReadyOtp,
      searchRequestId,
      pendingCount,
      downloadUser,
    } = this.state;
    return (
      <div className="dashboard-block">
        <div className="dashboard-upper">
          <div>
            <h6>Dashboard</h6>
          </div>

          <div class="row">
            <div class="col-md-2 padding-left">
              <CasesCountCard
                title="Extracted"
                countArr={countArrExtracted}
                statusType={statusType}
              />
            </div>
            <div class="col-md-3 padding-left">
              <CasesCountCard
                title=" Insufficiencies/Escalations"
                countArr={countArrInsuff}
                statusType={statusType}
              />
            </div>
            <div class="col-md-3 padding-left">
              <CasesCountCard
                title="Download Status"
                countArr={countArrDownload}
                statusType={statusType}
              />
            </div>
            <div class="col-md-4 padding-left">
              <CasesCountCard
                title="Others"
                countArr={countArrOthers}
                statusType={statusType}
              />
            </div>
            <div className="download">
            <div className="selectUser">
                <button
                  className="btn btn-primary upload-button-cases"
                  onClick={this.openSubmitOtp}
                >
                  Submit OTP
                </button>
              </div>
              <div className="selectUser" data-for="getUser" data-tip="">
                <ReactTooltip
                  place="top"
                  type="dark"
                  effect="solid"
                  id="getUser"
                  getContent={() => `Current User - ${downloadUser}`}
                />
                <button
                  className="btn btn-primary upload-button-cases"
                  onClick={this.openModalOtp}
                >
                  Select User For Download
                </button>
              </div>
              <button
                className="btn btn-primary upload-button-cases"
                onClick={this.openModalforNewCases}
              >
                Upload New Cases
              </button>
              <div>
                <ExportCSV
                  exportCSVArr={[
                    CTS_EXPORT_TO_CSV,
                    CTS_EXPORT_CASES_DOWNLOAD_CSV,
                  ]}
                  column={columToExport}
                  // data={totalBucketCases}
                  fileName={"dashboard"}
                />
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="loading-block">Loading...</div>
        ) : (
          <div>
            {cases.length === 0 ? (
              <div className="no-data"> NO data found</div>
            ) : (
              <div>
                <div class="row search-section">
                  <div class="col-md-6">
                    <h6 class="mt-2">CONSOLIDATED LIST</h6>
                  </div>
                  <div className="col-md-6 text-right">
                    <button
                      class="btn btn-light export-csv"
                      onClick={() => {
                        this.onFilterClick();
                      }}
                    >
                      Search
                    </button>
                  </div>
                </div>
                {isFilter && (
                  <div className="filter-block">
                    <div class="row shadow-lg bg-white rounded filter-section-block">
                      <div class="col-3">
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control input-section"
                            placeholder="Search Candidate ID"
                            onChange={(event) => {
                              this.updateBgvId(event.target.value);
                            }}
                          />
                          {searchBgvId && (
                            <div class="input-group-append">
                              <button
                                class="btn btn-secondary"
                                type="button"
                                onClick={() => {
                                  this.searchFilter();
                                }}
                              >
                                <i class="fa fa-search"></i>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* search for request id */}
                      <div class="col-3">
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control input-section"
                            placeholder="Search Request ID"
                            onChange={(event) => {
                              this.updateRequestId(event.target.value);
                            }}
                          />
                          {searchRequestId && (
                            <div class="input-group-append">
                              <button
                                class="btn btn-secondary"
                                type="button"
                                onClick={() => {
                                  this.searchFilter(true);
                                }}
                              >
                                <i class="fa fa-search"></i>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* end */}

                      <div class="col-6">
                        <div class="row" style={{ alignItems: "center" }}>
                          <label
                            for="fromDate"
                            class="col col-form-label"
                            style={{ textAlign: "right", fontSize: "15px" }}
                          >
                            From Date
                          </label>
                          <div class="col">
                            <CustomInput
                              type={"Date"}
                              onChange={(e) => {
                                this.addEventFromDate(e);
                              }}
                              value={minDate}
                            ></CustomInput>
                          </div>
                          <label
                            for="toDate"
                            class="col col-form-label"
                            style={{ textAlign: "right", fontSize: "15px" }}
                          >
                            To Date
                          </label>
                          <div class="col">
                            <CustomInput
                              type={"Date"}
                              onChange={(e) => {
                                this.addEventToDate(e);
                              }}
                              value={maxDate}
                            ></CustomInput>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="table-block">
                  <TableWrapper column={column} data={cases} />
                </div>
                <div className="pagination-block">
                  <Pagination
                    previous={previous}
                    next={next}
                    page={page}
                    callback={this.getPaginationCase}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.login.userInfo,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
