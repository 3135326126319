import React, { Component } from "react";
import api from "./../../../apiBaseConfig";
import { connect } from "react-redux";
import { update } from "./../../../screens/login/state/login.actions";
import { HCL_BUCKET_CASES, GET_ACTION_LOG_HISTORY } from "./../../../apiurl";
import LoadingScreen from "./../../../components/loadingScreen/loadingScreen";
import "./actionLogHistory.scss";

class ActionLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caseDetails: {},
      type: "checks",
      checkList: [],
      loading: false,
      actionHistoryLog: [],
    };
  }

  componentDidMount = () => {
    this.getCaseDetail();
  };

  getCaseDetail = async () => {
    const { id } = this.props.match.params;
    const { userInfo } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const myToken = urlParams.get("token");
    if (!userInfo.accessToken) {
      this.props.update({ token: myToken });
    }
    try {
      this.setState({ loading: true });
      const response = await api.get(`${HCL_BUCKET_CASES}${id}`);
      const actionLogresponse = await api.get(
        `${GET_ACTION_LOG_HISTORY}?pvt_id= ${id}`
      );
      const caseDetails = response.data;
      const actionHistoryLog = actionLogresponse.data || [];
      this.setState({
        actionHistoryLog: actionHistoryLog,
        caseDetails: caseDetails,
        checkList: caseDetails.check_list_json || [],
        loading: false,
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  changeTab = (type) => {
    const { hiringObj } = this.state;
    if (type == "checks") {
      this.setState({ type: "checks" });
    } else {
      this.setState({ type: "action" });
    }
  };

  render() {
    const {
      loading,
      type,
      checkList,
      caseDetails,
      actionHistoryLog,
    } = this.state;
    return (
      <div class="h-100 p-8 action-check-block">
        <div className="ars-number-block">
          Ars Number:{" "}
          <span style={{ color: "#40aefd" }}>{caseDetails.bridge_ars_no}</span>
        </div>
        <div className="row tab-section">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <span
                class={"nav-link" + (type == "checks" ? " active" : "")}
                onClick={() => {
                  this.changeTab("checks");
                }}
                style={{ cursor: "pointer" }}
              >
                Checks log
              </span>
            </li>
            <li class="nav-item">
              <span
                class={"nav-link" + (type == "action" ? " active" : "")}
                onClick={() => {
                  this.changeTab("action");
                }}
                style={{ cursor: "pointer" }}
              >
                Action log
              </span>
            </li>
          </ul>
        </div>

        {loading ? (
          <LoadingScreen isLoading={loading}></LoadingScreen>
        ) : (
          <div>
            {type === "action" ? (
              <div>
                {actionHistoryLog.length === 0 ? (
                  <div className="no-data"> no data found</div>
                ) : (
                  <React.Fragment>
                    <table className="table table-bordered table-striped small">
                      <thead>
                        <tr>
                          <th>Check name</th>
                          <th>User action</th>
                          <th>User prompt</th>
                          <th>Action taken by user</th>
                          <th>User</th>
                        </tr>
                      </thead>

                      <tbody>
                        {actionHistoryLog.map((check) => {
                          return (
                            <tr>
                              <td>{check.check_name}</td>
                              <td>{check.user_action}</td>
                              <td>{check.user_prompt}</td>
                              <td>{check.action_taken_by_user}</td>
                              <td>{check.user_name}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </React.Fragment>
                )}
              </div>
            ) : (
              <div>
                {checkList.length === 0 ? (
                  <div className="no-data"> no data found</div>
                ) : (
                  <React.Fragment>
                    <table className="table table-bordered table-striped small">
                      <thead>
                        <tr>
                          <th>Check name</th>
                          <th>Raised by</th>
                          <th>Antecedent name</th>
                          <th>Extracted value</th>
                          <th>Value submited by user</th>
                        </tr>
                      </thead>

                      <tbody>
                        {checkList.map((check) => {
                          return (
                            <tr>
                              <td>{check.check_name}</td>
                              <td>systen</td>
                              <td>
                                {check.check_fields.map((antecedentObj) => {
                                  return (
                                    <tr>{antecedentObj.antecedent_name}</tr>
                                  );
                                })}
                              </td>
                              <td>
                                {check.check_fields.map((antecedentObj) => {
                                  return (
                                    <tr>
                                      {antecedentObj.antecedent_value_initial}
                                    </tr>
                                  );
                                })}
                              </td>
                              <td>
                                {check.check_fields.map((antecedentObj) => {
                                  return (
                                    <tr>{antecedentObj.antecedent_value}</tr>
                                  );
                                })}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </React.Fragment>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.login.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  update: (payload) => dispatch(update(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionLog);
