import React, { Component } from "react";
import { modalManager } from "../../../utils/modalutils";
import './documentTagPopup.scss';

const Document = ({ document, callback }) => {
  function getDocument(data) {
    callback(data);
    modalManager.close({});
  }
  return (
    <div className="doc-type-block">
      <h5 class="text-primary">Add More documents for Tagging</h5>
      <hr class="border-top-0 border-bottom" />
      <div class="row">
        {document.map((data) => {
          return (
            <div class="col-md-12">
              <button
                class="btn btn-outline-primary mb-2 rounded btn-block"
                onClick={() => {
                  getDocument(data);
                }}
              >
                <span class="float-left wh20 btn bg-white rounded-circle p-1 d-flex align-items-center justify-content-center">
                  <i class="fa fa-plus icon-primary"></i>
                </span>
                <span>{data.bridge_name}</span>
              </button>
            </div>
          );
        })}
      </div>

      <div class="row">
        <div class="col-md-6">
          <button
            class="btn btn-danger btn-sm float-left"
            onClick={() => {
              modalManager.close({});
            }}
          >
            Close
          </button>
        </div>
        <div class="col-md-6">
          <button
            class="btn btn-primary btn-sm float-right"
            onClick={() => {
              modalManager.close({});
            }}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default Document;
