import React from "react";
import Asterisk from "../components/asterisk/asterisk";
import CustomInput from "../../../components/customInput/customInput";

export default function FromToAntecedants({
  checkObject,
  handleCheckChange,
  isCSE,
  isFromError,
  isToError,
  handleAntecedantSync = () => {}
}) {
  return (
    <div class="row">
      <div class="col-md-6">
        <div className="form-group">
          <label className="text-uppercase text-secondary">
            From
            <Asterisk />
          </label>
          <div className="d-flex">
            <div className="w-100">
              <CustomInput
                type="Date"
                onChange={e => {
                  checkObject.from = e.target.value;
                  // convertDatesToPeriod(
                  //   e.target.value,
                  //   true,
                  //   antecedantName,
                  //   checkObject
                  // );
                  handleAntecedantSync(
                    e,
                    checkObject.checkId,
                    checkObject.checkNumber,
                    null,
                    "from"
                  );
                  handleCheckChange(checkObject);
                }}
                isError={isFromError}
                value={checkObject.from}
                required={true}
                isDisable={checkObject.isSubmit || isCSE}
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div className="form-group">
          <label className="text-uppercase text-secondary">
            To
            <Asterisk />
          </label>
          <div className="d-flex">
            <div className="w-100">
              <CustomInput
                type="Date"
                onChange={e => {
                  checkObject.to = e.target.value;
                  // convertDatesToPeriod(
                  //   e.target.value,
                  //   false,
                  //   antecedantName,
                  //   checkObject
                  // );
                  handleAntecedantSync(
                    e,
                    checkObject.checkId,
                    checkObject.checkNumber,
                    null,
                    "to"
                  );

                  handleCheckChange(checkObject);
                }}
                isError={isToError}
                value={checkObject.to}
                required={true}
                isDisable={checkObject.isSubmit || isCSE}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
