import React, { Component } from "react";
import Selectbox from "../../../../components/Selectbox/selectbox";
import _isEqual from "lodash/isEqual";
import "./docPicker.scss";
export default class DocPicker extends Component {
  constructor(props) {
    super(props);
    this.state = { documentsSelectFormat: [], selectedDocuments: [] };
  }

  handleDocumentSelection = (selected, documentObject) => {
    documentObject.doc_path = selected.value;
    documentObject.doc_gcspath = selected.dataObject.gcspath;
    documentObject.doc_type = selected.dataObject.type;
    documentObject.id = selected.id;
    this.props.selectedDocumentsChanged(this.selectedDocuments);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const documentsSelectFormat = nextProps.documents.map(document => ({
      label: `${document.type}_${document.id}`,
      value: document.path,
      dataObject: document,
      id: document.id
    }));

    return {
      ...prevState,
      documentsSelectFormat: documentsSelectFormat
    };
  }

  deleteDocumentRow = index => {
    let selectedDocuments = this.selectedDocuments || [];
    selectedDocuments = selectedDocuments.filter(
      (document, documentIndex) => documentIndex !== index
    );

    this.props.selectedDocumentsChanged(selectedDocuments);
  };

  render() {
    this.selectedDocuments = this.props.selectedDocuments;
    return (
      <div className="col-md-12">
        {this.selectedDocuments &&
          this.selectedDocuments.length > 0 &&
          this.selectedDocuments.map((documentObject, index) => (
            <div
              className={`doc-picker-container mb-3 ${
                documentObject.doc_path === ""
                  ? `${
                      this.checkObject.isInsuff
                        ? "row-disabled insuff"
                        : "row-disabled"
                    }`
                  : ""
              }`}
              key={`${documentObject.documentId}-${index}`}
            >
              <div className="document-input-column">
                <div className="input-group-text border-primary fnt-size-inherit text-primary bg-light doc-name document-type-container">
                  <span style={{ overflow: "auto" }}>
                    {documentObject.documentName}
                  </span>
                </div>

                {documentObject.doc_id === 1 && (
                  <input
                    readonly=""
                    type="file"
                    name={`Documentbucketdoc.doc_id${documentObject.doc_id}`}
                    className="form-control border-primary fnt-size-inherit"
                    value={documentObject.doc_path}
                    // onChange need modification
                    onChange={e => console.log("file change", e)}
                  />
                )}
                {documentObject.doc_id !== 1 && (
                  <div className="select-container">
                    <Selectbox
                      option={this.state.documentsSelectFormat}
                      defaultValue={
                        documentObject.doc_path
                          ? {
                              label: `${documentObject.doc_type}_${documentObject.id}`,
                              value: documentObject.doc_path
                            }
                          : null
                      }
                      callback={selected =>
                        this.handleDocumentSelection(selected, documentObject)
                      }
                      isDisable={this.props.isSubmit}
                    />
                  </div>
                )}
                <input
                  type="text"
                  className="document-tag-input"
                  value={documentObject.page}
                  onChange={e => {
                    documentObject.page = e.target.value;
                    this.props.selectedDocumentsChanged(this.selectedDocuments);
                  }}
                ></input>
              </div>

              <div className="document-icons-column d-flex align-items-center">
                <button
                  href="#"
                  className={`m-2 transparent-borderless ${
                    documentObject.doc_path === "" ? "disabled" : ""
                  }`}
                  onClick={() =>
                    this.props.loadDocument(
                      documentObject.doc_path,
                      documentObject.id
                    )
                  }
                  disabled={!documentObject.doc_path}
                >
                  <i className="fa fa-eye fnt16"></i>
                </button>
                <button
                  className={`m-2 transparent-borderless text-danger ${
                    documentObject.doc_path === "" ? "disabled" : ""
                  }`}
                  onClick={() => this.deleteDocumentRow(index)}
                  disabled={this.props.isSubmit || this.props.isCSE}
                >
                  <i className="fa fa-times-circle fnt16"></i>
                </button>
              </div>
            </div>
          ))}
      </div>
    );
  }
}
