import React, { Component } from "react";
import { connect } from "react-redux";
import userImage from "../../assets/images/user.jpg";
import { modalManager } from "../../utils/modalutils";
import Clientrole from "./../../components/clientRoleIdSelection/clientRoleIdSelection";
import { logout } from "../../screens/login/state/login.actions";
import Changeuserrole from "./../../components/changeUserRole/changeUserRole";
import api from "./../../apiBaseConfig";
import { LOGOUT } from "./../../apiurl";
import "./header.scss";

class Header extends Component {
  getUsername = () => {
    const firsname = this.props.userInfo.userFirstName || "";
    const lastname = this.props.userInfo.userLastName || "";
    return `${firsname} ${lastname}`;
  };

  logout = async () => {
    try {
      const response = await api.delete(LOGOUT);
      this.props.logout();
    } catch (e) {
      console.log(e);
    }
  };

  changeUser = () => {
    modalManager.open({
      width: "small",
      isHeader: true,
      heading:'Change User Role',
      component: () => <Changeuserrole />,
    });
  };

  getRoleUrl = (roleId) => {
    switch (roleId) {
      case 71:
        return "/qcdashboard";
      case 72:
        return "/dashboard";
      case 73:
        return "/cseResourceDashboard";
      case 74:
        return "/cseSupervisorDashboard";

      default:
        return null;
    }
  };

  render() {
    const { userInfo } = this.props;
    return (
      <nav
        className="navbar navbar-light bg-light fixed-top p-0"
        style={{ zIndex: 15 }}
        role="navigation"
      >
        <a
          href={this.getRoleUrl(userInfo.roleId)}
          className="navbar-brand text-uppercase text-muted font-weight-bold ml-3"
        >
          Bridge
        </a>
        <span className="navbar-brand text-uppercase  font-weight-bold ">
          <span className="sidebar-company-name">
            {userInfo.clientName}
            <span className="sidebar-roll-name">({userInfo.roleName})</span>
          </span>
        </span>
        <ul className="nav ml-auto">
          <li className="nav-item dropdown text-nowrap">
            <a
              className="nav-link dropdown-toggle ln-ht-16 d-flex caret-mt20"
              href="#"
              data-toggle="dropdown"
            >
              <span className="float-left mr-2">
                <img
                  src={userImage}
                  className="rounded-circle"
                  alt="user"
                ></img>
              </span>
              <span className="float-left">
                <small>Hello</small>
                <br />
                {this.getUsername()}
              </span>
            </a>
            <div className="dropdown-menu">
              <a
                href="#"
                onClick={() => {
                  this.logout();
                }}
                className="dropdown-item"
              >
                Logout
              </a>
              <div>
                <a
                  href="#"
                  onClick={() => {
                    this.changeUser();
                  }}
                  className="dropdown-item"
                >
                  Change User Role
                </a>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.login.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
