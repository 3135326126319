import React, { Component } from "react";
import PdfViewer from "../pdfViewer/pdfViewer";
import FileViewer from "react-file-viewer";
import ImageViewer from "../imageViewer/imageViewer";
import "./documentViewer.scss";

export default class DocumentViewer extends Component {
  render() {
    return (
      <div className="row  border document-section">
        {this.props.currentDocumentURL && (
          <div id="document-view" className="document-view" ref={this.myRef}>
            {this.props.iframeDocumentLoading && (
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            )}
            {this.props.currentDocumentType === "doc" ? (
              <iframe
                width="100%"
                height="700px"
                frameBorder="0"
                onLoad={() => this.props.onIframeLoad()}
                src={`https://docs.google.com/gview?url=${this.props.currentDocumentURL}&embedded=true`}
              ></iframe>
            ) : this.props.currentDocumentType === "pdf" ? (
              <PdfViewer url={this.props.currentDocumentURL} />
            ) : (
              <div
                className="h-100"
                onError={(err) => console.log("error in loading doucment", err)}
                key={Math.random()}
              >
                {/* <FileViewer
                  fileType={this.props.currentDocumentType}
                  filePath={this.props.currentDocumentURL}
                  onError={err => console.log("error in loading doucment", err)}
                /> */}
                <ImageViewer url={this.props.currentDocumentURL} />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
