import React from "react";
import "./pagination.scss";
const Pagination = (props) => {
  return (
    <div>
      <nav aria-label="...">
        <ul class="pagination">
          <li
            class={"page-item" + (!props.previous ? " disabled" : "")}
            onClick={() => {
              if (props.previous) {
                props.callback(props.page - 1);
              }
            }}
          >
            <span class="page-link">Previous</span>
          </li>
          <li class="page-item">
            <span class="page-link"> {props.page}</span>
          </li>
          <li
            class={"page-item" + (!props.next ? " disabled" : "")}
            onClick={() => {
              if (props.next) {
                props.callback(props.page + 1);
              }
            }}
          >
            <a class="page-link" href="#">
              Next
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
