import axios from "axios";
import _get from "lodash/get";
import { logout } from "./screens/login/state/login.actions";
import { store } from "./index";
import { Toast } from "./utils/toastutils";
import { getResponseHeader } from "./utils/commonutils";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    const state = store.getState();
    const authToken = _get(state, "login.userInfo.accessToken") || "";
    if (authToken) {
      config.headers.Authorization = `AuthBridge ${
        _get(state, "login.userInfo.accessToken") || ""
      }`;
    }
    console.log("api request config", config);
    return config;
  },
  function (error) {
    console.log("error in api request", error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    console.log("request successful , response ", response);
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (
      error.response &&
      (error.response.status === 403 || error.response.status === 401)
    ) {
      // Toast.error({
      //   description: "access token invalid ",
      //   title: "Access Denied"
      // });
      store.dispatch(logout());
    }

    console.log("error in api response ", error.response);
    const title = getResponseHeader(error.response.status);
    if (error.response.status > 499) {
      let message;
      if (error.response && error.response.data) {
        message = error.response.data.detail || "Internal Server Error";
      }
      Toast.error({
        hideAfter: 8,
        description: message,
        title: title || "error",
      });
    }
    if (error.response.status <= 499) {
      let message = "";
      if (error.response && error.response.data) {
        const object = error.response.data || {};
        for (const property in object) {
          message = message + object[property] + ",";
        }
        // message = error.response.data.detail || "Bad Request";
      }
      if (error.response && error.response.data.data) {
        alert(
          error.response.data.detail +
            "--- \n" +
            error.response.data.data.join(",  \n")
        );
      } else {
        Toast.warning({
          hideAfter: 8,
          description: message,
          title: title || "Bad Request",
        });
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
