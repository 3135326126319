/***********
 * Modal Wrapper Component
 ***********/

import React, { Component } from "react";
import { modalManager } from "./../../../utils/modalutils";
import DROPDOWN from "./../../../components/dropdown/dropdown";
import TableWrapper from "./../../../components/Tablewrapper/tablewrapper";
import Gap from "./component/gapcheckcomponent";
import Gapcomment from "./component/gapcomment";
import {
  getGapInDays,
  monthNameFromNumber,
  reverseHyphenFormatDate,
  dateToReverseHyphenFormat,
} from "../../../utils/commonutils";
import { CASE_HISTORY_COMMENT, SUBMIT_COMMENT } from "./../../../apiurl";
import api from "./../../../apiBaseConfig";
import { Toast } from "./../../../utils/toastutils";
import "./gapcheck.scss";
import { constants } from "./../../../constants";
import _get from "lodash/get";
import _compact from "lodash/compact";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  updateAddressArray,
  updateEmploymentEducationArray,
  updateEducationAddressMismatchArray,
  updateEmploymentAddressMismatchArray,
} from "./state/gapcheck.actions";

class Gapcheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yearArr: [""],
      monthArray: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      gapAddressArray: [],
      gap: [],
      column: [
        {
          name: "From Date",
          field: "fromDate",
          template: function (listing) {
            return (
              <div>
                {listing.fromDate
                  ? listing.fromDate.split("-").reverse().join("-")
                  : ""}
              </div>
            );
          },
        },
        {
          name: "To Date",
          field: "toDate",
          template: function (listing) {
            return (
              <div>
                {listing.toDate
                  ? listing.toDate.split("-").reverse().join("-")
                  : ""}
              </div>
            );
          },
        },
        {
          name: "Gap Type",
          field: "gap.gapType",
          template: function (listing) {
            return <div>{listing.gap.gapType}</div>;
          },
        },
        {
          name: "Gap Between",
          field: "gapBetween",
          width: "27%;",
          template: function (listing) {
            return <div>{listing.gap.gapBetween}</div>;
          },
        },
        {
          name: "Status",
          field: "status",
          width: "10%;",
          template: function (listing) {
            return <div>{listing.status}</div>;
          },
        },
        {
          name: "Action",
          field: "",
          template: (listing, index) => {
            const option = [];
            if (listing.status === constants.GAP_OBJECT_STATUS_PENDING) {
              option.push({
                value: "escalating",
                label: "Raise Gap Check",
                // listing.gap.gapType ===
                //   constants.EDUCATION_TO_EMPLOYMENT_GAP ||
                // listing.gap.gapType === constants.EMPLOYMENT_TO_EMPLOYMENT_GAP
                //   ? "Raise Gap Check"
                //   : "Raise Insuff",
                status: "Escalation",
                index: index,
                template: (data) => {
                  return (
                    <Gapcomment
                      data={data}
                      callback={this.saveCheckHistoryComment}
                      btnLabel={"Raise Gap Check"}
                      //  { listing.gap.gapType ===
                      //     constants.EDUCATION_TO_EMPLOYMENT_GAP ||
                      //   listing.gap.gapType ===
                      //     constants.EMPLOYMENT_TO_EMPLOYMENT_GAP
                      //     ? "Raise Gap Check"
                      //     : "Raise Insuff"
                      // }
                      header={"raising Gap Check"}
                      //   {listing.gap.gapType ===
                      //     constants.EDUCATION_TO_EMPLOYMENT_GAP ||
                      //   listing.gap.gapType ===
                      //     constants.EMPLOYMENT_TO_EMPLOYMENT_GAP
                      //     ? "raising Gap Check"
                      //     : "raising insuffiencey"
                      // }
                    />
                  );
                },
              });

              option.push({
                value: "delete",
                label: "Delete",
                index: index,
                template: (data) => {
                  return (
                    <Gapcomment
                      data={data}
                      callback={this.deleteAndSaveComment}
                    />
                  );
                },
              });
            }

            option.push({
              value: "edit",
              label: "Edit",
            });

            return (
              <div>
                <DROPDOWN
                  isDropdown={true}
                  option={option}
                  callback={(data) => {
                    if (data.value === "edit") {
                      modalManager.close({});
                    } else {
                      modalManager.open({
                        //isHeader: true,
                        component: () => data.template({ ...data, ...listing }),
                      });
                    }
                  }}
                />
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    console.log(this.props, "ddd");
    // this.calculateAddressGaps(this.props.addresses);
    this.calculateEmploymentEducationGaps(
      this.props.employmentList,
      this.props.EducationCheckList
    );
    // this.calculateEmploymentLocationAdressesMismatch();
    // this.calculateEducationLocationAdressesMismatch();
    const yearArr = [""];
    try {
      let date = this.props.caseReceivedOn || "";
      const caseReceivedOnYear = new Date(date).getFullYear();
      // const firstAddressFromDate = _get(this.props.addresses, "[0].from", null);
      // const firstAddressFromYear = firstAddressFromDate
      //   ? new Date(firstAddressFromDate).getFullYear()
      //   : caseReceivedOnYear - 7;
      // const firstEmploymentFromDate = _get(
      //   this.props.employmentList,
      //   "[0].from",
      //   null
      // );
      // const firstEmploymentFromYear = firstEmploymentFromDate
      //   ? new Date(firstEmploymentFromDate).getFullYear()
      //   : caseReceivedOnYear - 7;

      // const highestEducationFromDate = _get(
      //   this.props.EducationCheckList,
      //   "from",
      //   null
      // );
      // const firstEducationFromYear = highestEducationFromDate
      //   ? new Date(highestEducationFromDate).getFullYear()
      //   : caseReceivedOnYear - 7;
      const startYear = Math.min(
        caseReceivedOnYear - 5
        // firstAddressFromYear,
        // firstEmploymentFromYear,
        // firstEducationFromYear
      );

      for (let year = startYear; year <= caseReceivedOnYear; year++) {
        yearArr.push(year);
      }

      this.setState({ yearArr });
    } catch (error) {
      console.log("error in caseReceivedOn date", error);
    }
  }

  // calculateEmploymentLocationAdressesMismatch = () => {
  //   const employmentAddressMismatchArray = this.props
  //     .employmentAddressMismatchArray;

  //   const employmentList = this.props.employmentList;

  //   const addresses = this.props.addresses;

  //   let { gap } = this.state;

  //   for (let employment of employmentList) {
  //     const filteredAddresses = this.findChecksWithinTimePeriod(
  //       employment.from,
  //       employment.to,
  //       addresses
  //     );

  //     for (let address of filteredAddresses) {
  //       if (address.location.locationId !== employment.location.locationId) {
  //         if (
  //           employmentAddressMismatchArray.findIndex(
  //             (gapObject) =>
  //               gapObject.id ===
  //               `${constants.ADDRESS_TO_ADDRESS_GAP}-${employment.checkUID}`
  //           ) !== -1
  //         ) {
  //           continue;
  //         }

  //         const gapObject = {
  //           address,
  //           employment,
  //           fromDate: employment.from,
  //           toDate: employment.to,
  //           gap: {
  //             gapBetween: `${employment.checkName} ${employment.checkNumber}: ${
  //               employment.verificationSource.label
  //             },${employment.location.address || ""} and ${address.checkName} ${
  //               address.checkNumber
  //             }: ${address.location.address}`,
  //             fromYear: new Date(employment.from).getFullYear(),
  //             fromMonth: new Date(employment.from).getMonth() + 1,
  //             fromMonthInWords: monthNameFromNumber(
  //               new Date(employment.from).getMonth() + 1
  //             ),
  //             toYear: new Date(employment.to).getFullYear(),
  //             toMonth: new Date(employment.to).getMonth() + 1,
  //             toMonthInWords: monthNameFromNumber(
  //               new Date(employment.to).getMonth() + 1
  //             ),
  //             gapType: constants.ADDRESS_MISMATCH_GAP,
  //             actionToTake: "Insuff to be raised",
  //             color: "pink",
  //             position: "up",
  //           },
  //           id: `${constants.ADDRESS_TO_ADDRESS_GAP}-${employment.checkUID}`,
  //           status: constants.GAP_OBJECT_STATUS_PENDING,
  //         };
  //         employmentAddressMismatchArray.push(gapObject);
  //         gap.push(gapObject);
  //       }
  //     }
  //   }

  //   this.props.updateEmploymentAddressMismatchArray(
  //     employmentAddressMismatchArray
  //   );
  // };

  // calculateEducationLocationAdressesMismatch = () => {
  //   const educationAddressMismatchArray = this.props
  //     .educationAddressMismatchArray;

  //   const educationCheck = this.props.EducationCheckList;

  //   if (!educationCheck) {
  //     return;
  //   }

  //   const addresses = this.props.addresses;

  //   let { gap } = this.state;

  //   const filteredAddresses = this.findChecksWithinTimePeriod(
  //     educationCheck.from,
  //     educationCheck.to,
  //     addresses
  //   );

  //   for (let address of filteredAddresses) {
  //     if (
  //       !educationAddressMismatchArray.length &&
  //       address.location.locationId !== educationCheck.location.locationId
  //     ) {
  //       const gapObject = {
  //         address,
  //         educationCheck,
  //         fromDate: educationCheck.from,
  //         toDate: educationCheck.to,
  //         gap: {
  //           gapBetween: `${educationCheck.checkName} ${
  //             educationCheck.checkNumber
  //           }: ${educationCheck.verificationSource.label || ""}, ${
  //             educationCheck.location.address || ""
  //           } and ${address.checkName} ${address.checkNumber}: ${
  //             address.location.address
  //           }`,
  //           fromYear: new Date(educationCheck.from).getFullYear(),
  //           fromMonth: new Date(educationCheck.from).getMonth() + 1,
  //           fromMonthInWords: monthNameFromNumber(
  //             new Date(educationCheck.from).getMonth() + 1
  //           ),
  //           toYear: new Date(educationCheck.to).getFullYear(),
  //           toMonth: new Date(educationCheck.to).getMonth() + 1,
  //           toMonthInWords: monthNameFromNumber(
  //             new Date(educationCheck.to).getMonth() + 1
  //           ),
  //           gapType: constants.ADDRESS_MISMATCH_GAP,
  //           actionToTake: "Insuff to be raised",
  //           color: "pink",
  //           position: "up",
  //         },
  //         id: `${constants.ADDRESS_MISMATCH_GAP}-${educationCheck.checkUID}`,
  //         status: constants.GAP_OBJECT_STATUS_PENDING,
  //       };
  //       educationAddressMismatchArray.push(gapObject);
  //       gap.push(gapObject);
  //     }
  //   }
  //   this.props.updateEducationAddressMismatchArray(
  //     educationAddressMismatchArray
  //   );
  // };

  // findChecksWithinTimePeriod = (from, to, checks) => {
  //   const checksWithinTimePeriod = [];
  //   const fromDate = new Date(from);
  //   const toDate = new Date(to);
  //   for (let check of checks) {
  //     const checkFromDate = new Date(check.from);
  //     const checkToDate = new Date(check.to);
  //     if (checkFromDate >= fromDate && checkFromDate <= toDate) {
  //       checksWithinTimePeriod.push(check);
  //     } else if (checkToDate >= fromDate && checkToDate <= toDate) {
  //       checksWithinTimePeriod.push(check);
  //     } else if (checkFromDate <= fromDate && checkToDate >= toDate) {
  //       checksWithinTimePeriod.push(check);
  //     }
  //   }
  //   return checksWithinTimePeriod;
  // };

  // calculateAddressGaps = (addressesProps) => {
  //   const addresses = [...addressesProps];
  //   let { gap } = this.state;
  //   const gapAddressArray = this.props.gapAddressArray;

  //   let caseReceivedOnForDummyObject = this.getCaseReceivedOnForDummyObject();
  //   const dummyAddressForLastDate = {
  //     checkName: "Present Date",
  //     checkNumber: null,
  //     from: caseReceivedOnForDummyObject,
  //     location: {},
  //     to: caseReceivedOnForDummyObject,
  //     verificationSource: {},
  //     checkUID: -1,
  //   };
  //   addresses.push(dummyAddressForLastDate);

  //   for (let i = 0; i < addresses.length - 1; i++) {
  //     const firstAddress = addresses[i];
  //     const secondAddress = addresses[i + 1];
  //     if (
  //       gapAddressArray.findIndex(
  //         (gapObject) =>
  //           gapObject.id ===
  //           `${constants.ADDRESS_TO_ADDRESS_GAP}-${firstAddress.checkUID}-${secondAddress.checkUID}`
  //       ) !== -1
  //     ) {
  //       continue;
  //     }

  //     let fromDateGap = new Date(firstAddress.to);
  //     fromDateGap.setDate(fromDateGap.getDate() + 1);
  //     fromDateGap = dateToReverseHyphenFormat(fromDateGap);

  //     let toDateGap = new Date(secondAddress.from);
  //     toDateGap.setDate(toDateGap.getDate() - 1);
  //     toDateGap = dateToReverseHyphenFormat(toDateGap);

  //     if (getGapInDays(fromDateGap, toDateGap) >= 31) {
  //       const gapObject = {
  //         fromDate: fromDateGap,
  //         toDate: toDateGap,
  //         gap: {
  //           gapBetween: `${firstAddress.checkName} ${
  //             firstAddress.checkNumber
  //           }: ${firstAddress.location.address} and ${
  //             secondAddress.checkName
  //           } ${secondAddress.checkNumber || ""}: ${
  //             secondAddress.location.address || ""
  //           }`,
  //           fromYear: new Date(fromDateGap).getFullYear(),
  //           fromMonth: new Date(fromDateGap).getMonth() + 1,
  //           fromMonthInWords: monthNameFromNumber(
  //             new Date(fromDateGap).getMonth() + 1
  //           ),
  //           toYear: new Date(toDateGap).getFullYear(),
  //           toMonth: new Date(toDateGap).getMonth() + 1,
  //           toMonthInWords: monthNameFromNumber(
  //             new Date(toDateGap).getMonth() + 1
  //           ),
  //           gapType: constants.ADDRESS_TO_ADDRESS_GAP,
  //           actionToTake: "Insuff to be raised",
  //           color: "green",
  //           position: "down",
  //         },
  //         id: `${constants.ADDRESS_TO_ADDRESS_GAP}-${firstAddress.checkUID}-${secondAddress.checkUID}`,
  //         status: constants.GAP_OBJECT_STATUS_PENDING,
  //       };
  //       gapAddressArray.push(gapObject);
  //       gap.push(gapObject);
  //     }
  //   }
  //   this.props.updateAddressArray(gapAddressArray);
  //   this.setState({ gap });
  // };

  getGapType = (address_1, address_2) => {
    const educationCheckId = [constants.checkIds.EDUCATION_W_CHECK_ID];
    const employmentCheckId = [
      constants.checkIds.PREVIOUS_EMPLOYMENT_VERIFICATION_CHECK_ID,
      constants.checkIds.CURRENT_EMPLOYMENT_VERIFICATION_CHECK_ID,
    ];

    if (address_2.checkId) {
      if (
        educationCheckId.includes(address_1.checkId) &&
        educationCheckId.includes(address_2.checkId)
      ) {
        return constants.EDUCATION_TO_EDUCATION_GAP;
      }
      if (
        employmentCheckId.includes(address_1.checkId) &&
        employmentCheckId.includes(address_2.checkId)
      ) {
        return constants.EMPLOYMENT_TO_EMPLOYMENT_GAP;
      }
      return constants.EDUCATION_TO_EMPLOYMENT_GAP;
    } else {
      if (educationCheckId.includes(address_1.checkId)) {
        return constants.EDUCATION_TO_EDUCATION_GAP;
      } else {
        return constants.EMPLOYMENT_TO_EMPLOYMENT_GAP;
      }
    }
  };

  //updateEmploymentEducationArray
  calculateEmploymentEducationGaps = (
    employmentListProps,
    EducationCheckList
  ) => {
    let employmentList = [];

    if (EducationCheckList && EducationCheckList.length) {
      const highestEducationCheck =
        EducationCheckList[EducationCheckList.length - 1];
      const filterEmploymentList = employmentListProps.filter(
        (item) => new Date(item.from) > new Date(highestEducationCheck.to)
      );
      console.log(filterEmploymentList, "filter");
      employmentList = [...EducationCheckList, ...filterEmploymentList];
      // employmentList.splice(0, 0, EducationCheckList);
    } else {
      employmentList = [...employmentListProps];
    }

    let { gap } = this.state;
    const gapEmploymentEducationArray = this.props.gapEmploymentEducationArray;

    let caseReceivedOnForDummyObject = this.getCaseReceivedOnForDummyObject();
    const dummyEmploymentForLastDate = {
      checkName: "Present Date",
      checkNumber: null,
      from: caseReceivedOnForDummyObject,
      location: {},
      to: caseReceivedOnForDummyObject,
      verificationSource: {},
    };

    employmentList.push(dummyEmploymentForLastDate);

    for (let i = 0; i < employmentList.length - 1; i++) {
      const firstAddress = employmentList[i];
      const secondAddress = employmentList[i + 1];

      if (
        gapEmploymentEducationArray.findIndex(
          (gapObject) =>
            gapObject.id ===
            `${this.getGapType(firstAddress, secondAddress)}-${
              firstAddress.checkUID
            }-${secondAddress.checkUID}`
        ) !== -1
      ) {
        continue;
      }

      let fromDateGap = new Date(firstAddress.to);
      fromDateGap.setDate(fromDateGap.getDate() + 1);
      fromDateGap = dateToReverseHyphenFormat(fromDateGap);

      let toDateGap = new Date(secondAddress.from);
      toDateGap.setDate(toDateGap.getDate() - 1);
      toDateGap = dateToReverseHyphenFormat(toDateGap);

      if (getGapInDays(fromDateGap, toDateGap) >= 180) {
        const gapObject = {
          fromDate: fromDateGap,
          toDate: toDateGap,

          gap: {
            gapBetween: `${firstAddress.checkName} ${
              firstAddress.checkNumber
            }: ${firstAddress.verificationSource.label || ""},${
              firstAddress.location.address || ""
            } and ${secondAddress.checkName} ${
              secondAddress.checkNumber || ""
            }: ${secondAddress.verificationSource.label || ""},${
              secondAddress.location.address || ""
            }`,
            gapBetweenForGapCheck: `${firstAddress.verificationSource.label} and ${secondAddress.verificationSource.label}`,

            fromYear: new Date(fromDateGap).getFullYear(),
            fromMonth: new Date(fromDateGap).getMonth() + 1,
            fromMonthInWords: monthNameFromNumber(
              new Date(fromDateGap).getMonth() + 1
            ),
            toYear: new Date(toDateGap).getFullYear(),
            toMonth: new Date(toDateGap).getMonth() + 1,
            toMonthInWords: monthNameFromNumber(
              new Date(toDateGap).getMonth() + 1
            ),
            gapType: this.getGapType(firstAddress, secondAddress),
            actionToTake: "Gap check to be raised",
            color: "yellow",
            position: "up",
          },
          id: `${this.getGapType(firstAddress, secondAddress)}-${
            firstAddress.checkUID
          }-${secondAddress.checkUID}`,
          status: constants.GAP_OBJECT_STATUS_PENDING,
        };
        gapEmploymentEducationArray.push(gapObject);
        gap.push(gapObject);
      }
    }
    console.log(gap, "employment");
    this.props.updateEmploymentEducationArray(gapEmploymentEducationArray);

    this.setState({ gap });
  };

  getCaseReceivedOnForDummyObject = () => {
    let caseReceivedOnForDummyObject = new Date(this.props.caseReceivedOn);
    caseReceivedOnForDummyObject.setDate(
      caseReceivedOnForDummyObject.getDate() + 1
    );
    caseReceivedOnForDummyObject = dateToReverseHyphenFormat(
      caseReceivedOnForDummyObject
    );
    return caseReceivedOnForDummyObject;
  };

  submitCaseComment = async (commentObject) => {
    const { userInfo } = this.props;
    try {
      const action = await api.post(
        `${CASE_HISTORY_COMMENT}?pvt_id=${commentObject.pvtId}`,
        {
          pvt_id: commentObject.pvtId,
          name: commentObject.action
            ? commentObject.action
            : "Comment Added on case",
          created_by: commentObject.email,
        }
      );
      const actionId = action.data.id;
      const response = await api.post(SUBMIT_COMMENT, {
        message: commentObject.comment,
        action: actionId,
        bridge_case_id: commentObject.bridgeCaseId,
      });
      return Promise.resolve(response);
    } catch (error) {
      console.log("error", error);
      return Promise.reject(error);
    }
  };

  deleteAndSaveComment = async (data) => {
    const { gap } = this.state;
    const { userId, caseId, pvtId, email } = this.props;
    const commentObj = {
      comment: data.comment,
      pvtId: pvtId,
      email: email,
      action: "gap Comment",
      bridgeCaseId: caseId || null,
    };
    try {
      const responseObj = await this.submitCaseComment(commentObj);

      // if (data.gap.gapType === constants.ADDRESS_TO_ADDRESS_GAP) {
      //   let gapAddressArray = this.props.gapAddressArray;
      //   gapAddressArray.forEach((addressObject) => {
      //     if (addressObject.id === data.id) {
      //       addressObject.status = constants.GAP_OBJECT_STATUS_DELETED;
      //     }
      //   });
      //   this.props.updateAddressArray(gapAddressArray);
      // }

      if (
        data.gap.gapType === constants.EDUCATION_TO_EMPLOYMENT_GAP ||
        data.gap.gapType === constants.EMPLOYMENT_TO_EMPLOYMENT_GAP ||
        data.gap.gapType === constants.EDUCATION_TO_EDUCATION_GAP
      ) {
        let gapEmploymentEducationArray = this.props
          .gapEmploymentEducationArray;
        gapEmploymentEducationArray.forEach((gapObject) => {
          if (gapObject.id === data.id) {
            gapObject.status = constants.GAP_OBJECT_STATUS_DELETED;
          }
        });

        this.props.updateEmploymentEducationArray(gapEmploymentEducationArray);
      }

      // if (data.gap.gapType === constants.ADDRESS_MISMATCH_GAP) {
      //   let educationAddressMismatchArray = this.props
      //     .educationAddressMismatchArray;
      //   educationAddressMismatchArray.forEach((gapObject) => {
      //     if (gapObject.id === data.id) {
      //       gapObject.status = constants.GAP_OBJECT_STATUS_DELETED;
      //     }
      //   });
      //   let employmentAddressMismatchArray = this.props
      //     .employmentAddressMismatchArray;
      //   employmentAddressMismatchArray.forEach((gapObject) => {
      //     if (gapObject.id === data.id) {
      //       gapObject.status = constants.GAP_OBJECT_STATUS_DELETED;
      //     }
      //   });

      //   this.props.updateEducationAddressMismatchArray(
      //     educationAddressMismatchArray
      //   );
      //   this.props.updateEmploymentAddressMismatchArray(
      //     employmentAddressMismatchArray
      //   );
      // }

      modalManager.close({});
      Toast.success({
        description: "Add comment successfully",
        title: "Success",
      });
      return Promise.resolve(responseObj);
    } catch (error) {
      Toast.error({ description: "something went wrong", title: "error" });
      return Promise.reject(error);
      console.log("error", error);
    }
  };

  saveCheckHistoryComment = async (gapObject) => {
    const { gap, index, fromDate, toDate, comment } = gapObject;
    try {
      return await this.props.handleGapRaiseInsuff(
        gap,
        comment,
        fromDate,
        toDate,
        gapObject
      );
    } catch (e) {
      return Promise.reject(e);
    }
  };

  isGapCheck = (data) => {
    const gap = [
      // ...this.props.gapAddressArray,
      ...this.props.gapEmploymentEducationArray,
      // ...this.props.educationAddressMismatchArray,
      // ...this.props.employmentAddressMismatchArray,
    ];
    const s = gap.filter(
      (value) =>
        value.gap.fromMonth === data.month && data.year === value.gap.fromYear
    );
    return (
      <div id={`${data.year}-${data.month}`}>
        {s.length ? (
          <Gap
            position={s[0].gap.position}
            color={s[0].gap.color}
            data={s[0]}
          />
        ) : (
          <span>.</span>
        )}
      </div>
    );
  };

  componentDidUpdate(prevProps, prevState) {
    this.renderLines();
  }

  renderLines = () => {
    const monthIdPositionMap = {};
    for (let year of this.state.yearArr) {
      for (let month of this.state.monthArray) {
        const id = `${year}-${month}`;
        const elem = document.getElementById(id);
        if (elem) {
          console.log(elem.getClientRects()[0].x, elem.getClientRects()[0].y);
          monthIdPositionMap[id] = {
            x: elem.getClientRects()[0].x,
            y: elem.getClientRects()[0].y,
          };
        }
      }
    }

    // this.renderAddressLine(monthIdPositionMap);
    this.renderEducationEmploymentLine(monthIdPositionMap);
  };

  renderEducationEmploymentLine = (monthIdPositionMap) => {
    let employmentListProps = this.props.employmentList;
    let EducationCheckList = this.props.EducationCheckList;
    let employmentList = [];
    if (this.props.EducationCheckList && this.props.EducationCheckList.length) {
      const highestEducationCheck =
        EducationCheckList[EducationCheckList.length - 1];
      const filterEmploymentList = employmentListProps.filter(
        (item) => new Date(item.from) > new Date(highestEducationCheck.to)
      );
      employmentList = [...EducationCheckList, ...filterEmploymentList];
      //employmentList.splice(0, 0, EducationCheckList);
    } else {
      employmentList = employmentListProps;
    }
    employmentList.map((employment) => {
      const from = employment.from || "";
      const to = employment.to || "";
      const [year, month] = from.split("-");
      const [year2, month2] = to.split("-");
      console.log({ employment });
      const fromId = `${year}-${parseInt(month)}`;
      const toId = `${year2}-${parseInt(month2)}`;
      let newDiv;
      if (!document.getElementById(`blueline-${fromId}-${toId}`)) {
        newDiv = document.createElement("div");
      } else {
        newDiv = document.getElementById(`blueline-${fromId}-${toId}`);
      }

      newDiv.className = "blue-address-line-container";
      newDiv.id = `blueline-${fromId}-${toId}`;
      console.log("monthIdPositionMap", monthIdPositionMap, [toId], [fromId]);
      let width = parseInt(
        (monthIdPositionMap[toId] ? monthIdPositionMap[toId].x : 0) -
          (monthIdPositionMap[fromId] ? monthIdPositionMap[fromId].x : 0)
      );
      console.log(
        "width",
        width,
        monthIdPositionMap[toId],
        monthIdPositionMap[fromId]
      );
      newDiv.style.width = `${width}px`;
      newDiv.innerHTML = `
      <i
      class="fa fa-circle-o blueline-circle"
      aria-hidden="true"
    ></i>
    <div class="blue-address-line"></div>
    <i
    class="fa fa-map-marker blueline-marker "
    aria-hidden="true"
  ></i>
  <span class="gapcheck-line-info-text ">${employment.verificationSource.label}, ${employment.location.address}</span>
     
      <div class="blue-address-line"></div>
      <i
      class="fa fa-circle-o blueline-circle"
      aria-hidden="true"
    ></i>
     `;
      // newDiv.appendChild(newContent);

      const elem = document.getElementById(fromId);
      console.log("elem", elem, fromId);
      if (elem) {
        console.log("inside123");
        elem.parentNode.insertBefore(newDiv, elem);
      }
    });
  };

  // renderAddressLine = (monthIdPositionMap) => {
  //   this.props.addresses.map((address) => {
  //     const from = address.from || "";
  //     const to = address.to || "";
  //     const [year, month] = from.split("-");
  //     const [year2, month2] = to.split("-");
  //     const fromId = `${year}-${parseInt(month)}`;
  //     const toId = `${year2}-${parseInt(month2)}`;
  //     let newDiv;
  //     if (!document.getElementById(`adressline-${fromId}-${toId}`)) {
  //       newDiv = document.createElement("div");
  //     } else {
  //       newDiv = document.getElementById(`adressline-${fromId}-${toId}`);
  //     }

  //     let newContent = document.createTextNode("here");
  //     newDiv.className = "red-address-line-container";
  //     newDiv.id = `adressline-${fromId}-${toId}`;
  //     console.log(
  //       "monthIdPositionMap",
  //       monthIdPositionMap,
  //       monthIdPositionMap[toId] ? monthIdPositionMap[toId].x : 0,
  //       monthIdPositionMap[fromId] ? monthIdPositionMap[fromId].x : 0,

  //       parseInt(
  //         (monthIdPositionMap[toId] ? monthIdPositionMap[toId].x : 0) -
  //           (monthIdPositionMap[fromId] ? monthIdPositionMap[fromId].x : 0)
  //       )
  //     );
  //     let width = parseInt(
  //       (monthIdPositionMap[toId] ? monthIdPositionMap[toId].x : 0) -
  //         (monthIdPositionMap[fromId] ? monthIdPositionMap[fromId].x : 0)
  //     );

  //     newDiv.style.width = `${width}px`;
  //     newDiv.innerHTML = `
  //     <i
  //     class="fa fa-circle-o redline-circle"
  //     aria-hidden="true"
  //   ></i>
  //   <div class="red-address-line"></div>
  //   <i
  //   class="fa fa-map-marker redline-marker "
  //   aria-hidden="true"
  // ></i>
  // <span class="gapcheck-line-info-text"> ${address.location.address}</span>

  //     <div class="red-address-line"></div>
  //     <i
  //     class="fa fa-circle-o redline-circle"
  //     aria-hidden="true"
  //   ></i>
  //    `;
  //     // newDiv.appendChild(newContent);

  //     const elem = document.getElementById(fromId);
  //     console.log("elem", elem, fromId);
  //     if (elem) {
  //       console.log("inside123");
  //       elem.parentNode.insertBefore(newDiv, elem);
  //     }
  //   });
  // };

  render() {
    const { yearArr, column, monthArray } = this.state;
    const gap = [
      // ...this.props.gapAddressArray,
      ...this.props.gapEmploymentEducationArray,
      // ...this.props.educationAddressMismatchArray,
      // ...this.props.employmentAddressMismatchArray,
    ];
    console.log(gap, "gap");
    const { addresses, employmentList, EducationCheckList } = this.props;
    let arr = [];
    // arr.push(EducationCheckList);
    arr = [...arr, ...EducationCheckList, ...employmentList];
    console.log(arr, "arrr");
    return (
      <div className="gap-block">
        <div className="gap-check-block">
          {yearArr.map((data) => {
            return (
              <div className="block-section">
                <div
                  className={"gap-check-type-section" + (!data ? " big" : "")}
                >
                  <span className="year">{data}</span>
                  <span className="year_1">{data}</span>
                </div>
                {monthArray.map((month) => {
                  return (
                    <div className="gap-check-type" key={month}>
                      {this.isGapCheck({ year: data, month: month })}
                      {/* {
                        <div>
                          {addresses.map((c) => {
                            if (c && c.from) {
                              const d = c.from.split("-");
                              const m = parseInt(d[1]);
                              if (d[0] == data && m == month) {
                                return (
                                  <div className="address-line">
                                    <div className="line"></div>
                                    <div className="address-circle">
                                      <i
                                        class="fa fa-circle-o"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>
                                );
                              }
                            }

                            if (c && c.to) {
                              const d = c.to.split("-");
                              const m = parseInt(d[1]);
                              if (d[0] == data && m == month) {
                                return (
                                  <div className="address-line">
                                    <div className="line"></div>
                                    <div className="address-circle">
                                      <i
                                        class="fa fa-circle-o"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>
                                );
                              }
                            }
                          })}
                        </div>
                      } */}

                      {
                        <div>
                          {arr.map((c, index) => {
                            if (c && c.from) {
                              const d = c.from.split("-");
                              const m = parseInt(d[1]);
                              if (d[0] == data && m == month) {
                                return (
                                  <div className="address-line-up">
                                    <div className="line-up"></div>
                                    <div className="address-circle-up">
                                      <i
                                        class="fa fa-circle-o"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>
                                );
                              }
                            }

                            if (c && c.to) {
                              const d = c.to.split("-");
                              const m = parseInt(d[1]);
                              if (d[0] == data && m == month) {
                                return (
                                  <div className="address-line-up">
                                    <div className="line-up"></div>
                                    <div className="address-circle-up">
                                      <i
                                        class="fa fa-circle-o"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>
                                );
                              }
                            }
                          })}
                        </div>
                      }
                    </div>
                  );
                })}
              </div>
            );
          })}
          <div className="block-section">
            <div className="gap-check-type-section big"></div>
          </div>
        </div>
        {gap.length ? (
          <div>
            <div className="gap-table-header">
              <div className="gap-left">GAP CHECK IDENTIFICATION</div>
              {/* <div className="gap-right">
                <button
                  className="btn btn-primary mr-2 export-csv raise-gap"
                  disabled
                >
                  RAISE GAP
                </button>
              </div> */}
            </div>
            <div className="gap-table-block">
              <TableWrapper column={column} data={gap} />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // gapAddressArray: state.gapcheck.gapAddressArray,
  gapEmploymentEducationArray: state.ctsGapcheck.gapEmploymentEducationArray,
  // educationAddressMismatchArray: state.gapcheck.educationAddressMismatchArray,
  // employmentAddressMismatchArray: state.gapcheck.employmentAddressMismatchArray,
});

const mapDispatchToProps = (dispatch) => ({
  // updateAddressArray: (gapAddressArray) =>
  //   dispatch(updateAddressArray(gapAddressArray)),
  updateEmploymentEducationArray: (gapEmploymentEducationArray) =>
    dispatch(updateEmploymentEducationArray(gapEmploymentEducationArray)),
  // updateEducationAddressMismatchArray: (educationAddressMismatchArray) =>
  //   dispatch(
  //     updateEducationAddressMismatchArray(educationAddressMismatchArray)
  //   ),
  // updateEmploymentAddressMismatchArray: (employmentAddressMismatchArray) =>
  //   dispatch(
  //     updateEmploymentAddressMismatchArray(employmentAddressMismatchArray)
  //   ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Gapcheck);
