export const constants = {
  ADDRESS_TO_ADDRESS_GAP: "Address to Address Gap",
  EDUCATION_TO_EMPLOYMENT_GAP: "Education to Employment Gap",
  EMPLOYMENT_TO_EMPLOYMENT_GAP: "Employment to Employment Gap",
  EDUCATION_TO_EDUCATION_GAP: "Education to Education Gap",
  ADDRESS_MISMATCH_GAP: "Address Mismatch",
  PERIOD_OF_STAY: "Period of Stay (Date From / To)",
  PERIOD_OF_STAY_COURT_VERIFICATION: "Period of stay",
  PERIOD_OF_EMPLOYMENT: "Period of Employment",
  YEAR_OF_PASSING: "Year of Passing",
  COMPANY_SOURCE_VERIFICATION_TYPE: 2,
  EDUCATION_SOURCE_VERIFICATION_TYPE: 4,
  CITY_SOURCE_VERIFICATION_TYPE: 5,
  COUNTRY_SOURCE_VERIFICATION_TYPE: 6,
  GAP_OBJECT_STATUS_PENDING: "PENDING",
  GAP_OBJECT_STATUS_CHECK_RAISED: "CHECK RAISED",
  GAP_OBJECT_STATUS_DELETED: "DELETED",
  SOURCE_VERIFICATION_TYPE_GAP_CHECK: 1,
  SOURCE_VERIFICATION_TYPE_ADDRESS_VERIFICATION_CHECK: 5,
  SOURCE_VERIFICATION_TYPE_INDIAN_COURT_CHECK: 5,
  checkIds: {
    FORM_16_VERIFICATION_CHECK_ID: 271,
    STATEMENT_OF_FACT_EMPLOYMENT_CHECK_ID: 280,
    PASSPORT_VERIFICATION_CHECK_ID: 44,
    PAN_NUMBER_VERIFICATION_CHECK_ID: 45,
    DRIVING_LICENCE_VERIFICATION_CHECK_ID: 101,
    NATIONAL_IDENTITY_CHECK_ID: 72,
    EDUCATION_W_CHECK_ID: 83,
    EDUCATION_CHECK_ID: 25,
    EDUCATION_VERIFICATION_WRITTEN_CHECK_ID: 144,
    PREVIOUS_EMPLOYMENT_VERIFICATION_CHECK_ID: 41,
    CURRENT_EMPLOYMENT_VERIFICATION_CHECK_ID: 58,
    PREVIOUS_COMPANY_CHECK_ID: 287,
    CURRENT_COMPANY_CHECK_ID: 286,
    BANK_VERIFICATION_CHECK_ID: 208,
    REFERENCE_CHECK_ID: 77,
    CURRENT_ADDRESS_VERIFICATION_CHECK_ID: 57,
    ADDRESS_VERIFICATION_CHECK_ID: 60,
    INDIA_COURT_RECORD_CHECK_THROUGH_LAW_FIRM_CHECK_ID: 90,
    GAP_CHECK_ID: 42,
    PERMANENT_VERIFICATION_CHECK_ID: 1,
    FACIS_LEVEL_3_DATABASE_VERIFICATION_CHECK_ID: 73,
    UAN_CHECK_ID: 285,
    GLOBAL_DATABASE_CHECK_ID: 55,
    GLOBAL_DATABASE_OFAC_CHECK_ID: 56,
    GSA_OIG_MEDICAL_DATABASE_CHECK_ID: 143,
    INDIA_COURT_RECORD_DATABASE_CHECK_ID: 132,
    INDIAN_DATABASE_CREDIT_AND_BANKRUPTCY_CHECK_ID: 66,
    INDIAN_DATABASE_VERIFICATION_CHECK_ID: 47,
    SOCIAL_MEDIA_CHECK_ID: 184,
    DOCUMENT_VERIFICATION_CHECK_ID: 119,
    COMPANY_CHECK_ID: 71,
    INDIAN_POLICE_RECORD_VERIFICATION_THROUGH_LAW_FIRM_CHECK_ID: 84,
    GAP_CHECK_COURT_RECORD_CHECK_VERIFICATION_THROUGH_LAW_FIRM_CHECK_ID: 198,
    GAP_CHECK_POLICE_RECORD_VERIFICATION_THROUGH_LAW_FIRM_CHECK_ID: 192,
    DIRECTORSHIP_CHECK_ID: 70,
    DATABASE_WEB_AND_MEDIA_CHECK_ID: 76,
    INDIA_CREDIT_DEFAULT_DATABASE_CHECK_ID: 63,
    CV_VALIDATION_CHECK_ID: 91,
    PROFESSIONAL_REFERENCE_CHECK_ID: 34,
    EMPLOYMENT_VERIFICATION: 118,
  },
  antecedantId: {
    EDUCATION_PERIOD_ID: 7372,
    ACCEDEMIC_YEAR: 5636,
    DOCUMENT_TYPE_ID: 2352,
    DOCUMENT_VERIFICATION_PERIOD_ID: 3728,
    ADDRESS_PERIOD_OF_STAY: 2568,
    CURRENT_ADDRESS_PERIOD_OF_STAY: 1814,
    CURRENT_ADDRESS_DURATION_OF_STAY: 3634,
    PERMANENT_ADDRESS_PERIOD_OF_STAY: 1848,
    PERMANENT_ADDRESS_DURATION_OF_STAY: 3633,
    ADDRESS_ADDRESS: 594,
    INDIA_COURT_RECORD_PERIOD_OF_STAY: 3632,
    INDIA_COURT_RECORD_PERIOD_OF_STAY_TSS: 1674,
    INDIA_COURT_RECORD_ADDRESS: 1421,
    INDIA_POLICE_RECORD_THROUGH_LAW_FIRM_PERIOD_ID: 1312,
    INDIA_POLICE_RECORD_THROUGH_LAW_FIRM_PERIOD_ID_TSS: 2596,
    CURRENT_EMPLOYMENT_PERIOD_OF_STAY: 1886,
    PREVIOUS_EMPLOYMENT_PERIOD_OF_STAY: 1099,
    DOCUMENT_TYPE: 2352,
    COMPANY_NAME: 3213,
    BANK_NAME: 3214,
    BANK_ACCOUNT_NUMBER: 3215,
    TAN: 4078,
    PAN: 4105,
    FORM_PAN: 6002,
    FORM_TAN: 6003,
    PASSPORT_MRZ1: 2849,
    PASSPORT_MR2: 2850,
    PASSPORT_TYPE: 2843,
    PASSPORT_NUMBER: 213,
    COUNTRY_CODE: 2844,
    PAN_NUMBER: 1438,
    DRIVING_LICENSE_NUMBER: 1579,
    NATIONAL_IDENTITY_CARD_NUMBER: 935,
    EDUCATION_W_ROLL_NO: 1219,
    EDUCATION_ROLL_NO: 92,
    PREVIOUS_EMPLOYMENT_CODE: 132,
    CURRENT_EMPLOYMENT_CODE: 531,
    GAP_TYPE: 189,
    GAP_BETWEEN: 4167,
    GAP_PERIOD_FORM: 185,
    GAP_PERIOD_TO: 186,
    YEAR_OF_PASSING: 1225,
    ADDRESS_PINCODE: 566,
    CTS_GAP_CHECK_POLICE_VERIFICATION_PERIOD_OF_STAY: 3943,
    CTS_GAP_CHECK_POLICE_VERIFICATION_GAP_ADDRESS: 3942,
    CTS_GAP_CHECK_COURT_RECORD_PERIOD_OF_STAY: 4086,
    CTS_GAP_CHECK_COURT_RECORD_GAP_ADDRESS: 4084,
    ADDRESS_TYPE: 1475,
    CTS_DIRECTORDHIP_DOB: 2838,
    INDIA_COURT_RECORD_DATABASE_PERIOD_OF_STAY: 5470,
    CURRENT_EMPLOYMENT_LAST_DRAWN_SALARY: 1827,
    PREVIOUS_EMPLOYMENT_LAST_DRAWN_SALARY: 1868,
    EDUCATION_VERIFICATION_WRITTEN_DEGREE_NAME: 2746,
    EDUCATION_VERIFICATION_COURSE_NAME: 1218,
    EDUCATION_COLLEGE_INSTITUTE_UNIVERSITY_LOCATION: 1216,
    EDUCATION_QUALIFICATION: 1926,
    UAN_DATE_OF_JOINING: 6482,
    UAN_DATE_OF_LEAVING: 6483,
    CURRENT_EMPLOYMENT_TENURE: 528,
    PREVIOUS_EMPLOYMENT_TENURE: 130,
    NATIONAL_IDENTITY_CHECK_MRZ1: 2856,
    NATIONAL_IDENTITY_CHECK_MRZ2: 2857,
    PREVIOUS_EMPLOYMENT_DATE_OF_JOINING: 5227,
    PREVIOUS_EMPLOYMENT_DATE_OF_LEAVING: 5228,
    CURRENT_EMPLOYMENT_DATE_OF_JOINING: 5229,
    CURRENT_EMPLOYMENT_DATE_OF_LEAVING: 5230,
    CURRENT_EMPLOYMENT_DESIGNATION: 529,
    CURRENT_EMPLOYMENT_PERIOD_OF_EMPLOYMENT: 1886,
    CURRENT_EMPLOYMENT_EMPLOYEE_NAME: 6357,
    PREVIOUS_EMPLOYMENT_DESIGNATION: 129,
    PREVIOUS_EMPLOYMENT_EMPLOYEE_NAME: 6358,
    IDENDITY_NO_ADDRESS_VERIFICATION:6618,
    CURRENT_EMPLOYER_CITY:9568,
    PREVIOUS_EMPLOYER_CITY:9567
  },
  caseStatus: {
    UNASSIGNED: "unassigned",
    ASSIGNED: "assigned",
    INSUFFICIENT: "insufficient",
    ESCALATION_ASSIGNED: "escalation_assigned",
    ESCALATION_RESPONDED: "escalation_responded",
    ESCALATION_RAISED: "escalation_raised",
    QC_COMPLETE: "qc_complete",
    ON_HOLD: "on_hold",
    DUBLICACY_UNASSIGNED: "duplicity_unassigned",
    SCRAPPED: "scrapped",
    DOWNLOAD_FAILED: "download_failed",
    DOWNLOAD_PENDING: "download_pending",
    DOWNLOAD_IN_PROGRESS: "download_in_progress",
    DISCARD: "discard",
    EXCEL_UPLOAD: "excel_uploads",
    MIGRATED: "migrated",
  },
  clients: {
    accenture: { name: "ACCENTURE", id: 98281 },
    hcl: { id: 122166, name: "HCL Technologies" },
    cts: {
      id: 170092,
      name: "Cognizant Technology Solutions India Private Limited(CTS)",
    },
    tcs: { id: 270741, name: "TCS" },
    infosys: { id: 214884, name: "INFOSYS LIMITED" },
    wipro: { id: 211905, name: "Wipro Limited" },
  },
  fromToDuration: [
    {
      checkId: 58,
      antecedentId: [1886],
    },
    {
      checkId: 41,
      antecedentId: [1099],
    },
    {
      checkId: 60,
      antecedentId: [2568],
    },
    {
      checkId: 90,
      antecedentId: [3632, 1674],
    },
    {
      checkId: 1,
      antecedentId: [1848, 3633],
    },
    {
      checkId: 57,
      antecedentId: [1814, 3634],
    },
    {
      checkId: 84,
      antecedentId: [1312, 2596],
    },
    {
      checkId: 119,
      antecedentId: [3728],
    },
    {
      checkId: 208,
      antecedentId: [4775],
    },
  ],
  fromToDurationCTS: [
    {
      checkId: 1,
      antecedentId: [3702],
    },
    {
      checkId: 41,
      antecedentId: [130],
    },
    {
      checkId: 90,
      antecedentId: [1674],
    },
    {
      checkId: 84,
      antecedentId: [2596],
    },
    {
      checkId: 60,
      antecedentId: [2568],
    },
    {
      checkId: 198,
      antecedentId: [4086],
    },
    {
      checkId: 192,
      antecedentId: [3943],
    },
    {
      checkId: 58,
      antecedentId: [6794],
    },
  ],
  fromToDurationTCS: [
    // {
    //   checkId: 58,
    //   antecedentId: [1825],
    // },
    // {
    //   checkId: 1,
    //   antecedentId: [138]
    // },
    // {
    //   checkId: 57,
    //   antecedentId: [379]
    // },
    // {
    //   checkId: 132,
    //   antecedentId: [5470],
    // },
  ],
  fromToDurationInfosys: [
    {
      checkId: 1,
      antecedentId: [1848, 3633],
    },
    {
      checkId: 57,
      antecedentId: [1814, 2235],
    },
    {
      checkId: 60,
      antecedentId: [2568],
    },
    {
      checkId: 90,
      antecedentId: [1674],
    },
    {
      checkId: 41,
      antecedentId: [1099],
    },
    {
      checkId: 58,
      antecedentId: [1886],
    },
  ],
  mirrorCheckIds: [
    {
      checkId: 60,
      mirrorId: 90,
      antecedentIdArray: [594, 2568, 2568],
      mirrorAntecedentIdArray: [1421, 3632, 1674],
    },
    {
      checkId: 90,
      mirrorId: 60,
      antecedentIdArray: [1421, 3632, 1674],
      mirrorAntecedentIdArray: [594, 2568, 2568],
    },
  ],
  packageIds: {
    TSS_STANDARD: 3987,
  },

  caseDocumentBridgeIds: {
    APPLICATION_FORM_PDF: 32,
    SIGN_OFF: 91,
    ANY_OTHER_CASE_DOCUMENT: 27,
    DOCUMENT_REFERRED_FOR_DOB: 138,
  },
  isDisableList: [
    7372,
    7070,
    7071,
    7072,
    7073,
    7074,
    7075,
    7076,
    7077,
    7078,
    7079,
    7080,
    7081,
    7082,
    7083,
    7084,
    7085,
    7086,
    7087,
    7088,
    7089,
    528,
    130,
  ],
  exclude_antecedent: [
    6622,
    6613,
    6618,
    6616,
    6623,
    6611,
    6612,
    6614,
    6619,
    6620,
    6621,
    6615,
    6617,
    7090,
    1218,
  ],
  exclude_antecedent_CTS: [1218],
  nid_address_antecedents: [6622],
  nid_type_antecedent: {
    "Aadhaar card": [],
    "Driving License": [6618, 6613],
    "e-aadhaar": [],
    Passport: [
      6611,
      6612,
      6613,
      6614,
      6615,
      6616,
      6617,
      6618,
      6619,
      6620,
      6621,
      7090,
    ],
    "Voter ID": [6618],
  },
};
