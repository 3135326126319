import React, { Component } from "react";
import BucketDetails from "../bucketDetails/bucketDetails";

export default class cseBucketDetail extends Component {
  render() {
    return (
      <BucketDetails
        id={this.props.match.params.id}
        isCSE={this.props.location.state.isCSE}
      />
    );
  }
}
