import React, { Component } from "react";
import Selectbox from "./../Selectbox/selectbox";
import { modalManager } from "./../../utils/modalutils";
import { GET_DOCUMENTS_PER_CHECK } from "./../../apiurl";
import { uniqueId } from "./../../utils/commonutils";
import Document from "./../../accenture/components/documentTagPopup/documentTagPopup";
import api from "./../../apiBaseConfig";
import "./docselection.scss";
let list = [];
export default class DocSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      docArray: [],
      docName: "",
      selectDropdownList: null,
    };
  }
  componentDidMount = () => {
    const { docArray } = this.props;
    this.getDocArray(docArray);
  };

  componentDidUpdate = (prevProps, prevState) => {
    let { docArray } = this.state;
    if (prevProps.docArray !== this.props.docArray) {
      this.getDocArray(this.props.docArray);
    }
  };
  getDocArray = (c) => {
    let { docArray, selectDropdownList } = this.state;
    const { selectDropdown } = this.props;
    if (list.length !== selectDropdown.length || docArray.length === 0) {
      list = [...selectDropdown];
      selectDropdownList = [...selectDropdown];
    }
    docArray = [...c];
    this.setState({ docArray, selectDropdownList });
  };

  getArray = (obj) => {
    let d = { ...obj };
    let { docArray } = this.state;
    d.indexNumber = uniqueId();
    docArray.push(d);
    this.setState({ docArray }, () => {
      this.props.callback(docArray);
    });
  };

  getDoc = () => {
    const { docArray } = this.state;
    this.props.callback(docArray);
  };

  deleteCheck = (index) => {
    let { docArray } = this.state;
    const response = docArray.filter((data) => data.indexNumber != index);
    this.setState({ docArray: response }, () => {
      this.props.callback(response);
    });
  };

  getDropdownList = (listObj) => {
    const { docArray } = this.state;
    if (listObj.bridge_name !== this.state.docName) {
      const tagIds = docArray.map((data) => {
        return data.id;
      });
      const dropDownList = list.filter((obj) => {
        const index = tagIds.findIndex((id) => id === obj.id);
        if (index === -1) {
          return true;
        } else {
          return false;
        }
      });
      this.setState({ selectDropdownList: dropDownList });
    } else {
      this.setState({ selectDropdownList: list });
    }
  };

  render() {
    const { docArray, selectDropdownList } = this.state;
    const {
      documentList,
      loadDocument,
      isDisable,
      documentCheck,
      selectDropdown,
    } = this.props;
    return (
      <div className="container-fluid doc-container">
        <div className="row mb-3">
          <div className="col-md-9">
            <button
              className="wh20 btn border border-primary rounded-circle p-1 d-flex align-items-center justify-content-center transparent-borderless"
              disabled={isDisable}
              onClick={() => {
                modalManager.open({
                  isHeader: false,
                  width: "small",
                  component: () => (
                    <Document
                      document={documentCheck}
                      callback={(data) => {
                        this.getArray(data);
                      }}
                    />
                  ),
                });
              }}
            >
              <i className="fa fa-plus icon-primary"></i>
            </button>
          </div>
        </div>
        <div>
          {docArray.map((data) => {
            return (
              <div className="row doc-block-section" key={data.indexNumber}>
                <div className="doc-left">
                  <div className="input-group doc-block">
                    <div className="doc-type-block">
                      <div className="input-group-text w-100 border-primary fnt-size-inherit text-primary bg-light doc-name">
                        {data.bridge_name}{" "}
                      </div>
                    </div>
                    <div className="doc-section">
                      <Selectbox
                        option={selectDropdownList || selectDropdown}
                        callback={(value) => {
                          data.actual_name = value.label;
                          data.id = value.id;
                          data.gcs_signed_url = value.gcspath;
                          data.document_type = value.documentType;
                          this.setState({ docArray }, () => {
                            this.getDoc();
                          });
                        }}
                        defaultValue={{ label: data.actual_name }}
                        isDisable={data.isDocDisable}
                        menuOpen={() => {
                          this.getDropdownList(data);
                        }}
                      />
                    </div>
                    <div
                      className="expand-dropdown-list"
                      tabindex="0"
                      data-toggle="tooltip"
                      title="full dropdown list"
                    >
                      <input
                        type="checkbox"
                        data-toggle="toggle"
                        checked={this.state.docName == data.bridge_name}
                        onChange={() => {
                          this.setState({
                            docName: data.bridge_name,
                          });
                        }}
                      ></input>
                    </div>
                    <div className="page-number-box">
                      <input
                        type="number"
                        value={data.start_page}
                        className="input-section text-primary"
                        onChange={(e) => {
                          data.start_page = parseInt(e.target.value);
                          this.setState({ docArray }, () => {
                            this.getDoc();
                          });
                        }}
                        disabled={data.isDocDisable}
                      />
                    </div>
                    <div className="page-number-box">
                      <input
                        type="number"
                        value={data.end_page}
                        className="input-section text-primary"
                        onChange={(e) => {
                          data.end_page = parseInt(e.target.value);
                          this.setState({ docArray }, () => {
                            this.getDoc();
                          });
                        }}
                        disabled={data.isDocDisable}
                      />
                    </div>
                  </div>
                </div>
                <div className="doc-right">
                  {data.actual_name && (
                    <span
                      className="m-2 "
                      onClick={() => {
                        loadDocument(
                          data.document_type,
                          data.gcs_signed_url,
                          data.id
                        );
                      }}
                    >
                      <i className="fa fa-eye fnt16"></i>
                    </span>
                  )}
                  {!data.isDocDisable && (
                    <span
                      className="m-2 text-danger "
                      onClick={() => {
                        this.deleteCheck(data.indexNumber);
                      }}
                    >
                      <i className="fa fa-times-circle fnt16"></i>
                    </span>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
