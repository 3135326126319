/***********
 * Modal Wrapper Component
 ***********/

import React, { Component } from "react";
import { modalManager } from "./../../utils/modalutils";
import Selectbox from "./../Selectbox/selectbox";
import api from "./../../apiBaseConfig";
import { USER_LIST } from "./../../apiurl";
import "./userlist.scss";

export default class Userlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      userList: [],
      selectUser: "",
      userId: "",
    };
  }

  componentDidMount = () => {
    //this.getUser();
  };

  // getUser = async () => {
  //   try {
  //     const response = await api.get(`${USER_LIST}`);
  //     const data = response.data.user_list.map((value) => {
  //       value.label = value.user_name;
  //       return value;
  //     });
  //     this.setState({ userList: data });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  submit = () => {
    const { userId, selectUser } = this.state;
    this.props.callback({
      ...this.props.data,
      userId: userId,
      userName: selectUser,
    });
  };

  getData = (data) => {
    this.setState({
      selectUser: data.user_name || data.cse_name,
      userId: data.user_id || data.cse_id,
      user: data,
    });
  };
  render() {
    const { selectUser, user } = this.state;
    const { userList } = this.props;
    return (
      <div className="user-block">
        <h6>Select User</h6>
        <div className="select-section">
          <Selectbox
            option={userList}
            callback={this.getData}
            defaultValue={user}
            menuHeight={100}
          />
        </div>
        {selectUser && (
          <div className="selected-user">
            <span className="head-section">Selected User:</span>
            <span>{selectUser}</span>
          </div>
        )}
        <div className="footer-section-block">
          <button
            class="btn btn-danger btn-sm float-left"
            data-dismiss="modal"
            onClick={() => {
              modalManager.close({});
            }}
          >
            Close
          </button>
          <button
            class="btn btn-primary btn-sm float-right"
            // type="submit"
            disabled={!selectUser}
            onClick={() => {
              this.submit();
            }}
          >
            Assign
          </button>
        </div>
      </div>
    );
  }
}
