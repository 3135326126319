/**
 * duplicate component
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { columnData } from "./column/column";
import { Toast } from "./../../../utils/toastutils";
import api from "./../../../apiBaseConfig";
import {
  WIP_GENERATE_ARS,
  WIP_CHANGE_STATUS_CASE,
  MAP_OLD_ARS_NUMBER_TCS,
} from "./../../../apiurl";
import { withRouter } from "react-router-dom";
import TableWrapper from "./../../../components/Tablewrapper/tablewrapper";
import DUPLICATEARSCASE from "./../../../screens/duplicatecase/component/duplicatearsnumber/duplicatearsnumber";
import LoadingScreen from "./../../../components/loadingScreen/loadingScreen";
import { constants } from "./../../../constants";
import "./duplicity.scss";
import { modalManager } from "./../../../utils/modalutils";

class Duplicity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      userId: "",
      pvtId: "",
      cases: [],
      duplicateCases: [],
      arsBody: {},
      duplicateOption: [],
      option: [
        {
          value: "None",
          label: "Create new case",
          template: async (data) => {
            const { pvtId } = this.state;
            try {
              this.setState({ isLoading: true });
              const response = await Promise.all([this.createNewCase()]);
              Toast.success({
                description: "ARS number generated",
                title: "Success",
              });
              this.setState({ isLoading: false });
              this.props.history.push({
                pathname: `/bucketdetails/${pvtId}`,
              });
            } catch (e) {
              this.setState({ isLoading: false });
            }
          },
        },
        {
          value: "Duplicate ARS number",
          label: "Map Duplicate case",
          template: (data) => {
            const { duplicateCases } = this.state;
            const duplicateData = duplicateCases.map((data) => {
              return {
                ...data,
                isEnable: false,
                arsNumber: data.ars_number,
                arsGenerateDate: data.ars_generate_date,
              };
            });
            return (
              <DUPLICATEARSCASE
                data={data}
                callback={this.submit}
                duplicateCases={duplicateData}
              />
            );
          },
        },
        {
          value: "Duplicity Manager",
          label: "Duplicity Manager",
          template: async (data) => {
            try {
              this.setState({ isLoading: true });
              const response = await Promise.all([
                this.changesStatus(constants.caseStatus.DUBLICACY_UNASSIGNED),
              ]);
              Toast.success({
                description: "changed status Successfully",
                title: "Success",
              });
              this.setState({ isLoading: false });
              this.props.history.push({
                pathname: "/qcdashboard",
              });
            } catch (e) {
              this.setState({ isLoading: false });
            }
          },
        },
      ],
    };
  }

  componentDidMount = () => {
    const {
      actualData,
      userId,
      pvtId,
      duplicates,
      arsBody,
    } = this.props.location.state;
    this.setState({
      duplicateCases: duplicates,
      cases: actualData,
      pvtId: pvtId,
      userId: userId,
      arsBody: arsBody,
    });
  };

  createNewCase = () => {
    const { pvtId } = this.state;
    return api.post(`${WIP_GENERATE_ARS}`, {
      pvt_id: pvtId,
      comment: "New Case created by QC",
      action: "New Case",
    });
  };
  createArs = () => {
    const { pvtId } = this.state;
    return api.post(`${WIP_GENERATE_ARS}`, { pvt_id: pvtId });
  };

  /**
   * map the duplicate ars number
   */
  mapOldArs = (duplicates, data, caseId, actionStr = "", commentStr = "") => {
    let array = [];
    for (let i = 0; i < duplicates.length; i++) {
      array.push(duplicates[i].case_id);
    }
    const body = {
      case_id: caseId,
      duplicates: array,
      comment: commentStr,
      action: actionStr,
    };
    return api.post(`${MAP_OLD_ARS_NUMBER_TCS}`, body);
  };

  changesStatus = (status) => {
    const { pvtId } = this.state;
    const body = {
      status,
    };
    return api.patch(`${WIP_CHANGE_STATUS_CASE}${pvtId}/`, body);
  };

  /**
   *this function use for creating ars number and map old (duplicate) ars number
   */
  submit = async (selectedType, ars, data) => {
    const { pvtId } = this.state;
    let arsString = "";
    for (let i = 0; i < ars.length; i++) {
      arsString = arsString + ars[i].arsNumber + ",";
    }
    try {
      if (selectedType.label == "Create new case") {
        const commentString = `New Case created by QC and mapped to ${arsString}`;
        const response = await this.createArs();
        const mapArs = await this.mapOldArs(
          ars,
          data,
          response.data.data.bridge_case_id,
          "New Case",
          commentString
        );
        Toast.success({
          description: "map old ars and create ars successfully",
          title: "Success",
        });
        this.props.history.push({
          pathname: `/bucketdetails/${pvtId}`,
        });
      }
      if (
        selectedType.label === "Internal error" ||
        selectedType.label === "Client error"
      ) {
        const commentString = `Case scrapped due to ${selectedType.label} and mapped to ${arsString}`;
        const createArs = await this.createArs();
        const response = await Promise.all([
          this.mapOldArs(
            ars,
            data,
            createArs.data.data.bridge_case_id,
            "Scrapped",
            commentString
          ),
          this.changesStatus(constants.caseStatus.SCRAPPED),
        ]);
        Toast.success({
          description: "Case scrapped successfully",
          title: "Success",
        });
        this.props.history.push({
          pathname: "/qcdashboard",
        });
      }
      if (selectedType.label == "Insuff Fulfilment") {
        const commentString = `Case scrapped due to ${selectedType.label} and mapped to ${arsString}`;
        const createArs = await this.createArs();
        const response = await Promise.all([
          this.mapOldArs(
            ars,
            data,
            createArs.data.data.bridge_case_id,
            "Scrapped",
            commentString
          ),
          this.changesStatus(constants.caseStatus.SCRAPPED),
        ]);

        Toast.success({
          description: "Case scrapped successfully",
          title: "Success",
        });
        this.props.history.push({
          pathname: "/qcdashboard",
        });
      }
      modalManager.close({});
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const {
      cases,
      duplicateCases,
      option,
      duplicateOption,
      isLoading,
    } = this.state;
    return (
      <div className="duplicate-block">
        <LoadingScreen isLoading={isLoading}></LoadingScreen>
        <div class="container-fluid pt-3">
          <div class="row mb-3">
            <div class="col-md-12">
              <h6 class="text-primary font-weight-bold mb-3">
                Possible Duplicates
              </h6>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive border border-primary border-2 rounded p-2">
                <TableWrapper
                  column={columnData(option, "", cases[0])}
                  data={cases}
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12">
              <div class="brdr-default brdr-dashed p-2 rounded">
                <h6 class="text-muted font-weight-bold mb-3">
                  Duplicate Cases
                </h6>
                <div class="row">
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <TableWrapper
                        column={columnData(
                          duplicateOption,
                          "duplicate",
                          cases[0]
                        )}
                        data={duplicateCases}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.login.userInfo,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Duplicity)
);
