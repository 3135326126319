import React, { Component } from "react";
import "./gapcheckcomponent.scss";
export default class Gap extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { color = "green", position = "down", data = {} } = this.props;

    return (
      <div>
        <div className="gapcheck-circle">
          <i
            class={"fa fa-arrows-h arrow-circle arrow-circle-" + color}
            aria-hidden="true"
          ></i>
        </div>
        <div
          className={"gap-circle" + (position === "up" ? " gap-circle-up" : "")}
        >
          <i class={"fa fa-circle circle-" + color} aria-hidden="true"></i>
        </div>
        <div
          className={
            "gap-line gap-line-" +
            color +
            (position === "up" ? " gap-line-up" : "")
          }
        ></div>
        <div
          className={
            "gap-template gap-template-" +
            color +
            (position === "up" ? " gap-template-up" : "")
          }
        >
          <div>{`${data.fromDate ? data.fromDate.split("-")[2] : ""}${
            data.gap.fromMonthInWords
          },${data.gap.fromYear}-${
            data.toDate ? data.toDate.split("-")[2] : ""
          }${data.gap.toMonthInWords},${data.gap.toYear}`}</div>
          <div className="gap-type">{data.gap.gapType}</div>
          <div>{data.gap.actionToTake}</div>
        </div>
      </div>
    );
  }
}
