import React, { useState } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import api from "./../../../apiBaseConfig";
import { UPLOAD_EXCEL_CSV } from "./../../../apiurl";
import "./uploadCaseExportToExcel.scss";
import { dateToReverseHyphenFormat } from "../../../utils/commonutils";
import ExportCSV from "../../../components/exporttoexcel/exporttoexcel";

const ExportUploadCaseCSV = ({ fileName, inputDate }) => {
  const [isLoader, setLoader] = useState(false);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportCSVArr = [UPLOAD_EXCEL_CSV];
  const column = [
    {
      name: "User Name",
      field: "abc",
    },
    {
      name: "HRC",
      field: "hrc_id",
    },
    {
      name: "CID Number",
      field: "cid",
    },
    {
      name: "Level for Employment",
      field: "employment_level",
    },
    {
      name: "Entity",
      field: "entity",
    },
    {
      name: "Recuiter Name / CE Initiation date",
      field: "recruiter",
    },
    {
      name: "Date of Offer Made",
      field: "offer_made_date",
    },
    {
      name: "Case received Timing",
      field: "time_of_initiation",
    },
    {
      name: "Receiving mode",
      field: "abc",
    },
    {
      name: "Career Level/Management Level",
      field: "management_level",
    },
    {
      name: "Channel",
      field: "channel",
    },
    {
      name: "Name",
      field: "candidate_name",
    },
    {
      name: "Father Name",
      field: "fathers_name",
    },
    {
      name: "DoB",
      field: "date_of_birth",
    },
    {
      name: "Contact Number",
      field: "mobile_number",
    },
    {
      name: "Mail ID",
      field: "email_address",
    },
    {
      name: "Current Address",
      field: "current_address",
    },
    {
      name: "Current POS",
      field: "current_pos",
    },
    {
      name: "Current City",
      field: "current_city",
    },
    {
      name: "Permenent address",
      field: "permanent_address",
    },
    {
      name: "Permanent City",
      field: "permanent_city",
    },
    {
      name: "Permanent POS",
      field: "permanent_pos",
    },
    {
      name: "CRC Task ID",
      field: "crc_task_no",
    },
    {
      name: "NID NO",
      field: "nid_no",
    },
    {
      name: "NID Type",
      field: "nid_name",
    },
    {
      name: "NID Name",
      field: "candidate_name",
    },
    {
      name: "NID Task ID",
      field: "nid_task_no",
    },
    {
      name: "Unique Identification Number",
      field: "nid_no",
    },
    {
      name: "Offer Letter Acceptance Date",
      field: "offer_accept_date",
    },
    {
      name: "Date of Birth as on document",
      field: "date_of_birth",
    },
    {
      name: "Document Type",
      field: "nid_name",
    },
    {
      name: "DOB Task ID",
      field: "dob_task_no",
    },
    {
      name: "Global Task ID",
      field: "global_task_no",
    },
    {
      name: "Gender",
      field: "gender",
    },
    {
      name: "Address Type Current",
      field: "current_address_type",
    },
    {
      name: "Address Type Permanent",
      field: "permanent_address_type",
    },
    {
      name: "Current Company",
      field: "current_company",
    },
    {
      name: "Previous Company",
      field: "previous_company",
    },
    {
      name: "GDB_Old Task Number",
      field: "global_old_task_no",
    },
    {
      name: "GDB_New Task Number",
      field: "global_new_task_no",
    },
    {
      name: "GDB_Additional Comments",
      field: "global_additional_comments",
    },
    {
      name: "GDB_Reinitiated By",
      field: "global_reinitiated_by",
    },    
    {
      name: "CRC_Old Task Number",
      field: "crc_old_task_no",
    },    
    {
      name: "CRC_New Task Number",
      field: "crc_new_task_no",
    },    
    {
      name: "CRC_Additional Comments",
      field: "crc_additional_comments",
    },    
    {
      name: "CRC_Reinitiated By",
      field: "crc_reinitiated_by",
    },    
    {
      name: "NID_Old Task Number",
      field: "nid_old_task_no",
    },    
    {
      name: "NID_New Task Number",
      field: "nid_new_task_no",
    },    
    {
      name: "NID_Additional Comments",
      field: "nid_additional_comments",
    },    
    {
      name: "NID_Reinitiated By",
      field: "nid_reinitiated_by",
    },    
    {
      name: "DOB_Old Task Number",
      field: "dob_old_task_no",
    },    
    {
      name: "DOB_New Task Number",
      field: "dob_new_task_no",
    },
    {
      name : "DOB_Additional Comments",
      field : "dob_additional_comments",
    },
    {
      name : "DOB_Reinitiated By",
      field : "dob_reinitiated_by",
    },
  ];

  const getResult = (data) => {
    let result = [];
    for (let i = 0; i < data.length; i++) {
      let S = data[i];
      let obj = {};
      for (let j = 0; j < column.length; j++) {
        const g = column[j];
        if (g["field"]) {
          obj[g["name"]] = S[g["field"]];
        }
        if (g["type"]) {
          const bgvId =
            S.verification_type === "PreJoining" ||
            S.verification_type === "Existing"
              ? S.bgv_id
              : S.bgv_id2;
          obj[g["name"]] = bgvId;
        }
      }
      result.push(obj);
    }
    return result;
  };

  const exportDownloadCSV = (exportCSV) => {
    return api.get(`${exportCSV}date_of_initiation__gte=${inputDate}`);
  };

  const getTotalBucketCases = async () => {
    let arr = [];
    for (let i = 0; i < exportCSVArr.length; i++) {
      arr.push(exportDownloadCSV(exportCSVArr[i]));
    }
    try {
      const response = await Promise.all(arr);
      let totalCases = [];
      for (let i = 0; i < response.length; i++) {
        totalCases = [...totalCases, ...response[i].data];
      }
      return Promise.resolve(totalCases);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  };

  const exportToCSV = async (fileName) => {
    let isLoader = true;
    try {
      setLoader(isLoader);
      const totalCases = await getTotalBucketCases();
      const result = getResult(totalCases);
      isLoader = false;
      setLoader(isLoader);
      const ws = XLSX.utils.json_to_sheet(result);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (e) {
      isLoader = false;
      setLoader(isLoader);
      console.log(e);
    }
  };
  return (
    <div className="export-case-block">
      {isLoader ? (
        <div
          class="spinner-border text-info float-right m-2 spinner-block"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      ) : (
        <button
          className="btn btn-success mr-2 export-csv"
          onClick={(e) => exportToCSV(fileName)}
        >
          upload case export to csv
        </button>
      )}
    </div>
  );
};

export default ExportUploadCaseCSV;
