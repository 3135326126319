export const BUCKET = "/cases/buckets";
export const DASHBOARD_CASES = "/cases/bucketCases/?qc=0&status=";
export const SEARCH_BGV_ID = "/cases/bucketCases/?bgvId=";
export const DASHBOARD_CASES_FILTER_BY_DATE = "/cases/bucketCases/?dateFrom=";
export const USER_LIST = "/bridge/getAssignedUserList/";
export const ASSIGN_USER = "/cases/assignUser/";
export const ESCALATION_END_POINT = "/cases/insuffEsc/";
export const QCDASHBOARD_CASE_DETAIL = "/cases/caseDetails/";
export const QCDASHBOARD_CHECKLIST_DETAIL = "/cases/checkListDetails/";
export const QCDASHBOARD_COMMENT_HISTORY = "/cases/getComments/";
export const QCDASHBOARD_ADD_COMMENT = "/cases/addComment/";
export const GET_PACKAGES = "/bridge/fetchMaps/package/";
export const CHECKS_PER_PROCESS = "/bridge/getChecksPerProcess/";
export const UPDATE_PROCESS_ID = "/cases/updateProcessId/";
export const SAVE_CHECK_LIST = "/cases/checkListDetails/";
export const ANTECEDANTS_PER_CHECK_PER_PROCESS =
  "/bridge/getAntecedantsPerCheckPerProcess/";
export const LOAD_ANTECEDANTS_ID_NAME_MAP = "/bridge/fetchMaps/antecedant/";
export const GET_DOCUMENTS_PER_CHECK = "/bridge/getDocumentsPerCheck/";
export const LOAD_DOCUMENT = "/cases/files/Download/";
export const PLAY_PAUSE = "/cases/playPause/";
export const DUPLICATE_CASE = "/cases/caseDuplicity/";
export const SUBMIT_ARS_CASE = "/cases/submitArs/";
export const UPLOAD_FILE = "cases/files/Upload/";
export const FETCH_DOCUMENTS_MAP = "/bridge/fetchMaps/document/";
export const SUBMIT_CHECK = "/cases/submitCheck/";
export const CHANGE_STATUS = "/cases/changeStatus/";
export const ENTITY_DROPDOWN = "/bridge/entityDropdown/";
export const LOCATION_ADDED_SEARCH = "/bridge/locationAddressSearch/";
export const ENTITY_ID_ADDRESS_SEARCH = "/bridge/entityIdAddressSearch/";
export const SAVE_PERSONAL_DATA = "/cases/personalDetails/";
export const SAVE_CASE_DETAILS = "/cases/caseDetails/";
export const HIRING_LIST = "/bridge/hiringList/";
export const MAP_OD_ARS = "/cases/mapOldArs/";
export const RAISE_CHECK_INSUFF = "/cases/raiseCheckInsuff/";
export const FETCH_CASE = "/cases/fetchCase/";
export const ADD_RESEARCH = "/cases/addResearch/";
export const DEFAULT_DOCUMENTS_PER_CHECK =
  "/bridge/getDocumentsToShowPerCheck/";
export const UPLOAD_CSIS = "/cases/csis/";
export const FINAL_SUBMIT = "/cases/finalSubmit/";
export const UPLOAD_BGV_ID = "/cases/caseRequest/";
export const Insuff_Raised = "/cases/raiseCaseInsuff/";

/**
 * bridge api
 */
export const LOCATION_LIST = "/bridge/location/";
export const USER_LIST_DATA = "/bridge/users/";
export const PACKAGE_LIST = "/bridge/packages/";
export const DROPDOWN_SEARCH = "/bridge/search-by-string/";
export const SEARCH_BY_ENTITY_ID = "/bridge/search-by-entity-id/";
export const SEARCH_BY_CITY_ID = "/bridge/search-by-string-and-city-id/";
export const MAP_OLD_ARS_NUMBER = "/bridge/map-old-ars-number/";
export const DOCUMENTS_PER_CHECK = "/bridge/documents-by-check/";
export const GET_HIRING_LIST = "/bridge/hiring-list/";
export const ANTECEDENT_BY_PACKAGE = "/bridge/antecedents-by-check-by-package/";
export const COURSE_LIST = "bridge/search-by-string/";
export const GET_DROP_DOWN_VALUES = "bridge/get-drop-down-values/";

// tcs bridge api

export const LOCATION_LIST_TCS = "/fpbridge/location/";
export const USER_LIST_DATA_TCS = "/fpbridge/users/";
export const PACKAGE_LIST_TCS = "/fpbridge/packages/";
export const DROPDOWN_SEARCH_TCS = "/fpbridge/search-by-string/";
export const SEARCH_BY_ENTITY_ID_TCS = "/fpbridge/search-by-entity-id/";
export const SEARCH_BY_CITY_ID_TCS = "/fpbridge/search-by-string-and-city-id/";
export const MAP_OLD_ARS_NUMBER_TCS = "/fpbridge/map-old-ars-number/";
export const DOCUMENTS_PER_CHECK_TCS = "/fpbridge/documents-by-check/";
export const GET_HIRING_LIST_TCS = "/fpbridge/hiring-list/";
export const ANTECEDENT_BY_PACKAGE_TCS = "/fpbridge/antecedents-by-check-by-package/";

/**
 * user api
 */
export const LOGIN = "/user/log-in/";
export const ROLE_ID = "/user/role-options/";
export const CLIENT = "/shared/clients/";
export const SET_CLIENT = "/user/set-client-role/";
export const LOGOUT = "/user/log-out/";

// tcs user api
export const LOGIN_TCS = "/user/fp-log-in/";

/**
 * accenture api
 */
export const BUCKET_COUNT = "/accenture/bucket/count/";
export const BUCKET_CASES = "/accenture/cases/";
export const FETCH_CASES = "/accenture/bucket/fetch-case";
export const CASE_HISTORY = "/accenture/case-history/";
export const CHANGE_STATUS_CASE = "/accenture/cases/";
export const CASE_HISTORY_COMMENT = "/shared/actions/";
export const SUBMIT_COMMENT = "/shared/comments/";
export const GET_DOCUMENTS = "/shared/documents/";
export const GET_ACTION_LOG_HISTORY="/shared/action-log/"
export const DUPLICITY_CHECK = "/accenture/bridge-migration/duplicity-check/";
export const GENERATE_ARS = "/accenture/bridge-migration/generate-ars/";
export const CSIS_DOWNLOAD = "/accenture/csis/";
export const GET_CHECK_LIST = "/accenture/generate-check-list/";
export const RAISE_CASE_INSUFF =
  "/accenture/bridge-migration/raise-case-insufficiency/";
export const EXPORT_TO_CSV = "/accenture/export-csv/";
export const EXPORT_CASES_DOWNLOAD_CSV = "/accenture/export-case-download-csv/";
export const CHECK_BY_PACKAGE = "/accenture/checks-by-package/";
export const FINAL_SUBMISSION_CASE =
  "/accenture/bridge-migration/final-submit/";
export const CASE_DOWNLOAD_CASES = "/accenture/cases-download/";
export const DOCUMENT_UPLOAD = "/accenture/document-upload/";
export const UPLOAD_CASE_BGV_ID = "/accenture/upload-case/";
export const UPLOAD_EXCEL_CSV="/accenture/export-case-details/?"
export const EXCEL_UPLOADS="/accenture/excel-uploads/"

/**
 * hcl api
 */

export const HCL_BUCKET_COUNT = "/hcl/bucket/count/";
export const HCL_BUCKET_CASES = "/hcl/cases/";
export const HCL_FETCH_CASES = "/hcl/bucket/fetch-case";
export const HCL_EXPORT_TO_CSV = "/hcl/export-csv/";
export const HCL_EXPORT_CASES_DOWNLOAD_CSV = "/hcl/export-case-download-csv/";
export const HCL_CHANGE_STATUS_CASE = "/hcl/cases/";
export const HCL_CASE_DOWNLOAD_CASES = "/hcl/cases-download/";
export const HCL_GET_CHECK_LIST = "/hcl/generate-check-list/";
export const HCL_RAISE_CASE_INSUFF =
  "/hcl/bridge-migration/raise-case-insufficiency/";
export const HCL_CHECK_BY_PACKAGE = "/hcl/checks-by-package/";
export const HCL_DOCUMENT_UPLOAD = "/hcl/document-upload/";
export const HCL_FINAL_SUBMISSION_CASE = "/hcl/bridge-migration/final-submit/";
export const HCL_CSIS_DOWNLOAD = "/hcl/csis/";
export const HCL_DUPLICITY_CHECK = "/hcl/bridge-migration/duplicity-check/";
export const HCL_GENERATE_ARS = "/hcl/bridge-migration/generate-ars/";
export const HCL_UPLOAD_CASE_BGV_ID = "/hcl/upload-case/";
export const HCL_EDIT_CASE_DESCRIPTION = "/hcl/case-instruction/";

/**
 * cts api
 */
export const CTS_BUCKET_COUNT = "/cognizant/bucket/count/";
export const CTS_BUCKET_CASES = "/cognizant/cases/";
export const CTS_FETCH_CASES = "/cognizant/bucket/fetch-case";
export const CTS_EXPORT_TO_CSV = "/cognizant/export-csv/";
export const CTS_EXPORT_CASES_DOWNLOAD_CSV =
  "/cognizant/export-case-download-csv/";
export const CTS_CASE_DOWNLOAD_CASES = "/cognizant/cases-download/";
export const CTS_DOCUMENT_UPLOAD = "/cognizant/document-upload/";
export const CTS_CSIS_DOWNLOAD = "/cognizant/csis/";
export const CTS_GET_CHECK_LIST = "/cognizant/generate-check-list/";
export const CTS_GENERATE_ARS = "/cognizant/bridge-migration/generate-ars/";
export const CTS_DUPLICITY_CHECK =
  "/cognizant/bridge-migration/duplicity-check/";
export const CTS_RAISE_CASE_INSUFF =
  "/cognizant/bridge-migration/raise-case-insufficiency/";
export const CTS_CHECK_BY_PACKAGE = "/cognizant/checks-by-package/";
export const CTS_FINAL_SUBMISSION_CASE =
  "/cognizant/bridge-migration/final-submit/";
export const CTS_CHANGE_STATUS_CASE = "/cognizant/cases/";
export const CTS_UPLOAD_CASE_BGV_ID = "/cognizant/upload-case/";
export const CTS_PORTAL_CREDENTIAL="/cognizant/portal-credentials/"
export const DOWNLOAD_READY_OTP="/cognizant/download-ready-otp/";

/* tcs api */

export const TCS_BUCKET_COUNT = "/tcs/bucket/count/";
export const TCS_BUCKET_CASES = "/tcs/cases/";
export const TCS_FETCH_CASES = "/tcs/bucket/fetch-case";
export const TCS_CHANGE_STATUS_CASE = "/tcs/cases/";
export const TCS_EXPORT_TO_CSV = "/tcs/export-csv/";
export const TCS_EXPORT_CASES_DOWNLOAD_CSV = "/tcs/export-case-download-csv/";
export const TCS_CASE_DOWNLOAD_CASES = "/tcs/cases-download/";
export const TCS_UPLOAD_CASE_BGV_ID = "/tcs/upload-case/";
export const TCS_GET_CHECK_LIST = "/tcs/generate-check-list/";
export const TCS_CSIS_DOWNLOAD="/tcs/csis";
export const TCS_RAISE_CASE_INSUFF =
  "/tcs/bridge-migration/raise-case-insufficiency/";
export const TCS_CHECK_BY_PACKAGE = "/tcs/checks-by-package/";
export const TCS_FINAL_SUBMISSION_CASE = "/tcs/bridge-migration/final-submit/";
export const TCS_DOCUMENT_UPLOAD = "/tcs/document-upload/";
export const TCS_GENERATE_ARS = "/tcs/bridge-migration/generate-ars/";
export const TCS_DUPLICITY_CHECK = "/tcs/bridge-migration/duplicity-check/";
export const TCS_UPLOAD_JAF = "/tcs/upload-jaf/";

/* Infosys api */

export const INFY_BUCKET_COUNT = "/infosys/bucket/count/";
export const INFY_BUCKET_CASES = "/infosys/cases/";
export const INFY_FETCH_CASES = "/infosys/bucket/fetch-case";
export const INFY_CHANGE_STATUS_CASE = "/infosys/cases/";
export const INFY_EXPORT_TO_CSV = "/infosys/export-csv/";
export const INFY_EXPORT_CASES_DOWNLOAD_CSV = "/infosys/export-case-download-csv/";
export const INFY_CASE_DOWNLOAD_CASES = "/infosys/cases-download/";
export const INFY_UPLOAD_CASE_BGV_ID = "/infosys/upload-case/";
export const INFY_GET_CHECK_LIST = "/infosys/generate-check-list/";
export const INFY_CSIS_DOWNLOAD="/infosys/csis";
export const INFY_RAISE_CASE_INSUFF =
  "/infosys/bridge-migration/raise-case-insufficiency/";
export const INFY_CHECK_BY_PACKAGE = "/infosys/checks-by-package/";
export const INFY_FINAL_SUBMISSION_CASE = "/infosys/bridge-migration/final-submit/";
export const INFY_DOCUMENT_UPLOAD = "/infosys/document-upload/";
export const INFY_GENERATE_ARS = "/infosys/bridge-migration/generate-ars/";
export const INFY_DUPLICITY_CHECK = "/infosys/bridge-migration/duplicity-check/";
export const INFY_UPLOAD_JAF = "/infosys/upload-jaf/";

/* Wipro api */

export const WIP_BUCKET_COUNT = "/wipro/bucket/count/";
export const WIP_BUCKET_CASES = "/wipro/cases/";
export const WIP_FETCH_CASES = "/wipro/bucket/fetch-case";
export const WIP_CHANGE_STATUS_CASE = "/wipro/cases/";
export const WIP_EXPORT_TO_CSV = "/wipro/export-csv/";
export const WIP_EXPORT_CASES_DOWNLOAD_CSV = "/wipro/export-case-download-csv/";
export const WIP_CASE_DOWNLOAD_CASES = "/wipro/cases-download/";
export const WIP_UPLOAD_CASE_BGV_ID = "/wipro/upload-case/";
export const WIP_GET_CHECK_LIST = "/wipro/generate-check-list/";
export const WIP_CSIS_DOWNLOAD="/wipro/csis";
export const WIP_RAISE_CASE_INSUFF =
  "/wipro/bridge-migration/raise-case-insufficiency/";
export const WIP_CHECK_BY_PACKAGE = "/wipro/checks-by-package/";
export const WIP_FINAL_SUBMISSION_CASE = "/wipro/bridge-migration/final-submit/";
export const WIP_DOCUMENT_UPLOAD = "/wipro/document-upload/";
export const WIP_GENERATE_ARS = "/wipro/bridge-migration/generate-ars/";
export const WIP_DUPLICITY_CHECK = "/wipro/bridge-migration/duplicity-check/";
export const WIP_UPLOAD_JAF = "/wipro/upload-jaf/";