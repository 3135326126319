import React, { Component } from "react";

export default class PopupMenuCheck extends Component {
  render() {
    const isDisabled = this.props.checkObject.isSubmit || this.props.isCSE;
    return (
      <div>
        <span class="float-right h6 mb-0">
          <button
            href="#"
            className="text-primary float-right h6 mb-0 transparent-borderless"
            id="dropdown_1"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-h icon-primary"></i>
          </button>

          <ul className="dropdown-menu" aria-labelledby="dropdown_1">
            {!isDisabled && this.props.isCeCheck !== true ? (
              <li>
                <button
                  href="#"
                  className="dropdown-item small"
                  onClick={(e) => this.props.raiseInsufficiencyCheck(e)}
                >
                  Raise Insufficiency
                </button>
              </li>
            ) : (
              ""
            )}
            <li>
              <button
                href=""
                className="dropdown-item small"
                data-toggle="modal"
                data-target="#commentHistoryPopup"
                onClick={() =>
                  this.props.openCheckHistoryModel(
                    this.props.checkObject.checkUID
                  )
                }
              >
                Check History
              </button>
            </li>
            {!isDisabled && (
              <li>
                <button
                  href=""
                  className="dropdown-item small"
                  onClick={(e) => this.props.setDisableCheck(e)}
                >
                  Disable
                </button>
              </li>
            )}
          </ul>
        </span>
      </div>
    );
  }
}
