import { TimerAction } from "./timer.type";

const initialState = {};

export default function rootReducer(state = initialState, { type, payload }) {
  let stateObj = { ...state };
  if (type === "DELETE") {
    delete stateObj[payload.pvtId];
    return stateObj;
  }
  switch (type) {
    case TimerAction.PAUSE:
      return {
        ...state,
        [payload.pvtId]: {
          minutes: payload.minutes,
          seconds: payload.seconds,
          actionType: payload.actionType,
        },
      };
    case TimerAction.RESUME:
      return {
        ...state,
        [payload.pvtId]: {
          actionType: payload.actionType,
          minutes: payload.minutes,
          seconds: payload.seconds,
        },
      };
    case TimerAction.HOLD:
      return {
        ...state,
        [payload.pvtId]: {
          actionType: payload.actionType,
        },
      };

    default:
      return state;
  }
}
