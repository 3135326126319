import { ActionTypes } from "./gapcheck.types";

const initialState = {
  gapAddressArray: [],
  gapEmploymentEducationArray: [],
  educationAddressMismatchArray: [],
  employmentAddressMismatchArray: []
};

export default function gapcheckReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case ActionTypes.UPDATE_ADDRESS_ARRAY:
      return {
        ...state,
        gapAddressArray: [...payload]
      };
    case ActionTypes.UPDATE_EMPLOYMENT_EDUCATION_ARRAY:
      return {
        ...state,
        gapEmploymentEducationArray: [...payload]
      };

    case ActionTypes.UPDATE_EDUCATION_ADDRESS_MISMATCH:
      return {
        ...state,
        educationAddressMismatchArray: [...payload]
      };
    case ActionTypes.UPDATE_EMPLOYMENT_ADDRESS_MISMATCH:
      return {
        ...state,
        employmentAddressMismatchArray: [...payload]
      };

    default:
      return state;
  }
}
