import React, { Component } from "react";
import OtpInput from "react-otp-input";
import api from "./../../../apiBaseConfig";
import { DOWNLOAD_READY_OTP } from "./../../../apiurl";
import { Toast } from "./../../../utils/toastutils";
import { modalManager } from "./../../../utils/modalutils";
import "../caseDownloadOtp/caseDownloadOtp.scss";

export default class SubmitOtpModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otp: "",
            isLoader: false,
        };
    }
    setOTP = (otp) => this.setState({ otp });

    submitOtp = async () => {
        const { otp } = this.state;
        try {
            const response = await api.patch(`${DOWNLOAD_READY_OTP}1/`, { otp });
            if (response.status === 200) {
                this.setState({ otp: "" });
                modalManager.close({});
            }

        } catch (e) { }
    };
    render() {
        const {
            otp,
            isLoader,
        } = this.state;
        return (
            <div style={{ paddingBottom: '50px' }}>
                <div className="otp-input-block">
                    <div>Enter OTP</div>
                    <OtpInput
                        value={otp}
                        onChange={this.setOTP}
                        numInputs={8}
                        separator={<span>-</span>}
                        inputStyle={{ width: '1.3em' }}
                    />
                </div>

                <div className="otp-footer" style={{ marginTop: 20 }}>
                    <button
                        class="btn btn-danger btn-sm float-left mr-2"
                        data-dismiss="modal"
                        onClick={() => {
                            modalManager.close({});
                        }}
                    >
                        Close
                    </button>
                    {!isLoader ? (
                        <button
                            class="btn btn-primary btn-sm float-right ml-2"
                            disabled={otp.length != 8}
                            onClick={(e) => this.submitOtp()}
                        >
                            SUBMIT OTP
                        </button>
                    ) : (
                        <div
                            class="spinner-border text-info float-right m-2 spinner-block"
                            role="status"
                        >
                            <span class="sr-only">Loading...</span>
                        </div>
                    )}

                </div>
            </div>
        );
    }
}
