/**
 * cse dashboard component
 */
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import TableWrapper from "../../components/Tablewrapper/tablewrapper";
import DROPDOWN from "./../../components/dropdown/dropdown";
import CommentBox from "./../../screens/bucketDetails/components/commentBox/commentBox";
import api from "../../apiBaseConfig";
import { modalManager } from "./../../utils/modalutils";
import Userlist from "./../../components/Userlist/userlist";
import { Toast } from "./../../utils/toastutils";
import ExportCSV from "./../../components/exporttoexcel/exporttoexcel";
import Csisupload from "./../../components/buckettimer/component/csisupload";
import CustomInput from "./../../components/customInput/customInput";
import {
  BUCKET,
  DASHBOARD_CASES,
  SEARCH_BGV_ID,
  DASHBOARD_CASES_FILTER_BY_DATE,
  ASSIGN_USER,
  ESCALATION_END_POINT,
  QCDASHBOARD_ADD_COMMENT,
  QCDASHBOARD_COMMENT_HISTORY,
  CHANGE_STATUS,
  FETCH_CASE,
  UPLOAD_CSIS,
  USER_LIST,
} from "./../../apiurl";

import "./csedashboard.scss";
let bucketCases = [];
class Csedashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csisUrl: [],
      column: [
        {
          name: "BGV ID",
          field: "",
          template: function (listing) {
            const { userInfo } = props;
            return (
              <div>
                {userInfo.roleId === 73 ? (
                  <Link
                    to={{
                      pathname: `/cseBucketDetail/${listing.id}`,
                      state: { isCSE: true },
                    }}
                  >
                    {listing.verification_type === "PreJoining" ||
                    listing.verification_type === "Existing"
                      ? listing.bgv_id
                      : listing.bgv_id2}
                  </Link>
                ) : (
                  <div>
                    {" "}
                    {listing.verification_type === "PreJoining" ||
                    listing.verification_type === "Existing"
                      ? listing.bgv_id
                      : listing.bgv_id2}
                  </div>
                )}
              </div>
            );
          },
          
          type: "bgvId",
        },
        {
          name: "ARS Number",
          field: "ars_num",
        },
        {
          name: "Candidate Name",
          field: "name",
        },
        {
          name: "Verification Type",
          field: "verification_type",
        },
        {
          name: "Case receive date",
          field: "sla_start_date",
        },
        {
          name: "Status",
          field: "status",
          template: function (listing) {
            return (
              <div class="alert alert-info mb-0 p-2 text-center">
                {listing.status}
              </div>
            );
          },
        },
        {
          name: "Priority",
          field: "priority_flag",
          template: function (listing) {
            return <div>{listing.priority_flag ? "Yes" : "No"}</div>;
          },
        },
        {
          name: "Action",
          field: "",
          template: (listing) => {
            const option = [];
            const { userInfo } = props;
            if (listing.status != "Escalation_responded") {
              option.push({
                value: "escalating",
                label: "Respond to escalation",
                status: "Escalation",
                template: (data) => {
                  return (
                    <CommentBox
                      isCheck={false}
                      pvtId={data.id}
                      userInfo={userInfo}
                      getCommentHistory={() => {
                        return this.getCaseCommentHistory(data.id);
                      }}
                      label="Case History"
                      buttonName="Respond to escalation"
                      submitComment={this.submitCaseComment}
                      closeCommentHistoryModal={() => modalManager.close({})}
                      successMessage={"Responded to escalation successfully"}
                      errorMessage={
                        "There is some error in responding to escalation"
                      }
                    />
                  );
                },
              });
            }

            if (userInfo.roleId != 73 && listing.status === "Escalation") {
              option.push({
                value: "assignto",
                label: "Assign To",
                status: "assignto",
                template: (data) => {
                  return (
                    <Userlist
                      data={data}
                      callback={this.assignToUser}
                      userList={this.state.userList}
                    />
                  );
                },
              });
            }
            if (userInfo.roleId != 73) {
              option.push({
                value: "escalating",
                label: "Add Comment",
                status: "Escalation",
                template: (data) => {
                  return (
                    <CommentBox
                      isCheck={false}
                      pvtId={data.id}
                      userInfo={userInfo}
                      getCommentHistory={() => {
                        return this.getCaseCommentHistory(data.id);
                      }}
                      label="Case History"
                      buttonName="Add Comment"
                      submitComment={this.submitComment}
                      closeCommentHistoryModal={() => modalManager.close({})}
                      successMessage={"comment added successfully"}
                      errorMessage={"There is some error in add comment"}
                    />
                  );
                },
              });
            }
            return (
              <div>
                <DROPDOWN
                  isDropdown={true}
                  option={option}
                  callback={(data) => {
                    const result = {
                      label: data.value,
                      statusValue: data.status,
                      ...listing,
                      userName: userInfo.roleName,
                      userId: userInfo.userId,
                    };
                    if (data.label != "Assign To") {
                      modalManager.open({
                        isHeader: false,
                        component: () => data.template(result),
                      });
                    } else {
                      modalManager.open({
                        isHeader: false,
                        width: "small",
                        component: () => data.template(result),
                      });
                    }
                  }}
                />
              </div>
            );
          },
        },
      ],

      bucket: {},
      cases: [],
      isFilter: false,
      searchBgvId: "",
      maxDate: null,
      minDate: null,
      loading: true,
      totalBucketCases: [],
      userList: [],
    };
  }

  componentDidMount = () => {
    let { column } = this.state;
    const { userInfo } = this.props;
    this.getUserList();
    this.getCsis();
    if (userInfo.roleId != 73) {
      column.splice(3, 0, {
        name: "Assigned TO",
        field: "userName",
        template: (listing) => {
          return (
            <div>{listing.status != "Escalation" ? listing.userName : ""}</div>
          );
        },
      });
    }
    this.refresh();
    this.getTotalBucketCases();
  };

  getUserList = async () => {
    try {
      const response = await api.get(`${USER_LIST}`);
      const data = response.data.map((value) => {
        value.label = value.user_name;
        return value;
      });
      this.setState({ userList: data });
    } catch (e) {
      console.log(e);
    }
  };

  getCsis = async () => {
    let arr = [];
    try {
      const response = await Promise.all([
        this.getDownloadCsis(1),
        this.getDownloadCsis(2),
        this.getDownloadCsis(3),
      ]);
      for (let i = 0; i < response.length; i++) {
        arr.push({
          url: `${process.env.REACT_APP_FILE_URL}${response[i].data.path}`,
        });
      }
      this.setState({ csisUrl: arr });
    } catch (e) {
      console.log("ee");
    }
  };
  changeStatus = async (pvtId, comment = "") => {
    const body = {
      pvtId: parseInt(pvtId),
      comment: comment,
    };
    try {
      const response = await api.post(
        `${CHANGE_STATUS}?status=escalation_responded`,
        body
      );
      Toast.success({
        description: "Responded to escalation successfully",
        header: "success",
      });
      this.refresh();
    } catch (e) {
      Toast.error({
        description: "Something went wrong",
        header: "Error",
      });
      console.log(e);
    }
  };
  getTotalBucketCases = async () => {
    const cases = ["escalation", "escalation_assigned", "escalation_responded"];
    let arr = [];
    for (let i = 0; i < cases.length; i++) {
      arr.push(this.getAllCases(cases[i]));
    }
    try {
      const response = await Promise.all(arr);

      if (response && response.length) {
        let allCases = [];
        for (let i = 0; i < response.length; i++) {
          allCases = [...allCases, ...response[i].data.result];
        }
        this.setState({ totalBucketCases: allCases });
      }
    } catch (e) {
      console.log(e);
    }
  };

  getAllCases = (type) => {
    return api.get(`${DASHBOARD_CASES}${type}`);
  };

  /**
   * this is use for submit the comment in case history
   */
  submitCaseComment = async (commentObject) => {
    const body = {
      pvtId: parseInt(commentObject.pvtId),
      comment: commentObject.comment,
    };
    try {
      const response = await api.post(
        `${CHANGE_STATUS}?status=escalation_responded`,
        body
      );
      Toast.success({
        description: "Responded to escalation successfully",
        header: "success",
      });
      this.refresh();
      return Promise.resolve(response);
    } catch (error) {
      console.log("error", error);
      return Promise.reject(error);
    }
    // try {
    //   let response = await api.post(QCDASHBOARD_ADD_COMMENT, {
    //     userId: commentObject.userId,
    //     pvtId: commentObject.pvtId,
    //     comment: commentObject.comment,
    //   });
    //   this.changeStatus(commentObject.pvtId, commentObject.comment);
    //   return Promise.resolve(response);
    // } catch (error) {
    //   console.log("error", error);
    //   return Promise.reject(error);
    // }
  };

  submitComment = async (commentObject) => {
    try {
      let response = await api.post(QCDASHBOARD_ADD_COMMENT, {
        userId: commentObject.userId,
        pvtId: commentObject.pvtId,
        comment: commentObject.comment,
      });
      return Promise.resolve(response);
    } catch (error) {
      console.log("error", error);
      return Promise.reject(error);
    }
  };

  /**
   * getting the comment history
   */
  getCaseCommentHistory = async (pvtId) => {
    let response;
    try {
      response = await api.get(QCDASHBOARD_COMMENT_HISTORY + `${pvtId}/`);
      response = response.data.comments;
      return Promise.resolve(response);
    } catch (error) {
      console.log("error", error);
      return Promise.reject(error);
    }
  };

  refresh = () => {
    this.getBucket();
    const { userInfo } = this.props;
    if (userInfo.roleId != 73) {
      this.getCases("escalation");
    } else {
      this.getCases("escalation_assigned");
    }
  };

  /**
   * assign the user by cse supervisor
   */
  assignToUser = async (data) => {
    const body = {
      pvt_id: data.id,
      user_id: data.userId,
    };
    try {
      const response = await api.post(`${ASSIGN_USER}`, body);
      modalManager.close({});
      this.refresh();
      Toast.success({
        description: "Assigned to user successfully",
        title: "Success",
      });
    } catch (e) {
      console.log(e);
      Toast.success({ description: "Something went wrong", title: "Error" });
    }
  };

  /**
   * getting all cases in cse dashboard
   */
  getCases = async (type) => {
    this.setState({ loading: true });
    let result = [];
    try {
      const response = await api.get(`${DASHBOARD_CASES}${type}`);
      result = response.data.result;
      bucketCases = [...result];
    } catch (e) {
      console.log(e);
    }
    this.setState({ cases: result, loading: false });
  };

  /**
   * getting all bucket number
   */
  getBucket = async () => {
    try {
      const response = await api.get(BUCKET);
      this.setState({ bucket: response.data });
    } catch (e) {
      console.log(e);
    }
  };

  onFilterClick = () => {
    const { isFilter, caseType } = this.state;
    this.setState({ isFilter: !isFilter, cases: bucketCases });
  };

  updateBgvId = (value) => {
    this.setState({ searchBgvId: value });
  };

  /**
   * search the cases according to bgv id
   */
  searchFilter = async () => {
    const { searchBgvId } = this.state;
    try {
      const response = await api.get(`${SEARCH_BGV_ID}${searchBgvId}`);
      this.setState({ cases: response.data.result });
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * set the from date
   */
  addEventFromDate = (event) => {
    this.setState({ minDate: event.target.value }, () => {
      const { minDate, maxDate } = this.state;
      if (maxDate === null) {
      } else if (minDate && maxDate >= minDate) {
        this.getDataBetweenDates();
      } else {
        alert("From Date can not be greater than To Date!");
      }
    });
  };

  /**
   * set the to date
   */
  addEventToDate = (event) => {
    this.setState({ maxDate: event.target.value }, () => {
      const { minDate, maxDate } = this.state;
      if (minDate === null) {
      } else if (maxDate >= minDate) {
        this.getDataBetweenDates();
      } else {
        alert("From Date can not be greater than To Date!");
      }
    });
  };

  /**
   * search the cases according to from date and to date
   */
  getDataBetweenDates = async () => {
    const { minDate, maxDate } = this.state;
    try {
      const response = await api.get(
        `${DASHBOARD_CASES_FILTER_BY_DATE}${minDate}&dateTo=${maxDate}`
      );
      this.setState({ cases: response.data.result });
    } catch (e) {
      console.log(e);
    }
  };

  fetchCase = () => {
    api
      .post(FETCH_CASE)
      .then(() => {
        this.refresh();
      })
      .catch((err) => {
        console.log("error while fetching cases", err);
        Toast.error({ description: "something went wrong", header: "error" });
      });
  };

  /**
   * download the csis excel sheet
   */
  openUrl = () => {
    const { csisUrl } = this.state;
    console.log(csisUrl);
    for (var i = 0; i < csisUrl.length; i++) {
      setTimeout(
        function (path) {
          window.location = path;
        },
        1000 + i * 1000,
        csisUrl[i].url
      );
    }
  };

  getDownloadCsis = (id) => {
    return api.get(`${UPLOAD_CSIS}${id}/`);
  };

  openWindow = () => {
    modalManager.open({
      width: "small",
      component: () => <Csisupload callback={this.uploadCsis} />,
    });
  };
  /**
   * this help for upload the csis file
   */
  uploadCsis = async (data, formData) => {
    try {
      const response = await api.post(`${UPLOAD_CSIS}${data.id}/`, formData);
      modalManager.close({});
      Toast.success({
        description: "csis uploaded successfully",
        title: "success",
      });
      this.getCsis();
    } catch (e) {
      Toast.error({ description: "somthing went wrong", title: "error" });
    }
  };

  render() {
    const {
      data,
      bucket,
      cases,
      isFilter,
      searchBgvId,
      column,
      loading,
      maxDate,
      minDate,
      totalBucketCases,
    } = this.state;
    const { userInfo } = this.props;
    return (
      <div className="dashboard-block">
        <div className="text-right p-2">
          {userInfo.roleId == 73 && (
            <button
              className="btn btn-primary mr-2 export-csv"
              onClick={() => {
                this.fetchCase();
              }}
            >
              Fetch Case
            </button>
          )}
        </div>
        <div className="dashboard-upper">
          <div className="cse-dashboard-header-block">
            <div>
              <h5>
                {userInfo.roleId === 73 ? "CSE Dashboard" : "CSE Dashboard"}
              </h5>
            </div>
            <div className="cse-csis-upload">
              <div class="d-flex">
                <button
                  class="btn btn-primary btn-sm text-uppercase border-0"
                  onClick={() => {
                    this.openUrl();
                  }}
                >
                  csis <i class="fa fa-arrow-down" aria-hidden="true"></i>
                </button>

                <button
                  class="btn btn-primary btn-sm text-uppercase border-0"
                  onClick={() => {
                    this.openWindow();
                  }}
                  style={{ marginLeft: "4px" }}
                >
                  csis <i class="fa fa-arrow-up" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="card text-white bg-white">
                <div class="card-body">
                  <h6 class="card-title text-danger m-0 font-weight-bold">
                    Escalations
                  </h6>
                  <div class="card-text d-flex justify-content-between">
                    {userInfo.roleId != 73 ? (
                      <div
                        class="float-left text-center pointer"
                        onClick={() => {
                          this.getCases("escalation");
                        }}
                      >
                        <h6 class="m-0 font-weight-bold text-danger">
                          {bucket.escalation}
                        </h6>
                        <small class="text-muted">Escalation raised</small>
                      </div>
                    ) : (
                      ""
                    )}

                    <div
                      class="float-left text-center pointer"
                      onClick={() => {
                        this.getCases("escalation_assigned");
                      }}
                    >
                      <h6 class="m-0 font-weight-bold text-danger">
                        {bucket.escalation_assigned}
                      </h6>
                      <small class="text-muted">Escalation-assigned</small>
                    </div>

                    <div
                      class="float-left text-center pointer"
                      onClick={() => {
                        this.getCases("escalation_responded");
                      }}
                    >
                      <h6 class="m-0 font-weight-bold text-primary">
                        {bucket.escalation_responded}
                      </h6>
                      <small class="text-muted">Escalations Responded</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="csis-block-action">
            {userInfo.roleId != 73 ? (
              <ExportCSV
                column={column}
                data={totalBucketCases}
                fileName={"dashboard"}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        {loading ? (
          <div className="loading-block">Loading...</div>
        ) : (
          <div>
            {cases.length === 0 ? (
              <div className="no-data"> NO data found</div>
            ) : (
              <div>
                <div class="row search-section">
                  <div class="col-md-6">
                    <h6 class="mt-2">CONSOLIDATED LIST</h6>
                  </div>
                  <div className="col-md-6 text-right">
                    <button
                      class="btn btn-light export-csv"
                      onClick={() => {
                        this.onFilterClick();
                      }}
                    >
                      Search
                    </button>
                  </div>
                </div>

                {isFilter && (
                  <div className="filter-block">
                    <div class="row shadow-lg bg-white rounded filter-section-block">
                      <div class="col-4">
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control input-section"
                            placeholder="Search BGV ID"
                            onChange={(event) => {
                              this.updateBgvId(event.target.value);
                            }}
                          />
                          {searchBgvId && (
                            <div class="input-group-append">
                              <button
                                class="btn btn-secondary"
                                type="button"
                                onClick={() => {
                                  this.searchFilter();
                                }}
                              >
                                <i class="fa fa-search"></i>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>

                      <div class="col-8">
                        <div class="row" style={{ alignItems: "center" }}>
                          <label
                            for="fromDate"
                            class="col col-form-label"
                            style={{ textAlign: "right", fontSize: "15px" }}
                          >
                            From Date
                          </label>
                          <div class="col">
                            <CustomInput
                              type={"Date"}
                              onChange={(e) => {
                                this.addEventFromDate(e);
                              }}
                              value={minDate}
                            ></CustomInput>
                          </div>
                          <label
                            for="toDate"
                            class="col col-form-label"
                            style={{ textAlign: "right", fontSize: "15px" }}
                          >
                            To Date
                          </label>
                          <div class="col">
                            <CustomInput
                              type={"Date"}
                              onChange={(e) => {
                                this.addEventToDate(e);
                              }}
                              value={maxDate}
                            ></CustomInput>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="table-block">
                  <TableWrapper column={column} data={cases} />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.login.userInfo,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Csedashboard);
