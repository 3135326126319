import React from "react";

const DROPDOWN = props => {
  const { option, isDropdown = false } = props;
  return (
    <div class="dropdown">
      <button
        class={
          "btn btn-primary transparent-borderless" +
          (isDropdown ? " dropdown-toggle" : "")
        }
        type="button"
        data-toggle="dropdown"
      >
        <i class="fa fa-ellipsis-h icon-primary" aria-hidden="true"></i>
        <span class="caret"></span>
      </button>
      <ul class="dropdown-menu">
        {option.map(data => {
          return (
            <li>
              <button
                className="dropdown-item small"
                onClick={e => props.callback(data, e)}
              >
                {data.label}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default DROPDOWN;
