import React, { Component } from "react";
import Selectbox from "./../../../../../components/Selectbox/selectbox";
import Asterisk from "./../../../../../screens/bucketDetails/components/asterisk/asterisk";

const HiringList = ({ checkObj, callback, getHiringList }) => {
  return (
    <div className="form-group">
      <label className="text-uppercase text-secondary">
        Found in Hiring List ?
      </label>
      <Asterisk />

      <div class="row">
        <div class="col-md-6">
          <Selectbox
            option={[
              { value: true, label: "yes" },
              { value: false, label: "no" },
            ]}
            callback={(data) => {
              callback(data);
            }}
            defaultValue={
              checkObj.is_in_hiring_list === null
                ? null
                : {
                    value: checkObj.is_in_hiring_list,
                    label: checkObj.is_in_hiring_list === true ? "yes" : "no",
                  }
            }
          />
        </div>
        {checkObj.verification_source ? (
          <div class="col-md-6 d-flex justify-content-end align-items-center">
            <button
              href="#"
              className="m-2 transparent-borderless"
              onClick={() => {
                getHiringList();
              }}
            >
              <i className="fa fa-eye fnt16"></i>
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default HiringList;
