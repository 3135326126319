import React, { Component } from "react";
import api from "./../../../apiBaseConfig";
import { GET_HIRING_LIST } from "./../../../apiurl";
import LoadingScreen from "./../../../components/loadingScreen/loadingScreen";
import "./hiringModal.scss";

export default class HiringModal extends Component {
  constructor(props) {
    super(props);
    this.tableBodyRef = React.createRef();
    this.state = {
      headerMargin: 0,
      hiringList: [],
      loading: true,
      searchString: "",
      entity: "",
      hiringObj: {},
      type: "ars",
    };
  }

  componentDidMount = () => {
    const { searchString, entity } = this.props;
    console.log(searchString, entity, "fff");
    this.setState({ searchString: searchString, entity: entity }, () => {
      this.getHiringList();
    });
  };
  getHiringList = async () => {
    const { searchString, entity, type } = this.state;
    const hiringString = searchString.split(" ")[0];
    this.setState({ loading: true });
    api
      .post(`${GET_HIRING_LIST}` + entity + "/", {
        search_string:hiringString ,
      })
      .then((response) => {
        const hiringList = response.data;
        this.setState({
          hiringList: type === "ars" ? hiringList.ars : hiringList.client,
          hiringObj: hiringList,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.tableBodyRef.current && !this.state.headerMargin) {
      const tableBody = this.tableBodyRef.current;
      let headerMargin = tableBody.offsetWidth - tableBody.clientWidth;
      //mac scrollbar only 2px bug
      if (headerMargin > 4 && headerMargin !== this.state.headerMargin) {
        console.log({ headerMargin });

        this.setState({ headerMargin: headerMargin });
      }
    }
  }

  changeTab = (type) => {
    const { hiringObj } = this.state;
    if (type == "ars") {
      this.setState({ hiringList: hiringObj.ars, type: "ars" });
    } else {
      this.setState({ hiringList: hiringObj.client, type: "hcl" });
    }
  };

  render() {
    const { hiringList, loading, searchString, entity, type } = this.state;
    return (
      <div class="h-100 p-2">
        <div class="row">
          <div class="col">
            <h5 class="text-muted">Subjective hiring list matches </h5>
          </div>
          <div class="col">
            <button class="close" onClick={() => this.props.closeModal()}>
              &times;
            </button>
          </div>
        </div>

        <hr class="brdr-dashed" />

        <div className="row">
          <div className="col-md-4">
            <input
              type="text"
              className="form-control input-section text-primary"
              value={searchString}
              onChange={(e) => {
                this.setState({ searchString: e.target.value });
              }}
            />
          </div>
          <div className="col-md-2">
            <button
              className="btn btn-primary"
              onClick={() => {
                this.getHiringList();
              }}
              disabled={!(entity && searchString)}
            >
              Search
            </button>
          </div>
        </div>
        <hr class="brdr-dashed" />
        <div className="row">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <span
                class={"nav-link" + (type == "ars" ? " active" : "")}
                onClick={() => {
                  this.changeTab("ars");
                }}
                style={{ cursor: "pointer" }}
              >
                ARS
              </span>
            </li>
            <li class="nav-item">
              <span
                class={"nav-link" + (type == "hcl" ? " active" : "")}
                onClick={() => {
                  this.changeTab("hcl");
                }}
                style={{ cursor: "pointer" }}
              >
                HCL
              </span>
            </li>
          </ul>
        </div>

        {loading ? (
          <LoadingScreen isLoading={loading}></LoadingScreen>
        ) : (
          // <div className="loading-block">Loading...</div>
          <div class="col-md-12 hiring-table-container ">
            {hiringList.length === 0 ? (
              <div className="no-data"> NO data found</div>
            ) : (
              <React.Fragment>
                <div
                  class="text-muted hiring-table-header"
                  style={{ marginRight: this.state.headerMargin }}
                >
                  <div className="hiring-table-row h-100">
                    <div class="hiring-col col-sn">S.NO</div>
                    <div class="hiring-col col-source">Source Name</div>
                    <div class="hiring-col col-client">Client</div>
                    <div class="hiring-col col-status">Hiring Status</div>
                    <div class="hiring-col col-locations">Locations</div>
                  </div>
                </div>
                <div class="header-filler"></div>
                <div class="hiring-table-body" ref={this.tableBodyRef}>
                  {hiringList.map((hiring, index) => (
                    <div className="hiring-table-row">
                      <div class="hiring-col col-sn text-muted">
                        <span class="text-muted">{index + 1}</span>
                      </div>
                      <div class="hiring-col col-source text-muted">
                        <span class="text-primary font-weight-bold">
                          {entity === "institute"
                            ? hiring.institute_name
                            : hiring.company_name}
                        </span>
                      </div>
                      <div class="hiring-col col-client text-muted">
                        <span>
                          {type == "ars"
                            ? "authbridge research services"
                            : "hcl"}
                        </span>
                      </div>
                      <div class="hiring-col col-status text-muted">
                        <span>{hiring.hiring_status}</span>
                      </div>
                      <div class="hiring-col col-locations text-muted">
                        <spam>{hiring.address}</spam>
                      </div>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    );
  }
}
