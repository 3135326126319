import React, { Component } from "react";
import TableWrapper from "../../../components/Tablewrapper/tablewrapper";
import { modalManager } from "../../../utils/modalutils";
import { constants } from "../../../constants";

export default class executiveSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column: [
        {
          name: "Check name",
          field: "check_name",
        },
        {
          name: "Verification Source",
          field: "",
          template: (listing, source) => {
            const checkIdObj = {
              285: 6480,
              280: 6303,
              271: 6315,
              42: 4167,
            };
            if (checkIdObj[listing.check_id]) {
              const antecedentArray =
                listing.check_fields &&
                listing.check_fields.filter(
                  (obj) => obj.antecedent_id == checkIdObj[listing.check_id]
                );
              source =
                antecedentArray.length && antecedentArray[0].antecedent_value;
            } else {
              listing.verification_source
                ? (source = listing.verification_source.label)
                : (source = "N/A");
            }
            return <div>{source}</div>;
          },
        },
        {
          name: "Period Of stay/Tenure",
          field: "",
          template: (listing, pos) => {
            if (listing.check_id === constants.checkIds.GAP_CHECK_ID) {
              let data = listing.check_fields.find(
                (obj) =>
                  obj.antecedent_id === constants.antecedantId.GAP_PERIOD_FORM
              );
              let data2 = listing.check_fields.find(
                (obj) =>
                  obj.antecedent_id === constants.antecedantId.GAP_PERIOD_TO
              );
              data.antecedent_value && data2.antecedent_value
                ? (pos = this.props.setDateFromToHelper(
                    data.antecedent_value,
                    data2.antecedent_value
                  ))
                : (pos = "N/A");
            } else if (listing.check_id === constants.checkIds.UAN_CHECK_ID) {
              let data = listing.check_fields.find(
                (obj) =>
                  obj.antecedent_id ===
                  constants.antecedantId.UAN_DATE_OF_JOINING
              );
              let data2 = listing.check_fields.find(
                (obj) =>
                  obj.antecedent_id ===
                  constants.antecedantId.UAN_DATE_OF_LEAVING
              );
              data.antecedent_value && data2.antecedent_value
                ? (pos = this.props.setDateFromToHelper(
                    data.antecedent_value,
                    data2.antecedent_value
                  ))
                : (pos = "N/A");
            } else {
              listing.date_from && listing.date_to
                ? (pos = this.props.setDateFromToHelper(
                    listing.date_from,
                    listing.date_to
                  ))
                : (pos = "N/A");
            }
            return <div>{pos}</div>;
          },
        },
        // {
        //   name: "Check Status",
        //   field: "",
        //   template: (listing , status ) => {
        //     if(listing.is_frozen && listing.is_disabled)
        //     status = "Disabled"
        //     else if(listing.is_frozen && listing.is_insufficiency_raised)
        //     status = "Insufficiency Raised"
        //     else if(listing.is_frozen && !listing.is_insufficiency_raised && !listing.is_disabled)
        //     status = "Submitted"
        //     else
        //     status = ""
        //     return <div>{status}</div>
        //   },
        // },
      ],
      data: [],
    };
  }

  close = () => {
    modalManager.close({});
    this.props.onClose && this.props.onClose();
  };

  render() {
    const { checkListArray, submittedChecks } = this.props;
    const { column } = this.state;
    return (
      <div className="summary">
        <div className="table">
          <TableWrapper column={column} data={submittedChecks} />
        </div>
        <button
          class="btn btn-danger btn-sm float-left mr-2"
          data-dismiss="modal"
          onClick={this.close}
        >
          Close
        </button>
        <button
          class="btn btn-primary btn-sm float-right ml-2 send-btn"
          //   disabled={!comment}
          onClick={(e) => {
            this.props.handleFormSubmit(e);
            this.close();
          }}
        >
          Submit
        </button>
      </div>
    );
  }
}
