import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../../screens/login/state/login.actions";
import { LoGIN, ROLE_ID, CLIENT, SET_CLIENT } from "../../apiurl";
import loginIcon from "../../assets/images/login.png";
import Selectbox from "../Selectbox/selectbox";
import { Toast } from "./../../utils/toastutils";
import api from "../../apiBaseConfig";
import "./clientRoleIdSelection.scss";

class Clientrole extends Component {
  state = {
    clientObj: {},
    roleObj: {},
    clientArray: [],
    roleArray: [],
  };

  componentDidMount = () => {
    this.getClient();
    this.getRoleId();
  };

  getClient = async () => {
    let { clientArray } = this.state;
    try {
      const response = await api.get(CLIENT);
      console.log("client", response);
      clientArray = response.data.map((client) => {
        return { ...client, label: client.name };
      });
      this.setState({ clientArray });
    } catch (e) { }
  };
  getRoleId = async () => {
    let { roleArray } = this.state;
    try {
      const response = await api.get(ROLE_ID);
      roleArray = response.data.map((role) => {
        return { ...role, label: role.role_name };
      });
      this.setState({ roleArray });
      console.log("role", response);
    } catch (e) { }
  };
  handleSubmit = async (e) => {
    const { clientObj, roleObj } = this.state;
    e.preventDefault();
    this.setState({ loading: true });
    this.props.login({
      client_id: clientObj.id,
      role_id: roleObj.role_id,
      role_name: roleObj.role_name,
    });
  };

  render() {
    const { userInfo } = this.props;
    console.log(userInfo, "ddd");
    const { roleArray, clientArray, clientObj, roleObj } = this.state;
    // if (userInfo.accessToken && userInfo.roleId) {
    //   Toast.success({ description: "login successfully" });
    // }

    if (userInfo.accessToken && userInfo.roleId) {
      switch (userInfo.roleId) {
        case 71:
          return <Redirect to="/qcdashboard" />;
        case 72:
          return <Redirect to="/dashboard" />;
        case 73:
          return <Redirect to="/cseResourceDashboard" />;
        case 74:
          return <Redirect to="/cseSupervisorDashboard" />;

        default:
          return null;
      }
    }
    if (userInfo.accessToken && userInfo.roleId === 71) {
      return <Redirect to="/qcdashboard" />;
    }
    if (userInfo.accessToken && userInfo.roleId === 72) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <div className = "background-img">
        <div className="container-box">
          <div className="costuminput">
            <label >Select Client Name</label>
            <Selectbox
              option={clientArray}
              callback={(value) => {
                this.setState({ clientObj: value });
              }}
            // defaultValue={null}
            />
          </div>
          <div className="costuminput">
            <label >Select Role Id</label>
            <Selectbox
              option={roleArray}
              callback={(value) => {
                this.setState({ roleObj: value });
              }}
            // defaultValue={null}
            /></div>

          <div className="client-button-section">
            <button
              class="btn btn-primary btn-sm ml-2"
              onClick={(e) => {
                this.handleSubmit(e);
              }}
              disabled={!(roleObj.role_id && clientObj.id)}
            >
              SUBMIT
           </button>
          </div>
        </div>
        </div>
      // <div className="client-box">
      //   <div className="client-role-section">
      //     <div className="client-section">
      //       <div className="text-block">Select Client Name</div>
      //       <div>
      //         <Selectbox
      //           option={clientArray}
      //           callback={(value) => {
      //             this.setState({ clientObj: value });
      //           }}
      //           // defaultValue={null}
      //         />
      //       </div>
      //     </div>
      //     <div className="role-section">
      //       <div className="text-block">Select Role Id</div>
      //       <div>
      //         <Selectbox
      //           option={roleArray}
      //           callback={(value) => {
      //             this.setState({ roleObj: value });
      //           }}
      //           // defaultValue={null}
      //         />
      //       </div>
      //     </div>
      //   </div>
      //   <div className="client-button-section">
      //     <button
      //       class="btn btn-primary btn-sm ml-2"
      //       onClick={(e) => {
      //         this.handleSubmit(e);
      //       }}
      //       disabled={!(roleObj.role_id && clientObj.id)}
      //     >
      //       SUBMIT
      //     </button>
      //   </div>
      // </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.login.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  login: (payload) => dispatch(login(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Clientrole);
