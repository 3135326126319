import React, { Component } from "react";
import CustomInput from "../../../components/customInput/customInput";
import _cloneDeep from "lodash/cloneDeep";

export default class MultipleTenure extends Component {
  handleDateFrom = (index, value) => {
    const { checkObject } = this.props;
    let checkObj = _cloneDeep(checkObject);
    checkObj.additional_tenures[index].from = value;
    this.props.updateAdditionalTenures(checkObj);
  };

  handleDateTo = (index, value) => {
    const { checkObject } = this.props;
    let checkObj = _cloneDeep(checkObject);
    checkObj.additional_tenures[index].to = value;
    this.props.updateAdditionalTenures(checkObj);
  };
  render() {
    const { checkObject } = this.props;
    return (
      <div class="row">
        <div class="col-md-12 second-tenure-addition-row">
          <button
            className={`wh20 btn border  rounded-circle p-1 d-flex align-items-center justify-content-center${
              checkObject.isSubmit || this.props.isCSE
                ? "border-secondary"
                : "border-primary"
            }`}
            onClick={() => {
              const { checkObject } = this.props;
              let checkObj = _cloneDeep(checkObject);
              if (checkObj.additional_tenures) {
                checkObj.additional_tenures.push({ from: null, to: null });
              } else {
                checkObj["additional_tenures"] = [{ from: null, to: null }];
              }
              this.props.updateAdditionalTenures(checkObj);
            }}
            disabled={checkObject.is_frozen}
          >
            <i className="fa fa-plus icon-primary"></i>
          </button>
          <span className="text-uppercase text-secondary">
            Add Another Tenure
          </span>
        </div>

        {checkObject.additional_tenures &&
          checkObject.additional_tenures.map((tenure, index) => (
            <React.Fragment>
              <div class="col-md-6">
                <div className="form-group">
                  <label className="text-uppercase text-secondary">From</label>
                  <div className="d-flex">
                    <div className="w-100">
                      <CustomInput
                        type="Date"
                        onChange={(e) =>
                          this.handleDateFrom(index, e.target.value)
                        }
                        value={tenure.from}
                        isDisable={checkObject.isSubmit || this.props.isCSE}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div className="form-group">
                  <label className="text-uppercase text-secondary">To</label>
                  <div className="d-flex">
                    <div className="w-100">
                      <CustomInput
                        type="Date"
                        onChange={(e) =>
                          this.handleDateTo(index, e.target.value)
                        }
                        value={tenure.to}
                        isDisable={checkObject.isSubmit || this.props.isCSE}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
      </div>
    );
  }
}
