import React, { Component } from "react";
import AsyncSelect from "react-select/async";
class AsyncSelectbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      inputValue: "",
      onBlurValue: "",
    };
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () => {
      this.props.onChange(selectedOption);
    });
  };

  handleBlur = (e) => {
    this.setState({ onBlurValue: e.target.value });
    if (this.state.selectedOption && this.state.selectedOption.value) {
      this.props.onChange(this.state.selectedOption);
    } else {
      this.props.onChange(this.getUnselectedOption());
    }
  };

  getUnselectedOption = () => {
    if (this.state.inputValue || this.state.onBlurValue) {
      return {
        label: this.state.inputValue || this.state.onBlurValue,
        value: null,
      };
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.overrideVerificationSourceSelectedOption) {
      this.props.overrideVerificationSourceSelectedOptionComplete();
      this.setState({ selectedOption: null, inputValue: "", onBlurValue: "" });
    }
  }

  render() {
    const {
      loadOptions,
      defaultValue = null,
      defaultOptions,
      isClearable = true,
      isDisable = false,
      isError = false,
      onBlur = () => {},
    } = this.props;
    const { selectedOption, inputValue, onBlurValue } = this.state;

    return (
      <div className={`select-block ${isError ? "error-border" : ""}`}>
        <AsyncSelect
          value={selectedOption || this.getUnselectedOption() || defaultValue}
          onChange={this.handleChange}
          loadOptions={loadOptions}
          // inputValue={
          //   (selectedOption && selectedOption.label) ||
          //   inputValue ||
          //   onBlurValue
          // }
          onInputChange={(value) => {
            this.setState({ inputValue: value });
          }}
          defaultOptions={defaultOptions}
          isClearable={isClearable}
          onBlur={(e) => {
            this.handleBlur(e);
          }}
          onFocus={() => {
            this.setState({
              inputValue: this.state.onBlurValue,
              onBlurValue: "",
            });
          }}
          isDisabled={isDisable}
        />
      </div>
    );
  }
}

export default AsyncSelectbox;
