import React from "react";
import ModalDialog, { ModalTransition } from "@atlaskit/modal-dialog";
import "./addMoreCheckModal.scss";

export default function AddMoreCheckModal(props) {
  const {
    isAddMoreCheckModalVisible,
    closeModal,
    addNewCheck,
    checkListPerPackage,
    getCheckIdForSyncedInput
  } = props;
  return (
    <ModalTransition>
      {isAddMoreCheckModalVisible && (
        <ModalDialog>
          <div className="modal-body small modal-container">
            <div className="row">
              <div className="col-md-12 heading-container">
                <h5 className="text-primary">Add More Check for Tagging</h5>
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  onClick={() => closeModal()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>

            <hr className="border-top-0 border-bottom" />

            <div className="check-name-list">
              {checkListPerPackage.map(checkObject => (
                <div className="row">
                  <div className="col-md-12">
                    <button
                      data-dismiss="modal"
                      className="btn btn-outline-primary mb-2 rounded btn-block transparent-borderless"
                      onClick={() => {
                        closeModal();
                        const secondCheckId = parseInt(
                          getCheckIdForSyncedInput(checkObject.checkId)
                        );
                        console.log("secondCheckId", secondCheckId);
                        if (secondCheckId) {
                          const secondCheckObject = checkListPerPackage.find(
                            check => check.checkId === secondCheckId
                          );
                          console.log("secondCheckObject", secondCheckObject);

                          secondCheckObject && addNewCheck(secondCheckObject);
                        }
                        addNewCheck(checkObject);
                      }}
                    >
                      <span
                        className="float-left wh20 btn bg-white rounded-circle p-1 d-flex align-items-center justify-content-center"
                        style={{ display: "none" }}
                      >
                        <i className="fa fa-plus icon-primary"></i>
                      </span>
                      <span> {checkObject.checkName}</span>
                    </button>
                  </div>
                </div>
              ))}
            </div>

            <div className="row">
              <div className="col-md-12">
                <button
                  className="btn btn-primary btn-sm float-right"
                  onClick={() => closeModal()}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </ModalDialog>
      )}
    </ModalTransition>
  );
}
