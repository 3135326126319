import React, { Component } from "react";

export default class PopupMenu extends Component {
  render() {
    const isDisabled = this.props.isCSE || this.props.isCaseInsuff;

    return (
      <div className="position-relative">
        <button
          href="#"
          className="text-primary float-right h6 mb-0 transparent-borderless"
          id="dropdown_1"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="fa fa-ellipsis-h icon-primary"></i>
        </button>

        <ul className="dropdown-menu" aria-labelledby="dropdown_1">
          {!isDisabled && (
            <li>
              <button
                href="#"
                className="dropdown-item small"
                onClick={e => this.props.raiseInsufficiency(e)}
              >
                Raise Insufficiency
              </button>
            </li>
          )}
          {!isDisabled && (
            <li>
              <button
                href="#"
                className="dropdown-item small"
                onClick={e => this.props.raiseEscalation(e)}
              >
                Escalate
              </button>
            </li>
          )}
          <li>
            <button
              href=""
              className="dropdown-item small"
              data-toggle="modal"
              data-target="#commentHistoryPopup"
              onClick={e => this.props.openCaseHistoryModel(e)}
            >
              {this.props.isCSE ? "Respond to escalation" : " Case History"}
            </button>
          </li>
        </ul>
      </div>
    );
  }
}
