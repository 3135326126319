import loginReducer from "./screens/login/state/login.reducer";
import timerReducer from "./components/buckettimer/state/timer.reducer";
import gapcheckReducer from "./hcl/components/gapcheck/state/gapcheck.reducer";
import ctsGapcheckReducer from './cts/components/gapcheck/state/gapcheck.reducer';

import { combineReducers } from "redux";
export const rootReducer = combineReducers({
  login: loginReducer,
  timer: timerReducer,
  gapcheck: gapcheckReducer,
  ctsGapcheck:ctsGapcheckReducer,
});
