import React, { Component } from "react";
import Select from "react-select";
import "./selectbox.scss";
class Selectbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null
    };
  }
  // UNSAFE_componentWillReceiveProps = props => {
  //   const { defaultValue = null } = props;
  //   this.setState({ selectedOption: defaultValue });
  // };
  handleChange = selectedOption => {
    this.setState({ selectedOption }, () => {
      this.props.callback(selectedOption);
    });
  };
  render() {
    const {
      option,
      isMulti = false,
      defaultValue = null,
      isDisable = false,
      menuHeight,
      isError,
      menuOpen = () => {},
    } = this.props;
    const { selectedOption } = this.state;
    return (
      <div className={`${isError ? "error-border" : ""}`}>
        <Select
          value={selectedOption || defaultValue}
          onChange={this.handleChange}
          options={option}
          isMulti={isMulti}
          isSearchable={true}
          isDisabled={isDisable}
          maxMenuHeight={menuHeight}
          onMenuOpen={menuOpen}
        />
      </div>
    );
  }
}

export default Selectbox;
