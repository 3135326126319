/**
 * client details component
 * which contain case details,client details
 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Selectbox from "./../../../components/Selectbox/selectbox";
import api from "./../../../apiBaseConfig";
import {
  DUPLICATE_CASE,
  SUBMIT_ARS_CASE,
  SAVE_PERSONAL_DATA,
  GENERATE_ARS,
  DUPLICITY_CHECK,
} from "./../../../apiurl";
import DocSelection from "./../../../components/docselection/docselection";
import { Toast } from "./../../../utils/toastutils";
import {
  getRealData,
  uniqueId,
  getDocuments,
  getDuplicityData,
} from "./../../../utils/commonutils";
import CustomInput from "./../../../components/customInput/customInput";

class ClientDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientFormData: {},
      candidateFormData: {},
      selectedDoc: [],
      isExpand: true,
      selectDropdown: [],
      updatedFormData: {},
      isAddCaseLoader: false,
    };
  }

  componentDidMount() {
    let {
      candidateFormData,
      clientFormData,
      selectedDoc,
      selectDropdown,
    } = this.state;
    const { caseDetails, isDocDisable = false, documentList } = this.props;
    clientFormData = { ...caseDetails };
    if (caseDetails.case_documents && caseDetails.case_documents.length) {
      selectedDoc = caseDetails.case_documents.map((data, index) => {
        return { ...data, indexNumber: uniqueId(), isDocDisable };
      });
    }
    selectDropdown = documentList.map((data) => {
      return {
        type: data.type,
        id: data.id,
        label: data.type + "_" + data.id,
        path: data.path,
        gcspath: data.gcspath,
        documentType: data.documentType,
      };
    });
    this.setState({
      updatedFormData: {},
      clientFormData,
      selectedDoc,
      isExpand: true,
      selectDropdown,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    let { clientFormData, selectedDoc, selectDropdown } = this.state;
    const { caseDetails, isDocDisable = false, documentList } = this.props;
    if (
      prevProps.caseDetails !== this.props.caseDetails ||
      prevProps.isDocDisable !== this.props.isDocDisable
    ) {
      clientFormData = { ...caseDetails };
      if (caseDetails.case_documents && caseDetails.case_documents.length) {
        selectedDoc = caseDetails.case_documents.map((data, index) => {
          return { ...data, indexNumber: uniqueId(), isDocDisable };
        });
      }
      selectDropdown = documentList.map((data) => {
        return {
          type: data.type,
          id: data.id,
          label: data.type + "_" + data.id,
          path: data.path,
          gcspath: data.gcspath,
          documentType: data.documentType,
        };
      });
      this.setState({
        updatedFormData: {},
        clientFormData,
        selectDropdown,
        selectedDoc,
        isExpand: true,
      });
    }
  }

  /**
   * this functioon use for modify the client details
   */
  setClintData = (key, value) => {
    let { clientFormData } = this.state;
    clientFormData[key] = value;
    this.setState({ clientFormData });
  };

  /**
   * this function use for modify the candidate details
   */

  setCandidateData = (key, value) => {
    let { candidateFormData } = this.state;
    candidateFormData[key] = value;
    this.setState({ candidateFormData });
  };

  /**
   * this function is used for document tagging in case label
   */
  getSelectDoc = (array) => {
    this.setState({ selectedDoc: array });
  };

  saveCaseDetails = async (e, flag = "") => {
    const { selectedDoc, clientFormData, updatedFormData } = this.state;
    const tagDocuments = selectedDoc.filter((document) => document.id);
    const re = /\S+@\S+\.\S+/;
    e.preventDefault();
    if (
      clientFormData.email_address &&
      !re.test(clientFormData.email_address)
    ) {
      Toast.info({
        description: "Please enter valid email format (abc@def.xx)",
      });
      return Promise.reject(null);
    }
    for (let key in updatedFormData) {
      if (!updatedFormData[key]) {
        delete updatedFormData[key];
      }
    }

    return this.props.saveDeatils(
      flag,
      this.state.clientFormData,
      tagDocuments,
      updatedFormData
    );
  };
  /**
   * this function use for generate the ars number
   * and check the duplicity
   */
  submit = async (e) => {
    e.preventDefault();
    const { pvtId, userId, packageName, selectedLocation } = this.props;
    const { clientFormData } = this.state;
    const f = [
      { key: "firstName", value: clientFormData.first_name },
      { key: "Current Company", value: clientFormData.current_company },
      { key: "dob", value: clientFormData.date_of_birth },
      { key: "employment level", value: clientFormData.employment_level },
      { key: "mobile", value: clientFormData.mobile_number },
      { key: "email", value: clientFormData.email_address },
      { key: "recruiter", value: clientFormData.recruiter },
      { key: "packageName", value: packageName },
      { key: "Previous Company", value: clientFormData.previous_company },
      { key: "status", value: clientFormData.status_display },
    ];
    let s = "";
    for (let i = 0; i < f.length; i++) {
      if (!f[i].value) {
        s = s + f[i].key + ",";
      }
    }
    const isDisabled =
      clientFormData.first_name &&
      clientFormData.date_of_birth &&
      clientFormData.mobile_number &&
      clientFormData.email_address &&
      packageName &&
      clientFormData.employment_level &&
      clientFormData.recruiter &&
      clientFormData.status_display &&
      clientFormData.previous_company &&
      clientFormData.current_company;

    if (clientFormData.bridge_ars_no) {
      Toast.info({
        description: "ARS number has already been generated for this case",
        title: "info",
      });
    } else {
      if (!isDisabled) {
        Toast.info({
          hideAfter: 8,
          description: `Please ensure that following mandatory fields are filled before clicking Add Case:${s}`,
          title: "info",
        });
      } else {
        try {
          this.setState({ isAddCaseLoader: true });
          await this.saveCaseDetails(e);
          // this.props.setLoadingValue(true);
          const response = await api.get(`${DUPLICITY_CHECK}${pvtId}`);
          if (response.status !== 200) {
            if (response.status === 205) {
              Toast.success({
                description: "ARS number from bridge mapped successfully",
                title: "Success",
              });
              this.props.callback();
              this.setState({ isExpand: false });
            } else {
              try {
                const result = await api.post(`${GENERATE_ARS}`, {
                  pvt_id: pvtId,
                });
                Toast.success({
                  description: "ARS number generated",
                  title: "Success",
                });
                this.props.callback();
                this.setState({ isExpand: false });
              } catch (e) {
                this.setState({ isAddCaseLoader: false });
                console.log(e);
              }
            }
          } else {
            const actualData = getDuplicityData(clientFormData);
            this.props.history.push({
              pathname: "/duplicates",
              state: {
                duplicates: response.data,
                actualData,
                userId: userId,
                pvtId,
              },
            });
          }
        } catch (e) {
          this.setState({ isAddCaseLoader: false });
          console.log(e);
        }
      }
    }
  };

  setAlternateMobile = () => {
    let { clientFormData } = this.state;
    clientFormData.alternate_mobile_numbers.push("");
    this.setState({ clientFormData });
  };
  getCaseDocument = () => {
    let caseDocumentArray = getDocuments() || [];
    caseDocumentArray.push({
      end_page: null,
      id: null,
      id_bridge: 25,
      bridge_name: "Application Form",
      actual_name: null,
      start_page: null,
      gcs_signed_url: null,
      document_type: null,
    });
    return caseDocumentArray;
  };

  render() {
    const {
      updatedFormData,
      clientFormData,
      selectedDoc,
      isExpand,
      selectDropdown,
      isAddCaseLoader,
    } = this.state;
    const {
      loadDocument,
      documentList,
      isCSE,
      isCaseInsuff,
      packageName,
    } = this.props;
    let isDisable = isCSE || isCaseInsuff || clientFormData.bridge_ars_no;
    return (
      <div>
        {isExpand ? (
          <div>
            <div className="row">
              <div>
                <hr className="brdr-dashed" />
                <div className="col">
                  <div className="small">
                    <h6
                      className={`${
                        isCaseInsuff ? "text-danger" : "text-muted"
                      } font-weight-bold mb-3`}
                    >
                      Client Details
                    </h6>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            HRC No
                          </label>
                          <CustomInput
                            type={"text"}
                            value={clientFormData.hrc_id}
                            onChange={(e) => {}}
                            required={true}
                            isDisable={true}
                          ></CustomInput>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Process Name
                          </label>
                          <CustomInput
                            type={"text"}
                            value={clientFormData.package_name}
                            onChange={(e) => {}}
                            required={true}
                            isDisable={true}
                          ></CustomInput>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Entity
                          </label>

                          <CustomInput
                            type={"text"}
                            value={clientFormData.entity}
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.entity = e.target.value;
                              updatedFormData.entity = e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={false}
                            isDisable={isDisable}
                          ></CustomInput>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Candidate Status
                          </label>
                          <CustomInput
                            type={"text"}
                            value={clientFormData.candidate_status}
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.candidate_status = e.target.value;
                              updatedFormData.candidate_status = e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={false}
                            isDisable={isDisable}
                          ></CustomInput>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Career Level
                          </label>
                          <CustomInput
                            type={"text"}
                            value={clientFormData.management_level}
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.management_level = e.target.value;
                              updatedFormData.management_level = e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={false}
                            isDisable={isDisable}
                          ></CustomInput>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            CID No.
                          </label>
                          <CustomInput
                            type={"text"}
                            value={clientFormData.cid}
                            onChange={(e) => {}}
                            required={false}
                            isDisable={true}
                          ></CustomInput>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Level for Employment{" "}
                            <sup className="staric-data">*</sup>
                          </label>

                          <CustomInput
                            type={"text"}
                            value={clientFormData.employment_level}
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.employment_level = e.target.value;
                              updatedFormData.employment_level = e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={false}
                            isDisable={isDisable}
                          ></CustomInput>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Recruiter Name <sup className="staric-data">*</sup>
                          </label>

                          <CustomInput
                            type={"text"}
                            value={clientFormData.recruiter}
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.recruiter = e.target.value;
                              updatedFormData.recruiter = e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={false}
                            isDisable={isDisable}
                          ></CustomInput>
                          {/* <CustomInput
                            type="Drop Down"
                            option={["yes", "no"]}
                            value={this.props.priorityFlag === 1 ? "yes" : "no"}
                            className={`input-section form-control text-primary ${
                              isDisable ? "row-disabled" : ""
                            }`}
                            // isDisable={true}
                            onChange={(e) => {
                              const flagValue =
                                e.target.value === "yes" ? 1 : 0;

                              this.setClintData("priorityFlag", flagValue);
                            }}
                          ></CustomInput> */}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Date of Offer Made{" "}
                          </label>

                          <CustomInput
                            type={"Date"}
                            value={
                              clientFormData.offer_made_date === "Not Mentioned"
                                ? null
                                : clientFormData.offer_made_date
                            }
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.offer_made_date = e.target.value;
                              updatedFormData.offer_made_date = e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={false}
                            isDisable={isDisable}
                          ></CustomInput>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Case Status <sup className="staric-data">*</sup>
                          </label>
                          <CustomInput
                            type={"text"}
                            value={clientFormData.status_display}
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.status_display = e.target.value;
                              updatedFormData.status_display = e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={false}
                            isDisable={isDisable}
                          ></CustomInput>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-muted text-uppercase label-header">
                            Channel
                          </label>
                          <CustomInput
                            type={"text"}
                            value={clientFormData.channel}
                            onChange={(e) => {
                              let { clientFormData } = this.state;
                              clientFormData.channel = e.target.value;
                              updatedFormData.channel = e.target.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            required={false}
                            isDisable={isDisable}
                          ></CustomInput>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <label className="text-muted text-uppercase label-header">
                          Current Company <sup className="staric-data">*</sup>
                        </label>
                        <div>
                          <Selectbox
                            option={[
                              { label: "NA Task", value: "NA Task" },
                              { label: "Not NA Task", value: "Not NA Task" },
                            ]}
                            defaultValue={
                              clientFormData.current_company
                                ? {
                                    label: clientFormData.current_company,
                                    value: clientFormData.current_company,
                                  }
                                : null
                            }
                            callback={(selectObj) => {
                              let { clientFormData } = this.state;
                              clientFormData.current_company = selectObj.value;
                              updatedFormData.current_company = selectObj.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            isDisable={isDisable}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="text-muted text-uppercase label-header">
                          Previous Company <sup className="staric-data">*</sup>
                        </label>
                        <div>
                          <Selectbox
                            option={[
                              { label: "NA Task", value: "NA Task" },
                              { label: "Not NA Task", value: "Not NA Task" },
                            ]}
                            defaultValue={
                              clientFormData.previous_company
                                ? {
                                    label: clientFormData.previous_company,
                                    value: clientFormData.previous_company,
                                  }
                                : null
                            }
                            callback={(selectObj) => {
                              let { clientFormData } = this.state;
                              clientFormData.previous_company = selectObj.value;
                              updatedFormData.previous_company =
                                selectObj.value;
                              this.setState({
                                clientFormData,
                                updatedFormData,
                              });
                            }}
                            isDisable={isDisable}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <DocSelection
                          callback={this.getSelectDoc}
                          loadDocument={loadDocument}
                          docArray={selectedDoc}
                          documentCheck={this.getCaseDocument()}
                          selectDropdown={selectDropdown}
                          isDisable={isDisable}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <hr className="brdr-dashed" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <h6
                          className={`${
                            isCaseInsuff ? "text-danger" : "text-muted"
                          } font-weight-bold mb-3`}
                        >
                          Candidate Details
                        </h6>
                        <div
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginBottom: "20px",
                          }}
                        >
                          All (*) fields are required!
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                First Name<sup className="staric-data">*</sup>
                              </label>
                              <CustomInput
                                type={"text"}
                                value={clientFormData.first_name}
                                onChange={(e) => {
                                  let { clientFormData } = this.state;
                                  clientFormData.first_name = e.target.value;
                                  updatedFormData.first_name = e.target.value;
                                  this.setState({
                                    clientFormData,
                                    updatedFormData,
                                  });
                                }}
                                required={false}
                                isDisable={isDisable}
                              ></CustomInput>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Middle Name{" "}
                              </label>
                              <CustomInput
                                type={"text"}
                                value={clientFormData.middle_name}
                                onChange={(e) => {
                                  let { clientFormData } = this.state;
                                  clientFormData.middle_name = e.target.value;
                                  updatedFormData.middle_name = e.target.value;
                                  this.setState({
                                    clientFormData,
                                    updatedFormData,
                                  });
                                }}
                                required={true}
                                isDisable={isDisable}
                              ></CustomInput>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Last Name{" "}
                              </label>
                              <CustomInput
                                type={"text"}
                                value={clientFormData.last_name}
                                onChange={(e) => {
                                  let { clientFormData } = this.state;
                                  clientFormData.last_name = e.target.value;
                                  updatedFormData.last_name = e.target.value;
                                  this.setState({
                                    clientFormData,
                                    updatedFormData,
                                  });
                                }}
                                required={true}
                                isDisable={isDisable}
                              ></CustomInput>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Date of Birth
                                <sup className="staric-data">*</sup>
                              </label>
                              <CustomInput
                                type={"Date"}
                                value={clientFormData.date_of_birth}
                                onChange={(e) => {
                                  let { clientFormData } = this.state;
                                  clientFormData.date_of_birth = e.target.value;
                                  updatedFormData.date_of_birth =
                                    e.target.value;
                                  this.setState({
                                    clientFormData,
                                    updatedFormData,
                                  });
                                }}
                                required={false}
                                isDisable={isDisable}
                              ></CustomInput>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Email
                                <sup className="staric-data">*</sup>
                              </label>

                              <CustomInput
                                type={"text"}
                                value={clientFormData.email_address}
                                onChange={(e) => {
                                  let { clientFormData } = this.state;
                                  clientFormData.email_address = e.target.value;
                                  updatedFormData.email_address =
                                    e.target.value;
                                  this.setState({
                                    clientFormData,
                                    updatedFormData,
                                  });
                                }}
                                required={true}
                                isDisable={isDisable}
                              ></CustomInput>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Father Name
                              </label>
                              <CustomInput
                                type={"text"}
                                value={clientFormData.fathers_name}
                                onChange={(e) => {
                                  let { clientFormData } = this.state;
                                  clientFormData.fathers_name = e.target.value;
                                  updatedFormData.fathers_name = e.target.value;
                                  this.setState({
                                    clientFormData,
                                    updatedFormData,
                                  });
                                }}
                                required={true}
                                isDisable={isDisable}
                              ></CustomInput>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Mobile Number{" "}
                                <sup className="staric-data">*</sup>
                              </label>

                              <CustomInput
                                type={"text"}
                                value={clientFormData.mobile_number}
                                onChange={(e) => {
                                  let { clientFormData } = this.state;
                                  clientFormData.mobile_number = e.target.value;
                                  updatedFormData.mobile_number =
                                    e.target.value;
                                  this.setState({
                                    clientFormData,
                                    updatedFormData,
                                  });
                                }}
                                required={true}
                                isDisable={isDisable}
                              ></CustomInput>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="text-muted text-uppercase label-header">
                                Data Source
                              </label>
                              <CustomInput
                                type={"text"}
                                value={"Client Portal"}
                                required={true}
                                isDisable={true}
                              ></CustomInput>
                            </div>
                          </div>
                        </div>
                        {/* alternaate mobile */}
                        <div class="row">
                          <div className="col-md-6">
                            <label className="text-muted text-uppercase label-header">
                              Alternate Mobile Number{" "}
                              <button
                                className="transparent-borderless"
                                onClick={() => {
                                  this.setAlternateMobile();
                                }}
                                disabled={isDisable}
                              >
                                <i className="fa fa-plus icon-primary alter-plus"></i>
                              </button>
                            </label>
                          </div>
                        </div>

                        <div className="row">
                          {clientFormData.alternate_mobile_numbers &&
                            clientFormData.alternate_mobile_numbers.map(
                              (value, index) => {
                                return (
                                  <div className="col-md-4" key={index}>
                                    <div className="form-group">
                                      <CustomInput
                                        type={"text"}
                                        value={value}
                                        required={false}
                                        isDisable={isDisable}
                                        onChange={(e) => {
                                          let { clientFormData } = this.state;
                                          clientFormData.alternate_mobile_numbers[
                                            index
                                          ] = e.target.value;
                                          updatedFormData.alternate_mobile_numbers = [
                                            ...clientFormData.alternate_mobile_numbers,
                                          ];
                                          this.setState({
                                            clientFormData,
                                            updatedFormData,
                                          });
                                        }}
                                      ></CustomInput>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>

                        <div class="row">
                          <div class="col">
                            <hr class="brdr-dashed" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <h6
                              className={`${
                                isCaseInsuff ? "text-danger" : "text-muted"
                              } font-weight-bold mb-3`}
                            >
                              National Identity Document
                            </h6>
                            <div class="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="text-muted text-uppercase label-header">
                                    Pan Card No.
                                    {/* <sup>*</sup> */}
                                  </label>
                                  <CustomInput
                                    type={"text"}
                                    value={clientFormData.pan}
                                    onChange={(e) => {
                                      let { clientFormData } = this.state;
                                      clientFormData.pan = e.target.value;
                                      updatedFormData.pan = e.target.value;
                                      this.setState({
                                        clientFormData,
                                        updatedFormData,
                                      });
                                    }}
                                    required={true}
                                    isDisable={isDisable}
                                  ></CustomInput>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="text-muted text-uppercase label-header">
                                    Passport Number
                                    {/* <sup>*</sup> */}
                                  </label>

                                  <CustomInput
                                    type={"text"}
                                    value={clientFormData.passport}
                                    onChange={(e) => {
                                      let { clientFormData } = this.state;
                                      clientFormData.passport = e.target.value;
                                      updatedFormData.passport = e.target.value;
                                      this.setState({
                                        clientFormData,
                                        updatedFormData,
                                      });
                                    }}
                                    required={true}
                                    isDisable={isDisable}
                                  ></CustomInput>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <hr className="brdr-dashed" />
                      <div className="col">
                        {!isAddCaseLoader ? (
                          <button
                            className="btn btn-primary btn-sm float-right m-2"
                            // type="submit"
                            disabled={this.props.isCaseInsuff || isCSE}
                            onClick={(e) => {
                              this.submit(e);
                            }}
                          >
                            Add Case
                          </button>
                        ) : (
                          <div
                            class="spinner-border text-info float-right m-2"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        )}
                        <button
                          className="btn btn-primary btn-sm float-right m-2 "
                          disabled={this.props.isCaseInsuff || isCSE}
                          onClick={(e) => {
                            this.saveCaseDetails(e, "save");
                          }}
                        >
                          Save Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto text-center expand-link text-primary small position-relative">
                &nbsp;&nbsp;
                <i
                  className="fa fa-caret-up text-muted"
                  style={{ fontSize: "36px" }}
                  onClick={() => {
                    this.setState({ isExpand: !isExpand });
                  }}
                ></i>
              </div>
            </div>
          </div>
        ) : (
          <div className="row justify-content-center">
            <div className="col-auto text-center expand-link text-primary small position-relative">
              &nbsp;&nbsp;
              <i
                className="fa fa-caret-down text-muted"
                style={{ fontSize: "36px" }}
                onClick={() => {
                  this.setState({ isExpand: !isExpand });
                }}
              ></i>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(ClientDetail);
