import React from "react";
import "./casesCountCard.scss";

export default function CasesCountCard({ title, countArr,statusType }) {
  return (
    <div className="card cases-count-card">
      <h5 className="card-header count-container-title">{title}</h5>
      <div className="card-body count-box-container">
        {countArr.map((element) => (
          <div
            className={`count-box ${
              element.status === statusType ? "selected" : ""
            }`}
            onClick={() => element.callback(element.status)}
          >
            <span className="count-text">{element.count}</span>
            <span className="status">{element.countTitle}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
