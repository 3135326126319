import { ActionTypes } from "./login.types";

export const login = payload => ({
  type: ActionTypes.LOGIN,
  payload: payload
});

export const loginSuccess = payload => ({
  type: ActionTypes.LOGIN_SUCCESS,
  payload: payload
});

export const loginFail = () => ({
  type: ActionTypes.LOGIN_FAIL
});

export const logout = () => ({
  type: ActionTypes.LOGOUT
});
export const update = (payload) => ({
  type: ActionTypes.UPDATE,
  payload:payload
});

