import React, { useState } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import api from "./../../apiBaseConfig";

const ExportCSV = ({ column, fileName, exportCSVArr = [] }) => {
  const [isLoader, setLoader] = useState(false);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const getResult = (data) => {
    let result = [];
    for (let i = 0; i < data.length; i++) {
      let S = data[i];
      let obj = {};
      for (let j = 0; j < column.length; j++) {
        const g = column[j];
        if (g["field"]) {
          obj[g["name"]] = S[g["field"]];
        }
        if (g["type"]) {
          const bgvId =
            S.verification_type === "PreJoining" ||
            S.verification_type === "Existing"
              ? S.bgv_id
              : S.bgv_id2;
          obj[g["name"]] = bgvId;
        }
      }
      result.push(obj);
    }
    return result;
  };

  const exportDownloadCSV = (exportCSV) => {
    return api.get(exportCSV);
  };

  const getTotalBucketCases = async () => {
    let arr = [];
    for (let i = 0; i < exportCSVArr.length; i++) {
      arr.push(exportDownloadCSV(exportCSVArr[i]));
    }
    try {
      const response = await Promise.all(arr);
      let totalCases = [];
      for (let i = 0; i < response.length; i++) {
        totalCases = [...totalCases, ...response[i].data];
      }
      return Promise.resolve(totalCases);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  };

  const exportToCSV = async (fileName) => {
    let isLoader = true;
    try {
      setLoader(isLoader);
      const totalCases = await getTotalBucketCases();
      const result = getResult(totalCases);
      isLoader = false;
      setLoader(isLoader);
      const ws = XLSX.utils.json_to_sheet(result);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (e) {
      isLoader = false;
      setLoader(isLoader);
      console.log(e);
    }
  };

  return (
    <div>
      {isLoader ? (
        <div
          class="spinner-border text-info float-right m-2 spinner-block"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      ) : (
        <button
          className="btn btn-success mr-2 export-csv"
          onClick={(e) => exportToCSV(fileName)}
        >
          Export To Excel
        </button>
      )}
    </div>
  );
};

export default ExportCSV;
