/**
 * Qc dashboard component(supervisor)
 */
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import TableWrapper from "./../../../components/Tablewrapper/tablewrapper";
import DROPDOWN from "./../../../components/dropdown/dropdown";
import { Link } from "react-router-dom";
import api from "./../../../apiBaseConfig";
import { modalManager } from "./../../../utils/modalutils";
import Uploadnewcases from "../../../components/uploadnewcases/uploadnewcases";
import Escalation from "./../../../components/Escalation/escalation";
import { genericFormatDateTime, caseCount } from "./../../../utils/commonutils";
import Userlist from "./../../../components/Userlist/userlist";
import { Toast } from "./../../../utils/toastutils";
import ExportCSV from "./../../../components/exporttoexcel/exporttoexcel";
import CommentBox from "./../../../screens/bucketDetails/components/commentBox/commentBox";
import CaseHistory from "./../../../components/caseHistory/caseHistory";
import CustomInput from "./../../../components/customInput/customInput";
import Pagination from "./../../../components/pagination/pagination";
import { constants } from "./../../../constants";
import CasesCountCard from "./../../../components/casesCountCard/casesCountCard";
import Csisupload from "./../../../components/buckettimer/component/csisupload";
import {
  BUCKET_CASES,
  BUCKET_COUNT,
  SEARCH_BGV_ID,
  DASHBOARD_CASES_FILTER_BY_DATE,
  QCDASHBOARD_COMMENT_HISTORY,
  QCDASHBOARD_ADD_COMMENT,
  ASSIGN_USER,
  ESCALATION_END_POINT,
  CHANGE_STATUS,
  USER_LIST,
  USER_LIST_DATA,
  CASE_HISTORY,
  CHANGE_STATUS_CASE,
  CASE_HISTORY_COMMENT,
  SUBMIT_COMMENT,
  EXPORT_TO_CSV,
  CSIS_DOWNLOAD,
} from "./../../../apiurl";

import "./cseDashboard.scss";
let bucketCases = [];

const REFRESH_TIME = 60000; //milliseconds;

class Csedashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      column: [
        {
          name: "HRC ID",
          field: "hrc_id",
          template: function (listing) {
            const { userInfo } = props;
            return (
              <div>
                {userInfo.roleId === 73 ? (
                  <Link
                    to={{
                      pathname: `/bucketdetails/${listing.id}`,
                      state: { isCSE: true },
                    }}
                  >
                    {listing.hrc_id}
                  </Link>
                ) : (
                  <div>{listing.hrc_id}</div>
                )}
              </div>
            );
          },
        },
        {
          name: "Candidate Name",
          field: "first_name",
        },
        {
          name: "Candidate Status",
          field: "candidate_status",
        },
        // {
        //   name: "Assign To",
        //   field: "",
        //   template: function (listing) {
        //     return <div>hello</div>;
        //   },
        // },
        {
          name: "Qc Status",
          field: "status_display",
          template: function (listing) {
            return (
              <div class="alert alert-info mb-0 p-2 text-center">
                {listing.status_display}
              </div>
            );
          },
        },
        {
          name: "Case Received Date",
          field: "date_of_initiation",
          template: (listing) => {
            return (
              <div>{genericFormatDateTime(listing.date_of_initiation)}</div>
            );
          },
        },
        {
          name: "Ars Id",
          field: "bridge_ars_no",
        },
        {
          name: "Action",
          field: "",
          template: (listing) => {
            const option = [];
            const { userInfo } = props;
              option.push({
                value: "escalating",
                label: "Add Comment",
                status: "Escalation",
                template: (data) => {
                  return (
                    <CaseHistory
                      bridgeCaseId={data.bridge_case_id || null}
                      isCheck={false}
                      pvtId={data.id}
                      userInfo={userInfo}
                      getCommentHistory={() => {
                        return this.getCaseCommentHistory(data.id);
                      }}
                      label="Case History"
                      buttonName="Add To Comment"
                      submitComment={this.submitCaseComment}
                      closeCommentHistoryModal={() => modalManager.close({})}
                      successMessage={"comment added successfully"}
                      errorMessage={"There is some error in adding comment"}
                    />
                  );
                },
              });
            

            if (
              userInfo.roleId != 73 &&
              listing.status === constants.caseStatus.ESCALATION_RAISED
            ) {
              option.push({
                value: "assignto",
                label: "Assign To",
                status: "assignto",
                template: (data) => {
                  return (
                    <Userlist
                      data={data}
                      callback={this.assignToUser}
                      userList={this.state.userList}
                    />
                  );
                },
              });
            }

            if (listing.status != constants.caseStatus.ESCALATION_RESPONDED) {
              option.push({
                value: "escalating",
                label: "Respond To Esclation",
                status: "escalating",
                template: (data) => {
                  const { userInfo } = props;
                  const escalationObject = {
                    ...data,
                    label: "Esacalting",
                    userName: `${userInfo.userFirstName} ${userInfo.userLastName}`,
                    userId: userInfo.userId,
                    status: constants.caseStatus.ESCALATION_RESPONDED,
                    action: "Escalation received",
                  };
                  return (
                    <Escalation
                      data={escalationObject}
                      callback={this.changeStatus}
                      btnLabel="Respond To Esclation"
                    />
                  );
                },
              });
            }
            return (
              <div>
                <DROPDOWN
                  isDropdown={true}
                  option={option}
                  callback={(data) => {
                    const { userInfo } = props;
                    const result = {
                      label: data.value,
                      statusValue: data.status,
                      ...listing,
                      userName: userInfo.roleName,
                      userId: userInfo.userId,
                    };
                    if (data.label === "Relive") {
                      data.template(result);
                    } else {
                      if (data.label != "Assign To") {
                        modalManager.open({
                          isHeader: false,
                          component: () => data.template(result),
                        });
                      } else {
                        modalManager.open({
                          isHeader: false,
                          width: "small",
                          component: () => data.template(result),
                        });
                      }
                    }
                  }}
                />
              </div>
            );
          },
        },
      ],

      bucket: {},
      bucketName: {},
      cases: [],
      isFilter: false,
      searchBgvId: "",
      maxDate: null,
      minDate: null,
      loading: true,
      statusType: constants.caseStatus.ESCALATION_ASSIGNED,
      totalBucketCases: [],
      countArrExtracted: [],
      countArrInsuff: [],
      countArrOthers: [],
      countArrDownload: [],
      previous: "",
      next: "",
      page: 1,
      columToExport: [],
    };
  }

  componentDidMount = () => {
    const { userInfo } = this.props;
    this.getBucket();
    this.getCases(constants.caseStatus.ESCALATION_ASSIGNED);
    if (userInfo.roleId !== 73) {
      this.getUserList();
      this.getTotalBucketCases();
    }
    const setIntervalId = setInterval(() => {
      this.refresh();
    }, REFRESH_TIME);
    this.setState({ setIntervalId });
  };

  componentWillUnmount() {
    clearInterval(this.state.setIntervalId);
  }

  getUserList = async () => {
    try {
      const response = await api.get(`${USER_LIST_DATA}`);
      const data = response.data.map((value) => {
        value.label = value.cse_name;
        return value;
      });
      this.setState({ userList: data });
    } catch (e) {
      console.log(e);
    }
  };

  changeStatus = async (obj) => {
    const { userInfo } = this.props;
    const body = {
      status: obj.status,
    };
    try {
      const commentObj = {
        comment: obj.comment,
        pvtId: obj.id,
        email: userInfo.email,
        action: obj.action,
        bridgeCaseId: obj.bridge_case_id || null,
      };
      const responseObj = await this.submitCaseComment(commentObj);
      const response = await api.patch(`${CHANGE_STATUS_CASE}${obj.id}/`, body);
      Toast.success({
        description: "change status successfully",
      });
      modalManager.close({});
      this.refresh();
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * this is use for getting case history comment
   */
  getCaseCommentHistory = async (pvtId) => {
    let response;
    try {
      response = await api.get(`${CASE_HISTORY_COMMENT}?pvt_id=${pvtId}`);
      console.log(response, "case history");
      const comments = response.data.map((commentObj) => {
        return {
          commentId: commentObj.id,
          createdAt: commentObj.created_at,
          comment: commentObj.comment && commentObj.comment.message,
          action: commentObj.name,
          userName: "",
          roleName: "",
        };
      });
      //response = response.data.comments;
      return Promise.resolve(comments);
    } catch (error) {
      console.log("error", error);
      return Promise.reject(error);
    }
  };

  /**
   * this is for submit the case history comment
   */
  submitCaseComment = async (commentObject) => {
    const { userInfo } = this.props;
    try {
      const action = await api.post(
        `${CASE_HISTORY_COMMENT}?pvt_id=${commentObject.pvtId}`,
        {
          pvt_id: commentObject.pvtId,
          name: commentObject.action
            ? commentObject.action
            : "Comment Added on case",
          created_by: commentObject.email,
        }
      );
      const actionId = action.data.id;
      const response = await api.post(SUBMIT_COMMENT, {
        message: commentObject.comment,
        action: actionId,
        bridge_case_id: commentObject.bridgeCaseId,
      });
      return Promise.resolve(response);
    } catch (error) {
      console.log("error", error);
      return Promise.reject(error);
    }
  };

  refresh = () => {
    const { statusType } = this.state;
    this.getBucket();
    this.getCases(statusType);
  };

  /**
   * this is use for assign the user by supervisor
   */
  assignToUser = async (data) => {
    const body = {
      status: constants.caseStatus.ESCALATION_ASSIGNED,
      cse_id: data.userId,
      cse_name: data.userName,
    };
    try {
      const response = await api.patch(
        `${CHANGE_STATUS_CASE}${data.id}/`,
        body
      );
      modalManager.close({});
      this.refresh();
      Toast.success({
        description: "Assigned to user successfully",
        title: "Success",
      });
    } catch (e) {
      console.log(e);
      Toast.success({ description: "Something went wrong", title: "Error" });
    }
  };

  getTotalBucketCases = async () => {
    try {
      const res = await api.get(EXPORT_TO_CSV);
      const totalCases = res.data || [];
      const columToExport = [
        {
          name: "Ars Id",
          field: "ars_id",
        },
        {
          name: "Assigned To",
          field: "assigned_to",
        },
        {
          name: "Candidate Name",
          field: "candidate_name",
        },
        {
          name: "Case Received Date",
          field: "case_received_date",
        },
        {
          name: "Hrc Id",
          field: "hrc_id",
        },
        {
          name: "Last Action Time",
          field: "last_action_time",
        },
        {
          name: "Status",
          field: "status",
        },
      ];
      this.setState({ totalBucketCases: totalCases, columToExport });
    } catch (e) {
      console.log(e);
    }
  };

  getAllCases = (type) => {
    return api.get(`${BUCKET_CASES}?status=${type}`);
  };
  /**
   * this is use for getting all cases
   */
  getCases = async (type, pageNumber = 1) => {
    this.setState({ loading: true });
    let result = [];
    let { page, previous, next } = this.state;
    try {
      const response = await api.get(
        `${BUCKET_CASES}?status=${type}&page=${pageNumber}`
      );
      result = response.data.results;
      bucketCases = [...result];
      page = pageNumber;
      previous = response.data.previous;
      next = response.data.next;
    } catch (e) {
      console.log(e);
    }
    this.setState({
      cases: result,
      loading: false,
      statusType: type,
      page,
      previous,
      next,
    });
  };

  getPaginationCase = (pageNumber) => {
    const { statusType } = this.state;
    this.getCases(statusType, pageNumber);
  };

  /**
   * getting for all bucket number
   */
  getBucket = async () => {
    let {
      bucket,
      bucketName,
      countArrExtracted,
      statusType,
      countArrInsuff = [],
      countArrDownload = [],
      countArrOthers = [],
    } = this.state;
    const { userInfo } = this.props;
    try {
      const response = await api.get(BUCKET_COUNT);
      bucket = {};
      response.data.map((bucketData) => {
        bucket[bucketData.id] = bucketData.count;
        bucketName[bucketData.id] = bucketData.name;
      });
      countArrInsuff = [
        caseCount(
          bucket[constants.caseStatus.ESCALATION_ASSIGNED],
          bucketName[constants.caseStatus.ESCALATION_ASSIGNED],
          this.getCases,
          constants.caseStatus.ESCALATION_ASSIGNED,
          statusType
        ),
        caseCount(
          bucket[constants.caseStatus.ESCALATION_RESPONDED],
          bucketName[constants.caseStatus.ESCALATION_RESPONDED],
          this.getCases,
          constants.caseStatus.ESCALATION_RESPONDED,
          statusType
        ),
      ];
      if (userInfo.roleId !== 73) {
        countArrInsuff.push(
          caseCount(
            bucket[constants.caseStatus.ESCALATION_RAISED],
            bucketName[constants.caseStatus.ESCALATION_RAISED],
            this.getCases,
            constants.caseStatus.ESCALATION_RAISED,
            statusType
          )
        );
      }
      this.setState({
        bucket,
        bucketName,
        countArrInsuff,
      });
    } catch (e) {
      console.log(e);
    }
  };

  onFilterClick = () => {
    const { isFilter, caseType } = this.state;
    this.setState({ isFilter: !isFilter, cases: bucketCases });
  };

  updateBgvId = (value) => {
    this.setState({ searchBgvId: value });
  };

  /**
   * download the csis excel sheet
   */
  openUrl = async () => {
    let csisArray = [];
    try {
      const csisFile = await api.get(CSIS_DOWNLOAD);
      const fileData = csisFile.data;
      Object.keys(fileData).map((key) => {
        csisArray.push({
          url: `${process.env.REACT_APP_FILE_URL}${fileData[key]}`,
        });
      });
      for (var i = 0; i < csisArray.length; i++) {
        setTimeout(
          function (path) {
            window.location = path;
          },
          1000 + i * 1000,
          csisArray[i].url
        );
      }
      console.log(csisArray, "csisfile");
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * this help for upload the csis file
   */
  uploadCsis = async (data, formData) => {
    try {
      const response = await api.post(CSIS_DOWNLOAD, formData);
      modalManager.close({});
      Toast.success({
        description: "csis uploaded successfully",
        title: "success",
      });
      // this.getCsis();
    } catch (e) {
      console.log(e);
    }
  };

  openWindow = () => {
    modalManager.open({
      width: "small",
      component: () => (
        <Csisupload
          fileCount={1}
          callback={this.uploadCsis}
          csisArray={[
            {
              label: "Accenture solution private limited",
              version: 0,
              id: 1,
              type: "accenture_solutions_private_limited_file",
            },
            // {
            //   label: "Existing Offshore Customer Package",
            //   version: 0,
            //   id: 2,
            //   type: "existing_offshore_file",
            // },
            // {
            //   label: "HCL Technologies_1951 (Special Instructions)",
            //   version: 97,
            //   id: 3,
            //   type: "special_instructions_file",
            // },
          ]}
        />
      ),
    });
  };

  /**
   * this help for search the cases according to bgv id
   */
  searchFilter = async () => {
    const { searchBgvId } = this.state;
    try {
      const response = await api.get(
        `${BUCKET_CASES}?hrc_id__iexact=${searchBgvId}`
      );
      this.setState({ cases: response.data.results });
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * set the from date for search the case
   */
  addEventFromDate = (event) => {
    this.setState({ minDate: event.target.value }, () => {
      const { minDate, maxDate } = this.state;
      if (maxDate === null) {
      } else if (minDate && maxDate >= minDate) {
        this.getDataBetweenDates();
      } else {
        alert("From Date can not be greater than To Date!");
      }
    });
  };

  /**
   * set the to date for search the cases
   */
  addEventToDate = (event) => {
    this.setState({ maxDate: event.target.value }, () => {
      const { minDate, maxDate } = this.state;
      if (minDate === null) {
      } else if (maxDate >= minDate) {
        this.getDataBetweenDates();
      } else {
        alert("From Date can not be greater than To Date!");
      }
    });
  };

  /**
   * search the cases according to from date and to date
   */
  getDataBetweenDates = async () => {
    const { minDate, maxDate } = this.state;
    try {
      const response = await api.get(
        `${BUCKET_CASES}?date_of_initiation__gte=${minDate}T00:00:00&date_of_initiation__lte=${maxDate}T00:00:00`
      );
      this.setState({ cases: response.data.results });
    } catch (e) {
      console.log(e);
    }
  };

  openModalforNewCases = () => {
    modalManager.open({
      isHeader: true,
      heading: "Upload New Cases",
      component: () => <Uploadnewcases callback={this.refresh} />,
    });
  };
  render() {
    const {
      statusType,
      bucketName,
      data,
      bucket,
      cases,
      isFilter,
      searchBgvId,
      column,
      loading,
      maxDate,
      minDate,
      totalBucketCases,
      countArrInsuff,
      page,
      previous,
      next,
      columToExport,
    } = this.state;
    const { userInfo } = this.props;
    return (
      <div className="dashboard-block">
        <div className="dashboard-upper">
          <div>
            <h6>Dashboard</h6>
            <div className="cse-csis-upload">
              <div class="d-flex">
                <button
                  class="btn btn-primary btn-sm text-uppercase border-0"
                  onClick={() => {
                    this.openUrl();
                  }}
                >
                  csis <i class="fa fa-arrow-down" aria-hidden="true"></i>
                </button>

                <button
                  class="btn btn-primary btn-sm text-uppercase border-0"
                  onClick={() => {
                    this.openWindow();
                  }}
                  style={{ marginLeft: "4px" }}
                >
                  csis <i class="fa fa-arrow-up" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 padding-left">
              <CasesCountCard
                title="Escalations"
                countArr={countArrInsuff}
                statusType={statusType}
              />
            </div>
            <div className="download">
              {/* <button
                className="btn btn-primary upload-button-cases"
                onClick={this.openModalforNewCases}
              >
                Upload New Cases
              </button> */}
              {userInfo.roleId !== 73 ? (
                <div>
                  <ExportCSV
                    column={columToExport}
                    data={totalBucketCases}
                    fileName={"dashboard"}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {loading ? (
          <div className="loading-block">Loading...</div>
        ) : (
          <div>
            {cases.length === 0 ? (
              <div className="no-data"> NO data found</div>
            ) : (
              <div>
                <div class="row search-section">
                  <div class="col-md-6">
                    <h6 class="mt-2">CONSOLIDATED LIST</h6>
                  </div>
                  <div className="col-md-6 text-right">
                    <button
                      class="btn btn-light export-csv"
                      onClick={() => {
                        this.onFilterClick();
                      }}
                    >
                      Search
                    </button>
                  </div>
                </div>
                {isFilter && (
                  <div className="filter-block">
                    <div class="row shadow-lg bg-white rounded filter-section-block">
                      <div class="col-4">
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control input-section"
                            placeholder="Search BGV ID"
                            onChange={(event) => {
                              this.updateBgvId(event.target.value);
                            }}
                          />
                          {searchBgvId && (
                            <div class="input-group-append">
                              <button
                                class="btn btn-secondary"
                                type="button"
                                onClick={() => {
                                  this.searchFilter();
                                }}
                              >
                                <i class="fa fa-search"></i>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>

                      <div class="col-8">
                        <div class="row" style={{ alignItems: "center" }}>
                          <label
                            for="fromDate"
                            class="col col-form-label"
                            style={{ textAlign: "right", fontSize: "15px" }}
                          >
                            From Date
                          </label>
                          <div class="col">
                            <CustomInput
                              type={"Date"}
                              onChange={(e) => {
                                this.addEventFromDate(e);
                              }}
                              value={minDate}
                            ></CustomInput>
                          </div>
                          <label
                            for="toDate"
                            class="col col-form-label"
                            style={{ textAlign: "right", fontSize: "15px" }}
                          >
                            To Date
                          </label>
                          <div class="col">
                            <CustomInput
                              type={"Date"}
                              onChange={(e) => {
                                this.addEventToDate(e);
                              }}
                              value={maxDate}
                            ></CustomInput>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="table-block">
                  <TableWrapper column={column} data={cases} />
                </div>
                <div className="pagination-block">
                  <Pagination
                    previous={previous}
                    next={next}
                    page={page}
                    callback={this.getPaginationCase}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.login.userInfo,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Csedashboard);
