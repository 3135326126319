import React, { Component } from "react";
import "./qcDashboard.scss";
import api from "../../../apiBaseConfig";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import LoadingScreen from "../../../components/loadingScreen/loadingScreen";
import { modalManager } from "../../../utils/modalutils";
import Escalation from "../../../components/Escalation/escalation";
import TableWrapper from "../../../components/Tablewrapper/tablewrapper";
import CasesCountCard from "../../../components/casesCountCard/casesCountCard";
import { CASE_HISTORY_COMMENT, SUBMIT_COMMENT } from "./../../../apiurl";
import DROPDOWN from "../../../components/dropdown/dropdown";
import { resume } from "../../../components/buckettimer/state/timer.action";
import CaseHistory from "./../../../components/caseHistory/caseHistory";
import Pagination from "./../../../components/pagination/pagination";
import {
  HCL_BUCKET_CASES,
  HCL_FETCH_CASES,
  HCL_BUCKET_COUNT,
} from "../../../apiurl";
import { constants } from "../../../constants";
import { genericFormatDateTime } from "./../../../utils/commonutils";

const { caseStatus } = constants;

class QCDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bucketCases: [],
      loading: true,
      selectedPvtId: null,
      statusType: constants.caseStatus.ASSIGNED,
      loading: false,
      isCommentModelOpen: false,
      bucket: {},
      bucketName: {},
      countArrExtracted: [],
      countArrInsuff: [],
      countArrOthers: [],
      page: 1,
      previous: "",
      next: "",
    };
  }

  column = [
    {
      name: "BGV ID",
      field: "",
      template: function (listing) {
        return (
          <div>
            {listing.status === constants.caseStatus.QC_COMPLETE ? (
              <div>{listing.bgv_id}</div>
            ) : (
              <Link
                to={{
                  pathname: `/bucketdetails/${listing.id}`,
                }}
              >
                {listing.bgv_id}
              </Link>
            )}
          </div>
        );
      },
    },
    {
      name: "Candidate Name",
      field: "candidate_name",
    },
    {
      name: "Assign To",
      field: "user_name",
    },
    {
      name: "Qc Status",
      field: "status_display",
      template: function (listing) {
        return (
          <div class="alert alert-info mb-0 p-2 text-center">
            {listing.status_display}
          </div>
        );
      },
    },
    {
      name: "Ars Id",
      field: "bridge_ars_no",
    },
    {
      name: "Case Received Date",
      field: "date_of_initiation",
      template: (listing) => {
        return <div>{genericFormatDateTime(listing.date_of_initiation)}</div>;
      },
    },
    {
      name: "Sla Start Date",
      field: "sla_start_date",
      template: (listing) => {
        return <div>{genericFormatDateTime(listing.sla_start_date)}</div>;
      },
    },
    {
      name: "Verification Type",
      field: "verification_type",
    },
    {
      name: "Action",
      field: "",
      template: (listing) => {
        const option = [];
        const { userInfo } = this.props;

        option.push({
          value: "escalating",
          label: "Case History",
          status: "Escalation",
          template: (data) => {
            return (
              <CaseHistory
                bridgeCaseId={data.bridge_case_id || null}
                isCheck={false}
                pvtId={data.id}
                userInfo={userInfo}
                getCommentHistory={() => {
                  return this.getCaseCommentHistory(data.id);
                }}
                label="Case History"
                buttonName="Add To Comment"
                submitComment={this.submitCaseComment}
                closeCommentHistoryModal={() => modalManager.close({})}
                successMessage={"comment added successfully"}
                errorMessage={"There is some error in adding comment"}
              />
            );
          },
        });

        return (
          <div>
            <DROPDOWN
              isDropdown={true}
              option={option}
              callback={(data) => {
                const { userInfo } = this.props;
                const result = {
                  label: data.value,
                  statusValue: data.status,
                  ...listing,
                  userName: userInfo.roleName,
                  userId: userInfo.userId,
                };
                if (data.label === "Relive") {
                  data.template(result);
                } else {
                  if (data.label != "Assign To") {
                    modalManager.open({
                      isHeader: false,
                      component: () => data.template(result),
                    });
                  } else {
                    modalManager.open({
                      isHeader: false,
                      width: "small",
                      component: () => data.template(result),
                    });
                  }
                }
              }}
            />
          </div>
        );
      },
    },
  ];

  componentDidMount() {
    console.log("hcl");
    //  this.getBucketCases();
    this.getBucket();
    this.getCases(constants.caseStatus.ASSIGNED);
  }

  fetchCases = () => {
    this.setState({ loading: true });

    api
      .get(HCL_FETCH_CASES)
      .then(() => this.refresh())
      .catch((err) => {
        this.setState({ loading: false });
        console.log("error while fetching cases", err);
      });
  };

  /**
   * this is use for getting case history comment
   */
  getCaseCommentHistory = async (pvtId) => {
    let response;
    try {
      response = await api.get(`${CASE_HISTORY_COMMENT}?pvt_id=${pvtId}`);
      const comments = response.data.map((commentObj) => {
        return {
          commentId: commentObj.id,
          createdAt: commentObj.created_at,
          comment: commentObj.comment && commentObj.comment.message,
          action: commentObj.name,
          userName: "",
          roleName: "",
        };
      });
      //response = response.data.comments;
      return Promise.resolve(comments);
    } catch (error) {
      console.log("error", error);
      return Promise.reject(error);
    }
  };

  /**
   * this is for submit the case history comment
   */
  submitCaseComment = async (commentObject) => {
    const { userInfo } = this.props;
    try {
      const action = await api.post(
        `${CASE_HISTORY_COMMENT}?pvt_id=${commentObject.pvtId}`,
        {
          pvt_id: commentObject.pvtId,
          name: commentObject.action
            ? commentObject.action
            : "Comment Added on case",
          created_by: commentObject.email,
        }
      );
      const actionId = action.data.id;
      const response = await api.post(SUBMIT_COMMENT, {
        message: commentObject.comment,
        action: actionId,
        bridge_case_id: commentObject.bridgeCaseId,
      });
      return Promise.resolve(response);
    } catch (error) {
      console.log("error", error);
      return Promise.reject(error);
    }
  };

  refresh = () => {
    const { statusType } = this.state;
    this.getBucket();
    this.getCases(statusType);
  };
  getCases = async (type, pageNumber = 1) => {
    this.setState({ loading: true });
    let result = [];
    try {
      const response = await api.get(
        `${HCL_BUCKET_CASES}?status=${type}&page=${pageNumber}`
      );
      console.log("response ::: ", response);
      const bucketCases = response.data.results;
      this.setState({
        bucketCases: bucketCases,
        loading: false,
        statusType: type,
        page: pageNumber,
        previous: response.data.previous,
        next: response.data.next,
      });
    } catch (e) {
      console.log(e);
    }
  };

  getPaginationCase = (pageNumber) => {
    const { statusType } = this.state;
    this.getCases(statusType, pageNumber);
  };

  getBucket = async () => {
    let {
      bucket,
      bucketName,
      countArrExtracted,
      countArrInsuff,
      countArrOthers,
      statusType,
    } = this.state;
    try {
      const response = await api.get(HCL_BUCKET_COUNT);
      console.log("res", response);
      bucket = {};
      response.data.map((bucketData) => {
        bucket[bucketData.id] = bucketData.count;
        bucketName[bucketData.id] = bucketData.name;
      });

      countArrExtracted = [
        {
          count: bucket[constants.caseStatus.ASSIGNED],
          countTitle: bucketName[constants.caseStatus.ASSIGNED],
          callback: this.getCases,
          status: constants.caseStatus.ASSIGNED,
          selectedStatus: statusType,
        },
      ];
      countArrInsuff = [
        {
          count: bucket[constants.caseStatus.INSUFFICIENT],
          countTitle: bucketName[constants.caseStatus.INSUFFICIENT],
          callback: this.getCases,
          status: constants.caseStatus.INSUFFICIENT,
          selectedStatus: statusType,
        },
        {
          count: bucket[constants.caseStatus.ESCALATION_RAISED],
          countTitle: bucketName[constants.caseStatus.ESCALATION_RAISED],
          callback: this.getCases,
          status: constants.caseStatus.ESCALATION_RAISED,
          selectedStatus: statusType,
        },
        {
          count: bucket[constants.caseStatus.ESCALATION_RESPONDED],
          countTitle: bucketName[constants.caseStatus.ESCALATION_RESPONDED],
          callback: this.getCases,
          status: constants.caseStatus.ESCALATION_RESPONDED,
          selectedStatus: statusType,
        },
      ];
      countArrOthers = [
        {
          count: bucket[constants.caseStatus.QC_COMPLETE],
          countTitle: bucketName[constants.caseStatus.QC_COMPLETE],
          callback: this.getCases,
          status: constants.caseStatus.QC_COMPLETE,
          selectedStatus: statusType,
        },
        {
          count: bucket[constants.caseStatus.ON_HOLD],
          countTitle: bucketName[constants.caseStatus.ON_HOLD],
          callback: this.getCases,
          status: constants.caseStatus.ON_HOLD,
          selectedStatus: statusType,
        },
      ];

      this.setState({
        bucket,
        bucketName,
        countArrExtracted,
        countArrInsuff,
        countArrOthers,
      });
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const {
      bucketCases,
      selectedPvtId,
      loading,
      bucket,
      isCommentModelOpen,
      countArrExtracted,
      countArrInsuff,
      countArrOthers,
      statusType,
      page,
      previous,
      next,
    } = this.state;
    return (
      <div className="container-fluid qcdashboard-container h-100">
        <LoadingScreen isLoading={loading}></LoadingScreen>
        <main>
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <h2>Dashboard</h2>
              </div>
            </div>
            <div className="cards-container">
              <CasesCountCard
                title="Extracted"
                countArr={countArrExtracted}
                statusType={statusType}
              />
              <CasesCountCard
                title="Insufficiencies/Escalations"
                countArr={countArrInsuff}
                statusType={statusType}
              />
              <CasesCountCard
                title="Others"
                countArr={countArrOthers}
                statusType={statusType}
              />
            </div>

            <div className="row">
              <div className="col">
                <h5 className="mt-2">CONSOLIDATED LIST</h5>
              </div>
              {statusType === constants.caseStatus.ASSIGNED ? (
                <div className="col text-right">
                  <button
                    className="btn btn-primary mr-2"
                    onClick={() => this.fetchCases()}
                  >
                    Fetch Case
                  </button>
                </div>
              ) : null}
            </div>

            {bucketCases.length === 0 ? (
              <div className="no-data"> NO data found</div>
            ) : (
              <div className="row mt-3">
                <div className="col-md-12">
                  <div>
                    <TableWrapper column={this.column} data={bucketCases} />
                  </div>
                </div>
              </div>
            )}
            {bucketCases.length ? (
              <div className="pagination-block">
                <Pagination
                  page={page}
                  previous={previous}
                  next={next}
                  callback={this.getPaginationCase}
                />
              </div>
            ) : null}
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.login.userInfo,
});
const mapDispathToProps = (dispatch) => ({
  resume: (data) => dispatch(resume(data)),
});
export default connect(mapStateToProps, mapDispathToProps)(QCDashboard);
