import React, { Component } from "react";
import FileInput from "../../fileInput/fileInput";
import { modalManager } from "./../../../utils/modalutils";
import { getFileType } from "./../../../utils/commonutils";
import Selectbox from "./../../../components/Selectbox/selectbox";
import "./csisupload.scss";

export default class Csisupload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedobj: null,
      fileName: "",
      formList: {},
    };
  }
  // getFileType = (url) => {
  //   let type = "";

  //   if (url) {
  //     for (let i = url.length - 1; i >= 0; i--) {
  //       if (url[i] === ".") {
  //         break;
  //       }
  //       type = type.concat(url[i]);
  //     }

  //     type = type.split("").reverse().join("").toLowerCase();

  //     return type;
  //   }
  // };

  onDocumentSelected = (file) => {
    let { selectedobj, formList } = this.state;
    const type = getFileType(file.name);
    if (type === "xlsx") {
      formList[selectedobj.type] = file;
      this.setState({ formList });
    } else {
      alert("Please upload xlsx file type");
    }
  };

  submit = () => {
    const { selectedobj, fileName, formList } = this.state;
    let formData = new FormData();
    Object.keys(formList).map((key) => {
      formData.append(key, formList[key], formList[key].name);
    });
    console.log(formList, "ffff");
    this.props.callback(selectedobj, formData);
  };

  render() {
    const { selectedobj, fileName, formList } = this.state;
    const { csisArray = [], fileCount } = this.props;
    return (
      <div className="upload-csis-block">
        <div className="row csis-type">
          <div className="col-md-10">
            <Selectbox
              option={csisArray}
              defaultValue={selectedobj}
              callback={(obj) => this.setState({ selectedobj: obj })}
              menuHeight={100}
            />
          </div>
          {selectedobj && selectedobj.type ? (
            <div className="col-md-2 plus-icon">
              <FileInput onChange={(file) => this.onDocumentSelected(file)} />
            </div>
          ) : null}
        </div>
        <div className="row csis-file-input">
          {Object.keys(formList).map((key) => {
            return (
              <div
                style={{ fontSize: "11px", marginLeft: "10px", color: "blue" }}
              >
                <span style={{ color: "grey", fontWeight: "bold" }}>
                  {key}:
                </span>
                {formList[key].name}
              </div>
            );
          })}
          {/* {fileName} */}
        </div>
        <div className="row">
          <div class="col-md-6">
            <button
              class="btn btn-danger btn-sm float-left"
              onClick={() => {
                modalManager.close({});
              }}
            >
              Close
            </button>
          </div>
          <div class="col-md-6">
            <button
              class="btn btn-primary btn-sm float-right"
              onClick={() => {
                this.submit();
              }}
              disabled={!(Object.keys(formList).length === fileCount)}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }
}
