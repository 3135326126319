import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  // loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import { login, update, logout } from "./state/login.actions";
import { LOGIN, LOGIN_TCS } from "./../../apiurl";
import loginIcon from "../../assets/images/login.png";
import api from "./../../apiBaseConfig";
import "./login.scss";
import Selectbox from "../../components/Selectbox/selectbox";
class Login extends Component {
  state = {
    isAuthenticated: false,
    username: "",
    password: "",
    touched: { username: false, password: false },
    loading: false,
    invalidCredentials: false,
    user: "Authbridge",
  };
  componentDidMount = () => {
    this.props.logout();
    // loadCaptchaEnginge(6);
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    const { username, password, user } = this.state;
    const userLogin = user === "Authbridge" ? LOGIN : LOGIN_TCS;

    // let user_captcha = document.getElementById("user_captcha_input").value;

    // if (validateCaptcha(user_captcha) === true) {
      if (username && password) {
        this.setState({ loading: true, invalidCredentials: false });
        try {
          const response = await api.post(userLogin, {
            email: username,
            password: password,
          });
          console.log(response, "resp");
          this.props.update({ token: response.data.token });
          this.props.history.push("/clientrole");
        } catch (e) {
          this.setState({ loading: false, invalidCredentials: true });
        }

        // this.props.login({ username, password });
      }

      // loadCaptchaEnginge(6);
      // document.getElementById("user_captcha_input").value = "";
    } 
    // else {
    //   alert("Captcha Does Not Match");
    //   document.getElementById("user_captcha_input").value = "";
    // }
  // };

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.userInfo !== this.props.userInfo) {
  //     if (!this.props.userInfo.accessToken) {
  //       this.setState({ invalidCredentials: true, loading: false });
  //     }
  //   }
  // }

  render() {
    const { userInfo } = this.props;
    const { invalidCredentials, user } = this.state;
    // if (userInfo.accessToken) {
    //   switch (userInfo.roleId) {
    //     case 71:
    //       return <Redirect to="/qcdashboard" />;
    //     case 72:
    //       return <Redirect to="/dashboard" />;
    //     case 73:
    //       return <Redirect to="/cseResourceDashboard" />;
    //     case 74:
    //       return <Redirect to="/cseSupervisorDashboard" />;

    //     default:
    //       return null;
    //   }
    // }
    // if (userInfo.accessToken && userInfo.roleId === 71) {
    //   return <Redirect to="/qcdashboard" />;
    // }
    // if (userInfo.accessToken && userInfo.roleId === 72) {
    //   return <Redirect to="/dashboard" />;
    // }

    return (
      <div className="wrapper fadeInDown background-img">
        <div id="formContent">
          {invalidCredentials && (
            <div className="alert alert-danger">
              username or password not correct
            </div>
          )}
          <div className="fadeIn first">
            <img src={loginIcon} id="icon" alt="User Icon" />
          </div>
          <form>
            <div className="form-group dropdown">
              <label className="text-muted text-uppercase label-header">
                Select User
              </label>

              <Selectbox
                defaultValue={{
                  value: user,
                  label: user,
                }}
                option={[
                  { value: "Authbridge", label: "Authbridge" },
                  { value: "Footprints", label: "Footprints" },
                ]}
                callback={(selectedOption) => {
                  let { user } = this.state;
                  user = selectedOption.value;
                  this.setState({
                    user,
                  });
                }}
              />
            </div>
            <div className="form-group">
              <input
                placeholder="username"
                type="text"
                className="fadeIn second"
                onChange={(event) =>
                  this.setState({ username: event.target.value })
                }
                onBlur={() =>
                  this.setState({
                    touched: { ...this.state.touched, username: true },
                  })
                }
              />
              {!this.state.username && this.state.touched.username && (
                <div className="invalid-feedback d-block">
                  Username is required
                </div>
              )}
            </div>
            <div className="form-group">
              <input
                placeholder="password"
                type="password"
                className="fadeIn third"
                onChange={(event) =>
                  this.setState({ password: event.target.value })
                }
                onBlur={() =>
                  this.setState({
                    touched: { ...this.state.touched, password: true },
                  })
                }
              />
              {!this.state.password && this.state.touched.password && (
                <div className="invalid-feedback d-block">
                  Password is required
                </div>
              )}
            </div>

            {/* 
                  login captcha
                */}
            {/* <div className="form-group">
              <div className="col mt-3">
                <LoadCanvasTemplate />
              </div>

              <div className="col mt-3">
                <div>
                  <input
                    placeholder="Enter Captcha Value"
                    id="user_captcha_input"
                    name="user_captcha_input"
                    type="text"
                  ></input>
                </div>
              </div>
            </div> */}

            {/* 
                end
                */}

            <div className="form-group">
              <button
                className="btn btn-primary fadeIn fourth"
                onClick={(e) => this.handleSubmit(e)}
              >
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                Log In
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.login.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  login: (payload) => dispatch(login(payload)),
  update: (payload) => dispatch(update(payload)),
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
