import { constants } from "./../constants";
export const getCommentTime = (date, isCheck) => {
  if (date && !isCheck) {
    const dateArr = date.split(" ");
    dateArr[0] = reverseHyphenFormatDate(dateArr[0]);
    date = dateArr[0] + " " + dateArr[1];
    console.log("date2", date);

    date = new Date(date);
  } else if (date && isCheck) {
    date = new Date(date);
  } else {
    date = new Date();
  }

  const currentTime = date;
  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const dateString =
    days[currentTime.getDay()] +
    ", " +
    currentTime.getDate() +
    "/" +
    (currentTime.getMonth() + 1) +
    "/" +
    currentTime.getFullYear() +
    " " +
    currentTime.getHours() +
    ":" +
    currentTime.getMinutes() +
    ":" +
    currentTime.getSeconds() +
    " ";
  return dateString;
};

export const formatTime = (timeStamp) => {
  const time = Math.floor(timeStamp / 60);
  const hour = parseInt(time / 60);
  const minutes = time % 60;
  return (
    "0" +
    hour +
    ":" +
    ("00" + minutes).slice(-2) +
    ":" +
    ("00" + Math.floor(timeStamp - time * 60)).slice(-2)
  );
};

export const getRealData = (clientData, candidateData) => {
  let obj = {
    status: clientData.caseStatus,
    arsNumber: clientData.arsNo,
    arsGenerateDate: clientData.arsCreatedAt,
    caseId: clientData.caseId,
    client: {
      clientName: "HCL Technology Pvt Ltd.",
      packageId: clientData.packageId,
      packageName: clientData.packageNameQc,
      recievedDate: clientData.caseReceivedOn,
    },
    candidateInfo: {
      candidateName: candidateData.firstName + candidateData.lastName,
      fatherName: candidateData.fathersName,
      mobileNumber: candidateData.mobile,
      emailId: candidateData.email,
      dob: candidateData.dob,
    },
    nid: {
      panNumber: candidateData.panNo,
      aadharCard: "",
      drivingLicence: candidateData.drivingLicenseNo,
      passportNumber: candidateData.passportNo,
    },
    clientSpecificField: {
      bgvId1: clientData.bgvId,
      verificationType: clientData.verificationType,
      employeeId: clientData.empId,
      candidateId: clientData.candidateId,
      bgvId2: clientData.bgvId2,
      dateOfReinitiation: candidateData.dateOfReinitiation,
    },
  };

  let array = [];
  array.push(obj);
  return array;
};

export const getDuplicityData = (clientData, candidateData = {}) => {
  let obj = {
    aadhar_card: clientData.aadhaar,
    ars_generate_date: "unknown",
    ars_number: clientData.bridge_ars_no,
    bgv_id1: clientData.cid,
    bgv_id2: "unknown",
    candidate_id: "unknown",
    candidate_name: clientData.first_name + clientData.last_name,
    case_id: clientData.bridge_case_id,
    client_name: "accenture",
    date_of_reinitiation: "unknown",
    dob: clientData.date_of_birth,
    driving_licence: clientData.driving_license,
    email_id: clientData.email_address,
    employee_id: "unknown",
    father_name: clientData.fathers_name,
    mobile_number: clientData.mobile_number,
    package_name: clientData.package_name,
    pan_number: clientData.pan,
    passport_number: clientData.passport,
    received_date: "unknown",
    status: clientData.status,
    verification_type: "unknown",
    voter_id: "",
  };

  let array = [];
  array.push(obj);
  return array;
};

export const getTcsDuplicityData = (clientData, candidateData = {}) => {
  let obj = {
    aadhar_card: clientData.aadhaar,
    account_name: clientData.account_name,
    applicant_id: clientData.applicant_id,
    ars_generate_date: clientData.ars_created_at,
    ars_number: clientData.bridge_ars_no,
    candidate_id: clientData.employee_id,
    candidate_name: clientData.candidate_name,
    case_id: clientData.bridge_case_id,
    client_name: "INFOSYS Ltd.",
    dob: clientData.date_of_birth,
    driving_licence: "",
    email_id: clientData.email_address,
    employee_id: clientData.employee_id,
    father_name: clientData.fathers_name,
    mobile_number: clientData.mobile_number,
    package_name: clientData.package_name,
    pan_number: clientData.pan,
    passport_number: "",
    received_date: clientData.case_received_date,
    recruitment_branch: clientData.recruitment_branch,
    request_id: clientData.request_id,
    status: clientData.status,
    voter_id: "",
  };

  let array = [];
  array.push(obj);
  return array;
};

export const getHclDuplicityData = (clientData, candidateData = {}) => {
  let obj = {
    aadhar_card: clientData.aadhaar,
    ars_generate_date: clientData.ars_created_at,
    ars_number: clientData.bridge_ars_no,
    bgv_id: clientData.bgv_id,
    bgv_id1: "unknown",
    candidate_id: clientData.candidate_id,
    candidate_name: clientData.first_name + clientData.last_name,
    case_id: clientData.bridge_case_id,
    client_name: "hcl",
    date_of_reinitiation: clientData.date_of_initiation,
    dob: clientData.date_of_birth,
    driving_licence: clientData.driving_license,
    email_id: clientData.email_address,
    employee_id: clientData.employee_id,
    father_name: clientData.fathers_name,
    mobile_number: clientData.mobile_number,
    package_name: clientData.package_name,
    pan_number: clientData.pan,
    passport_number: clientData.passport,
    received_date: "unknown",
    status: clientData.status,
    verification_type: "unknown",
    voter_id: "",
  };

  let array = [];
  array.push(obj);
  return array;
};

export const getCtsDuplicityData = (clientData, candidateData = {}) => {
  let obj = {
    aadhar_card: clientData.aadhaar,
    ars_generate_date: clientData.ars_created_at,
    ars_number: clientData.bridge_ars_no,
    bgv_id: clientData.bgv_id,
    bgv_id1: "unknown",
    candidate_id: clientData.candidate_id,
    candidate_name: clientData.candidate_name,
    case_id: clientData.bridge_case_id,
    client_name: "Cognizant",
    date_of_reinitiation: clientData.date_of_initiation,
    dob: clientData.date_of_birth,
    driving_licence: clientData.driving_license,
    email_id: clientData.email_address,
    employee_id: clientData.employee_id,
    father_name: clientData.fathers_name,
    mobile_number: clientData.mobile_number,
    package_name: clientData.package_name,
    pan_number: clientData.pan,
    passport_number: clientData.passport,
    received_date: "unknown",
    status: clientData.status,
    verification_type: "unknown",
    voter_id: "",
    request_id: clientData.request_id,
  };

  let array = [];
  array.push(obj);
  return array;
};

export const uniqueId = () => {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
};

//From May,1994 To Till Date
export const getFormatDate = (date) => {
  if (!date) {
    return [null, null];
  }
  console.log({ date });
  if (!date.includes("From")) {
    date = "From ".concat(date);
    console.log("modified date", date);
  }
  let dateArray = [];

  const d = date.split(" ");
  console.log("split date", d);
  const fromDate = d[1].split(",");
  let toDate = [];
  var today = "";
  const c = (d[d.length - 1] + d[d.length - 2]).toLowerCase();
  console.log({ c });
  if (c === "datetill" || c === "date,till") {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    var yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;
  } else {
    toDate = d[d.length - 1].split(",");
  }
  dateArray[0] = getDate(fromDate, "from");
  console.log({ toDate });

  if (toDate.length === 0) {
    dateArray[1] = today;
  } else {
    dateArray[1] = getDate(toDate, "to");
  }

  console.log("return date array");
  return dateArray;
};

export const getDate = (date, type) => {
  if (!date) {
    return null;
  }
  console.log({ date });
  const obj = {
    jan: { month: "01", day: "31" },
    feb: { month: "02", day: date[1] % 4 === 0 ? "29" : "28" },
    mar: { month: "03", day: "31" },
    apr: { month: "04", day: "30" },
    may: { month: "05", day: "31" },
    jun: { month: "06", day: "30" },
    jul: { month: "07", day: "31" },
    aug: { month: "08", day: "31" },
    sep: { month: "09", day: "30" },
    oct: { month: "10", day: "31" },
    nov: { month: "11", day: "30" },
    dec: { month: "12", day: "31" },
  };
  const m = date[0].substr(0, 3).toLowerCase();
  if (!obj[m]) {
    return null;
  }
  console.log(obj[m], { m });
  const string =
    date[1] +
    "-" +
    obj[m]["month"] +
    "-" +
    (type === "from" ? "01" : obj[m]["day"]);
  console.log("return date", string);
  return string;
};

export const monthNameFromNumber = (number) => {
  const monthName = {
    "01": "Jan",
    1: "Jan",
    "02": "Feb",
    2: "Feb",
    "03": "Mar",
    3: "Mar",
    "04": "Apr",
    4: "Apr",
    "05": "May",
    5: "May",
    "06": "Jun",
    6: "Jun",
    "07": "Jul",
    7: "Jul",
    "08": "Aug",
    8: "Aug",
    "09": "Sep",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  return monthName[number];
};

export const fullMonthNameFromNumber = (number) => {
  const monthName = {
    "01": "January",
    1: "January",
    "02": "February",
    2: "February",
    "03": "March",
    3: "March",
    "04": "April",
    4: "April",
    "05": "May",
    5: "May",
    "06": "June",
    6: "June",
    "07": "July",
    7: "July",
    "08": "August",
    8: "August",
    "09": "September",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };

  return monthName[number];
};

export const numberFormHalfMonthName = (name) => {
  const monthNum = {
    "Jan" : "01",
    "Feb" : "02",
    "Mar" : "03",
    "Apr" : "04",
    "May" : "05",
    "Jun" : "06",
    "Jul" : "07",
    "Aug" : "08",
    "Sep" : "09",
    "Oct" : "10",
    "Nov" : "11",
    "Dec" : "12",
  };

  return monthNum[name];
};

export const numberFromFullMonthName = (name) => {
  const monthNum = {
    "January" : "01",
    "February" : "02",
    "March" : "03",
    "April" : "04",
    "May" : "05",
    "June" : "06",
    "July" : "07",
    "August" : "08",
    "September" : "09",
    "October" : "10",
    "November" : "11",
    "December" : "12",
  };

  return monthNum[name];
};

export const monthDetailFromName = (name) => {
  const shortName = name.substr(0, 3).toLowerCase();
  const monthDetail = {
    jan: { monthNumber: "01", lastDay: "31" },
    feb: { monthNumber: "02", lastDay: "28" },
    mar: { monthNumber: "03", lastDay: "31" },
    apr: { monthNumber: "04", lastDay: "30" },
    may: { monthNumber: "05", lastDay: "31" },
    jun: { monthNumber: "06", lastDay: "30" },
    jul: { monthNumber: "07", lastDay: "31" },
    aug: { monthNumber: "08", lastDay: "31" },
    sep: { monthNumber: "09", lastDay: "30" },
    oct: { monthNumber: "10", lastDay: "31" },
    nov: { monthNumber: "11", lastDay: "30" },
    dec: { monthNumber: "12", lastDay: "31" },
  };

  return monthDetail[shortName];
};

export const isWithinTimePeriod = (date, years = 7) => {
  const diff = Math.abs(new Date(date).getYear() - new Date().getYear());

  if (diff <= 7) {
    return true;
  } else {
    return false;
  }
};
export const isWithinTimePeriodFromDoi = (date, doi, years = 7) => {
  let dateArr = date.split("-");
  let prevDoiArr = doi.split("-");
  const diff = new Date(date).getYear() - (new Date(doi).getYear() - 7);
  if (
    (diff > 0 && diff <= 7) ||
    (diff == 0 && dateArr[1] > prevDoiArr[1] ) || 
    (diff == 0 && dateArr[1] == prevDoiArr[1] && dateArr[2] >= prevDoiArr[2])
  ) {
    return true;
  } else {
    return false;
  }
};
export const isWithinTimePeriodForCts = (date, years = 5) => {
  const diff = Math.abs(new Date(date).getYear() - new Date().getYear());

  if (diff <= 5) {
    return true;
  } else {
    return false;
  }
};

export const getGapInDays = (date1, date2) => {
  const difference_ms = Math.abs(new Date(date1) - new Date(date2));
  //Get 1 day in milliseconds
  var one_day = 1000 * 60 * 60 * 24;

  return Math.round(difference_ms / one_day);
};

export const getGapInMinutes = (date1, date2) => {
  const difference_ms = Math.abs(new Date(date1) - new Date(date2));
  //Get 1 minute in milliseconds
  var one_minute = 1000 * 60;

  return Math.round(difference_ms / one_minute);
};

export const convertPassingYearToDate = (
  checkObject,
  antecedantName,
  handleCheckChange
) => {
  const periodCheck = checkObject.checkFields.find(
    (checkField) => checkField.antecedantName === antecedantName
  );
  if (!periodCheck) {
    return "";
  }
  const passingDate = periodCheck.antecedantValue;
  if (!passingDate) {
    return "";
  }
  const [monthName, year] = passingDate.split(",");
  const monthDetail = monthDetailFromName(monthName);
  const toDate = `${year}-${monthDetail.monthNumber}-${monthDetail.lastDay}`;
  if (periodCheck.antecedantValue && toDate !== checkObject.to) {
    checkObject.to = toDate;

    handleCheckChange(checkObject);
  }
  return toDate;
};

export const reverseHyphenFormatDate = (date) => {
  if (!date) {
    return "";
  }
  try {
    return date.split("-").reverse().join("-");
  } catch (error) {
    console.log("date format invalid", error);
  }
};

export const dateToReverseHyphenFormat = (date) => {
  if (!date) {
    return "";
  }
  try {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (month <= 9) {
      month = "0" + month;
    }
    if (day <= 9) {
      day = "0" + day;
    }
    return `${date.getFullYear()}-${month}-${day}`;
  } catch (error) {
    console.log("date format invalid", error);
  }
};

export const getCurrentLocalIsoTime = () => {
  var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
  return localISOTime;
};

export const genericFormatDateTime = (dateTime) => {
  if (!dateTime) {
    return "";
  }
  const date = new Date(dateTime);
  const month =
    date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
  const dateFormat = `${date.getDate()}-${month}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
  return dateFormat;
};

export const formatDate = (dateObj) => {
  const monthArray = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = new Date(dateObj);
  const month = date.getMonth();
  const day = date.getDate();
  const year = date.getFullYear();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();
  return `${day} ${monthArray[month]}, ${year}:${hour}:${minute}:${second}`;
};

export const caseCount = (count, countTitle, callback, caseStatus, status) => {
  return {
    count: count,
    countTitle: countTitle,
    callback: callback,
    status: caseStatus,
    selectedStatus: status,
  };
};

export const getDocuments = () => {
  let documentCheck = [
    {
      end_page: null,
      id: null,
      id_bridge: 32,
      bridge_name: "Application Form Pdf",
      actual_name: null,
      start_page: null,
      gcs_signed_url: null,
      document_type: null,
    },
    {
      end_page: null,
      id: null,
      id_bridge: 9,
      bridge_name: "Candidate Photograph",
      actual_name: null,
      start_page: null,
      gcs_signed_url: null,
      document_type: null,
    },
    {
      end_page: null,
      id: null,
      id_bridge: 91,
      bridge_name: "Sign-off",
      actual_name: null,
      start_page: null,
      gcs_signed_url: null,
      document_type: null,
    },
    {
      end_page: null,
      id: null,
      id_bridge: 26,
      bridge_name: "Authorized Release Note",
      actual_name: null,
      start_page: null,
      gcs_signed_url: null,
      document_type: null,
    },
    {
      end_page: null,
      id: null,
      id_bridge: 115,
      bridge_name: "Consent Form",
      actual_name: null,
      start_page: null,
      gcs_signed_url: null,
      document_type: null,
    },
    {
      end_page: null,
      id: null,
      id_bridge: 27,
      bridge_name: "Any Other Case Documents",
      actual_name: null,
      start_page: null,
      document_type: null,
      gcs_signed_url: null,
    },
  ];
  return documentCheck;
};

export const getFileType = (url) => {
  let type = "";

  if (url) {
    for (let i = url.length - 1; i >= 0; i--) {
      if (url[i] === ".") {
        break;
      }
      type = type.concat(url[i]);
    }

    type = type.split("").reverse().join("").toLowerCase();

    return type;
  }
};
export const getTimeArray = () => {
  var hours, minutes, ampm;
  let timeArray = [];
  for (var i = 0; i <= 1440; i += 30) {
    hours = Math.floor(i / 60);
    minutes = i % 60;
    if (minutes < 10) {
      minutes = "0" + minutes; // adding leading zero
    }
    timeArray.push({ label: hours + ":" + minutes + ":00" });
  }
  return timeArray;
};

export const getCheckPriority = (checkId) => {
  switch (checkId) {
    case constants.checkIds.PASSPORT_VERIFICATION_CHECK_ID:
      return 10;
    case constants.checkIds.PAN_NUMBER_VERIFICATION_CHECK_ID:
      return 20;
    case constants.checkIds.DRIVING_LICENCE_VERIFICATION_CHECK_ID:
      return 30;
    case constants.checkIds.NATIONAL_IDENTITY_CHECK_ID:
      return 40;
    case constants.checkIds.EDUCATION_W_CHECK_ID:
      return 50;
    case constants.checkIds.EDUCATION_CHECK_ID:
      return 60;
    case constants.checkIds.PREVIOUS_EMPLOYMENT_VERIFICATION_CHECK_ID:
      return 70;
    case constants.checkIds.CURRENT_EMPLOYMENT_VERIFICATION_CHECK_ID:
      return 80;
    case constants.checkIds.REFERENCE_CHECK_ID:
      return 90;
    case constants.checkIds.CURRENT_ADDRESS_VERIFICATION_CHECK_ID:
      return 100;
    case constants.checkIds.ADDRESS_VERIFICATION_CHECK_ID:
      return 110;
    case constants.checkIds.INDIA_COURT_RECORD_CHECK_THROUGH_LAW_FIRM_CHECK_ID:
      return 120;
    case constants.checkIds.PERMANENT_VERIFICATION_CHECK_ID:
      return 130;
    case constants.checkIds.FACIS_LEVEL_3_DATABASE_VERIFICATION_CHECK_ID:
      return 140;
    case constants.checkIds.GLOBAL_DATABASE_CHECK_ID:
      return 150;
    case constants.checkIds.GLOBAL_DATABASE_OFAC_CHECK_ID:
      return 160;
    case constants.checkIds.GSA_OIG_MEDICAL_DATABASE_CHECK_ID:
      return 170;
    case constants.checkIds.INDIA_COURT_RECORD_DATABASE_CHECK_ID:
      return 180;
    case constants.checkIds.INDIAN_DATABASE_CREDIT_AND_BANKRUPTCY_CHECK_ID:
      return 190;
    case constants.checkIds.INDIAN_DATABASE_VERIFICATION_CHECK_ID:
      return 200;
    case constants.checkIds.SOCIAL_MEDIA_CHECK_ID:
      return 210;
    default:
      return 500;
  }
};

export const getResponseHeader = (statusCode) => {
  const httpResponseStatusCode = new Map([
    [100, "Continue"],
    [101, "Switching Protocols"],
    [103, "Early Hints"],
    [200, "OK"],
    [201, "Created"],
    [202, "Accepted"],
    [203, "Non-Authoritative Information"],
    [204, "No Content"],
    [205, "Reset Content"],
    [206, "Partial Content"],
    [300, "Multiple Choices"],
    [301, "Moved Permanently"],
    [302, "Found"],
    [303, "See Other"],
    [304, "Not Modified"],
    [307, "Temporary Redirect"],
    [308, "Permanent Redirect"],
    [400, "Bad Request"],
    [401, "Unauthorized"],
    [402, "Payment Required"],
    [403, "Forbidden"],
    [404, "Not Found"],
    [405, "Method Not Allowed"],
    [406, "Not Acceptable"],
    [407, "Proxy Authentication Required"],
    [408, "Request Timeout"],
    [409, "Conflict"],
    [410, "Gone"],
    [411, "Length Required"],
    [412, "Precondition Failed"],
    [413, "Payload Too Large"],
    [414, "URI Too Long"],
    [415, "Unsupported Media Type"],
    [416, "Range Not Satisfiable"],
    [417, "Expectation Failed"],
    [418, "I'm a teapot"],
    [422, "Unprocessable Entity"],
    [425, "Too Early"],
    [426, "Upgrade Required"],
    [428, "Precondition Required"],
    [429, "Too Many Requests"],
    [431, "Request Header Fields Too Large"],
    [451, "Unavailable For Legal Reasons"],
    [500, "Internal Server Error"],
    [501, "Not Implemented"],
    [502, "Bad Gateway"],
    [503, "Service Unavailable"],
    [504, "Gateway Timeout"],
    [505, "HTTP Version Not Supported"],
    [506, "Variant Also Negotiates"],
    [507, "Insufficient Storage"],
    [508, "Loop Detected"],
    [510, "Not Extended"],
    [511, "Network Authentication Required"],
  ]);
  const headerText = httpResponseStatusCode.get(statusCode);
  return headerText;
};

export const getOverlapDate = (normalCheck, submitCheck) => {
  if (normalCheck.date_from && normalCheck.date_to) {
    if (
      submitCheck.date_to < normalCheck.date_from ||
      submitCheck.date_from > normalCheck.date_to
    ) {
      return null;
    }

    if (
      getGapInDays(submitCheck.date_from, normalCheck.date_from) >= 31 ||
      getGapInDays(submitCheck.date_from, normalCheck.date_to) >= 31 ||
      getGapInDays(submitCheck.date_to, normalCheck.date_from) >= 31 ||
      getGapInDays(submitCheck.date_to, normalCheck.date_to) >= 31
    ) {
      return { checkName: normalCheck.check_name };
    }
  }
  return null;
};

export const getEncodeError = (checkObj) => {
  let isEncodeError = false;
  let s = "";
  checkObj.check_fields.forEach((element) => {
    if (element.antecedent_value && element.antecedent_value.includes("\\u")) {
      isEncodeError = true;
      s = s + element.antecedent_name + " , ";
    }
  });
  return {
    isEncodeError: isEncodeError,
    s: s,
  };
};
