import React, { Component } from "react";
import userImage from "../../assets/images/user.jpg";
import { getCommentTime, formatDate } from "./../../utils/commonutils";
import "./caseHistoryComment.scss";

export default class CaseHistoryComment extends Component {
  constructor(props) {
    super(props);
    this.state = { newComment: "", newCommentTouched: false };
  }

  render() {
    const { commentObject = {} } = this.props;
    const { newComment, newCommentTouched } = this.state;
    return (
      <div className="row">
        <div className="col-md-2">
          <div align="center">
            <img
              src={userImage}
              className="rounded-circle mb-1"
              alt=""
              width="20"
            ></img>
          </div>
          <div align="center" className="text-muted text-uppercase h6">
            <u>{commentObject.userName}</u>
            <br />
            <a href="javascript:void()" className="small">
              {commentObject.roleName}
            </a>
          </div>
        </div>
        {!commentObject.isManualComment && (
          <div className="col-md-3">
            <div
              className="border border-default rounded bg-white p-3 text-muted position-relative verticle-line wrap-text"
              style={{ fontSize: "10px" }}
            >
              {commentObject.action}
            </div>
          </div>
        )}
        {!commentObject.isManualComment && (
          <div className="col-md-7">
            <div className="border border-default rounded bg-white p-3 text-muted position-relative verticle-line wrap-text">
              {commentObject.comment}
            </div>
            <small className="text-primary pl-3">
              Commented on{" "}
              {formatDate(commentObject.createdAt, this.props.isCheck)}
            </small>
          </div>
        )}

        {commentObject.isManualComment && (
          <div className="col-md-8">
            <form className="form-group">
              <textarea
                className="form-control col-xs-12"
                name="tempComment"
                onChange={(e) => this.setState({ newComment: e.target.value })}
                onBlur={() => this.setState({ newCommentTouched: true })}
                required
              ></textarea>
              {newCommentTouched && !newComment && (
                <div className="text-danger">Comment is required</div>
              )}
              <button
                className="btn btn-primary mb-2 rounded btn-sm"
                disabled={!newComment}
                onClick={(e) => this.props.handleSubmit(e, newComment)}
              >
                <span className="text-uppercase">&nbsp;Submit</span>
              </button>
            </form>
          </div>
        )}
      </div>
    );
  }
}
